import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getRequestAuthHeader } from 'app/js/generalFunctions'
import { ERROR_MESSAGE_OBJ } from 'app/js/globalConsts'
import { showMessage } from 'app/store/fuse/messageSlice'
import axios from 'axios'
import { getHistoryData } from './utils/utils'
import dayjs from 'dayjs'

// Constants
export const RECORD_HISTORY_URL = process.env.REACT_APP_URL_DJANGO + '/api/get_record_history'

// Initial State
const INITIAL_STATE = {
  recordHistory: [],
  currentRecordId: null,
  recordHistoryModalVisibility: false,
  loading: false,
}

// Helper function to format updated values
const formatUpdatedValues = (updated) => {
  const formattedValues = {}

  for (const key in updated) {
    if (Array.isArray(updated[key])) {
      formattedValues[key] = updated[key].join(', ')
    } else if (updated[key] && typeof updated[key] === 'object') {
      formattedValues[key] = {}

      if (Array.isArray(updated[key].added)) {
        formattedValues[key].added = updated[key].added
          .map((user) => `${user.customUser} (${user.is_manager ? 'Manager' : 'Member'})`)
          .join(', ')
      } else {
        formattedValues[key].added = updated[key].added || 'No changes'
      }

      if (Array.isArray(updated[key].removed)) {
        formattedValues[key].removed = updated[key].removed
          .map((user) => `${user.customUser} (${user.is_manager ? 'Manager' : 'Member'})`)
          .join(', ')
      } else {
        formattedValues[key].removed = updated[key].removed || 'No changes'
      }
    } else {
      formattedValues[key] = updated[key]
    }
  }

  return formattedValues
}

// Async Thunk
export const getRecordHistoryThunk = createAsyncThunk(
  'recordHistory/getRecordHistory',
  async ({ modelName, recordId }, { dispatch, rejectWithValue }) => {
    try {
      const queryString = new URLSearchParams({
        data: JSON.stringify({ modelName, recordId }),
      }).toString()

      const response = await axios({
        method: 'GET',
        url: `${RECORD_HISTORY_URL}?${queryString}`,
        headers: getRequestAuthHeader(),
      })

      if (response.status !== 200) throw new Error('Error getting record history')
      return response.data
    } catch (error) {
      console.error('Error getting record history:', error)
      dispatch(showMessage(ERROR_MESSAGE_OBJ))
      return rejectWithValue(error.message)
    }
  },
)

// Slice
export const recordHistorySlice = createSlice({
  name: 'recordHistory',
  initialState: INITIAL_STATE,
  reducers: {
    resetRecordHistory: (state) => {
      state.recordHistory = []
      state.recordHistoryModalVisibility = false
    },
    setCurrentRecordId: (state, { payload: recordId }) => {
      state.currentRecordId = recordId
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecordHistoryThunk.pending, (state) => {
        state.recordHistoryModalVisibility = true
        state.loading = true
      })
      .addCase(getRecordHistoryThunk.fulfilled, (state, { payload: recordHistory }) => {
        if (!recordHistory.length) {
          state.recordHistory = []
          state.loading = false
        } else {
          const recordHistoryFormat = recordHistory.map((recordHistoryData) => {
            let { timestamp, action, changes } = recordHistoryData
            timestamp = dayjs(timestamp).format('YYYY-MM-DDTHH:mm:ss').split('T').join(' ')

            // Handle the values formatting correctly
            const values =
              action === 'update'
                ? formatUpdatedValues(changes.updated)
                : getHistoryData(changes.values)

            action = `${action.charAt().toUpperCase()}${action.substring(1)}`

            return { ...recordHistoryData, timestamp, values, action }
          })

          state.recordHistory = recordHistoryFormat
          state.recordHistoryModalVisibility = true
          state.loading = false
        }
      })
      .addCase(getRecordHistoryThunk.rejected, (state) => {
        state.recordHistory = []
        state.recordHistoryModalVisibility = false
        state.loading = false
      })
  },
})

// Export Actions
export const { resetRecordHistory, setCurrentRecordId } = recordHistorySlice.actions

// Export Reducer
export default recordHistorySlice.reducer
