import { useEffect, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import {
  cambiarVisibilidadModalInsertarRyOAction,
  insertarRyOModalInsertarAction,
  updateRyOAction,
  mostrarRyOAPIAction,
  reevaluateRyOAction,
  cambiarValorSeleccionRyOAction,
  saveRyOIdAction,
  setMyRyoOAction,
  setNotMyRyOsAction,
  setMyNoRevRyOs,
  setRyOStructure
} from '../../store/RyO/actions.js'
import {
  mostrarProcessAPIAction,
} from '../../../../Managment/Process/store/actions'
import {
  mostrarSubProcessAPIAction,
} from '../../../../Managment/Process/store/subProcessStore/actions'
import {
  mostrarCategoryAPIAction,
  mostrarTiposUnicosAPIAction
} from '../../../../Managment/Category/store/actions'
import {
  mostrarMisionNoDepartamentosAPIAction,
} from '../../../../Gestion/Mision/store/actions.js'
import {
  mostrarRulesAPIAction,
} from '../../../../Managment/Rules/store/actions'
import {
  getSubMisionNoDepartamentalesAPIAction
} from '../../../../Gestion/SubMision/store/actions.js'
import {
  insertarLogRiskAPIAction
} from '../../../../Managment/LogRisk/store/actions.js'
import {
  insertarNewNotificationAPIAction,
} from '../../../PeopleManagement/store/actions.js'
import { useGetPermissions } from "../../../../../app/js/customHooks/PermissionsHooks.js"
import axios from 'axios'
import { getCookie, getIdsFromArr } from 'app/js/generalFunctions'
import { fetchAllRyOActions, updateFmeaReviewDate } from '../../features/actionPlan/ryoActionPlanThunks.js'
import { RyOActions } from '../../features/actionPlan/ryoActionPlanSlice.js'

// * Functions for custom hooks
// ? Messages
function usePostMessage() {
  const dispatch = useDispatch()
  const postMessage = ({ message }) => {
    dispatch(
      showMessage({
        message: message,
        variant: "error"
      }))
  }
  return { postMessage }
}

// ? Get Anfe id
function useGetAnfe() {
  const anfeListAPI = useSelector(state => state.fuse.anfeComponente.anfeListAPI)
  const getidAnfe = missionSelected => {
    if (!missionSelected) return
    const RyOAnfe = anfeListAPI.find(anfe => anfe.mision === missionSelected)
    const idAnfe = RyOAnfe?.id
    return { RyOAnfe, idAnfe }
  }
  return { getidAnfe }
}

// ? Insert log
function useCreateLog() {
  const dispatch = useDispatch()
  const personLogin = useSelector(state => state.fuse.userComponente.person)
  const RyOFilaSeleccionada = useSelector(state => state.fuse.RyOComponente.filaSeleccionadaGrid)
  const createLog = ({ action, descripcion }) => {
    dispatch(insertarLogRiskAPIAction({
      persona: personLogin.id,
      fecha_accion: new Date().toISOString().split("T")[0],
      hora_accion: new Date().toLocaleTimeString(),
      accion: action,
      ro_relacionado: (typeof RyOFilaSeleccionada === 'object' ? RyOFilaSeleccionada.id : RyOFilaSeleccionada),
      descripcion: `${descripcion} ${RyOFilaSeleccionada}`
    }))
  }
  return { createLog }
}

// ? Notification for member RyO edited
function useNotificationForMembers() {
  const dispatch = useDispatch()
  const personLogin = useSelector(state => state.fuse.userComponente.person)
  const idRyO = useSelector(state => state.fuse.RyOComponente.filaSeleccionadaGrid)

  const notificacionForMembers = ({ RyOAnfe, text }) => {

    for (let member in RyOAnfe['member']) {
      const notificationObj = {
        origen_notification_id: personLogin.id,
        destino_notification_id: RyOAnfe['member'][member],
        fecha: new Date().toISOString().split("T")[0],
        observations: `User ${personLogin.first_name} ${personLogin.last_name} has ${text} the R&O:  ${idRyO}.`,
        typeNotification: "informacion",
        active: true
      }
      notificationObj.observations = !text ? `The R&O with id ${RyOAnfe.id}, is pending to be re-evaluated.` : notificationObj.observations
      // dispatch(insertarNewNotificationAPIAction(notificationObj))
    }
  }
  return { notificacionForMembers }
}

// * Custom Hooks
// ? Get RyOs
export function useGetRyOs() {
  const dispatch = useDispatch()
  const mostrarRyOAPI = () => dispatch(mostrarRyOAPIAction())

  const RyOListAPI = useSelector((state) => state.fuse.RyOComponente.RyOListAPI)
  return { RyOListAPI, mostrarRyOAPI }
}

// ? Get my RyOs
export const useGetMyRyOs = () => {
  const dispatch = useDispatch()
  const RyOList = useSelector((state) => state.fuse.RyOComponente.RyOListAPI)
  const { myAnfesList, notMyAnfesList, anfesICanSee } = useSelector(
    (state) => state.fuse.anfeComponente,
  )

  const anfesICanSeeIds = getIdsFromArr(anfesICanSee)
  const myAnfesListIds = getIdsFromArr(myAnfesList)
  const notMyAnfesListIds = getIdsFromArr(notMyAnfesList)

  useEffect(() => {
    if (!RyOList.length || !anfesICanSee.length) return

    // Ç 1º definimos los que podemos ver, los nuestros y los no nuestros
    // Ç Podemos ver => Si el anfe al que pertenecen lo podemos ver (pertenece a nuestra estructura o no pero tenemos permiso All)
    // Ç Nuestros => El anfe pertenece a nuestra estructura
    // Ç No nuestros => El anfe no pertenece a nuestra estructura

    let RyOStructure = RyOList.reduce(
      (acc, ryo) => {
        const { id_risk_management: RyOAnfe, padreRiskOpportunity } = ryo

        if (padreRiskOpportunity)
          acc.revsRyOIds = [...acc.revsRyOIds, parseInt(padreRiskOpportunity)]
        if (anfesICanSeeIds.includes(RyOAnfe)) acc.RyOsICanSee = [...acc.RyOsICanSee, ryo]

        if (myAnfesListIds.includes(RyOAnfe)) acc.myRyOsList = [...acc.myRyOsList, ryo]
        else if (notMyAnfesListIds.includes(RyOAnfe)) acc.notMyRyOList = [...acc.notMyRyOList, ryo]
        return acc
      },
      { RyOsICanSee: [], myRyOsList: [], notMyRyOList: [], revsRyOIds: [] },
    )

    const { RyOsICanSee, myRyOsList, notMyRyOList, revsRyOIds } = RyOStructure
    const canSeeRevStructure = RyOsICanSee.reduce(
      (acc, ryo) => {
        const { id: idRyO } = ryo
        if (revsRyOIds.includes(idRyO)) acc.revRyOICanSee = [...acc.revRyOICanSee, ryo]
        else acc.noRevRyOICanSee = [...acc.noRevRyOICanSee, ryo]
        return acc
      },
      { noRevRyOICanSee: [], revRyOICanSee: [] },
    )

    const myRyOsStructure = myRyOsList.reduce(
      (acc, ryo) => {
        const { id: idRyO } = ryo
        if (revsRyOIds.includes(idRyO)) acc.myRevRyO = [...acc.myRevRyO, ryo]
        else acc.myNoRevRyO = [...acc.myNoRevRyO, ryo]
        return acc
      },
      { myRevRyO: [], myNoRevRyO: [] },
    )

    const notMyRyOStructure = notMyRyOList.reduce(
      (acc, ryo) => {
        const { id: idRyO } = ryo
        if (revsRyOIds.includes(idRyO)) acc.notMyRevRyO = [...acc.notMyRevRyO, ryo]
        else acc.notMyNoRevRyO = [...acc.notMyNoRevRyO, ryo]
        return acc
      },
      { notMyRevRyO: [], notMyNoRevRyO: [] },
    )

    const { noRevRyOICanSee, revRyOICanSee } = canSeeRevStructure
    const { myRevRyO, myNoRevRyO } = myRyOsStructure
    const { notMyRevRyO, notMyNoRevRyO } = notMyRyOStructure

    RyOStructure = {
      ...RyOStructure,
      noRevRyOICanSee,
      revRyOICanSee,
      myRevRyO,
      myNoRevRyO,
      notMyRevRyO,
      notMyNoRevRyO,
    }

    // console.log("🚀 ~ useEffect ~ RyOStructure:", RyOStructure)

    dispatch(setRyOStructure(RyOStructure))
  }, [RyOList])
}

// ? Edit / Create mode control
export function useIsEditMode() {
  const { RyOListAPI } = useGetRyOs()

  const isEditMode = (visibilidadModalInsertarRO, modoRyO, RyOFilaSeleccionada) => {
    let responseObj = {}
    if (!visibilidadModalInsertarRO) {
      responseObj = {
        riesgoActual: '',
        opportunityActual: '',
        fechaDeteccionActual: '',
        efectoFalloActual: '',
        causaFalloActual: '',
        controlesActual: '',
        gravedadActual: '',
        ocurrenciaActual: '',
        deteccionActual: '',
        nprActual: 'Severity,frequency and detection are required',
        priorizacionActual: 'NPR is necessary',
        observacionesActual: '',
        categoriaActual: '',
        processSelectedState: null,
        subProcessSelectedState: null,
        missionSelected: null,
        secMissionsSelected: [],
        rulesActual: null,
      }
    } else if (
      visibilidadModalInsertarRO &&
      RyOFilaSeleccionada &&
      (modoRyO === 'editar' || modoRyO === 'rev')
    ) {
      const rmROActual = RyOListAPI.find((registro) => registro.id === (typeof RyOFilaSeleccionada === 'object' ? RyOFilaSeleccionada.id : RyOFilaSeleccionada))

      const RyOSons = RyOListAPI.filter((registro) => registro.id_padre_ryo === rmROActual.id)
      const RyOSonsMissions = RyOSons.map((ryo) => ryo.mision_id)
      responseObj = {
        riesgoActual: rmROActual.risk_description,
        opportunityActual: rmROActual.opportunity_description,
        fechaDeteccionActual: rmROActual.d_detection,
        efectoFalloActual: rmROActual.glitch_effect,
        causaFalloActual: rmROActual.cause_failure,
        controlesActual: rmROActual.current_controls,
        gravedadActual: rmROActual.gravity,
        ocurrenciaActual: rmROActual.idea,
        deteccionActual: rmROActual.detection,
        nprActual: rmROActual.npr,
        priorizacionActual: rmROActual.priorization,
        observacionesActual: rmROActual.observations,
        categoriaActual: rmROActual.categorizacion,
        processSelectedState: rmROActual.proceso_id,
        subProcessSelectedState: rmROActual.subproceso_id,
        missionSelected: rmROActual.mision_id,
        secMissionsSelected: RyOSonsMissions,
        rulesActual: rmROActual.rules_id,
      }
    }

    responseObj = Object.keys(responseObj).length ? responseObj : null
    return responseObj
  }
  return { isEditMode }
}

// ? Create RyO
export function useCreateRyO() {
  const dispatch = useDispatch()
  const insertarRyO = (riskManagement) => dispatch(insertarRyOModalInsertarAction(riskManagement))
  const cambiarVisibilidadModalInsertarRO = (valor) =>
    dispatch(cambiarVisibilidadModalInsertarRyOAction(valor))
  const cambiarValorSeleccionRO = (valor) => dispatch(cambiarValorSeleccionRyOAction(valor))
  const reeValuatedFalse = (state) => dispatch(reevaluateRyOAction(state))
  const saveRyOId = (rowItem) => dispatch(saveRyOIdAction(rowItem))
  const { RyOListAPI, mostrarRyOAPI } = useGetRyOs()
  const { getidAnfe } = useGetAnfe()

  const urlRyOApi = process.env.REACT_APP_URL_DJANGO + '/api/rm_risk_opportunity/'

  const { myAnfesList } = useSelector((state) => state.fuse.anfeComponente)

  const createRyO = async (data) => {
    if (!data) return
    const { secMissionsSelected, missionSelected } = data
    // const {idAnfe} = await getidAnfe(data.missionSelected);
    // const idAnfe = myAnfesList.find(({mision}) => mision === missionSelected)
    const getAnfeFromMission = (modalMision) =>
      myAnfesList.find(({ mision }) => mision === modalMision).id
    const idAnfeMissionSelected = getAnfeFromMission(missionSelected)

    const insertObj = {
      id_risk_management: idAnfeMissionSelected,
      risk_description: data.riesgoActual,
      opportunity_description: data.opportunityActual,
      d_detection: data.fechaDeteccionActual,
      glitch_effect: data.efectoFalloActual,
      cause_failure: data.causaFalloActual,
      current_controls: data.controlesActual,
      gravity: data.gravedadActual,
      idea: data.ocurrenciaActual,
      detection: data.deteccionActual,
      npr: data.nprActual,
      priorization: data.priorizacionActual,
      observations: data.observacionesActual,
      categorizacion: data.categoriaActual,
      rev: 1,
      proceso_id: data.processSelectedState,
      subproceso_id: data.subProcessSelectedState,
      mision_id: data.missionSelected,
      rules_id: data.rulesActual,
      id_padre_ryo: data?.id_padre_ryo,
      type: 'r',
    }

    const ryoPost = await dispatch(insertarRyOModalInsertarAction(insertObj))
    saveRyOId(ryoPost)

    if (secMissionsSelected?.length && ryoPost?.id) {
      try {
        for (let mission of secMissionsSelected) {
          insertObj.mision_id = mission.id
          insertObj.id_risk_management = getAnfeFromMission(mission.id)
          insertObj.id_padre_ryo = ryoPost.id
          const response = await dispatch(insertarRyOModalInsertarAction(insertObj))
        }
      } catch (error) {
        console.error("🚀 ~ createRyO ~ error:", error)
      }
    }

    dispatch(updateFmeaReviewDate(ryoPost.id_risk_management))
    cambiarValorSeleccionRO('')
    cambiarVisibilidadModalInsertarRO(false)
    mostrarRyOAPI()
    reeValuatedFalse(false)
  }
  return { createRyO: createRyO }
}

// ? Edit RyO
export function useEditRyo() {
  const dispatch = useDispatch()
  const cambiarValorSeleccionRO = (valor) => dispatch(cambiarValorSeleccionRyOAction(valor))
  const { RyOsICanSee } = useSelector((state) => state.fuse.RyOComponente)
  const insertarRyO = (riskManagement) => dispatch(insertarRyOModalInsertarAction(riskManagement))
  const updateRyO = (selectedRow, RyO) => dispatch(updateRyOAction(selectedRow, RyO))
  const cambiarVisibilidadModalInsertarRO = (valor) =>
    dispatch(cambiarVisibilidadModalInsertarRyOAction(valor))
  const { mostrarRyOAPI } = useGetRyOs()
  const { getidAnfe } = useGetAnfe()
  const { createLog } = useCreateLog()
  const { notificacionForMembers } = useNotificationForMembers()
  const saveRyOId = (rowItem) => dispatch(saveRyOIdAction(rowItem))

  const { getAddPermission } = useGetPermissions()
  const { postMessage } = usePostMessage()
  const { createRyO } = useCreateRyO()

  const editRyo = async (selectedRow, data, modo) => {
    if (!selectedRow && !data) return
    const { RyOAnfe, idAnfe } = await getidAnfe(data.missionSelected)
    const createEditedRyO = {
      id_risk_management: idAnfe,
      type: 'r',
      risk_description: data.riesgoActual,
      opportunity_description: data.opportunityActual,
      d_detection: data.fechaDeteccionActual,
      glitch_effect: data.efectoFalloActual,
      cause_failure: data.causaFalloActual,
      current_controls: data.controlesActual,
      gravity: data.gravedadActual,
      idea: data.ocurrenciaActual,
      detection: data.deteccionActual,
      npr: data.nprActual.toString(),
      priorization: data.priorizacionActual,
      observations: data.observacionesActual,
      categorizacion: data.categoriaActual,
      site: data.vectorIDSite,
      proceso_id: data.processSelectedState,
      subproceso_id: data.subProcessSelectedState,
      rules_id: data.rulesActual,
      mision_id: data.missionSelected,
    }

    if (modo === 'editar') {
      const RyOSelected = RyOsICanSee.find((ryo) => ryo.id === (typeof selectedRow === 'object' ? selectedRow.id : selectedRow))

      const { id, rev } = RyOSelected
      saveRyOId(RyOSelected)
      updateRyO((typeof selectedRow === 'object' ? selectedRow.id : selectedRow), {
        ...createEditedRyO,
        rev: rev,
      })

    } else if (modo === 'rev') {
      const RyOSelected = RyOsICanSee.find((ryo) => ryo.id === (typeof selectedRow === 'object' ? selectedRow.id : selectedRow))
      const { id, rev } = RyOSelected
      saveRyOId(RyOSelected)

      await insertarRyO({
        ...createEditedRyO,
        rev: rev + 1,
        padreRiskOpportunity: id,
      })
    }
    setTimeout(() => {
      mostrarRyOAPI()
      cambiarVisibilidadModalInsertarRO(false)
    }, 300)

    dispatch(updateFmeaReviewDate(idAnfe))

    // * Log and notifications
    const logAndNotificationMesages = {
      editar: {
        log: {
          action: 'Edit',
          descripcion: 'R&O edition with id:',
        },
        notification: 'edited',
      },
      rev: {
        log: {
          action: 'Re-evaluated',
          descripcion: 'R&O re-evaluated with id:',
        },
        notification: ' re-evaluated',
      },
    }

    createLog({
      action: logAndNotificationMesages[modo]?.log.action,
      descripcion: logAndNotificationMesages[modo]?.log.descripcion,
    })
    RyOAnfe &&
      notificacionForMembers({ RyOAnfe, text: logAndNotificationMesages[modo]?.notification })
    cambiarValorSeleccionRO('')
  }
  return { editRyo }
}

// ? Delete RyO
export function useDeleteRyO() {
  const dispatch = useDispatch()
  const { RyOListAPI, mostrarRyOAPI } = useGetRyOs()
  const RyOFilaSeleccionada = useSelector((state) => state.fuse.RyOComponente.filaSeleccionadaGrid)
  const rmROActual = RyOListAPI.find((registro) => registro.id === RyOFilaSeleccionada?.id)
  const cambiarValorSeleccionRO = (valor) => dispatch(cambiarValorSeleccionRyOAction(valor))
  const updateRyO = (selectedRow, RyO) => dispatch(updateRyOAction(selectedRow, RyO))
  const { createLog } = useCreateLog()

  const deleteRyOAction = () => {
    if (rmROActual) {
      updateRyO(RyOFilaSeleccionada.id, {
        id_risk_management: rmROActual.id_risk_management,
        risk_description: rmROActual.risk_description,
        d_detection: rmROActual.d_detection,
        glitch_effect: rmROActual.glitch_effect,
        cause_failure: rmROActual.cause_failure,
        current_controls: rmROActual.current_controls,
        gravity: rmROActual.gravity,
        idea: rmROActual.idea,
        detection: rmROActual.detection,
        npr: rmROActual.npr,
        priorization: rmROActual.priorization,
        observations: rmROActual.observations,
        categorizacion: rmROActual.v,
        type: rmROActual.type,
        site: rmROActual.site,
        rev: rmROActual.rev,
        active: false,
      })
      setTimeout(() => {
        mostrarRyOAPI()
      }, 500)

      // Log
      createLog({ action: 'Delete', descripcion: 'R&O delete with id:' })
      cambiarValorSeleccionRO('')
    }
  }
  return { deleteRyOAction }
}

// ? Input control
export function useInputsControl() {
  const [inputControlResponse, setinputControlResponse] = useState()

  const inputControl = (data) => {
    if (!data) return
    useEffect(() => {
      delete data.secMissionsSelected
      if (!data.subProcessRelatedState) {
        delete data.subProcessSelectedState
        delete data.subProcessRelatedState
      }
      const allComplete = Object.values(data).every((value) => !!value === true)
      allComplete ? setinputControlResponse(true) : setinputControlResponse(false)
    })
    return inputControlResponse
  }
  return { inputControl }
}

// ? npr calc
export function useNprCalc() {
  const nprCalc = (gravedadActual, ocurrenciaActual, deteccionActual) => {
    let npr = null

    if (!gravedadActual || !ocurrenciaActual || !deteccionActual) {
      npr = 'Severity, frequency and detection are required'
    } else {
      const calc = gravedadActual * ocurrenciaActual * deteccionActual
      npr = calc
    }
    return { npr }
  }
  return { nprCalc }
}

// ? npr control
export function useNprControl() {
  const nprControl = (nprActual) => {
    let priorizacionActual = null
    let tomarAccion = null
    let alertaTipo = null
    let porcentajeNPR = null
    let hiddenGrafico = null

    if (nprActual > 100) {
      priorizacionActual = 'It is necessary to evaluate and take action'
      tomarAccion = 'Create actions'
      alertaTipo = 'error'
    } else if (nprActual > 80) {
      priorizacionActual = 'The manager will decide whether to take action'
      alertaTipo = 'warning'
    } else {
      priorizacionActual = 'No action required'
      tomarAccion = 'No action required'
      alertaTipo = 'info'
    }

    if (nprActual != 'Severity, frequency and detection are required') {
      porcentajeNPR = [(parseInt(nprActual) * 100) / 1000]
      hiddenGrafico = false
    } else {
      hiddenGrafico = true
    }
    return { priorizacionActual, tomarAccion, alertaTipo, porcentajeNPR, hiddenGrafico }
  }
  return { nprControl }
}

// ? Can hace childrem control
export function useCanHaveChildremControl() {
  const { RyOListAPI } = useGetRyOs()

  const canHaveChildremControl = ({ visibilidadModalInsertarRO, missionSelected }) => {
    const misionSelectedHasFather =
      !visibilidadModalInsertarRO || !missionSelected
        ? null
        : RyOListAPI.filter((ryo) => ryo.mision_id === missionSelected).some(
          (ryo) => !!ryo.id_padre_ryo,
        )

    return { misionSelectedHasFather }
  }
  return { canHaveChildremControl }
}

// ? Handle mission input change
export function useHandleMissionChange() {
  const handleMissionChangeHook = (mission, secMissionsSelected) => {
    let newSecMissions = [...(secMissionsSelected || [])]

    // * Ensure mission is valid before processing
    if (!mission) {
      return { missionSelected: null, newSecMissions: [] }
    }

    // * Remove the main mission from the secondary missions if it exists
    newSecMissions = newSecMissions.filter((selectedMission) => selectedMission.id !== mission.id)

    return { missionSelected: mission, newSecMissions }
  }

  return { handleMissionChangeHook }
}

// ? Handle missions related input change in create mode
export function useHandleSecMissionsChangeInCreate() {
  const handleSecMissionsChangeInCreate = ({ newValue, secMissionsSelected }) => {
    const secMissionsSelectedResult = secMissionsSelected?.includes(newValue)
      ? secMissionsSelected.filter((item) => item !== newValue)
      : newValue
    return { secMissionsSelectedResult }
  }
  return { handleSecMissionsChangeInCreate }
}

// ? Handle missions related input change in edit mode
export function useHandleSecMissionsChangeInEdit() {
  const handleSecMissionsChangeInEdit = ({ newValue, secMissionPrevEditMode }) => {
    const newValuesInEditMode = newValue.filter(
      (mission) => !secMissionPrevEditMode.includes(mission) && mission,
    )
    const secMissionsSelectedResult = !newValuesInEditMode.length
      ? secMissionPrevEditMode
      : [...new Set([...secMissionPrevEditMode, ...newValuesInEditMode])]

    return { secMissionsSelectedResult, newValuesInEditMode }
  }
  return { handleSecMissionsChangeInEdit }
}

export const useDispatchModuleDependencies = () => {
  const dispatch = useDispatch()

  const RyOActionsList = useSelector(RyOActions)
  const processList = useSelector(state => state.fuse.processComponent.processListAPI)
  const subProcessList = useSelector(state => state.fuse.subProcessComponent.subProcessListAPI)
  const categoryList = useSelector(state => state.fuse.categoriaComponent.categoriaListAPI)
  const misionNoDepartamentosList = useSelector(state => state.fuse.misionComponent.listAllMisionAPI)
  const rulesList = useSelector(state => state.fuse.rulesComponent.rulesListAPI)
  const subMisionNoDepartamentosList = useSelector(state => state.fuse.subMisionComponent.listSubMisiones)
  const tiposUnicosList = useSelector(state => state.fuse.categoriaComponent.tipoCategoriasAPI)

  const actions = [
    ...(RyOActionsList.length === 0 ? [fetchAllRyOActions] : []),
    ...(processList.length === 0 ? [mostrarProcessAPIAction] : []),
    ...(subProcessList.length === 0 ? [mostrarSubProcessAPIAction] : []),
    ...(categoryList.length === 0 ? [mostrarCategoryAPIAction] : []),
    ...(misionNoDepartamentosList.length === 0 ? [mostrarMisionNoDepartamentosAPIAction] : []),
    ...(rulesList.length === 0 ? [mostrarRulesAPIAction] : []),
    ...(subMisionNoDepartamentosList.length === 0 ? [getSubMisionNoDepartamentalesAPIAction] : []),
    ...(tiposUnicosList.length === 0 ? [mostrarTiposUnicosAPIAction] : []),
  ]

  const dispatchModuleDependencies = () => {
    actions.forEach(action => dispatch(action()))
  }

  return { dispatchModuleDependencies }
}
