import { CSSTransition } from 'react-transition-group'
import Grid from '@mui/material/Grid'
import { renderExpandedContent, rowColorStyle } from '../utils/functions'
import { ExpandedRowData } from './ExpandedRowData'
import routes from 'app/fuse-configs/routesConfig'

const ExpandableRow = ({ row, columns, headerGroups, data, selectedRowIndex }) => {
  const duration = 300

  const defaultStyle = {
    transition: `max-height ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
    maxHeight: 0,
    opacity: 0,
    overflow: 'hidden',
  }

  const transitionStyles = {
    entering: { maxHeight: '500px', opacity: 1 },
    entered: { maxHeight: '500px', opacity: 1 },
    exiting: { maxHeight: 0, opacity: 0 },
    exited: { maxHeight: 0, opacity: 0 },
  }

  return (
    <CSSTransition in={row.isExpanded} timeout={duration} unmountOnExit>
      {(state) => (
        <tr
          className={`p-0 m-0 h-0 ${rowColorStyle(
            row,
            selectedRowIndex,
          )} border-b-3 border-gray-300`}
        >
          <td
            colSpan={columns.length + 1}
            className={`p-0 m-0 h-0 ${rowColorStyle(
              row,
              selectedRowIndex,
            )} border-b-3 border-gray-300`}
          >
            <div
              className={`${rowColorStyle(row, selectedRowIndex)}`}
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <Grid
                container
                spacing={2}
                columns={12}
                className="w-full pb-24 m-auto bg-transparent"
              >
                <ExpandedRowData headerGroups={headerGroups} data={data[row.index]} />
              </Grid>
            </div>
          </td>
        </tr>
      )}
    </CSSTransition>
  )
}

export default ExpandableRow
