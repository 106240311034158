import {

    SET_LOADING_USERS_TRUE,
    SET_USER_LIST,
    SET_USERS_FETCH_ERROR,

    SESION_USER,
    SESION_USER_EXITO,
    SESION_USER_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_USER,
    CAMBIAR_VALOR_SELECCION_GRID_USER_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_USER_ERROR,

    OBTENER_CONTRATOS_USER,
    OBTENER_CONTRATOS_USER_EXITO,
    OBTENER_CONTRATOS_USER_EXITO_RESPALDO,
    OBTENER_CONTRATOS_USER_ERROR,

    PERMISOS_SESION_USER,
    PERMISOS_SESION_USER_EXITO,
    PERMISOS_SESION_USER_ERROR,

    MOSTRAR_USER_PERMISOS,
    MOSTRAR_USER_PERMISOS_EXITO,
    MOSTRAR_USER_PERMISOS_ERROR,

    GET_USER_AGRESSO,
    GET_USER_AGRESSO_EXITO,
    GET_USER_AGRESSO_ERROR,

    PUT_USER,
    PUT_USER_EXITO,
    PUT_USER_ERROR,

    INSERTAR_NEW_USER,
    INSERTAR_NEW_USER_EXITO,
    INSERTAR_NEW_USER_ERROR,

    MOSTRAR_USER_PERMISOS_BY_GROUP,
    MOSTRAR_USER_PERMISOS_BY_GROUP_EXITO,
    MOSTRAR_USER_PERMISOS_BY_GROUP_ERROR,

    MOSTRAR_ALL_USER,
    MOSTRAR_ALL_USER_EXITO,
    MOSTRAR_ALL_USER_ERROR,

    PUT_PASSWORD_USER,
    PUT_PASSWORD_USER_EXITO,
    PUT_PASSWORD_USER_ERROR,

    PUT_PASSWORD_USER_MANUAL,
    PUT_PASSWORD_USER_MANUAL_EXITO,
    PUT_PASSWORD_USER_MANUAL_ERROR,

    GET_BLOQUES_HEREDADOS,
    GET_BLOQUES_HEREDADOS_EXITO,
    GET_BLOQUES_HEREDADOS_ERROR,
    GET_ID_USER_LOGIN,
    GET_ID_USER_LOGIN_EXITO,
    GET_ID_USER_LOGIN_ERROR,

    SET_SECTIONS_VIEWS,
    // !! 2.0
    SET_USER_SECTIONS_VIEWS,
    INSERT_NEW_SECTION_IN_SECTIONS_VIEWS,
    EDIT_SECTION_IN_SECTIONS_VIEWS,

    SET_MODULES_STRUCTURE,
    SET_USER_EMPTY,
    DELETE_PERMISSIONS,
    RESET_USER_VALUES
} from './types';
import axios from 'axios'
import store from "app/store/index"
import { showMessage } from 'app/store/fuse/messageSlice'
import { deleteCookies, getCookie } from 'app/js/generalFunctions'


//Constantes globales
const urlUserApi = process.env.REACT_APP_URL_DJANGO + "/api/user/"
const urlAuthApi = process.env.REACT_APP_URL_DJANGO + "/api/auth/token/"
const urlAuthPermisosApi = process.env.REACT_APP_URL_DJANGO + "/api/auth/token/permisos/"
const urlContratosUserApi = process.env.REACT_APP_URL_DJANGO + "/api/contractuserByUser/"
const urlUserAgressoApi = process.env.REACT_APP_URL_DJANGO + "/api/user/agresso/"
const urlMisionResponsableApi = process.env.REACT_APP_URL_DJANGO + "/api/subMision/misSubmisiones"

// ? Views URLs
const GeneralManagerView = process.env.REACT_APP_URL_DJANGO + "/api/general_manager_view/"


// ? organization chart URL
const organizationChartURL = process.env.REACT_APP_URL_DJANGO + "/api/modules_structure/"

import { workPackageSectionCategoryTypeDptoOrService, getSectionStoreIdAndInfoKeys, setSectionOrgChartStructure } from './utils/functions';
import { STRUCTURE_FATHERS_CATEGORY_TYPES_NAMES } from './utils/consts';



//************************ GET USER INFO **********************************************

// !BORRAR CUANDO LA NUEVA ESTRUCTURA ESTE COMPLETA
export const getUserViewsDependsOfRol = ({ idUser = store.getState().fuse.userComponente.person.id, rolUser = store.getState().fuse.userComponente.person.rolUser } = {}) => {
    return async (dispatch) => {

        const request = await axios({
            method: "GET",
            url: `${GeneralManagerView}${idUser}`,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }
        })

        if (request.status === 200) {
            const response = request.data
            dispatch(setSectonsViews(response))
        }

    }
}

const setSectonsViews = (sectionsData) => ({
    type: SET_SECTIONS_VIEWS,
    payload: sectionsData
})
// !BORRAR CUANDO LA NUEVA ESTRUCTURA ESTE COMPLETA
const URL_FILTER_BA = `${process.env.REACT_APP_URL_DJANGO}/api/filter_info`

// ? Org chart AND SECTIONS VIEWS
export const getUserOrganizationChart = () => {
    return async (dispatch) => {
        try {
            const response = await axios({
                method: "GET",
                url: `${URL_FILTER_BA}/false`,
                headers: { 'Authorization': `Token ${getCookie('token')}` }
            })
            if (response.status === 200) {

                const {sections_structure, team_structure} = response.data

                dispatch(setOrganizationChart({ modulesStructure: team_structure }))
                dispatch(setUserSectionsViews(sections_structure));
            }

        }
        catch (error) {
            console.log(error)
            const emptyResponseUserSectionsView = { ids: [], info: [] };
            const emptyObjKeys = ["divisions", "subdivisions", "departmentDiectorates", "departments", "services", "departamentalWps", "wps", "departamentalMissions", "missions", "submissions", "departamentalSubmisions", "departamentalAndNoDepartamentalWps", "departamentalAndNoDepartamentalMissions", "departamentalAndNoDepartamentalSubMissions"];

            const emptyObj = Object.fromEntries(emptyObjKeys.map(key => [key, emptyResponseUserSectionsView]));
            const { divisions, subdivisions, departmentDiectorates, departments, services, departamentalWps, wps, departamentalMissions, missions, submissions, departamentalSubmisions,  departamentalAndNoDepartamentalWps, departamentalAndNoDepartamentalMissions, departamentalAndNoDepartamentalSubMissions } = emptyObj;

            dispatch(setUserSectionsViews({ divisions, subdivisions, departmentDiectorates, departments, services, departamentalWps, wps, departamentalMissions, missions, submissions, departamentalSubmisions, departamentalAndNoDepartamentalWps, departamentalAndNoDepartamentalMissions, departamentalAndNoDepartamentalSubMissions }));
            dispatch(setOrganizationChart({ modulesStructure: [] }))
        }

    }
}

const setOrganizationChart =({modulesStructure})=>({
    type: SET_MODULES_STRUCTURE,
    payload: modulesStructure
})

const setUserSectionsViews = (sections_structure)=>({
    type: SET_USER_SECTIONS_VIEWS,
    payload : sections_structure
})


export const inserSectionInSectionViews = ({sectionInfo, sectionCategoryType}) => {
    return (dispatch) => {

        const sectionTypesInStore  = getSectionStoreIdAndInfoKeys({sectionCategoryType, isDepartamental: sectionInfo.isDepartamental})

        dispatch({
            type: INSERT_NEW_SECTION_IN_SECTIONS_VIEWS,
            payload: { sectionTypesInStore, sectionInfo }
        })
    }
}

export const editSectionInSectionsViews =({sectionInfo, sectionCategoryType}) => {
    return (dispatch) => {
        const sectionTypesInStore = getSectionStoreIdAndInfoKeys({sectionCategoryType, isDepartamental: sectionInfo?.isDepartamental})

        dispatch({
            type: EDIT_SECTION_IN_SECTIONS_VIEWS,
            payload: { sectionTypesInStore, sectionInfo }
        })
    }
}

export const insertSectionInModuleStructure =({sectionInfo, sectionCategoryType}) => {
    // Ç Incorporamos una nueva seccion a la estructura
    // Ç La seccion creada tiene que depender de alguna superior que ya este en organizationChart
    // Ç Cuando la creamos, su propiedad children esta vacia
    return async (dispatch) => {

        // Ç Cambiamos el nombre de seccion de wp dependiendo de si es dpto o no
        sectionCategoryType = workPackageSectionCategoryTypeDptoOrService({sectionInfo, sectionCategoryType})

        
        // Ç Transformamos las keys de la info de la seccion incluida a la estructura de las keys de orgChartStructure
        const sectionInfoWithOrgChartStructure = setSectionOrgChartStructure({sectionInfo, sectionCategoryType })

        const {organizationChart} = store.getState().fuse.userComponente

        // Ç Primero buscamos la seccion padre en el primer nivel
        // Ç Obtenemos el nombre de la categoria padre en la estructra. Ej => incluimos submission (padre = mission)
        // Ç Si lo encontramos vemos si el registro que hemos incluido tiene como papa_id el id del registro encontrado
        // Ç Ejemplo => hemos incluido una submision con id_mision === 1 // buscamos en el primer nivel de la estructura la categoria con nombre "Mision", y si a ncontramos, vemos si el id de la mision encontrada es igual a la submision incluida "mision_id". Si es asi, significa que la submisson que acabamos de incluir es hija de esa mision. Si no lo encontramos, significa que la mision papa no esta en el primer nivel de la estructura y pasamos a la busqueda en los children de la estructura (que es lo mismo pero menos optima, ya que tenemos que buscar en todos los children)
        const structureFhaterCategoryTypeName = STRUCTURE_FATHERS_CATEGORY_TYPES_NAMES[sectionCategoryType]

        const sectionFatherInFistLevelIndex = organizationChart.findIndex(section => section.category_type === structureFhaterCategoryTypeName &&  sectionInfoWithOrgChartStructure.father_id.includes(section.id))



        if(sectionFatherInFistLevelIndex !==-1){
            const missionFatherFromManagerValue = organizationChart[sectionFatherInFistLevelIndex]
            missionFatherFromManagerValue.children = [...missionFatherFromManagerValue.children, sectionInfoWithOrgChartStructure]
        }
        else{
            // Ç Buscamos dentro de los children de la estructura
            const findSection =(orgChartSection)=>{
                orgChartSection.forEach(category=>{
                    const indexFinder = category.children.findIndex(section => section.category_type === structureFhaterCategoryTypeName && sectionInfoWithOrgChartStructure.father_id.includes(section.id))
                    if(indexFinder === -1) findSection(category.children)
                    else{
                        const theSectionFather = category.children[indexFinder]
                        theSectionFather.children = [...theSectionFather.children, sectionInfoWithOrgChartStructure]
                    }
                })
            }
            findSection(organizationChart)
        }
        dispatch(setOrganizationChart({modulesStructure: organizationChart}))

    }
    
}

export const editSectionInModuleStructure=({sectionInfo, sectionCategoryType})=>{
    // Ç Como estamos editando y no creando, es posible que la seccion se encuentre en el primer nivel y no como hijx de una ya creada
    return async (dispatch) => {
        
        // Ç Cambiamos el nombre de seccion de wp dependiendo de si es dpto o no
        sectionCategoryType = workPackageSectionCategoryTypeDptoOrService({sectionInfo, sectionCategoryType})

        // Ç Submission with orgChart keys
        const sectionInfoWithOrgChartStructure = setSectionOrgChartStructure({sectionInfo, sectionCategoryType })

        const {organizationChart} = store.getState().fuse.userComponente

        // Ç Primero buscamos la seccion en el primer nivel       
        const structureFhaterCategoryTypeName = STRUCTURE_FATHERS_CATEGORY_TYPES_NAMES[sectionCategoryType]

        const sectionFatherInFistLevelIndex = organizationChart.findIndex(section => section.category_type === structureFhaterCategoryTypeName &&  sectionInfoWithOrgChartStructure.father_id.includes(section.id))

        if(sectionFatherInFistLevelIndex !==-1){
            // Ç La seccion editada nos llega de sectionInfoWithOrgChartStruture sin los children que esa categoria tiene, por lo que antes de editarla, guardamos los children y los volvemos a incluir en la seccion una vez sustituida la infornacion antigua por la nueva
            const fatherChildrenList = organizationChart[sectionFatherInFistLevelIndex]?.children
            const sectionIndexInChildren = fatherChildrenList && fatherChildrenList.findIndex(section => section.id === sectionInfo.id)
            // if(!fatherChildrenList[sectionIndexInChildren]?.children) return
            fatherChildrenList.splice(sectionIndexInChildren, 1,  {...sectionInfoWithOrgChartStructure, children: fatherChildrenList[sectionIndexInChildren].children})
        }
        else{
            // Ç Buscamos dentro de los children de la estructura
            const findSection =(orgChartSection)=>{
                orgChartSection.forEach(category=>{
                    const indexFinder = category.children.findIndex(section => section.category_type === sectionCategoryType && section.id === sectionInfo.id)
                    
                    if(indexFinder === -1) findSection(category.children)
                    else{
                // Ç La seccion editada nos llega de sectionInfoWithOrgChartStruture sin los children que esa categoria tiene, por lo que antes de editarla, guardamos los children y los volvemos a incluir en la seccion una vez sustituida la infornacion antigua por la nueva
                        const childrenFromSectionToEdit = category.children[indexFinder].children
                        category.children.splice(indexFinder, 1, {...sectionInfoWithOrgChartStructure, children: childrenFromSectionToEdit})
                    }
                })
            }
            findSection(organizationChart)
        }
        dispatch(setOrganizationChart({modulesStructure: organizationChart}))

    }
}






export function getSesionActualAPIAction({token}) {
    return async (dispatch) => {

        dispatch(getSesionUserAPI())
        await axios({
            method: "POST",
            url: urlAuthApi,
            data: {token: token}
        })
            .then(response => {
                dispatch(getSesionUserAPIExito(response.data))
                dispatch(getUserOrganizationChart({idUser: response.data.id}));
                dispatch(mostrarUserAPIAction())
                dispatch(getPermisosSesionActualAPIAction(token))
                
            })
            .catch(error => {
                console.log(error)
                dispatch(getSesionUserAPIError())
            })

    }
}

const getSesionUserAPI = () => ({
    type: SESION_USER,
    payload: true

})

const getSesionUserAPIExito = person => ({
    type: SESION_USER_EXITO,
    payload: person

})

const getSesionUserAPIError = () => ({
    type: SESION_USER_ERROR,
    payload: false
})


// ** SET USER EMPTY => LOGOUT
export const setUserEmpty =()=>{
    return(dispatch) => {
        dispatch({type: SET_USER_EMPTY})
    }
}


//************************ GET BLOQUES HEREDADOS Y NO USUARIO **********************************************

export function getBloquesHeredadosYNoAPIAction(idUser) {

    return async (dispatch) => {
        dispatch(getBloquesHeredadosYNoAPI())

        await axios({
            method: "GET",
            url: urlUserApi + "allBlokByUser/" + idUser,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(getBloquesHeredadosYNoAPIExito(response.data))
            })
            .catch(error => {
                console.log(error.response)
                dispatch(getBloquesHeredadosYNoAPIError(true))
            })

    }
}

const getBloquesHeredadosYNoAPI = () => ({
    type: GET_BLOQUES_HEREDADOS,
    payload: false

})

const getBloquesHeredadosYNoAPIExito = userData => ({
    type: GET_BLOQUES_HEREDADOS_EXITO,
    payload: userData

})

const getBloquesHeredadosYNoAPIError = estado => ({
    type: GET_BLOQUES_HEREDADOS_ERROR,
    payload: estado
})

//************************DELETE RELACIONES USER**********************************************

// export function deleteRelacionesUserAPIAction(idUser) {

//     return async (dispatch) => {
//         dispatch(putPasswordUserManualAPI())
//         axios({
//             method: "PUT",
//             url: urlUserApi + "customUserDeleteRelaciones/" + idUser,
//             headers: {
//                 'Authorization': `Token ${getCookie('token')}`
//             }

//         })
//             .then(response => {
//                 dispatch(
//                     showMessage({
//                         message: "User assignments deleted",
//                         variant: "success"
//                     })
//                 )

//             })
//             .catch(error => {
//                 console.log(error.response)

//                 dispatch(
//                     showMessage({
//                         message: "Error in deleting user assignments",
//                         variant: "error"
//                     })
//                 )
//             })

//     }
// }

//************************PUT PASSWORD USER**********************************************

export function putPasswordUserManualAPIAction(idUser, jsonPassword) {

    return async (dispatch) => {
        dispatch(putPasswordUserManualAPI())
        axios({
            method: "POST",
            url: urlUserApi + "postPasswordUserManual/" + idUser,
            data: jsonPassword,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(putPasswordUserManualAPIExito(response.data))

                dispatch(
                    showMessage({
                        message: "Password successfully updated",
                        variant: "success"
                    })
                )

            })
            .catch(error => {
                console.log(error.response)
                dispatch(putPasswordUserManualAPIError(true))

                dispatch(
                    showMessage({
                        message: "A problem has occurred",
                        variant: "error"
                    })
                )
            })

    }
}

const putPasswordUserManualAPI = (user) => ({
    type: PUT_PASSWORD_USER_MANUAL,
    payload: user

})

const putPasswordUserManualAPIExito = estado => ({
    type: PUT_PASSWORD_USER_MANUAL_EXITO,
    payload: estado

})

const putPasswordUserManualAPIError = estado => ({
    type: PUT_PASSWORD_USER_MANUAL_ERROR,
    payload: estado
})

//************************PUT PASSWORD USER**********************************************

export function putPasswordUserAPIAction(idUser) {

    return async (dispatch) => {
        dispatch(putPasswordUserAPI())
        axios({
            method: "PUT",
            url: urlUserApi + "putPasswordUser/" + idUser,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(putPasswordUserAPIExito(response.data))

                dispatch(
                    showMessage({
                        message: "Password successfully updated",
                        variant: "success"
                    })
                )

            })
            .catch(error => {
                console.log(error.response)
                dispatch(putPasswordUserAPIError(true))

                dispatch(
                    showMessage({
                        message: "A problem has occurred",
                        variant: "error"
                    })
                )
            })

    }
}

const putPasswordUserAPI = (user) => ({
    type: PUT_PASSWORD_USER,
    payload: user

})

const putPasswordUserAPIExito = estado => ({
    type: PUT_PASSWORD_USER_EXITO,
    payload: estado

})

const putPasswordUserAPIError = estado => ({
    type: PUT_PASSWORD_USER_ERROR,
    payload: estado
})

//************************ FUNCION PARA COMPROBAR SI EL USUARIO ES RESPONSABLE DE UNA MISION**********************************************
export function getUsuarioResponsableMisionAPIAction(idPersona) {

    return async (dispatch) => {
        //dispatch (getUsuarioResponsableMisionAPI())

        await axios({
            method: "GET",
            url: urlMisionResponsableApi + idPersona,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                //dispatch (getUsuarioResponsableMisionAPIExito(response.data))
                if (response.data.length != 0) {
                    dispatch(obtenerContratosUserAPIExito(response.data))
                }
                else {
                    dispatch(obtenerContratosUserAPIAction(idPersona, "1"))
                }
            })
            .catch(error => {
                console.log(error.response)
                //dispatch (getUsuarioResponsableMisionAPIError(true))
            })

    }
}

//************************ OBTENER CONTRATOS DEL USUARIO **********************************************

export function obtenerContratosUserAPIAction(idPersona, modo) {

    return async (dispatch) => {
        dispatch(obtenerContratosUserAPI(true))


        await axios({
            method: "GET",
            url: urlContratosUserApi + idPersona,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                if (modo == "1") {
                    dispatch(obtenerContratosUserAPIExito(response.data))
                }
                if (modo == "2") {
                    dispatch(obtenerContratosUserAPIExitoRespaldo(response.data))
                }

            })
            .catch(error => {
                console.log(error.response)
                dispatch(obtenerContratosUserAPIError(true))
            })

    }
}

const obtenerContratosUserAPI = (estado) => ({
    type: OBTENER_CONTRATOS_USER,
    payload: true

})

const obtenerContratosUserAPIExito = contratos_user => ({
    type: OBTENER_CONTRATOS_USER_EXITO,
    payload: contratos_user

})

const obtenerContratosUserAPIExitoRespaldo = contratos_user => ({
    type: OBTENER_CONTRATOS_USER_EXITO_RESPALDO,
    payload: contratos_user

})

const obtenerContratosUserAPIError = estado => ({
    type: OBTENER_CONTRATOS_USER_ERROR,
    payload: estado
})

//************************ INSERTA RM_ACCION MODAL INSERTAR**********************************************

export function insertarNewUserAPI(user) {

    return async (dispatch) => {
        // dispatch(createNewUser())
        axios({
            method: "POST",
            url: urlUserApi,
            data: user,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {

                dispatch(
                    showMessage({
                        message: "User successfully created",
                        variant: "success"
                    })
                )

                dispatch(createNewUserExito(response.data))

            })
            .catch(error => {
                console.log(error.response)
                dispatch(createNewUserError(true))

                dispatch(
                    showMessage({
                        message: "Error creating User",
                        variant: "error"
                    })
                )
            })

    }
}

const createNewUser = (user) => ({
    type: INSERTAR_NEW_USER,
    payload: user

})

const createNewUserExito = estado => ({
    type: INSERTAR_NEW_USER_EXITO,
    payload: estado

})

const createNewUserError = estado => ({
    type: INSERTAR_NEW_USER_ERROR,
    payload: estado
})

//PUT USER

export function putUserAPI({idUser, userIndexInList, userValues}) {

    return async (dispatch) => {

        dispatch(putUser())
        axios({
            method: "PUT",
            url: urlUserApi + idUser,
            data: userValues,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }
        })
            .then(response => {
                // dispatch(putUserExito(response.data))

                dispatch(
                    showMessage({
                        message: "User successfully modified",
                        variant: "success"
                    })
                )
                // Ç unsubscribedUser => Cuando desactivamos un usuario enviamos en el objeto la key "is_active" si no lo desactivamos no se envia esa key
                // Ç createdPermissionNewUser => Cuando creamos lo permisos de un nuevo usuario no nos llega userIndexInList
                const unsubscribedUser = userValues.is_active === undefined ? false : true;
                const createdPermissionNewUser = userIndexInList ?? true
                if (unsubscribedUser)  dispatch(deleteUnsubscribedUserFromUserList({ ...response.data, indexValue: userIndexInList })) 
                else if(createdPermissionNewUser) dispatch(setNewUserPermissions(response.data))
                else dispatch(editUserList({  ...response.data, indexValue: userIndexInList }))
                // dispatch(editUserList({ ...response.data, indexValue: userIndexInList }))
                // dispatch(mostrarUserAPIAction())

                //OBTENER PERMISOS DEL USUARIO MODIFICADO
                // dispatch(mostrarUserPermisosAPIAction(id))
                // dispatch(mostrarUserPermisosByGroupAPIAction(id))

            })
            .catch(error => {
                console.log(error)
                dispatch(putUserError(true))

                dispatch(
                    showMessage({
                        message: "Error modified User",
                        variant: "error"
                    })
                )
            })

    }
}

const setNewUserPermissions=(newUser)=>{
    const {usersListAPI} = store.getState().fuse.userComponente;
    const newUserId = newUser.id;
    const editedUserList = usersListAPI.map((user, index)=> user.id === newUserId ? newUser : user);
    return{
        type: SET_USER_LIST,
        payload: editedUserList
    }
}

const deleteUnsubscribedUserFromUserList=(user)=>{
    const {usersListAPI} = store.getState().fuse.userComponente;
    const userIndexInList = user.indexValue;
    const editedUserList = usersListAPI.filter((user, index)=> index !== userIndexInList && user);
    return{
        type: SET_USER_LIST,
        payload: editedUserList
    }
}

const editUserList=(user) => {
    const {usersListAPI} = store.getState().fuse.userComponente;
    const userIndexInList = user.indexValue;
    const editedUserList = usersListAPI.with(userIndexInList, user);
    return{
        type: SET_USER_LIST,
        payload: editedUserList
    }
}

const putUser = () => ({
    type: PUT_USER,
    payload: true

})


const putUserError = estado => ({
    type: PUT_USER_ERROR,
    payload: estado
})

//************************ GET DATOS PERSONA AGRESSO **********************************************

export function getUserAgressoAPIAction(idAgresso) {

    return async (dispatch) => {
        dispatch(getUserAgressoAPI())

        await axios({
            method: "GET",
            url: urlUserAgressoApi + idAgresso,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(getUserAgressoAPIExito(response.data))
            })
            .catch(error => {
                console.log(error.response)
                dispatch(getUserAgressoAPIError(true))
            })

    }
}

const getUserAgressoAPI = () => ({
    type: GET_USER_AGRESSO,
    payload: true

})

const getUserAgressoAPIExito = userData => ({
    type: GET_USER_AGRESSO_EXITO,
    payload: userData

})

const getUserAgressoAPIError = estado => ({
    type: GET_USER_AGRESSO_ERROR,
    payload: estado
})





//************************ GESTION PERMISOS SESION PERSON **********************************************

export function getPermisosSesionActualAPIAction(token) {
    return async (dispatch) => {
        dispatch(getPermisosSesionUserAPI())
        await axios({
            method: "POST",
            url: urlAuthPermisosApi,
            data: {token: token}
        })
            .then(response => {
                dispatch(getPermisosSesionUserAPIExito(response.data))
            })
            .catch(error => {
                console.log(error.response)
                dispatch(getPermisosSesionUserAPIError())
            })

    }
}

export function deleteSessionPermissions() {
    return async (dispatch) => {
       dispatch({
        type: DELETE_PERMISSIONS
       })

    }
}

const getPermisosSesionUserAPI = () => ({
    type: PERMISOS_SESION_USER,
    payload: true

})

const getPermisosSesionUserAPIExito = person => ({
    type: PERMISOS_SESION_USER_EXITO,
    payload: person

})

const getPermisosSesionUserAPIError = () => ({
    type: PERMISOS_SESION_USER_ERROR,
    payload: false
})

export const resetUserValues =()=>{
    return dispatch => {
        dispatch({type: RESET_USER_VALUES})
        // history.replace("/login");
        // window.location.reload();
    }
}



//************************ MOSTRAR USER API GET **********************************************

export function mostrarUserAPIAction() {

    return async (dispatch) => {
        const {loading, fReuqestThrow} = store.getState().fuse.userComponente
        if (loading && fReuqestThrow) return
        dispatch(loadingUsers(true))


        await axios({
            method: "GET",
            url: urlUserApi,
            headers: {
'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(mostrarUserAPIExito(response.data))
            })
            .catch(error => {
                console.log(error.response)
                // deleteCookies();
                //  location.replace('/login')
            })

    }
}

const loadingUsers = () => ({
    type: SET_LOADING_USERS_TRUE,
})

const mostrarUserAPIExito = users => ({
    type: SET_USER_LIST,
    payload: users

})

const mostrarUserAPIError = () => ({
    type: SET_USERS_FETCH_ERROR,
})

//************************ MOSTRAR PERMISOS QUE PROVIENEN DEL GROUPO ASOCIADOS AL USUARIO API GET **********************************************

export function mostrarUserPermisosByGroupAPIAction(idUser) {

    return async (dispatch) => {
        dispatch(mostrarUserPermisosByGroupAPI(true))


        await axios({
            method: "GET",
            url: urlUserApi + "permisosByGroup/" + idUser,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(mostrarUserPermisosByGroupAPIExito(response.data))
            })
            .catch(error => {
                console.log(error.response)
                dispatch(mostrarUserPermisosByGroupAPIError(true))
            })

    }
}

const mostrarUserPermisosByGroupAPI = (estado) => ({
    type: MOSTRAR_USER_PERMISOS_BY_GROUP,
    payload: false

})

const mostrarUserPermisosByGroupAPIExito = permissions => ({
    type: MOSTRAR_USER_PERMISOS_BY_GROUP_EXITO,
    payload: permissions

})

const mostrarUserPermisosByGroupAPIError = estado => ({
    type: MOSTRAR_USER_PERMISOS_BY_GROUP_ERROR,
    payload: estado
})

//************************ MOSTRAR PERMISOS ASOCIADOS AL USUARIO API GET **********************************************

export function mostrarUserPermisosAPIAction(idUser) {

    return async (dispatch) => {
        dispatch(mostrarUserPermisosAPI(true))


        await axios({
            method: "GET",
            url: urlUserApi + idUser,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(mostrarUserPermisosAPIExito(response.data))
            })
            .catch(error => {
                console.log(error.response)
                dispatch(mostrarUserPermisosAPIError(true))
            })

    }
}

const mostrarUserPermisosAPI = (estado) => ({
    type: MOSTRAR_USER_PERMISOS,
    payload: false

})

const mostrarUserPermisosAPIExito = permissions => ({
    type: MOSTRAR_USER_PERMISOS_EXITO,
    payload: permissions

})

const mostrarUserPermisosAPIError = estado => ({
    type: MOSTRAR_USER_PERMISOS_ERROR,
    payload: estado
})


//************************ CAMBIAR SELECCION GRID ACTION**********************************************

export const  setUserSelectedAction =(newValue)=>  (dispatch) =>  dispatch(setUserSelected(newValue))


const setUserSelected = newValue => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_USER_EXITO,
    payload: newValue

})

