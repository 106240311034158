import { getCookie } from 'app/js/generalFunctions'
import { IMPROVEMENT_ACTION_PLAN_URL } from './const'
const getAuth = () => ({ 'Authorization': `Token ${getCookie('token')}` })

export const GET_IMPROVEMENTS_ACTION_PLANS_REQUEST_OBJ = () => ({
    method: 'GET',
    url: IMPROVEMENT_ACTION_PLAN_URL,
    headers: getAuth()
})

export const GET_IMPROVEMENTS_ACTION_PLANS_BY_IMPROVEMENT_REQUEST_OBJ = (improvementId) => ({
    method: 'GET',
    url: `${IMPROVEMENT_ACTION_PLAN_URL}by_improvement/${improvementId}`,
    headers: getAuth()
})

export const PUT_IMPROVEMENT_ACTION_PLAN_REQUEST_OBJ = (improvementId, data) => ({
    method: 'PUT',
    url: `${IMPROVEMENT_ACTION_PLAN_URL}${improvementId}`,
    data: data,
    headers: getAuth()
})

export const POST_IMPROVEMENT_ACTION_PLAN_REQUEST_OBJ = (data) => ({
    method: 'POST',
    url: IMPROVEMENT_ACTION_PLAN_URL,
    data: data,
    headers: getAuth()
})

export const DELETE_IMPROVEMENT_ACTION_PLAN_REQUEST_OBJ = (improvementId) => ({
    method: 'DELETE',
    url: `${IMPROVEMENT_ACTION_PLAN_URL}${improvementId}`,
    headers: getAuth()
})
