import { JOB_IN_SUBMISSION_URL, STAFF_IN_SUBMISSION_URL } from "./consts"

import { getCookie } from 'app/js/generalFunctions'
const getAuth = () => ({ 'Authorization': `Token ${getCookie('token')}` })

export const GET_PERSONEL_IN_SUBMISSION_REQUEST_OBJ = (submissionId) => ({
    method: "GET",
    url: `${STAFF_IN_SUBMISSION_URL}${submissionId}`,
    headers: getAuth()
})

export const PUT_JOB_IN_TECHNICIAN_REQUEST_OBJ = (job) => ({
    method: "PUT",
    url: `${JOB_IN_SUBMISSION_URL}${job.id}`,
    data: job,
    headers: getAuth()
})