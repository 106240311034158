import { useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip, Typography, Button } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import WorkIcon from '@mui/icons-material/Work'
import AddchartIcon from '@mui/icons-material/Addchart'
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import DownloadIcon from '@mui/icons-material/Download'
import EditIcon from '@mui/icons-material/Edit'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { useGetPermissions } from 'app/js/customHooks/PermissionsHooks'

import {
  useAddInitialAuthJobIconVisibility,
  useKnowIfPersonLoginCanAddInspectors,
  useCloseInspectorAuthModalVisibility,
  useSetDetailAuthTableVisibility,
  useCloseAuthViewIcon,
  useContinuousEvIconView,
  useOpenJobsTable,
  useCloseJobsTable,
  useEditInspectorButtomVisibility,
} from '../utils/hooks.js'
import {
  setContinuousEvaluationModalVisibility,
  setCreateInspectorModalVisibility,
  setEditInspectorModalVisibility,
  setJobModalVisibility,
  setJobModalOnlyViewVisibility,
  setInspectorBasicListRowSelectedAction,
  clearInspectorBasicList,
} from '../store/AuthOfInspectorsSlice.js'
import { downloadInspectorsReportThunk } from '../store/AuthOfInspectorsThunk.js'

export const InspectorsTopButtoms = () => {
  const dispatch = useDispatch()
  const {
    inspectorBasicListRowSelected,
    detailsAuthTableVisibility,
    inspectorRowSelected,
    inspectorBasicList,
    jobsTableVisibility,
    jobRowSelected,
    inspectorsList,
    belowPeopleInStructure,
  } = useSelector(({ fuse }) => fuse.inspectorsComponent)

  const { getViewAllPermission } = useGetPermissions()
  const canViewAllInspectors = getViewAllPermission('inspectors')
  const { canAddInspectors } = useKnowIfPersonLoginCanAddInspectors()
  const { editButtomVisibility } = useEditInspectorButtomVisibility()
  const { addInitialAuthJobIconVisibility } = useAddInitialAuthJobIconVisibility()
  const closeAuthIconView = useCloseAuthViewIcon()
  const openCreateInspectorModal = () => {
    dispatch(setInspectorBasicListRowSelectedAction(null))
    dispatch(setCreateInspectorModalVisibility(true))
  }

  const openEditInspectorModal = () => dispatch(setEditInspectorModalVisibility(true))
  const openJobModal = () => dispatch(setJobModalVisibility(true))
  const openJobOnlyViewModal = () => {
    dispatch(setJobModalOnlyViewVisibility(true))
  }

  const { setDetailAuthTableVisibility } = useSetDetailAuthTableVisibility()
  const openContinuousEvaluationModal = () => dispatch(setContinuousEvaluationModalVisibility(true))
  const { opencloseInspectorAuthModal } = useCloseInspectorAuthModalVisibility()
  const { continuousEvIconView } = useContinuousEvIconView()
  const { openJobTable } = useOpenJobsTable()
  const { closeJobTable } = useCloseJobsTable()

  const addInpectorButtomVisibilityCondition =
    !!canAddInspectors && !detailsAuthTableVisibility && !jobsTableVisibility
  const editInpectorButtomVisibilityCondition =
    !!canAddInspectors &&
    !detailsAuthTableVisibility &&
    !jobsTableVisibility &&
    editButtomVisibility
  const inspectorHeadingVisibility = !!detailsAuthTableVisibility || !!jobsTableVisibility

  const handleDownloadReport = () => {
    console.log('Download report')
    dispatch(
      downloadInspectorsReportThunk({
        inspector_id: inspectorBasicListRowSelected.inspector_info.id,
        inspector_idres: inspectorBasicListRowSelected.inspector_info.IDRes,
        program_name: inspectorBasicListRowSelected.program_info.name,
      }),
    )
  }

  const handleClearInspectorBasicList = () => {
    dispatch(clearInspectorBasicList())
  }

  return (
    <section className="flex flex-row items-center justify-between gap-3 my-2 h-[35px] relative">
      <div className="flex flex-row items-center gap-2">
        <div className="border-1 rounded-lg border-transparent px-2 flex items-center gap-2">
          {inspectorHeadingVisibility && (
            <div className="absolute w-full flex flex-row items-center justify-between gap-2 my-2">
              <Typography className="w-full text-center" variant="h6">
                {inspectorBasicListRowSelected.inspector_info.last_name},{' '}
                {inspectorBasicListRowSelected.inspector_info.first_name} (
                {inspectorBasicListRowSelected.program_info.name})
              </Typography>
            </div>
          )}
          {!!canViewAllInspectors && inspectorBasicList.length > 0 && (
            <div className="absolute w-full flex flex-row items-end pr-10">
              <Button
                onClick={handleClearInspectorBasicList}
                className="ml-auto"
                variant="contained"
                color="primary"
              >
                Refine search
              </Button>
            </div>
          )}

          <div className="flex flex-row items-center justify-between gap-2 my-2 z-10 bg-transparent">
            <div className="border-1 rounded-lg border-transparent p-2 flex items-center gap-2">
              {!!detailsAuthTableVisibility && (
                <Tooltip title="Back to inspectors basic info" placement="top">
                  <ArrowBackIosIcon
                    variant="outlined"
                    onClick={() => setDetailAuthTableVisibility(false)}
                  />
                </Tooltip>
              )}

              {!!jobsTableVisibility && (
                <Tooltip title="Back to inspector auth info" placement="top">
                  <ArrowBackIosIcon variant="outlined" onClick={closeJobTable} />
                </Tooltip>
              )}

              {addInpectorButtomVisibilityCondition && (
                <Tooltip title="New inspector" placement="top">
                  <AddCircleIcon
                    className={`${!inspectorsList.length && !belowPeopleInStructure.length ? 'pointer-events-none text-grey-400' : ''}`}
                    variant="outlined"
                    onClick={openCreateInspectorModal}
                  />
                </Tooltip>
              )}

              {/* {
            editInpectorButtomVisibilityCondition && (
              <Tooltip title="Edit inspector" placement="top">
                <EditIcon variant="outlined" onClick={openEditInspectorModal} />
              </Tooltip>
            )
          } */}

              {!!inspectorBasicListRowSelected &&
                !detailsAuthTableVisibility &&
                !jobsTableVisibility && (
                  <Tooltip title="View auth details" placement="top">
                    <ManageSearchIcon
                      variant="outlined"
                      onClick={() => setDetailAuthTableVisibility(true)}
                    />
                  </Tooltip>
                )}

              {!!inspectorBasicListRowSelected &&
                !detailsAuthTableVisibility &&
                !jobsTableVisibility && (
                  <Tooltip title="Export report (docx)" placement="top">
                    <DownloadIcon variant="outlined" onClick={handleDownloadReport} />
                  </Tooltip>
                )}

              {!!addInitialAuthJobIconVisibility && (
                <Tooltip title="New job" placement="top">
                  <WorkIcon variant="outlined" onClick={openJobModal} />
                </Tooltip>
              )}

              {!!continuousEvIconView && (
                <Tooltip title="New continuous evaluation job" placement="top">
                  <AddchartIcon variant="outlined" onClick={openContinuousEvaluationModal} />
                </Tooltip>
              )}

              {!!closeAuthIconView && (
                <Tooltip title="Close authorization" placement="top">
                  <FactCheckIcon variant="outlined" onClick={opencloseInspectorAuthModal} />
                </Tooltip>
              )}

              {!!detailsAuthTableVisibility && !jobsTableVisibility && (
                <Tooltip title="View inspector jobs" placement="top">
                  <ContentPasteSearchIcon variant="outlined" onClick={openJobTable} />
                </Tooltip>
              )}

              {!!jobRowSelected && (
                <Tooltip title="View inspector jobs" placement="top">
                  <RemoveRedEyeIcon variant="outlined" onClick={openJobOnlyViewModal} />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
