import React from 'react'
import PropTypes from 'prop-types'
import {
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

const PopOverInfo = ({
  icon: Icon = InfoOutlined,
  iconButtonCustomStyle = 'w-28 h-28',
  iconCustomStyle = 'text-20 text-orange-600',
  tableColumns,
  tableData,
  popoverStyles,
  anchorOrigin,
  transformOrigin,
  anchorEl,
  setAnchorEl,
}) => {
  const open = Boolean(anchorEl)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <IconButton className={iconButtonCustomStyle}>
          <Icon className={iconCustomStyle} />
        </IconButton>
      </Typography>

      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none', ...popoverStyles }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={transformOrigin || { vertical: 'top', horizontal: 'center' }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <TableContainer>
          <Table aria-label="generic table">
            <TableHead>
              <TableRow>
                {tableColumns.map((column, index) => (
                  <TableCell
                    className={column.customCSS || ''}
                    key={index}
                    align={column.align || 'left'}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {tableColumns.map((column, colIndex) => (
                    <TableCell
                      className={row.customCSS || ''}
                      key={colIndex}
                      align={column.align || 'left'}
                    >
                      {row[column.key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Popover>
    </>
  )
}

PopOverInfo.propTypes = {
  icon: PropTypes.element,
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      align: PropTypes.oneOf(['left', 'center', 'right']),
    }),
  ).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  popoverStyles: PropTypes.object,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  anchorEl: PropTypes.any,
  setAnchorEl: PropTypes.func.isRequired,
}

export default PopOverInfo
