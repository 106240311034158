import { DEPARTMENT_SUBMISSION_URL, SUBMISSION_URL, ALL_USERS_URL, TECHNICIAN_JOBS_URL } from "./consts"

import { getCookie } from 'app/js/generalFunctions'
const getAuth = () => ({ 'Authorization': `Token ${getCookie('token')}` })

export const GET_ALL_DEPARTMENT_SUBMISSIONS_REQUEST_OBJ = () => (
    {
        method: "GET",
        url: DEPARTMENT_SUBMISSION_URL,
        headers: getAuth()
    })

export const POST_SUBMISSION_REQUEST_OBJ = (submissionData) => (
    {
        method: "POST",
        url: SUBMISSION_URL,
        data: submissionData,
        headers: getAuth()
    })

export const PUT_SUBMISSION_REQUEST_OBJ = (submissionID, submissionData) => (
    {
        method: "PUT",
        url: `${SUBMISSION_URL}${submissionID}`,
        data: submissionData,
        headers: getAuth()
    })

export const GET_JOBS_FROM_SELECTED_TECHNICIAN_REQUEST_OBJ = (technicianID) => (
    {
        method: "GET",
        url: `${TECHNICIAN_JOBS_URL}mySubMision/${technicianID}`,
        headers: getAuth()
    })
