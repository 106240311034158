import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'components/GlobalComponents/tables/Table2'

import { RYO_ACTIONS_COLUMNS } from '../../utils/sectionColumns.js'
import { useStyles } from '../../utils/RyOUtils.js'

import { cambiarVisibilidadModalHistoricoActionsAction } from '../../store/RyO/actions.js'
import { RyOActions } from '../../features/actionPlan/ryoActionPlanSlice.js'

export const HistorialActions = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const loading = useSelector(state => state.fuse.RyOComponente.loading)
  const visibilidadModalHistoricoActions = useSelector(state => state.fuse.RyOComponente.visibilidadModalHistoricoActions)
  const RyOHistoryFilaSeleccionada = useSelector(state => state.fuse.RyOComponente.filaSeleccionadaGridHistory)
  const actionsList = useSelector(RyOActions)
  const [actionsRows, setActionsRows] = useState(null)

  const cambiarVisibilidadModalHistoricoActions = valor => dispatch(cambiarVisibilidadModalHistoricoActionsAction(valor))


  useEffect(() => {
    if (!visibilidadModalHistoricoActions || !RyOHistoryFilaSeleccionada) return
    const RyOActions = actionsList.filter(action => action.id_record === RyOHistoryFilaSeleccionada.id)
    setActionsRows(RyOActions)
  }, [visibilidadModalHistoricoActions, RyOHistoryFilaSeleccionada])

  return (
    <Dialog
      open={visibilidadModalHistoricoActions}
      disableScrollLock={false}
      onClose={() => cambiarVisibilidadModalHistoricoActions(false)}
      fullWidth
      maxWidth='xl'
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" component="div">
              {`R&O "${RyOHistoryFilaSeleccionada ? RyOHistoryFilaSeleccionada.id : ''}" actions history`}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => cambiarVisibilidadModalHistoricoActions(false)}>
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent
        sx={{
          p: 0,
          m: 0,
          height: '80vh',
        }}
        dividers
      >
        <Table
          rowsProp={actionsRows}
          columnsProp={RYO_ACTIONS_COLUMNS}
          loading={loading}
          funcionSetValue={() => { }}
          headerTitleSection={true}
        />
      </DialogContent>
    </Dialog>
  )
}
