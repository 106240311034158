import { CONTRACT_USER_BY_CONTRACT_USER_URL, CONTRACT_USER_URL, JOB_IN_SUBMISSION_URL, USER_CONTRACTS_URL } from "./consts"

import { getCookie } from 'app/js/generalFunctions'
const getAuth = () => ({ 'Authorization': `Token ${getCookie('token')}` })

export const POST_CONTRACT_USER = (contractUserData) => (
    {
        method: "POST",
        url: CONTRACT_USER_URL,
        data: contractUserData,
        headers: getAuth()
    })

export const PUT_CONTRACT_USER_REQUEST_OBJ = ({ idContractUser, contractUserData }) => (
    {
        method: "PUT",
        url: `${CONTRACT_USER_URL}${idContractUser}`,
        data: contractUserData,
        headers: getAuth()
    })

export const POST_JOB_IN_SUBMISSION_REQUEST_OBJ = (job) => (
    {
        method: "POST",
        url: JOB_IN_SUBMISSION_URL,
        data: job,
        headers: getAuth()
    })

export const GET_JOB_IN_SUBMISSION_REQUEST_OBJ = (submissionId) => (
    {
        method: "GET",
        url: `${JOB_IN_SUBMISSION_URL}mySubMision/${submissionId}`,
        headers: getAuth()
    })

export const GET_CONTRACT_USER_REQUEST_OBJ = (id) => (
    {
        method: "GET",
        url: `${CONTRACT_USER_BY_CONTRACT_USER_URL}${id}`,
        headers: getAuth()
    })

export const DELETE_CONTRACT_USER_REQUEST_OBJ = (contractUserData) => (
    {
        method: "DELETE",
        url: CONTRACT_USER_BY_CONTRACT_USER_URL,
        data: contractUserData,
        headers: getAuth()
    })

export const GET_USER_CONTRACTS_REQUEST_OBJ = (userId) => (
    {
        method: "GET",
        url: `${USER_CONTRACTS_URL}${userId}`,
        headers: getAuth()
    })
