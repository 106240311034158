export const CONTRACT_USER_URL = process.env.REACT_APP_URL_DJANGO + "/api/contractuser/"
export const JOB_IN_SUBMISSION_URL = process.env.REACT_APP_URL_DJANGO + "/api/jobInSubMision/"
export const CONTRACT_USER_BY_CONTRACT_USER_URL = process.env.REACT_APP_URL_DJANGO + "/api/contractuserByContratoUser/"
export const USER_CONTRACTS_URL = process.env.REACT_APP_URL_DJANGO + "/api/contractuserByUser/"

export const ERROR_TRY_AGAIN = {
    message: "Error, try again",
    variant: "error"
}

export const SUCESS_ASIGN_PERSON = {
    message: "Person correctly assigned",
    variant: "success"
}

export const SUCESS_CREATE_JOB = {
    message: "Created job in sub mision",
    variant: "success"
}

export const SUCESS_DELETE = {
    message: "Correctly removed",
    variant: "success"
}
