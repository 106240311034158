import { useDispatch } from "react-redux"
import { COMPLETE_ALL_FIELDS_MESSAGE } from "../globalConsts"
import { showMessage } from "app/store/fuse/messageSlice"
import { makeStyles } from "@material-ui/core/styles";



export const useCompleteAllFieldsMessage =()=>{
    const dispatch = useDispatch()
    const completeAllFieldsMessage =()=>  dispatch(showMessage(COMPLETE_ALL_FIELDS_MESSAGE))
    return completeAllFieldsMessage
}


export const useStyles = makeStyles({

    customDialogTitle: {
            backgroundColor: 'rgb(37, 47, 62)',
            color: 'rgb(255, 255, 255)',
            marginBottom: '0.5em'
    },

    alertDialogTitle: {
            backgroundColor: '#f44336;',
            color: 'rgb(255, 255, 255)',
            marginBottom: '0.5em'
    }

});