import * as global from 'global'
import { createSlice } from '@reduxjs/toolkit'
import { deleteImprovementActionPlan, fetchImprovementActionPlans, fetchImprovementActionPlansByImprovement, postImprovementActionPlan, putImprovementActionPlan } from './improvementProposalActionPlanThunks'

const initialState = {
    status: {
        fetchImprovementActionPlans: 'idle',
        fetchImprovementActionPlansByImprovement: 'idle',
        putImprovementActionPlan: 'idle',
        postImprovementActionPlan: 'idle'
    },
    error: null,
    improvementActionPlans: [],
    improvementActionPlansByImprovement: [],
    modalVisibilityAction: false,
    modalActionPlanValues: {
        id: null,
        proposal: '',
        date_planned: null,
        date_executed: null,
        observations: '',
        completed: false,
        members: [],
    },
    isEditMode: false,
    showCloseDate: false,
    permissionRoles: [global.rolN4, global.rolN5, global.rolN6, global.rolN7],
    filteredUsers: [],
    mft_team: [],
}

const improvementProposalActionPlanSlice = createSlice({
    name: 'improvementProposalActionPlan',
    initialState,
    reducers: {
        setModalVisibilityAction: (state, action) => {
            state.modalVisibilityAction = action.payload
        },
        setModalActionPlanValues: (state, action) => {
            state.modalActionPlanValues = { ...state.modalActionPlanValues, ...action.payload }
        },
        resetModalActionPlanValues: (state) => {
            state.modalActionPlanValues = initialState.modalActionPlanValues
        },
        filterUsers: (state, action) => {
            state.filteredUsers = action.payload.filter(user =>
                state.permissionRoles.includes(user.rolUser)
            );
        },
        setIsEditMode: (state, action) => {
            state.isEditMode = action.payload
        },
        setShowCloseDate: (state, action) => {
            state.showCloseDate = action.payload
        },
        setMFT_team: (state, action) => {
            state.mft_team = [...action.payload.equipoMFT_team_members, ...action.payload.equipoMFT_managers]
        },
        clearActionPlansByImprovement: (state) => {
            state.improvementActionPlansByImprovement = []
        }
    },
    extraReducers: (builder) => {
        builder
            // handle fetchImprovementActionPlans
            .addCase(fetchImprovementActionPlans.pending, (state) => {
                state.status.fetchImprovementActionPlans = 'pending'
                state.error = false
            })
            .addCase(fetchImprovementActionPlans.rejected, (state, action) => {
                state.status.fetchImprovementActionPlans = 'rejected'
                state.error = true
            })
            .addCase(fetchImprovementActionPlans.fulfilled, (state, action) => {
                state.status.fetchImprovementActionPlans = 'fulfilled'
                state.improvementActionPlans = action.payload
            })
            // handle fetchImprovementActionPlansByImprovement
            .addCase(fetchImprovementActionPlansByImprovement.pending, (state) => {
                state.status.fetchImprovementActionPlansByImprovement = 'pending'
                state.error = false
            })
            .addCase(fetchImprovementActionPlansByImprovement.rejected, (state, action) => {
                state.status.fetchImprovementActionPlansByImprovement = 'rejected'
                state.error = true
            })
            .addCase(fetchImprovementActionPlansByImprovement.fulfilled, (state, action) => {
                state.status.fetchImprovementActionPlansByImprovement = 'fulfilled'
                state.improvementActionPlansByImprovement = action.payload
            })
            // handle putImprovementActionPlan
            .addCase(putImprovementActionPlan.pending, (state) => {
                state.status.putImprovementActionPlan = 'pending'
                state.error = false
            })
            .addCase(putImprovementActionPlan.rejected, (state, action) => {
                state.status.putImprovementActionPlan = 'rejected'
                state.error = true
            })
            .addCase(putImprovementActionPlan.fulfilled, (state, action) => {
                state.status.putImprovementActionPlan = 'fulfilled'
                state.improvementActionPlans = state.improvementActionPlans.map(plan => plan.id === action.payload.id ? action.payload : plan
                )
                state.improvementActionPlansByImprovement = state.improvementActionPlansByImprovement.map(plan => plan.id === action.payload.id ? action.payload : plan
                )
            })
            // handle postImprovementActionPlan
            .addCase(postImprovementActionPlan.pending, (state) => {
                state.status.postImprovementActionPlan = 'pending'
                state.error = false
            })
            .addCase(postImprovementActionPlan.rejected, (state, action) => {
                state.status.postImprovementActionPlan = 'rejected'
                state.error = true
            })
            .addCase(postImprovementActionPlan.fulfilled, (state, action) => {
                state.status.postImprovementActionPlan = 'fulfilled'
                state.improvementActionPlans = [...state.improvementActionPlans, action.payload]
                state.improvementActionPlansByImprovement = [...state.improvementActionPlansByImprovement, action.payload]
            })
            // handle deleteImprovementActionPlan
            .addCase(deleteImprovementActionPlan.pending, (state) => {
                state.status.deleteImprovementActionPlan = 'pending'
                state.error = false
            })
            .addCase(deleteImprovementActionPlan.rejected, (state, action) => {
                state.status.deleteImprovementActionPlan = 'rejected'
                state.error = true
            })
            .addCase(deleteImprovementActionPlan.fulfilled, (state, action) => {
                state.status.deleteImprovementActionPlan = 'fulfilled'
                state.improvementActionPlans = state.improvementActionPlans.filter(plan => plan.id !== action.payload.id)
                state.improvementActionPlansByImprovement = state.improvementActionPlansByImprovement.filter(plan => plan.id !== action.payload.id)
            })
    }
})

export const {
    setModalVisibilityAction,
    setModalActionPlanValues,
    setIsEditMode,
    setShowCloseDate,
    resetModalActionPlanValues,
    filterUsers,
    setMFT_team,
    clearActionPlansByImprovement
} = improvementProposalActionPlanSlice.actions

export const improvementActionPlans = state => state.fuse.improvementProposalActionPlan.improvementActionPlans
export const improvementActionPlansStatus = state => state.fuse.improvementProposalActionPlan.status.fetchImprovementActionPlans
export const improvementActionPlansByImprovement = state => state.fuse.improvementProposalActionPlan.improvementActionPlansByImprovement
export const improvementActionPlansByImprovementStatus = state => state.fuse.improvementProposalActionPlan.status.fetchImprovementActionPlansByImprovement
export const showCloseDateStatus = state => state.fuse.improvementProposalActionPlan.showCloseDate
export const modalVisibilityAction = state => state.fuse.improvementProposalActionPlan.modalVisibilityAction
export const modalActionPlanValues = state => state.fuse.improvementProposalActionPlan.modalActionPlanValues
export const isEditModeStatus = state => state.fuse.improvementProposalActionPlan.isEditMode
export const filteredUsers = state => state.fuse.improvementProposalActionPlan.filteredUsers
export const mft_team = state => state.fuse.improvementProposalActionPlan.mft_team

export default improvementProposalActionPlanSlice.reducer
