export const getIdsAndNamesFromArray = array =>{
    const arrayreduce = array.reduce((acc, {id, name})=>{
        acc.ids = [...acc.ids, id]
        acc.names = [...acc.names, name]
        return acc
    },{ids: [], names:[]})
    return arrayreduce
}

export const getDependenciesInfo =(arr)=>{
    return arr.reduce((acc, entry)=>{
        acc.ids = [...acc.ids, entry.id]
        acc.info = [...acc.info, {id: entry.id, name: entry.name}]
        return acc
    }, {ids: [], info: []})
}

