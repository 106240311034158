import {

    // COMPONENTE PRINCIPAL ------------------

    CAMBIAR_VALOR_VISIBILIDAD_SUBDIVISION,
    CAMBIAR_VALOR_VISIBILIDAD_SUBDIVISION_EXITO,
    CAMBIAR_VALOR_VISIBILIDAD_SUBDIVISION_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION,
    CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION_ERROR,

    ELIMINAR_SUBDIVISION,
    ELIMINAR_SUBDIVISION_EXITO,
    ELIMINAR_SUBDIVISION_ERROR,

    MOSTRAR_SUBDIVISION_API,
    MOSTRAR_SUBDIVISION_EXITO,
    MOSTRAR_SUBDIVISION_ERROR,

    // MODAL INSERTAR  -----------------------

    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION_EXITO,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION_ERROR,

    INSERTAR_SUBDIVISION_MODAL_INSERTAR,
    INSERTAR_SUBDIVISION_MODAL_INSERTAR_EXITO,
    INSERTAR_SUBDIVISION_MODAL_INSERTAR_ERROR,

    ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR,
    ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR_EXITO,
    ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR_ERROR

} from './types';
import axios from 'axios'
import {getCookie, getIdsFromArr} from 'app/js/generalFunctions'
import { showMessage } from 'app/store/fuse/messageSlice'

//Constantes
const urlSubdivisionApi = process.env.REACT_APP_URL_DJANGO + "/api/subdivision/"

//************************CAMBIAR VISIBILIDAD ACTION**********************************************

export function cambiarValorVisibilidadAction(valorNuevo) {

    return (dispatch) => {
        dispatch (cambiarValorVisibilidad())

        try {
            dispatch (cambiarValorVisibilidadExito(valorNuevo))

        } catch (error) {

            dispatch (cambiarValorVisibilidadError(true))
        }

    }

}

const cambiarValorVisibilidad = () => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_SUBDIVISION,
    payload: true

})

const cambiarValorVisibilidadExito = valorNuevo => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_SUBDIVISION_EXITO,
    payload: valorNuevo

})

const cambiarValorVisibilidadError = estado => ({
  type: CAMBIAR_VALOR_VISIBILIDAD_SUBDIVISION_ERROR,
  payload: estado
})

//************************ CAMBIAR SELECCION GRID ACTION**********************************************

export function cambiarValorSeleccionAction(valorNuevo) {

    return (dispatch) => {
        dispatch (cambiarValorSeleccion())

        try {
            dispatch (cambiarValorSeleccionExito(valorNuevo))

        } catch (error) {

            dispatch (cambiarValorSeleccionError(true))
        }

    }

}

const cambiarValorSeleccion = () => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION,
    payload: false

})

const cambiarValorSeleccionExito = valorNuevo => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION_EXITO,
    payload: valorNuevo

})

const cambiarValorSeleccionError = estado => ({
  type: CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION_ERROR,
  payload: estado
})


//************************ ELIMINAR PROGRAMA **********************************************

export function eliminarSubDivisionAction(id) {

    return (dispatch) => {
        dispatch (eliminarSubDivision())

            
               axios.delete(urlSubdivisionApi + id)
                 .then((response) => {
                         dispatch (eliminarSubDivisionExito(response.data))
                 })
                 .catch(error => {
                    console.log(error.response)
                    dispatch (eliminarSubDivisionError(true))
                })

       
      }
}

const eliminarSubDivision = (id) => ({
    type: ELIMINAR_SUBDIVISION,
    payload: id

})

const eliminarSubDivisionExito = estado => ({
    type: ELIMINAR_SUBDIVISION_EXITO,
    payload: estado

})

const eliminarSubDivisionError = estado => ({
    type:  ELIMINAR_SUBDIVISION_ERROR,
    payload: estado
})



//************************ MOSTRAR PROGRAMAS API **********************************************

export function mostrarSubDivisionsAPIAction() {

    return async (dispatch) => {
        dispatch (mostrarSubDivisionsAPI(true))

        
            await axios({
                method: "GET",
                url: urlSubdivisionApi,
                headers: {
                    'Authorization': `Token ${getCookie('token')}` 
                        }
                
                    })
                     .then(response=>{
                        dispatch (mostrarSubDivisionsAPIExito(response.data))
                    })
                    .catch(error => {
                        console.log(error.response)
                        dispatch (mostrarSubDivisionsAPIError(true))

                        
                    })

          }
}

const mostrarSubDivisionsAPI = () => ({
    type: MOSTRAR_SUBDIVISION_API,
    payload: true

})

const mostrarSubDivisionsAPIExito = programas => ({
    type: MOSTRAR_SUBDIVISION_EXITO,
    payload: programas

})

const mostrarSubDivisionsAPIError = estado => ({
  type: MOSTRAR_SUBDIVISION_ERROR,
  payload: estado
})

//************************CAMBIAR VISIBILIDAD MODAL INSERTAR**********************************************

export function cambiarVisibilidadModalInsertarAction(valorNuevo, modoApertura) {

    return (dispatch) => {
        dispatch (cambiarVisibilidadModalInsertar())

        try {
            dispatch (cambiarVisibilidadModalInsertarExito(valorNuevo, modoApertura))

        } catch (error) {

            dispatch (cambiarVisibilidadModalInsertarError(true))
        }
    }
}

const cambiarVisibilidadModalInsertar = () => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION,
    payload: true

})

const cambiarVisibilidadModalInsertarExito = (valorNuevo, modoApertura) => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION_EXITO,
    payload: valorNuevo,
    visibilidadModal: modoApertura

})

const cambiarVisibilidadModalInsertarError = estado => ({
  type:  CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION_ERROR,
  payload: estado
})

//************************ INSERTAR PROGRAMA MODAL INSERTAR**********************************************

export function insertarSubDivisionModalInsertarAction(subdivision) {
    
    return  (dispatch) => {
        dispatch (insertarSubDivisionModalInsertar())
        const id_program = getIdsFromArr(subdivision.id_program)
        const subdivisionObj = {...subdivision, id_program}
        console.log("🚀 ~ return ~ subdivisionObj:", subdivisionObj)

            
             axios({
                    method: "POST",
                    url: urlSubdivisionApi,
                    data: subdivisionObj,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (insertarSubDivisionModalInsertarExito(response.data))

                 dispatch(
                    showMessage({
                        message: "Successfully created",
                        variant: "success"
                    })
                )

                 dispatch (mostrarSubDivisionsAPIAction())
             })
             .catch(error => {
                console.log(error.response)
                dispatch (insertarSubDivisionModalInsertarError(true))

                dispatch(
                    showMessage({
                        message: "Error creating",
                        variant: "error"
                    })
                )
            })

    }
}

const insertarSubDivisionModalInsertar = (programa) => ({
    type: INSERTAR_SUBDIVISION_MODAL_INSERTAR,
    payload: programa

})

const insertarSubDivisionModalInsertarExito = estado => ({
    type: INSERTAR_SUBDIVISION_MODAL_INSERTAR_EXITO,
    payload: estado

})

const insertarSubDivisionModalInsertarError = estado => ({
    type:  INSERTAR_SUBDIVISION_MODAL_INSERTAR_ERROR,
    payload: estado
})

//************************ INSERTAR PROGRAMA MODAL INSERTAR**********************************************

export function actualizarSubDivisionModalInsertarAction(id, subdivision) {

    return  (dispatch) => {
        dispatch (actualizarSubDivisionModalInsertar())

        const id_program = getIdsFromArr(subdivision.id_program)
        console.log("🚀 ~ return ~ subdivision:", subdivision)
        const subdivisionObj = {...subdivision, id_program}

            
             axios({
                    method: "PUT",
                    url: urlSubdivisionApi+id,
                    data: subdivisionObj,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (actualizarSubDivisionModalInsertarExito(response.data))

                 if (response.data['active'] == false) {
                    dispatch(
                        showMessage({
                            message: "Delete Sub Division",
                            variant: "success"
                        })
                    )
                }
                else {

                    dispatch(
                        showMessage({
                            message: "Updated Sub Division",
                            variant: "success"
                        })
                    )
                }

                 dispatch (mostrarSubDivisionsAPIAction())
             })
             .catch(error => {
                console.log(error.response)
                dispatch (actualizarSubDivisionModalInsertarError(true))

                dispatch(
                    showMessage({
                        message: "Error when updating Sub Division",
                        variant: "error"
                    })
                )
            })

    }
}

const actualizarSubDivisionModalInsertar = (programa) => ({
    type: ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR,
    payload: programa

})

const actualizarSubDivisionModalInsertarExito = estado => ({
    type: ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR_EXITO,
    payload: estado

})

const actualizarSubDivisionModalInsertarError = estado => ({
    type:  ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR_ERROR,
    payload: estado
})

