export const JOB_IN_SUBMISSION_URL = process.env.REACT_APP_URL_DJANGO + "/api/jobInSubMision/"
export const STAFF_IN_SUBMISSION_URL = process.env.REACT_APP_URL_DJANGO + "/api/contractuserByContratoUser/"

export const SUCESS_CREATE_JOB = {
    message: "Created job in sub mision",
    variant: "success"
}

export const ERROR_TRY_AGAIN = {
    message: "Error, try again",
    variant: "error"
}

export const SUCCESS_REMOVED = {
    message: "Deleted",
    variant: "success"
}
