import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';
i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);





const navigationConfig = [
  {

    id: 'pages',
    title: 'Pages',
    type: 'group',
    icon: 'pages',
    children: [
      {
        id: 'home',
        title: 'Home',
        type: 'item',
        icon: 'home',
        url: '/',
      },
      //TABSEXCEL**********************

      {
        id: 'tabsExcel',
        title: 'Modules',
        type: 'collapse',
        icon: 'assessment',
        children: [
          {
            id: 'riskManagement',
            title: 'R&O',
            type: 'item',
            url: 'pages/tabsExcel/riskmanagement',
          },
          {
            id: 'peopleManagement',
            title: 'My information',
            type: 'collapse',
            children: [
              {
                id: 'miInformacion',
                title: 'Team',
                type: 'item',
                url: 'pages/tabsExcel/peoplemanagement',
              },
            ]
          },
          {
            id: 'improvementProposals',
            title: 'Improvement proposals',
            type: 'item',
            url: 'pages/tabsExcel/improvementProposals',
          },
          {
            id: 'deliverables',
            title: 'Data deliverables',
            type: 'item',
            url: 'pages/tabsExcel/dataDeliverables',
          },
          {
            id: 'dataKpi',
            title: 'Data KPI',
            type: 'item',
            url: 'pages/tabsExcel/dataKpi',
          },
          {
            id: 'skillMatrix',
            title: 'Skill matrix',
            type: 'item',
            url: 'pages/modules/skillMatrix',
          },
          {
            id: 'training',
            title: 'Training',
            type: 'item',
            url:  'pages/modules/training',
          },
          {
            id: 'authorizationOfInspectors',
            title: 'Authorization of inspectors',
            type: 'item',
            url:  'pages/modules/authorizationOfInspectors',
          },

        ],
      },
      {

        //GESTIONES**************************

        id: 'gestiones',
        title: 'Management',
        type: 'collapse',
        icon: 'build',
        children: [

          {
            id: 'divisiones',
            title: 'Divisions',
            type: 'item',
            url: 'pages/gestiones/divisiones',
          },
          {
            id: 'contract',
            title: 'Contract',
            type: 'item',
            url: 'pages/management/contract',
          },
          {
            id: 'department',
            title: 'Department',
            type: 'item',
            url: 'pages/management/department',
          },
          {
            id: 'deliverables',
            title: 'Deliverables',
            type: 'item',
            url: 'pages/management/deliverables',
          },
          {
            id: 'process',
            title: 'Process',
            type: 'item',
            url: 'pages/gestiones/process',
          },
          {
            id: 'categories',
            title: 'Categories',
            type: 'item',
            url: 'pages/gestiones/categories',
          },
          {
            id: 'kpi',
            title: 'KPI',
            type: 'item',
            url: 'pages/gestiones/kpi',
          },
          {
            id: 'requirements',
            title: 'Requirements',
            type: 'item',
            url: 'pages/management/requirements',
          },
          {
            id: 'log',
            title: 'Log',
            type: 'item',
            url: 'pages/gestiones/log',
          },
          {
            id: 'rules',
            title: 'Standards',
            type: 'item',
            url: 'pages/gestiones/rules',
          },
          {
            id: 'PowerBiManagement',
            title: 'Business analytics',
            type: 'item',
            url: 'pages/gestiones/PowerBiManagement',
          },

        ],
      },

      //ADMINISTRACION
      {

        //USUARIOS**************************

        id: 'usuarios',
        title: 'User management',
        type: 'collapse',
        icon: 'person',
        children: [
          {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: 'pages/gestiones/users',
          },
          {
            id: 'customers',
            title: 'Customers',
            type: 'item',
            url: 'pages/gestiones/customers',
          },
          {
            id: 'logPerson',
            title: 'Movements of people',
            type: 'item',
            url: 'pages/gestiones/logPerson',
          },
          {
            id: 'permisos',
            title: 'Permissions group',
            type: 'item',
            url: 'pages/gestiones/permissionsGroup',
          },

        ],
      },

      {

        //POWER BI**************************

        id: 'powerBI',
        title: 'Business analytics',
        type: 'collapse',
        icon: 'FullscreenExit',
        // icon: <AddchartIcon/>,
        icon: "addchart",
        children: [
          {
            id: 'Performance',
            title: 'Performance',
            type: 'item',
            url: 'pages/powerBI/performance',
          },
          {
            id: 'Time management',
            title: 'Time management',
            type: 'item',
            url: 'pages/powerBI/timeManagement',
          },
          {
            id: 'Audits',
            title: 'Audits',
            type: 'item',
            url: 'pages/powerBI/Audits',
          },
          {
            id: 'Sales volume',
            title: 'Sales volume',
            type: 'item',
            url: 'pages/powerBI/SalesVolume',
          },
          {
            id: 'Summary',
            title: 'Summary',
            type: 'item',
            url: 'pages/powerBI/Summary',
          },
        ],
      },






    ],
  }

];

export default navigationConfig;
