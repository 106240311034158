export const DIRECTORATE_URL = process.env.REACT_APP_URL_DJANGO + "/api/direccionDepartamental/"

export const SUCESS_MESSAGE_CREATED_DIRECTORATE = {
    message: "Directorate successfully created",
    variant: "success"
}

export const SUCESS_MESSAGE_EDITED_DIRECTORATE = {
    message: "Directorate successfully updated",
    variant: "success"
}

export const ERROR_TRY_AGAIN = {
    message: "Error, try again",
    variant: "error"
}
