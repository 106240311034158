import {

    MOSTRAR_PROCESS,
    MOSTRAR_PROCESS_EXITO,
    MOSTRAR_PROCESS_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_PROCESS,
    CAMBIAR_VALOR_SELECCION_GRID_PROCESS_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_PROCESS_ERROR,

    PUT_PROCESS,
    PUT_PROCESS_EXITO,
    PUT_PROCESS_ERROR,

    INSERTAR_NEW_PROCESS,
    INSERTAR_NEW_PROCESS_EXITO,
    INSERTAR_NEW_PROCESS_ERROR,

    CAMBIAR_MODAL_NEW_PROCESS,
    CAMBIAR_MODAL_NEW_PROCESS_EXITO,
    CAMBIAR_MODAL_NEW_PROCESS_ERROR


} from './types';
import axios from 'axios'
import { showMessage } from 'app/store/fuse/messageSlice'
import {getCookie} from 'app/js/generalFunctions'
import { useDispatchError } from 'app/js/customHooks/GeneralHooks';

//Constantes globales
const urlProcessApi = process.env.REACT_APP_URL_DJANGO + "/api/process/"

//************************CAMBIAR VISIBILIDAD MODAL NUEVO USUARIO**********************************************

export function cambiarVisibilidadModalInsertarProcessAPI(valorNuevo, modoDialogProcess) {

    return (dispatch) => {
        dispatch (cambiarVisibilidadModalNuevoProcess())

        try {
            dispatch (cambiarVisibilidadModalNuevoProcessExito(valorNuevo, modoDialogProcess))

        } catch (error) {

            dispatch (cambiarVisibilidadModalNuevoProcessError(true))
        }
    }
}

const cambiarVisibilidadModalNuevoProcess = () => ({
    type: CAMBIAR_MODAL_NEW_PROCESS,
    payload: true

})

const cambiarVisibilidadModalNuevoProcessExito = (valorNuevo, modoDialogProcess) => ({
    type: CAMBIAR_MODAL_NEW_PROCESS_EXITO,
    nombre: modoDialogProcess,
    payload: valorNuevo

})

const cambiarVisibilidadModalNuevoProcessError = estado => ({
  type:  CAMBIAR_MODAL_NEW_PROCESS_ERROR,
  payload: estado
})

//************************ INSERTA RM_ACCION MODAL INSERTAR**********************************************

export function insertarNewProcessAPIAction(processData) {

    return async (dispatch) => {
        dispatch (createNewProcess())
             axios({
                    method: "POST",
                    url: urlProcessApi,
                    data: processData,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (createNewProcessExito(response.data))

                 dispatch(
                    showMessage({
                        message: "Process successfully created",
                        variant: "success"
                    })
                 ) 

                 dispatch(mostrarProcessAPIAction())

             })
             .catch(error => {
                console.log(error.response)
                const proccesErrorMessage = {
                    message: "Error creating Process",
                    variant: "error"
                }
                useDispatchError(dispatch, error, proccesErrorMessage)
                dispatch (createNewProcessError(true))

                // dispatch(
                //     showMessage({
                //         message: "Error creating Process",
                //         variant: "error"
                //     })
                // )
            })

    }
}

const createNewProcess = (customer) => ({
    type: INSERTAR_NEW_PROCESS,
    payload: customer

})

const createNewProcessExito = estado => ({
    type: INSERTAR_NEW_PROCESS_EXITO,
    payload: estado

})

const createNewProcessError = estado => ({
    type:  INSERTAR_NEW_PROCESS_ERROR,
    payload: estado
})

//PUT PROCESS

export function putProcessAPIAction(id,customer) {

    return async (dispatch) => {
        dispatch (putProcess())
             axios({
                    method: "PUT",
                    url: urlProcessApi + id,
                    data: customer,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (putProcessExito(response.data))

                 dispatch(
                    showMessage({
                        message: "Process successfully modified",
                        variant: "success"
                    })
                 ) 

                 dispatch(mostrarProcessAPIAction())

             })
             .catch(error => {
                console.log(error.response)

                const proccesErrorMessage = {
                        message: "Error modified Process",
                        variant: "error"
                }

                useDispatchError(dispatch, error, proccesErrorMessage)
                dispatch (putProcessError(true))

                // dispatch(
                //     showMessage({
                //         message: "Error modified Process",
                //         variant: "error"
                //     })
                // )
            })

    }
}

const putProcess = () => ({
    type: PUT_PROCESS,
    payload: true

})

const putProcessExito = estado => ({
    type: PUT_PROCESS_EXITO,
    payload: estado

})

const putProcessError = estado => ({
    type:  PUT_PROCESS_ERROR,
    payload: estado
})

//************************ CAMBIAR SELECCION GRID ACTION**********************************************

export function cambiarValorSeleccionAction(valorNuevo) {

    return (dispatch) => {
        dispatch (cambiarValorSeleccion())

        try {
            dispatch (cambiarValorSeleccionExito(valorNuevo))
            

        } catch (error) {

            dispatch (cambiarValorSeleccionError(true))
        }

    }

}

const cambiarValorSeleccion = () => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_PROCESS,
    payload: false

})

const cambiarValorSeleccionExito = valorNuevo => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_PROCESS_EXITO,
    payload: valorNuevo

})

const cambiarValorSeleccionError = estado => ({
  type: CAMBIAR_VALOR_SELECCION_GRID_PROCESS_ERROR,
  payload: estado
})

//************************ OBTENER PROCESOS RELACIONADOS **********************************************

export function mostrarProcessAPIAction() {

    return async (dispatch) => {
        dispatch (mostrarProcessAPI(true))

        
            await axios({
                method: "GET",
                url: urlProcessApi,
                headers: {
                    'Authorization': `Token ${getCookie('token')}` 
                        }
                
                    })
                     .then(response=>{
                        dispatch (mostrarProcessAPIExito(response.data))
                    })
                    .catch(error => {
                        console.log(error.response)
                        dispatch (mostrarProcessAPIError(true))
                    })

          }
}

export function obtenerAllProcesosAPIAction() {

    return async (dispatch) => {
        dispatch (mostrarProcessAPI(true))

        
            await axios({
                method: "GET",
                url: urlProcessApi,
                headers: {
                    'Authorization': `Token ${getCookie('token')}` 
                        }
                
                    })
                     .then(response=>{
                        dispatch (mostrarProcessAPIExito(response.data))
                    })
                    .catch(error => {
                        console.log(error.response)
                        dispatch (mostrarProcessAPIError(true))

                        
                    })

          }
}



export function obtenerProcesosMisionAPIAction(idMision) {

    return async (dispatch) => {
        dispatch (mostrarProcessAPI(true))

        if (idMision)
            await axios({
                method: "GET",
                url: urlProcessApi + "mision/" + idMision,
                headers: {
                    'Authorization': `Token ${getCookie('token')}` 
                        }
                
                    })
                     .then(response=>{
                        dispatch (mostrarProcessAPIExito(response.data))
                    })
                    .catch(error => {
                        console.log(error.response)
                        dispatch (mostrarProcessAPIError(true))

                        
                    })

          }
}

const mostrarProcessAPI = () => ({
    type: MOSTRAR_PROCESS,
    payload: false

})

const mostrarProcessAPIExito = programas => ({
    type: MOSTRAR_PROCESS_EXITO,
    payload: programas

})

const mostrarProcessAPIError = estado => ({
  type: MOSTRAR_PROCESS_ERROR,
  payload: estado
})