import React, {
  forwardRef,
  useImperativeHandle,
  memo,
  useRef,
  useEffect,
  useState,
  createContext,
} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import FuseScrollbars from '@fuse/core/FuseScrollbars'
import GlobalStyles from '@mui/material/GlobalStyles'
import { styled } from '@mui/material/styles'

import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import DashboardIcon from '@mui/icons-material/Dashboard'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import SupportIcon from '@mui/icons-material/Support'

import FusePageCardedHeader from './FusePageCardedHeader'
import FusePageCardedSidebar from './FusePageCardedSidebar'

import {
  cambiarValorVisibilidadAction,
  cambiarValorVisibilidadEstadoIncidenciasAction,
} from '../../../components/Gestion/Kanban/store/actions'

// -----------------------------------------------------------------------------
// Constants
// -----------------------------------------------------------------------------
const drawerWidth = 240
const headerHeight = 150
const toolbarHeight = 64
const headerContentHeight = headerHeight - toolbarHeight - 80
const bottomSafeArea = 0

// -----------------------------------------------------------------------------
// Context to pass currentLocationName to the whole App
// -----------------------------------------------------------------------------
export const CurrentLocationContext = createContext({
  currentLocationName: '',
  tabNameFromCurrentLocation: '',
  setTabNameFromCurrentLocation: () => {},
})

// -----------------------------------------------------------------------------
// Context to manage sidebar visibility
// -----------------------------------------------------------------------------
export const SidebarVisibilityContext = createContext({
  isSidebarVisible: false,
  toggleSidebarVisibility: () => {},
})

// -----------------------------------------------------------------------------
// Helper function: Determines the current location name based on the module name
// -----------------------------------------------------------------------------
const getLocationName = (moduleName) => {
  let prefix = ''

  const vectorUser = ['users', 'customers', 'logPerson', 'permissionsGroup']
  if (vectorUser.includes(moduleName)) {
    prefix = 'User management - '
  }

  const vectorModulos = [
    'riskmanagement',
    'peoplemanagement',
    'staffManagement',
    'improvementProposals',
    'dataDeliverables',
    'dataKpi',
    'skillMatrix',
    'trainingFollowUp',
    'authorizationOfInspectors',
  ]
  if (vectorModulos.includes(moduleName)) {
    prefix = 'Modules - '
  }

  const vectorManagment = [
    'divisiones',
    'programas',
    'deliverables',
    'contract',
    'department',
    'process',
    'categories',
    'kpi',
    'requirements',
    'log',
    'PowerBiManagement',
    'rules',
  ]
  if (vectorManagment.includes(moduleName)) {
    prefix = 'Management - '
  }

  const powerBi = ['performance', 'timeManagement', 'SalesVolume', 'Audits']
  if (powerBi.includes(moduleName)) {
    prefix = 'Business analytics - '
  }

  const audit = ['FOD', 'PRL']
  if (audit.includes(moduleName)) {
    prefix = 'Audit - '
  }

  switch (moduleName) {
    case 'riskmanagement':
      return prefix + 'Risk & Opportunities'
    case 'peoplemanagement':
      return prefix + 'My information -> Team'
    case 'improvementProposals':
      return prefix + 'Improvement Proposals'
    case 'kpi':
      return prefix + 'KPI'
    case 'skillMatrix':
      return prefix + 'Skill matrix'
    case 'dataDeliverables':
      return prefix + 'Data deliverables'
    case 'trainingFollowUp':
      return prefix + 'Training Follow-Up'
    case 'rules':
      return prefix + 'Standards'
    case 'PowerBiManagement':
      return prefix + 'Business analytics'
    case 'dataKpi':
      return prefix + 'Data KPI'
    case 'divisiones':
      return prefix + 'Divisions'
    case 'programas':
      return prefix + 'Programs'
    case 'logPerson':
      return prefix + 'Movements of people'
    case 'permissionsGroup':
      return prefix + 'Permissions Group'
    case 'timeManagement':
      return prefix + 'Time management'
    case 'SalesVolume':
      return prefix + 'Sales volume'
    case 'authorizationOfInspectors':
      return prefix + 'Authorization of inspectors'
    default:
      return prefix + moduleName.charAt(0).toUpperCase() + moduleName.slice(1)
  }
}

// -----------------------------------------------------------------------------
// Styled Root component
// -----------------------------------------------------------------------------
const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  minWidth: 0,
  height: '100vh', // fixed height for the main container
  overflow: 'hidden', // disable main page scrolling
  position: 'relative',
  width: '100%',
  backgroundColor: theme.palette.background.default,

  '& .FusePageCarded-innerScroll': {
    height: '100%',
  },

  '& .FusePageCarded-topBg': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: headerHeight,
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
    backgroundSize: 'cover',
    pointerEvents: 'none',
  },

  '& .FusePageCarded-contentWrapper': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    zIndex: 2,
    width: '100%',
    maxHeight: '100%',
    margin: '0 auto',
    padding: '0 1rem',
    paddingTop: '1rem',
  },

  '& .FusePageCarded-header': {
    height: headerContentHeight,
    minHeight: headerContentHeight,
    maxHeight: headerContentHeight,
    display: 'flex',
    color: theme.palette.primary.contrastText,
  },

  '& .FusePageCarded-headerSidebarToggleButton': {
    color: theme.palette.primary.contrastText,
  },

  '& .FusePageCarded-contentCard': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    minHeight: 0,
    borderRadius: '20px 20px 0px 0px',
  },

  '& .FusePageCarded-toolbar': {
    maxHeight: toolbarHeight,
    minHeight: toolbarHeight,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  // Modified content area with a bottom safe area
  '& .FusePageCarded-content': {
    flex: 1,
    maxHeight: `calc(100% - ${bottomSafeArea}px)`,
    paddingBottom: `${bottomSafeArea}px`,
    overflow: 'none',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '20px 20px 0px 0px',
    // [theme.breakpoints.down('sm')]: {
    //   maxHeight: `calc(100% - ${bottomSafeArea + 10}px)`,
    //   paddingBottom: `${bottomSafeArea + 10}px`
    // }
  },

  '& .FusePageCarded-sidebarWrapper': {
    position: 'absolute',
    backgroundColor: 'transparent',
    zIndex: 5,
    overflow: 'hidden',
    '&.permanent': {
      [theme.breakpoints.up('lg')]: {
        zIndex: 1,
        position: 'relative',
      },
    },
  },

  '& .FusePageCarded-sidebar': {
    position: 'absolute',
    '&.permanent': {
      [theme.breakpoints.up('lg')]: {
        backgroundColor: 'transparent',
        position: 'relative',
        border: 'none',
        overflow: 'hidden',
      },
    },
    width: drawerWidth,
    maxHeight: '100%',
  },

  '& .FusePageCarded-leftSidebar': {},
  '& .FusePageCarded-rightSidebar': {},

  '& .FusePageCarded-sidebarHeader': {
    maxHeight: headerHeight,
    minHeight: headerHeight,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
    '&.permanent': {
      [theme.breakpoints.up('lg')]: {
        backgroundColor: 'transparent',
      },
    },
  },

  '& .FusePageCarded-sidebarContent': {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    [theme.breakpoints.up('lg')]: {
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
    },
  },

  '& .FusePageCarded-backdrop': {
    position: 'absolute',
  },
}))

// -----------------------------------------------------------------------------
// FusePageCarded Component
// -----------------------------------------------------------------------------
const FusePageCarded = forwardRef((props, ref) => {
  const location = useLocation()
  const dispatch = useDispatch()

  const leftSidebarRef = useRef(null)
  const rightSidebarRef = useRef(null)
  const rootRef = useRef(null)

  const [open, setOpen] = useState(false)
  const [currentLocationName, setCurrentLocationName] = useState('')
  const [tabNameFromCurrentLocation, setTabNameFromCurrentLocation] = useState('')
  const [isSidebarVisible, setSidebarVisible] = useState(false)

  // ---------------------------------------------------------------------------
  // Handlers
  // ---------------------------------------------------------------------------
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const toggleSidebarVisibility = () => {
    setSidebarVisible(!isSidebarVisible)
  }

  // Redux actions
  const cambiarValorVisibilidad = (valorNuevo) =>
    dispatch(cambiarValorVisibilidadAction(valorNuevo))
  const cambiarValorVisibilidadEstadoIncidencias = (valorNuevo) =>
    dispatch(cambiarValorVisibilidadEstadoIncidenciasAction(valorNuevo))

  const isRightSidebar = props.rightSidebarHeader || props.rightSidebarContent
  const isLeftSidebar = props.leftSidebarHeader || props.leftSidebarContent

  useImperativeHandle(ref, () => ({
    rootRef,
    toggleLeftSidebar: () => {
      if (leftSidebarRef.current) {
        leftSidebarRef.current.toggleSidebar()
      }
    },
    toggleRightSidebar: () => {
      if (rightSidebarRef.current) {
        rightSidebarRef.current.toggleSidebar()
      }
    },
  }))

  // ---------------------------------------------------------------------------
  // Set the current location name (currentLocationName)
  // ---------------------------------------------------------------------------
  useEffect(() => {
    const pathSegments = location.pathname.split('/')
    const moduleName = pathSegments[3] || ''
    const locationName = getLocationName(moduleName)
    setCurrentLocationName(locationName)
    if (!['riskmanagement', 'improvementProposals'].includes(moduleName)) {
      setTabNameFromCurrentLocation('')
    }
  }, [location.pathname])

  return (
    <CurrentLocationContext.Provider
      value={{
        currentLocationName: currentLocationName,
        tabNameFromCurrentLocation: tabNameFromCurrentLocation,
        setTabNameFromCurrentLocation,
      }}
    >
      <SidebarVisibilityContext.Provider
        value={{
          isSidebarVisible,
          toggleSidebarVisibility,
        }}
      >
        <>
          <GlobalStyles
            styles={(theme) => ({
              '#fuse-main': {
                height: '100vh',
                overflow: 'hidden',
              },
            })}
          />
          <Root
            className={clsx(
              'FusePageCarded-root',
              props.innerScroll && 'FusePageCarded-innerScroll',
              props.className,
            )}
            ref={rootRef}
          >
            <div className="FusePageCarded-topBg" />

            <div className="flex container w-full">
              {isLeftSidebar && (
                <FusePageCardedSidebar
                  position="left"
                  header={props.leftSidebarHeader}
                  content={props.leftSidebarContent}
                  variant={props.leftSidebarVariant || 'permanent'}
                  innerScroll={props.innerScroll}
                  ref={leftSidebarRef}
                  rootRef={rootRef}
                />
              )}

              <div
                className={clsx(
                  'FusePageCarded-contentWrapper',
                  isLeftSidebar &&
                    (props.leftSidebarVariant === undefined ||
                      props.leftSidebarVariant === 'permanent') &&
                    'lg:ltr:pl-0 lg:rtl:pr-0',
                  isRightSidebar &&
                    (props.rightSidebarVariant === undefined ||
                      props.rightSidebarVariant === 'permanent') &&
                    'lg:pr-0',
                )}
              >
                <h3 style={{ color: '#FFFFFF' }}>{currentLocationName}</h3>

                <FusePageCardedHeader header={props.header} />

                <div
                  className={clsx(
                    'FusePageCarded-contentCard',
                    props.innerScroll && 'inner-scroll',
                  )}
                >
                  {props.contentToolbar && (
                    <div className="FusePageCarded-toolbar">{props.contentToolbar}</div>
                  )}

                  {props.content && <div className="FusePageCarded-content">{props.content}</div>}
                </div>
              </div>

              {isRightSidebar && (
                <FusePageCardedSidebar
                  position="right"
                  header={props.rightSidebarHeader}
                  content={props.rightSidebarContent}
                  variant={props.rightSidebarVariant || 'permanent'}
                  innerScroll={props.innerScroll}
                  ref={rightSidebarRef}
                  rootRef={rootRef}
                />
              )}
            </div>
          </Root>
        </>
      </SidebarVisibilityContext.Provider>
    </CurrentLocationContext.Provider>
  )
})

FusePageCarded.propTypes = {
  rightSidebarHeader: PropTypes.node,
  rightSidebarContent: PropTypes.node,
  rightSidebarVariant: PropTypes.node,
  leftSidebarHeader: PropTypes.node,
  leftSidebarContent: PropTypes.node,
  leftSidebarVariant: PropTypes.node,
  header: PropTypes.node,
  content: PropTypes.node,
  contentToolbar: PropTypes.node,
  innerScroll: PropTypes.bool,
}

FusePageCarded.defaultProps = {}

export default memo(styled(FusePageCarded)``)
