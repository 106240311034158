
import * as global from 'global.js'
import { createSlice } from "@reduxjs/toolkit"
import { getDirectorate, postDirectorate, putDirectorate } from './directorateThunks'

const initialState = {
    status: {
        getDirectorate: 'idle',
    },
    error: null,
    directorate: [],
    selectedDirectorateRow: null,
    modalVisibilityAction: { action: false, mode: null },
    modalData: {
        nameData: '',
        codeData: '',
        managersData: [],
        subDivisionData: null,
        descriptionData: ''
    },
    managerRoles: [global.rolN6, global.rolN7],
    usersManagers: []
}

const directorateSlice = createSlice({
    name: 'directorate',
    initialState,
    reducers: {
        setSelectedDirectorateRow: (state, action) => {
            state.selectedDirectorateRow = action.payload
        },
        setModalVisibilityAction: (state, action) => {
            state.modalVisibilityAction = action.payload
        },
        setModalData: (state, action) => {
            state.modalData = { ...state.modalData, ...action.payload }
        },
        resetModalData: (state) => {
            state.modalData = initialState.modalData
        },
        filterUsersManagers: (state, action) => {
            state.usersManagers = action.payload.filter(user =>
                state.managerRoles.includes(user.rolUser)
            );
        }
    },
    extraReducers: (builder) => {
        // Handling getDirectorate
        builder
            .addCase(getDirectorate.pending, (state) => {
                state.status.getDirectorate = 'pending'
                state.error = null
            })
            .addCase(getDirectorate.rejected, (state, action) => {
                state.status.getDirectorate = 'rejected'
                state.error = action.error.message
            })
            .addCase(getDirectorate.fulfilled, (state, action) => {
                state.status.getDirectorate = 'fulfilled'
                state.directorate = action.payload
            })

        // Handling postDirectorate
        builder
            .addCase(postDirectorate.pending, (state) => {
                state.status.postDirectorate = 'pending'
                state.error = null
            })
            .addCase(postDirectorate.rejected, (state, action) => {
                state.status.postDirectorate = 'rejected'
                state.error = action.error.message
            })
            .addCase(postDirectorate.fulfilled, (state, action) => {
                state.status.postDirectorate = 'fulfilled'
                state.directorate = [action.payload, ...state.directorate]
            })

        // Handling putDirectorate
        builder
            .addCase(putDirectorate.pending, (state) => {
                state.status.putDirectorate = 'pending'
                state.error = null
            })
            .addCase(putDirectorate.rejected, (state, action) => {
                state.status.putDirectorate = 'rejected'
                state.error = action.error.message
            })
            .addCase(putDirectorate.fulfilled, (state, action) => {
                state.status.putDirectorate = 'fulfilled'
                const index = state.directorate.findIndex(directorate => directorate.id === action.payload.id)
                if (index !== -1) {
                    state.directorate[index] = action.payload
                }
            })

    }
})

export const {
    setSelectedDirectorateRow,
    setModalVisibilityAction,
    setModalData,
    resetModalData,
    filterUsersManagers
} = directorateSlice.actions

export const directorate = state => state.fuse.directorate.directorate
export const directorateStatus = state => state.fuse.directorate.status.getDirectorate
export const selectedDirectorateRow = state => state.fuse.directorate.selectedDirectorateRow
export const modalVisibilityAction = state => state.fuse.directorate.modalVisibilityAction
export const modalData = state => state.fuse.directorate.modalData
export const usersManagers = state => state.fuse.directorate.usersManagers

export default directorateSlice.reducer
