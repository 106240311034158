import { createAsyncThunk } from '@reduxjs/toolkit'
import { showMessage } from 'app/store/fuse/messageSlice'
import axios from 'axios'
import { ERROR_TRY_AGAIN, SUCESS_ASIGN_PERSON, SUCESS_CREATE_JOB, SUCESS_DELETE } from './utils/consts'
import {
    DELETE_CONTRACT_USER_REQUEST_OBJ,
    GET_CONTRACT_USER_REQUEST_OBJ,
    GET_JOB_IN_SUBMISSION_REQUEST_OBJ,
    GET_USER_CONTRACTS_REQUEST_OBJ,
    POST_CONTRACT_USER,
    POST_JOB_IN_SUBMISSION_REQUEST_OBJ,
    PUT_CONTRACT_USER_REQUEST_OBJ
} from './utils/functions'


export const postPersonalInSubMission = createAsyncThunk(
    'insertEditTechnicianModal/postPersonalInSubMission',
    async (submissionData, { rejectWithValue, dispatch }) => {
        const { contractUserData, throwMesagge, submissionId } = submissionData
        try {
            const config = POST_CONTRACT_USER(contractUserData)
            const response = await axios(config)

            if (response.status !== 201) {
                dispatch(showMessage(ERROR_TRY_AGAIN))
                throw new Error(`Error: Received status code ${response.status}`)
            }

            throwMesagge && dispatch(showMessage(SUCESS_ASIGN_PERSON))

            return response.data
        } catch (error) {
            dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(
                error.response?.data?.message || 'Failed to post person'
            )
        }
    }
)

export const putPersonalInSubMission = createAsyncThunk(
    'insertEditTechnicianModal/putPersonalInSubMission',
    async (submissionData, { rejectWithValue, dispatch }) => {
        const { idContractUser, contractUserData } = submissionData
        try {
            const config = PUT_CONTRACT_USER_REQUEST_OBJ({ idContractUser, contractUserData })
            const response = await axios(config)

            if (response.status !== 200) {
                dispatch(showMessage(ERROR_TRY_AGAIN))
                throw new Error(`Error: Received status code ${response.status}`)
            }

            return response.data
        } catch (error) {
            dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(error.response?.data?.message || 'Failed to put person')
        }
    }
)

export const getJobInDepartmentSubmission = createAsyncThunk(
    'insertEditTechnicianModal/getJobInDepartmentSubmission',
    async (submissionData, { rejectWithValue, dispatch }) => {
        const { id, jobsFromSubmissionToSwitch = null } = submissionData
        try {
            const config = GET_JOB_IN_SUBMISSION_REQUEST_OBJ(id)
            const response = await axios(config)

            if (response.status !== 200) {
                dispatch(showMessage(ERROR_TRY_AGAIN))
                throw new Error(`Error: Received status code ${response.status}`)
            }

            if (jobsFromSubmissionToSwitch !== null) return { data: response.data, type: jobsFromSubmissionToSwitch ? 'switch' : 'selected' }
            return response.data
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Failed to get job')
        }
    }
)

export const postJobDepartmentSubMision = createAsyncThunk(
    'insertEditTechnicianModal/postJobDepartmentSubMision',
    async (submissionData, { rejectWithValue, dispatch }) => {
        try {
            const config = POST_JOB_IN_SUBMISSION_REQUEST_OBJ(submissionData)
            const response = await axios(config)

            if (response.status !== 201) {
                dispatch(showMessage(ERROR_TRY_AGAIN))
                throw new Error(`Error: Received status code ${response.status}`)
            }

            dispatch(getJobInDepartmentSubmission({ id: submissionData.sub_mision, jobsFromSubmissionToSwitch: false }))

            dispatch(showMessage(SUCESS_CREATE_JOB))
            return response.data
        } catch (error) {
            dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(
                error.response?.data?.message || 'Failed to post job'
            )
        }
    }
)

export const getAssignedPeopleToContract = createAsyncThunk(
    'insertEditTechnicianModal/assignedPeopleToContract',
    async (submissionData, { rejectWithValue, dispatch }) => {
        const { id } = submissionData
        try {
            const config = GET_CONTRACT_USER_REQUEST_OBJ(id)
            const response = await axios(config)

            if (response.status !== 200) {
                dispatch(showMessage(ERROR_TRY_AGAIN))
                throw new Error(`Error: Received status code ${response.status}`)
            }

            return response.data
        } catch (error) {
            dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(error.response?.data?.message || 'Failed to get job')
        }
    }
)

export const getUserContracts = createAsyncThunk(
    'insertEditTechnicianModal/getUserContracts',
    async (userId, { rejectWithValue, dispatch }) => {
        try {
            const config = GET_USER_CONTRACTS_REQUEST_OBJ(userId)
            const response = await axios(config)

            if (response.status !== 200) {
                dispatch(showMessage(ERROR_TRY_AGAIN))
                throw new Error(`Error: Received status code ${response.status}`)
            }

            return response.data
        } catch (error) {
            dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(error.response?.data?.message || 'Failed to get job')
        }
    }
)

export const deleteContractUser = createAsyncThunk(
    'insertEditTechnicianModal/deleteContractUser',
    async (contractUserData, { rejectWithValue, dispatch }) => {
        const { data, message } = contractUserData
        const { subMision_id } = data
        try {
            const config = DELETE_CONTRACT_USER_REQUEST_OBJ(data)
            const response = await axios(config)

            if (response.status !== 204) {
                dispatch(showMessage(ERROR_TRY_AGAIN))
                throw new Error(`Error: Received status code ${response.status}`)
            }

            message && dispatch(showMessage(SUCESS_DELETE))

            !message && dispatch(getAssignedPeopleToContract({ id: subMision_id }))

            return response.data
        } catch (error) {
            dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(error.response?.data?.message || 'Failed to get job')
        }
    }
)
