import { createContext } from 'react'
import { useDeliManagementReducer } from './internalReducer'

export const DeliverablesManagementContext = createContext(null)

export const DeliverablesManagementContextProvider =({children})=>{
    const {deliManagementState, reducerActions} = useDeliManagementReducer()
    const {setMissionSelected, setCompareModalVisibility,setCompareModalFilters } = reducerActions


    return(
        <DeliverablesManagementContext.Provider value={{
            deliManagementState, setMissionSelected, setCompareModalVisibility,setCompareModalFilters
        }}>
            {children}
        </DeliverablesManagementContext.Provider>
    )
}

