import { useState, useEffect, useMemo } from 'react'
import {
  Grid,
  TextField,
  Autocomplete,
  FormControl,
  IconButton,
  Alert,
  Typography,
  Popover,
  List,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
} from '@mui/material'

// MUI Icons
import InfoOutlined from '@mui/icons-material/InfoOutlined'

// Chart Related
import { Bar, Line } from 'react-chartjs-2'
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip as ChartTooltip,
  scales,
} from 'chart.js'
import PopOverInfo from 'components/GlobalComponents/PopOverInfo/PopOverInfo'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  RadialLinearScale,
  Title,
  ChartTooltip,
  Legend,
  Filler,
)

const Ecc = ({
  revalorando,
  efectoFalloActual,
  setEfectoFalloActual,
  causaFalloActual,
  setCausaFalloActual,
  controlesActual,
  setControlesActual,
  gravedadActual,
  setGravedadActual,
  ayudaSeverity,
  setAyudaSeverity,
  rowsSeverity,
  ocurrenciaActual,
  setOcurrenciaActual,
  ayudaFrequency,
  setAyudaFrequency,
  rowsFrequency,
  deteccionActual,
  setDeteccionActual,
  ayudaDetection,
  setAyudaDetection,
  rowsDetection,
  alertaTipo,
  nprActual,
  priorizacionActual,
  nprChartOptions,
  nprChart,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElSeverity, setAnchorElSeverity] = useState(null)
  const [anchorElFrequency, setAnchorElFrequency] = useState(null)
  const [anchorElDetection, setAnchorElDetection] = useState(null)

  const infoColumns = [
    { key: 'severity', label: 'Severity', align: 'left' },
    { key: 'criteria', label: 'Criteria', align: 'left' },
    { key: 'value', label: 'Value', align: 'right' },
  ]

  const nprTableData = [
    {
      npr: 'NPR>100',
      action: 'It is necessary to evaluate and take action',
      customCSS: 'bg-red-100 font-semibold border-hidden py-3',
    },
    {
      npr: 'NPR>80',
      action: 'The manager will decide whether to take action',
      customCSS: 'bg-yellow-100 font-medium border-hidden py-3',
    },
    {
      npr: 'NPR≤80',
      action: 'No action required',
      customCSS: 'bg-white border-hidden py-3',
    },
  ]

  const nprColumns = [
    { key: 'npr', label: 'NPR', align: 'center', customCSS: 'py-2' },
    { key: 'action', label: 'Action', align: 'left', customCSS: 'py-2 px-4' },
  ]

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handlePopoverOpenSeverity = (event) => {
    setAnchorElSeverity(event.currentTarget)
  }
  const handlePopoverCloseSeverity = () => {
    setAnchorElSeverity(null)
  }

  const handlePopoverOpenFrequency = (event) => {
    setAnchorElFrequency(event.currentTarget)
  }
  const handlePopoverCloseFrequency = () => {
    setAnchorElFrequency(null)
  }

  const handlePopoverOpenDetection = (event) => {
    setAnchorElDetection(event.currentTarget)
  }
  const handlePopoverCloseDetection = () => {
    setAnchorElDetection(null)
  }

  const open = Boolean(anchorEl)
  const openSeverity = Boolean(anchorElSeverity)
  const openFrequency = Boolean(anchorElFrequency)
  const openDetection = Boolean(anchorElDetection)

  const showChart = useMemo(() => gravedadActual && ocurrenciaActual && deteccionActual, [gravedadActual, ocurrenciaActual, deteccionActual])

  return (
    <>
      <Alert severity="info" variant="outlined" className={`rounded-md py-2 mb-5 ${revalorando ? '' : 'hidden'}`}>
        You are reevaluating the R&O as all actions created have been completed.
      </Alert>

      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={revalorando}
            label="Effect"
            multiline
            rows={11}
            id="efectoFallo"
            name='efectoFallo'
            value={efectoFalloActual}
            size="small"
            sx={{ width: '100%' }}
            onChange={(e) => setEfectoFalloActual(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={revalorando}
            label="Cause"
            multiline
            rows={11}
            id="causaFallo"
            name='causaFallo'
            value={causaFalloActual}
            size="small"
            sx={{ width: '100%' }}
            onChange={(e) => setCausaFalloActual(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={revalorando}
            label="Current controls"
            id="controlesActuales"
            name='controlesActuales'
            multiline
            rows={11}
            value={controlesActual || ''}
            size="small"
            sx={{ width: '100%' }}
            onChange={(e) => setControlesActual(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <div className="w-full flex flex-row justify-center items-center">
            <FormControl className="w-full relative">
              <Autocomplete
                options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
                getOptionLabel={(option) => option.toString()}
                id='gravedad'
                name='gravedad'
                value={gravedadActual || null}
                disabled={revalorando}
                onChange={(event, newValue) => setGravedadActual(newValue)}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Severity"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <PopOverInfo
                            tableColumns={infoColumns}
                            tableData={rowsSeverity}
                            anchorEl={anchorElSeverity}
                            setAnchorEl={setAnchorElSeverity}
                          />
                          {params.InputProps.endAdornment}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </div>
        </Grid>

        <Grid item xs={12} md={4}>
          <div className="w-full flex flex-row justify-center items-center">
            <FormControl className="w-full" fullWidth>
              <Autocomplete
                options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
                getOptionLabel={(option) => option.toString()}
                id='ocurrencia'
                name='ocurrencia'
                value={ocurrenciaActual || null}
                onChange={(event, newValue) => setOcurrenciaActual(newValue)}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Frequency"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <PopOverInfo
                            tableColumns={infoColumns}
                            tableData={rowsFrequency}
                            anchorEl={anchorElFrequency}
                            setAnchorEl={setAnchorElFrequency}
                          />
                          {params.InputProps.endAdornment}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </div>
        </Grid>

        <Grid item xs={12} md={4}>
          <div className="w-full flex flex-row justify-center items-center">
            <FormControl className="w-full" fullWidth>
              <Autocomplete
                options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
                getOptionLabel={(option) => option.toString()}
                id='deteccion'
                name='deteccion'
                value={deteccionActual || null}
                onChange={(event, newValue) => setDeteccionActual(newValue)}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Detection"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <PopOverInfo
                            tableColumns={infoColumns}
                            tableData={rowsDetection}
                            anchorEl={anchorElDetection}
                            setAnchorEl={setAnchorElDetection}
                          />
                          {params.InputProps.endAdornment}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className="radialbar flex justify-center items-center">
            <Alert
              severity={alertaTipo}
              className="mt-5 w-full max-w-sm text-center flex flex-col items-center"
              sx={{
                '.MuiAlert-icon': {
                  display: 'none',
                },
              }}
            >
              <Typography variant='h6' className="text-lg font-semibold">
                {nprActual === 'Severity, frequency and detection are required'
                  ? nprActual
                  : `NPR: ${nprActual}`}
              </Typography>

              <div className="flex justify-center items-center mt-2">
                <strong>{priorizacionActual}</strong>
                <PopOverInfo
                  tableColumns={nprColumns}
                  tableData={nprTableData}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                />
              </div>

              {(nprChart && showChart) && (
                <div className="h-20 w-fit my-2">
                  <Bar options={nprChartOptions} data={nprChart} />
                </div>
              )}
            </Alert>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default Ecc
