import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';



// Ç El filtro establece la fecha con un mes menos al real, por lo que debemos duplicar el estado de fecha y crear uno para el filtro (para que muestre la fecha que hemos seleccionado) y establecer para dateSelected la fecha seleccionada + 1 month
export const DateYearFilterYMD = ({ disabled = false, componentContextDate, setFatherDate, label }) => {


    // * States
    const [filterDateState, setFilterDateState] = useState(null)

    // * Functions
    const setFilterDate = (dateValue) => {
        setFilterDateState(dateValue)
        if (!dayjs(dateValue)?.isValid()) { setFatherDate(null); return }
        setFatherDate(dateValue)
    }

    // useEffect(() => !componentContextDate && setFilterDateState(null), [componentContextDate])
    useEffect(() =>  setFilterDateState(!componentContextDate ? null : componentContextDate), [componentContextDate])
    useEffect(() => componentContextDate && disabled && setFilterDateState(componentContextDate), [componentContextDate, disabled])

    // * Component
    return (
        <FormControl variant="standard" fullWidth sx={{ height: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                <DatePicker
                    disabled={disabled}
                    label={label}
                    fullWidth
                    id="fechaInicial"
                    inputFormat="DD-MM-YYYY"
                    format="DD-MM-YYYY"
                    value={filterDateState}
                    minDate={dayjs().subtract(5, 'year').toDate()} // Establecer el año mínimo permitido
                    maxDate={dayjs()}
                    onChange={dateValue => setFilterDate(dateValue)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            size="small"
                            fullWidth
                            sx={{ height: '100%' }}
                        />
                    )}
                />
            </LocalizationProvider>
        </FormControl>
    )

}
