import { createSlice } from "@reduxjs/toolkit"
import * as global from 'global'
import { createDepartmentSubMission, getAllDepartmentSubMissions, updateDepartmentSubMission } from './technicianThunks'

const initialState = {
    status: {
        getAllSubMissions: 'idle',
        getAllMissions: 'idle',
        update: 'idle',
        create: 'idle',
    },
    error: null,
    subMissionData: [],
    missionData: [],
    usersData: [],
    usersManagers: [],
    selectedTechnicianRow: null,
    insertModal: { action: false, mode: null },
    jobsModal: false,
    peopleModal: false,
    managerFilters: [global.rolN1, global.rolN2, global.rolN3, global.rolN4, global.rolN5, global.rolN6, global.rolN7]
}

const technicianSlice = createSlice({
    name: 'technicians',
    initialState,
    reducers: {
        setSelectedTechnicianRow: (state, action) => {
            state.selectedTechnicianRow = action.payload
        },
        setInsertModal: (state, action) => {
            state.insertModal = {
                action: action.payload.action,
                mode: action.payload.mode
            }
        },
        setJobsModal: (state, action) => {
            state.jobsModal = action.payload
        },
        setPeopleModal: (state, action) => {
            state.peopleModal = action.payload
        },
        addOrUpdateData: (state, action) => {
            const newData = action.payload
            const index = state.subMissionData.findIndex(item => item.id === newData.id)
            if (index !== -1) {
                state.subMissionData[index] = newData
            } else {
                state.subMissionData.push(newData)
            }
        },
        clearError: (state) => {
            state.error = null
        },
        filterUsersManagers: (state, action) => {
            state.usersManagers = action.payload.filter(user =>
                state.managerFilters.includes(user.rolUser)
            );
        }
    },
    extraReducers: builder => {
        // Handling getAllDepartmentSubMissions
        builder
            .addCase(getAllDepartmentSubMissions.pending, (state) => {
                state.status.getAllSubMissions = 'pending'
                state.error = null
            })
            .addCase(getAllDepartmentSubMissions.rejected, (state, action) => {
                state.status.getAllSubMissions = 'rejected'
                state.error = action.error.message
            })
            .addCase(getAllDepartmentSubMissions.fulfilled, (state, action) => {
                state.status.getAllSubMissions = 'fulfilled'
                state.subMissionData = action.payload
            })

        // Handling updateDepartmentSubMission
        builder
            .addCase(updateDepartmentSubMission.pending, (state) => {
                state.status.update = 'pending'
                state.error = null
            })
            .addCase(updateDepartmentSubMission.rejected, (state, action) => {
                state.status.update = 'rejected'
                state.error = action.error.message
            })
            .addCase(updateDepartmentSubMission.fulfilled, (state, action) => {
                state.status.update = 'fulfilled'
                state.subMissionData = state.subMissionData.map(item =>
                    item.id === action.payload.id ? action.payload : item
                )
            })

        // Handling createDepartmentSubMission
        builder
            .addCase(createDepartmentSubMission.pending, (state) => {
                state.status.create = 'pending'
                state.error = null
            })
            .addCase(createDepartmentSubMission.rejected, (state, action) => {
                state.status.create = 'rejected'
                state.error = action.error.message
            })
            .addCase(createDepartmentSubMission.fulfilled, (state, action) => {
                state.status.create = 'fulfilled'
                state.subMissionData = [...state.subMissionData, action.payload]
            })
    }
})

export const departamentalSubMissions = state => state.fuse.technicians.subMissionData
export const users = state => state.fuse.technicians.usersData
export const usersManagers = state => state.fuse.technicians.usersManagers
export const departamentalSubMissionsStatus = state => state.fuse.technicians.status.getAllSubMissions
export const updateDepartmentSubMissionStatus = state => state.fuse.technicians.status.update
export const createDepartmentSubMissionStatus = state => state.fuse.technicians.status.create
export const selectedTechnicianRow = state => state.fuse.technicians.selectedTechnicianRow
export const insertModal = state => state.fuse.technicians.insertModal
export const jobsModal = state => state.fuse.technicians.jobsModal
export const peopleModal = state => state.fuse.technicians.peopleModal

export const {
    setSelectedTechnicianRow,
    setInsertModal,
    setJobsModal,
    setPeopleModal,
    addOrUpdateData,
    addOrUpdateMissions,
    clearError,
    filterUsersManagers
} = technicianSlice.actions

export default technicianSlice.reducer
