import { useSelector } from "react-redux"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { SCOPE_NAMES_JOB_MODAL } from "../../utils/consts";


export const UserCanBeInspector = () => {
    const { userScope } = useSelector(({ fuse }) => fuse.inspectorsComponent)

    return (
        <div className='pt-3'>

            <FormGroup className="flex-row">
                {
                    Object.keys(SCOPE_NAMES_JOB_MODAL).map(scopeCode => (
                        <FormControlLabel key={scopeCode} control={<Checkbox checked={userScope[scopeCode]} />} label={SCOPE_NAMES_JOB_MODAL[scopeCode]} />
                    ))
                }
            </FormGroup>
        </div>
    )
}
