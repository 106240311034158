import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getRequestAuthHeader } from 'app/js/generalFunctions'
import axios from 'axios'


const urlDeliverablesApi = process.env.REACT_APP_URL_DJANGO + "/api/deliverables"


const INITIAL_STATE = {
    error: false,
    loading: false,
    deliverablesManagentList : []
}

export const getDeliverablesList = createAsyncThunk(
    'managementDeliverables/getDeliverables',
    async({missionSelected}, thunkAPI)=>{
        try{
            const queryString = new URLSearchParams({
                missionSelected: missionSelected
            }).toString()

            const response = await axios({
                url: `${urlDeliverablesApi}?${queryString}`,
                headers: getRequestAuthHeader(),
              });
              
              if(response.status !== 200) throw new Error('error getting delibverables')
        
              return response.data

        }
        catch (e) {
            console.log("🚀 ~ async ~ e:", e)
            return thunkAPI.rejectWithValue(
                error.response ? error.response.data : new Error('Network error')
            )
        }
    }
)

const deliverablesManagementSlice = createSlice({
    name: 'deliverablesManagement',
    initialState: INITIAL_STATE,

    extraReducers:(builder)=>{
        builder.addCase(getDeliverablesList.pending, (state)=>{
            state.error= false
            state.deliverablesManagentList = []
            state.loading= true
        }),
        builder.addCase(getDeliverablesList.fulfilled, (state, {payload})=>{
            state.error= false
            state.loading= false
            state.deliverablesManagentList = payload
        })
        builder.addCase(getDeliverablesList.rejected, (state, action)=>{
            state.loading= false
            state.deliverablesManagentList = []
            state.error = action.error.message
        })
    }
})

export default deliverablesManagementSlice.reducer