import { React, useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'components/GlobalComponents/tables/Table2'
import { RYO_COLUMNS } from '../utils/sectionColumns'
import {
  cambiarValorSeleccionRyOAction,
  visibilidadModalActionRequired,
  saveRyOIdAction,
  actionIsRequiredAction,
} from '../store/RyO/actions'
import { useGetRyOs, useGetMyRyOs } from '../hooks/RyO/RyOMainSectionHooks'
import { BotonesSuperioresRyO } from '../components/Buttons/RyOButtons'
import ModalInsertarRyO from '../modals/RyO/insertRyO'
import { ModalEliminarRyO } from '../modals/RyO/eliminarRyO'
import { ModalRyOHistory } from '../modals/RyO/historialRyO'
import { HistorialActions } from '../modals/RyO/historialActions'
import DetallesLogRisk from '../../../Managment/LogRisk/modals/detallesLogRisk'
import { LinearLoader } from 'components/GlobalComponents/loaders/loader'
import PedingActionAdvise from '../modals/ActionPlanModal/PendingActionAdvise'
import { RyOActions } from '../features/actionPlan/ryoActionPlanSlice'

const RyO = ({ tableRowFunctions, setLoadingActions, globalFilterValue }) => {
  const dispatch = useDispatch()
  const { mostrarRyOAPI } = useGetRyOs()
  const [loading, setLoading] = useState(true)
  const [pendingActions, setPendingActions] = useState([])

  const needToTakeActionRef = useRef(null)

  const { handleClearRowSelected, selectedRowFunctions } = tableRowFunctions

  const fetchDataCalled = useRef(false)

  const { RyOListAPI: RyOList, noRevRyOICanSee, visibilidadModalInsertar: visibilidadModalInsertarRO, dismissed } = useSelector((state) => state.fuse.RyOComponente)
  const { visibilidad: ryoActionsModal } = useSelector(
    (state) => state.fuse.tasksAccionesComponente,
  )
  const actionsList = useSelector(RyOActions)

  const cambiarValorSeleccionRO = (valor) => dispatch(cambiarValorSeleccionRyOAction(valor))

  // Fetch data only if lists are empty and fetchData has not been triggered
  useEffect(() => {
    const fetchData = async () => {
      if (!fetchDataCalled.current) {
        setLoading(true)
        fetchDataCalled.current = true

        // Fetch data if RyOList is empty
        if (!RyOList.length) {
          await mostrarRyOAPI()
        }

        setLoadingActions(false)
        setLoading(false)
        cambiarValorSeleccionRO('')
      }
    }

    // Only call fetchData if RyOList or actionsList are empty
    if (!RyOList.length) {
      fetchData()
    } else {
      setLoading(false)
      setLoadingActions(false)
    }
  }, [])

  useGetMyRyOs()

  // Handling the action check logic in useEffect
  const checkPendingActions = useCallback(() => {
    if (
      !noRevRyOICanSee.length ||
      !actionsList.length ||
      ryoActionsModal ||
      visibilidadModalInsertarRO
    ) {
      return
    }

    const ryoIdsNprHigherThan100 = noRevRyOICanSee.filter((ryo) => parseInt(ryo.npr, 10) > 100)
    const ryoIdsWithActions = actionsList.map((action) => action.id_record)
    const ryoNoHaveActions = ryoIdsNprHigherThan100
      .filter((ryo) => !ryoIdsWithActions.includes(ryo.id))
      .map((ryo) => ryo)
    setPendingActions(ryoNoHaveActions || [])

    needToTakeActionRef.current = ryoNoHaveActions

    // Only show modal if there are pending actions and it's not dismissed
    if (
      ryoNoHaveActions.length &&
      !ryoActionsModal &&
      !visibilidadModalInsertarRO &&
      !dismissed
    ) {
      dispatch(saveRyOIdAction(ryoNoHaveActions[0]))
      dispatch(actionIsRequiredAction(true))
      dispatch(visibilidadModalActionRequired(true, false))
    } else {
      dispatch(visibilidadModalActionRequired(false, true))
    }
  }, [
    noRevRyOICanSee,
    actionsList,
    ryoActionsModal,
    visibilidadModalInsertarRO,
    dismissed,
    dispatch,
  ])

  useEffect(() => {
    checkPendingActions()
  }, [checkPendingActions])

  const ryoFilteredList = useMemo(() => {
    if (!globalFilterValue) return noRevRyOICanSee
    return noRevRyOICanSee.filter(item => item.amfe_name.includes(globalFilterValue.title))
  }, [noRevRyOICanSee, globalFilterValue])

  return (
    <>
      {loading ? (
        <LinearLoader />
      ) : (
        <>
          <BotonesSuperioresRyO />
          <Table
              rowsProp={ryoFilteredList}
              columnsProp={RYO_COLUMNS}
              loading={loading}
              funcionSetValue={cambiarValorSeleccionRO}
              selectedRowFunctions={selectedRowFunctions}
              headerTabs={true}
          />
          <ModalInsertarRyO handleClearRowSelected={handleClearRowSelected} />
          <ModalEliminarRyO handleClearRowSelected={handleClearRowSelected} />
          <ModalRyOHistory handleClearRowSelected={handleClearRowSelected} />
            <DetallesLogRisk handleClearRowSelected={handleClearRowSelected} />
            <HistorialActions handleClearRowSelected={handleClearRowSelected} />
          <PedingActionAdvise pendingActions={pendingActions} />
        </>
      )}
    </>
  )
}

export default RyO
