import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { GET_AGRESSO_CODES_REQUEST_OBJ, GET_ALL_DEPARTMENT_MISSIONS_REQUEST_OBJ, GET_SITES_REQUEST_OBJ, GET_VISUAL_TIME_GROUPS_REQUEST_OBJ, POST_TEAM_REQUEST_OBJ, PUT_TEAM_REQUEST_OBJ } from './utils/functions'
import { ERROR_TRY_AGAIN, SUCESS_MESSAGE_CREATED_TEAM, SUCESS_MESSAGE_EDITED_TEAM } from './utils/const'
import { showMessage } from 'app/store/fuse/messageSlice'

export const getTeams = createAsyncThunk(
    'team/getTeams',
    async (_, { rejectWithValue }) => {
        try {
            const config = GET_ALL_DEPARTMENT_MISSIONS_REQUEST_OBJ()

            const response = await axios(config)

            if (response.status !== 200) {
                throw new Error(`Error: Received status code ${response.status}`)
            }

            return response.data
        } catch (error) {
            return rejectWithValue(
                error || 'Failed to fetch teams'
            )
        }
    }
)

export const postTeam = createAsyncThunk(
    'team/postTeam',
    async (newTeam, { rejectWithValue, dispatch }) => {
        try {
            const config = POST_TEAM_REQUEST_OBJ(newTeam)

            const response = await axios(config)

            if (response.status !== 201) throw new Error(`Error: Recieved status code ${response.status}`)

            dispatch(showMessage(SUCESS_MESSAGE_CREATED_TEAM))

            const data = { id: response.data.id, ...newTeam }
            return data
        } catch (error) {
            dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(
                error || 'Failed to post data'
            )
        }
    }
)

export const putTeam = createAsyncThunk(
    'team/putTeam',
    async (dataToEdit, { rejectWithValue, dispatch }) => {
        const { id } = dataToEdit
        try {
            const config = PUT_TEAM_REQUEST_OBJ(id, dataToEdit)

            const response = await axios(config)

            if (response.status !== 200) throw new Error(`Error: Recieved status code ${response.status}`)

            dispatch(showMessage(SUCESS_MESSAGE_EDITED_TEAM))

            return dataToEdit
        } catch (error) {
            dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(
                error || 'Failed to put data'
            )
        }
    }
)

export const getAgressoCodes = createAsyncThunk(
    'team/getAgressoCodes',
    async (_, { rejectedWithValue }) => {
        try {
            const config = GET_AGRESSO_CODES_REQUEST_OBJ()

            const response = await axios(config)

            if (response.status !== 200) throw new Error(`Error: Recieved status code ${response.status}`)

            return response.data
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || 'Failed to fetch data'
            )
        }
    }
)

export const getVisualTimeGroups = createAsyncThunk(
    'team/getVisualTimeGroups',
    async (_, { rejectWithValue }) => {
        try {
            const config = GET_VISUAL_TIME_GROUPS_REQUEST_OBJ()

            const response = await axios(config)

            if (response.status !== 200) throw new Error(`Error: Recieved status code ${response.status}`)

            return response.data
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message ||'Failed to fetch data'
            )
        }
    }
)

export const getSites = createAsyncThunk(
    'team/getSites',
    async (_, { rejectWithValue }) => {
        try {
            const config = GET_SITES_REQUEST_OBJ()

            const response = await axios(config)

            if (response.status !== 200) throw new Error(`Error: Recieved status code ${response.status}`)

            return response.data
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || 'Failed to fetch data'
            )
        }
    }
)
