import {

    GET_SUB_CONJUNTO,
    GET_SUB_CONJUNTO_EXITO,
    GET_SUB_CONJUNTO_ERROR,

    POST_SUB_CONJUNTO,
    POST_SUB_CONJUNTO_EXITO,
    POST_SUB_CONJUNTO_ERROR,

    PUT_SUB_CONJUNTO,
    PUT_SUB_CONJUNTO_EXITO,
    PUT_SUB_CONJUNTO_ERROR,

    CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO,
    CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO_EXITO,
    CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO,
    CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO_ERROR,

    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO_EXITO,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO_ERROR,


} from './types';
import axios from 'axios'
import {getCookie} from 'app/js/generalFunctions'
import { showMessage } from 'app/store/fuse/messageSlice'


//Constantes
const urlSubConjuntoApi = process.env.REACT_APP_URL_DJANGO + "/api/subset/"


//************************ MOSTRAR PROGRAMAS API **********************************************

export function mostrarSubConjuntosAPIAction() {

    return async (dispatch) => {
        dispatch (mostrarSubConjuntosAPI(true))

        
            await axios({
                method: "GET",
                url: urlSubConjuntoApi,
                headers: {
                    'Authorization': `Token ${getCookie('token')}` 
                        }
                
                    })
                     .then(response=>{
                        dispatch (mostrarSubConjuntosAPIExito(response.data)) 
                    })
                    .catch(error => {
                        console.log(error.response)
                        dispatch (mostrarSubConjuntosAPIError(true))
                    })

          }
}

const mostrarSubConjuntosAPI = () => ({
    type: GET_SUB_CONJUNTO,
    payload: true

})

const mostrarSubConjuntosAPIExito = programas => ({
    type: GET_SUB_CONJUNTO_EXITO,
    payload: programas

})

const mostrarSubConjuntosAPIError = estado => ({
  type: GET_SUB_CONJUNTO_ERROR,
  payload: estado
})


//************************POST CONJUNTOS**********************************************

export function crearSubConjuntoActionAPIAction(conjunto) {

    return  (dispatch) => {
        dispatch (crearSubConjunto())

            
             axios({
                    method: "POST",
                    url: urlSubConjuntoApi,
                    data: conjunto,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (crearSubConjuntoExito(response.data))
                 dispatch(
                    showMessage({
                        message: "Created Sub Assembly",
                        variant: "success"
                    })
                 )
                 dispatch(mostrarSubConjuntosAPIAction())
             })
             .catch(error => {
                console.log(error.response)
                dispatch (crearSubConjuntoError(true))

                dispatch(
                    showMessage({
                        message: "Error when created Sub Assembly",
                        variant: "error"
                    })
                )
            })

    }
}

const crearSubConjunto = (programa) => ({
    type: POST_SUB_CONJUNTO,
    payload: programa

})

const crearSubConjuntoExito = estado => ({
    type: POST_SUB_CONJUNTO_EXITO,
    payload: estado

})

const crearSubConjuntoError = estado => ({
    type:  POST_SUB_CONJUNTO_ERROR,
    payload: estado
})

//************************PUT CONJUNTO**********************************************


export function updateSubConjuntoActionAPIAction(id, json) {

    return async (dispatch) => {
        dispatch (updateSubConjunto())

             axios({
                    method: "PUT",
                    url: urlSubConjuntoApi + id,
                    data: json,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (updateSubConjuntoExito(response.data))

                 if (response.data['active'] == false) {
                    dispatch(
                        showMessage({
                            message: "Delete Sub Assembly",
                            variant: "success"
                        })
                    )
                }
                else {

                    dispatch(
                        showMessage({
                            message: "Updated Sub Assembly",
                            variant: "success"
                        })
                    )
                }
                

                 dispatch(mostrarSubConjuntosAPIAction())

             })
             .catch(error => {
                console.log(error.response)
                dispatch (updateSubConjuntoError(true))

                dispatch(
                    showMessage({
                        message: "Error when updating Sub Assembly",
                        variant: "error"
                    })
                )
            })

    }
}

const updateSubConjunto = (rm_accion) => ({
    type: PUT_SUB_CONJUNTO,
    payload: rm_accion

})

const updateSubConjuntoExito = estado => ({
    type: PUT_SUB_CONJUNTO_EXITO,
    payload: estado

})

const updateSubConjuntoError = estado => ({
    type:  PUT_SUB_CONJUNTO_ERROR,
    payload: estado
})

//************************CAMBIAR VISIBILIDAD ACTION**********************************************

export function cambiarValorVisibilidadAction(valorNuevo) {

    return (dispatch) => {
        dispatch (cambiarValorVisibilidad())

        try {
            dispatch (cambiarValorVisibilidadExito(valorNuevo))

        } catch (error) {

            dispatch (cambiarValorVisibilidadError(true))
        }

    }

}

const cambiarValorVisibilidad = () => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO,
    payload: true

})

const cambiarValorVisibilidadExito = valorNuevo => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO_EXITO,
    payload: valorNuevo

})

const cambiarValorVisibilidadError = estado => ({
  type: CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO_ERROR,
  payload: estado
})

//************************ CAMBIAR SELECCION GRID ACTION**********************************************

export function cambiarValorSeleccionAction(valorNuevo) {

    return (dispatch) => {
        dispatch (cambiarValorSeleccion())

        try {
            dispatch (cambiarValorSeleccionExito(valorNuevo))

        } catch (error) {

            dispatch (cambiarValorSeleccionError(true))
        }

    }

}

const cambiarValorSeleccion = () => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO,
    payload: false

})

const cambiarValorSeleccionExito = valorNuevo => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO_EXITO,
    payload: valorNuevo

})

const cambiarValorSeleccionError = estado => ({
  type: CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO_ERROR,
  payload: estado
})

//************************CAMBIAR VISIBILIDAD MODAL INSERTAR**********************************************

export function cambiarVisibilidadModalInsertarSubConjuntoAction(valorNuevo, modo) {

    return (dispatch) => {
        dispatch (cambiarVisibilidadModalInsertar())

        try {
            dispatch (cambiarVisibilidadModalInsertarExito(valorNuevo, modo))

        } catch (error) {

            dispatch (cambiarVisibilidadModalInsertarError(true))
        }
    }
}

const cambiarVisibilidadModalInsertar = () => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO,
    payload: true

})

const cambiarVisibilidadModalInsertarExito = (valorNuevo, modo) => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO_EXITO,
    payload: valorNuevo,
    modo: modo

})

const cambiarVisibilidadModalInsertarError = estado => ({
  type:  CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO_ERROR,
  payload: estado
})

