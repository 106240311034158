import { lazy, Suspense, useState } from 'react'
import FusePageCarded from '@fuse/core/FusePageCarded';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import { LinearLoader } from 'components/GlobalComponents/loaders/loader';
import { CONTAINER_STYLES } from 'components/TabsExcel/SkillMatrix/utils/consts';

const RequirementsRoles = lazy(()=> import('./Sections/Roles'))


export const RequirementsPage = () => {

    const [tabValue, settabValue] = useState('roles')
    const setTabValue = (e, tabValue) => settabValue(tabValue);




    const modalView = {
        roles:
            <Suspense fallback={<LinearLoader />}>
                <RequirementsRoles />
            </Suspense>,

    }

    return (
        <FusePageCarded

            content={
                    <Box >
                        <div style={CONTAINER_STYLES}>

                            <TabContext value={tabValue}>
                                <Tabs
                                    value={tabValue}
                                    onChange={setTabValue}
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                    aria-label="secondary tabs example"
                                >
                                    <Tab value={'roles'} label={'Roles'} />

                                </Tabs>






                            </TabContext>
                        </div>



                        {modalView[tabValue]}





                    </Box>

            }
        />
    );
}
