import { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormControl from '@mui/material/FormControl'
import FusePageCarded from '@fuse/core/FusePageCarded'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { SKILL_MATRIX_TAB_LABELS, SKILL_MATRIX_TAB_VALUES } from './utils/consts'
import { SkillMatrixProvider } from './utils/providerSkillMatrix'
import { useGetPermissions } from 'app/js/customHooks/PermissionsHooks'
import { getAllMissionsAction } from 'components/Managment/Contract/Missions/store/actions'
import { AutoCompleteByName } from 'components/GlobalComponents/ModalsFilters/AutocompleteFields'
import Requirements from './Requirements/Requirements.jsx'
import TeamTraining from './TeamTraining/TeamTraining.jsx'
import Experience from './Experience/Experience.jsx'
import ConfirmationModal from 'components/GlobalComponents/modals/Confirmation/ConfirmationModal'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { getCookie } from 'app/js/generalFunctions'

export const SkillMatrix = () => {
  const dispatch = useDispatch()
  const [tabValue, setTabValue] = useState(SKILL_MATRIX_TAB_VALUES.requirements)
  const [missionSelected, setMissionSelected] = useState(null)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const token = getCookie('token')

  const { departamentalAndNoDepartamentalMissionsInfo } = useSelector(
    (state) => state.fuse.userComponente,
  )
  const { allMissionsList } = useSelector((state) => state.fuse.missionsReducer)
  const { canSeeAllPermission } = useGetPermissions()
  const canSeeAllSkillMatrix = useMemo(
    () => canSeeAllPermission('skill matrix'),
    [canSeeAllPermission],
  )

  useEffect(() => {
    if (canSeeAllSkillMatrix && !allMissionsList.length) {
      dispatch(getAllMissionsAction())
    }
  }, [canSeeAllSkillMatrix, allMissionsList.length, dispatch])

  const modalView = {
    [SKILL_MATRIX_TAB_VALUES.requirements]: <Requirements missionSelected={missionSelected} />,
    [SKILL_MATRIX_TAB_VALUES.teamTraining]: <TeamTraining missionSelected={missionSelected} />,
    [SKILL_MATRIX_TAB_VALUES.experience]: <Experience missionSelected={missionSelected} />,
  }

  const handleModalStatus = () => {
    setIsOpenModal((prev) => !prev)
  }

  const handleGoToSkillMatrix = () => {
    window.open(`http://192.168.1.90:8501/?token=${token}`, '_blank')
  }

  return (
    <FusePageCarded
      content={
        <section className="flex flex-col h-full relative">
          <ConfirmationModal
            open={isOpenModal}
            onClose={handleModalStatus}
            onDecline={() => {}}
            closeOutter={() => {}}
            onConfirm={handleGoToSkillMatrix}
            confirmColor="primary"
            declineColor="error"
            title="Skill Matrix External Link"
            contentTitle="VPN Connection Required"
            secondaryContent="Please make sure you are connected to the VPN before opening the link"
            confirmText={
              <>
                <span className="text-sm pr-10">I'm sure that I've connected the VPN</span>
                <OpenInNewIcon className="text-[18px]" />
              </>
            }
            declineText="Cancel"
          />
          <Grid container spacing={0}>
            <Grid item xs={12} md={8} className="flex flex-row items-center justify-between">
              <Tabs
                value={tabValue}
                onChange={(event, value) => setTabValue(value)}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab
                  value={SKILL_MATRIX_TAB_VALUES.requirements}
                  label={SKILL_MATRIX_TAB_LABELS.requirements}
                />
                <Tab
                  value={SKILL_MATRIX_TAB_VALUES.teamTraining}
                  label={SKILL_MATRIX_TAB_LABELS.teamTraining}
                />
                <Tab
                  value={SKILL_MATRIX_TAB_VALUES.experience}
                  label={SKILL_MATRIX_TAB_LABELS.experience}
                />
              </Tabs>
              <Button
                onClick={handleModalStatus}
                className="px-5 py-2 mt-auto mb-1 rounded-[4px] h-[35px]"
                variant="outlined"
                color="secondary"
              >
                <span className="text-sm pr-10">Go to Skill Matrix</span>
                <OpenInNewIcon className="text-[18px]" />
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              className={tabValue === '' ? 'hidden' : 'flex justify-end px-4 pt-3 gap-1'}
              sx={{ marginTop: { xs: '10px', md: '0px' } }}
            >
              <FormControl variant="standard" fullWidth>
                <AutoCompleteByName
                  options={
                    canSeeAllSkillMatrix
                      ? allMissionsList
                      : departamentalAndNoDepartamentalMissionsInfo
                  }
                  value={missionSelected}
                  setValueFunc={setMissionSelected}
                  label={
                    canSeeAllSkillMatrix && !allMissionsList.length
                      ? 'Loading missions'
                      : 'Missions'
                  }
                  disabled={canSeeAllSkillMatrix && !allMissionsList.length}
                />
              </FormControl>
            </Grid>
          </Grid>
          <SkillMatrixProvider missionSelected={missionSelected}>
            {modalView[tabValue]}
          </SkillMatrixProvider>
        </section>
      }
    />
  )
}
