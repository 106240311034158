import { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import { setFilterSearchButtom } from 'components/Filter/store/actions'
import { fetchAnnouncements, fetchCourses } from '../store/trainingThunks'

const initialTrainingState = {
  fRequestMade: false,
  loading: false,
  error: false,
  data: [],
}

const useTrainingRequestCommon = (action) => {
  const dispatch = useDispatch()
  const { staffForSearch, sectionsAreCompleted, launchSearch } = useSelector(
    (state) => state.fuse.filterComponente,
  )
  const staffForSearchControl = staffForSearch.join()
  const prevStaffForSearch = useRef(null)
  const [trainingData, setTrainingData] = useState(initialTrainingState)

  const showError = useCallback(
    (message) => {
      dispatch(showMessage({ variant: 'error', message }))
      dispatch(setFilterSearchButtom(false))
    },
    [dispatch],
  )

  const fetchTrainingData = useCallback(async () => {
    setTrainingData((prev) => ({ ...prev, loading: true, error: false }))

    try {
      const result = await dispatch(action(staffForSearch)).unwrap()
      setTrainingData({
        data: result,
        error: false,
        loading: false,
        fRequestMade: true,
      })
    } catch (err) {
      setTrainingData((prev) => ({ ...prev, error: true, loading: false }))
    } finally {
      dispatch(setFilterSearchButtom(false))
      prevStaffForSearch.current = staffForSearchControl
    }
  }, [dispatch, action, staffForSearch, staffForSearchControl])

  useEffect(() => {
    if (!staffForSearch.length || !launchSearch) {
      setTrainingData(initialTrainingState)
      return
    }

    if (prevStaffForSearch.current === staffForSearchControl) {
      showError('This request has the same values as the previous one')
      return
    }

    if (!sectionsAreCompleted) {
      showError(
        `To get training data: you must select a submission. If you can't see the submission filter, it's because your selected sections have no related submissions.`,
      )
      return
    }

    fetchTrainingData()
  }, [staffForSearchControl, sectionsAreCompleted, launchSearch, fetchTrainingData, showError])

  return { trainingData }
}

export const useTrainingRequest = () => useTrainingRequestCommon(fetchCourses)
export const useTrainingRequestForAnnouncements = () => useTrainingRequestCommon(fetchAnnouncements)
