import { createAsyncThunk } from '@reduxjs/toolkit'
import { getIdsFromArr, getRequestAuthHeader } from 'app/js/generalFunctions'
import messageSlice, { showMessage } from 'app/store/fuse/messageSlice'
import axios from 'axios'
import store from 'app/store/index'
import dayjs from 'dayjs'
import { ERROR_MESSAGE_OBJ } from 'app/js/globalConsts'
import { getIdsAndNamesFromArray } from 'components/Managment/Contract/ContractService/Modals/InsertEditModal/utils/functions'
import {
  INPECTOR_AUTH_CLOSED_CORRETLY,
  INSPECTOR_JOB_POSTED_CORRECTLY,
  INSPECTOR_JOB_EXPORT_REPORT_SUCCESS,
} from '../utils/consts'
import { useSelector } from 'react-redux'
import { consultaRiskOpportunityOrganigramaAPIAction } from 'components/TabsExcel/RiskManagement/store/RyO/actions'

const urlN2AndN1 = process.env.REACT_APP_URL_DJANGO + '/api/get_n1_and_n2_from_people_id'
const urlProgramsFromStructure =
  process.env.REACT_APP_URL_DJANGO + '/api/get_programs_from_structure'
const urlInspectorBasicInfo =
  process.env.REACT_APP_URL_DJANGO + '/api/get_possible_inspector_basic_info'
const uulSetInspector = process.env.REACT_APP_URL_DJANGO + '/api/post_inspector'
const urlGetInspectors = process.env.REACT_APP_URL_DJANGO + '/api/get_inspectors'
const urlGetInspectorJobs = process.env.REACT_APP_URL_DJANGO + '/api/get_inspector_jobs'
const urlPostInspectorJobs = process.env.REACT_APP_URL_DJANGO + '/api/post_inspector_jobs'
const urlCloseInpectorAuth = process.env.REACT_APP_URL_DJANGO + '/api/close_inspector_auth'
const urlContinuousEvaluation =
  process.env.REACT_APP_URL_DJANGO + '/api/get_continuous_ev_inspectors'

const urlDownloadInspectorsReport = process.env.REACT_APP_URL_DJANGO + '/api/get_report_export'

const urlGetInspectorsDetails = process.env.REACT_APP_URL_DJANGO + '/api/all_inspectors_details'

// ? GET PROGRAMS FROM STRUCTURE
export const getProgramsFromStructureThunk = createAsyncThunk(
  'authorizationOfInspectors/getProgramsFromStructureThunk',
  async ({ allWpsIds, allMissionsIds }, { dispatch }) => {
    try {
      const queryString = new URLSearchParams({ allWpsIds, allMissionsIds }).toString()
      const response = await axios({
        method: 'GET',
        headers: getRequestAuthHeader(),
        url: `${urlProgramsFromStructure}?${queryString}`,
      })

      if (response.status !== 200) throw new Error('error')
      return response.data
    } catch (e) {
      dispatch(showMessage({ message: 'Error getting programs', variant: 'error' }))
      console.log('🚀 ~ getProgramsFromStructureThunk ~ e:', e)
      return []
    }
  },
)

// ? GET INSPECTOR BASIC COURSES - SCOPES INFO
export const getInspectorBasicInfoThunk = createAsyncThunk(
  'authorizationOfInspectors/getInspectorBasicInfoThunk',
  async (inspectorSelectedID, { dispatch, rejectWithValue }) => {
    try {
      const queryString = new URLSearchParams({ inspector: inspectorSelectedID }).toString()
      const response = await axios({
        method: 'GET',
        headers: getRequestAuthHeader(),
        url: `${urlInspectorBasicInfo}?${queryString}`,
      })

      if (response.status !== 200) throw new Error()
      console.log('Inspector basic info', response.data)
      return response.data
    } catch (e) {
      console.log('🚀 ~ getInspectorBasicInfoThunk ~ e:', e)
      dispatch(showMessage(ERROR_MESSAGE_OBJ))
      return rejectWithValue()
    }
  },
)

// ? GET INSPECTORS
export const getInspectorsThunk = createAsyncThunk(
  'authorizationOfInspectors/getInspectors',
  async (peopleBelowList, { dispatch, rejectWithValue }) => {
    try {
      const queryString = new URLSearchParams({
        data: JSON.stringify({ peopleList: getIdsFromArr(peopleBelowList) }),
      }).toString()
      const response = await axios({
        method: 'GET',
        headers: getRequestAuthHeader(),
        url: `${urlGetInspectors}?${queryString}`,
      })

      if (response.status !== 200) throw new Error('Error')
      return response.data
    } catch (e) {
      console.log('🚀 ~ getInspectorsThunk ~ e:', e)
      dispatch(showMessage(ERROR_MESSAGE_OBJ))
      return rejectWithValue()
    }
  },
)

// GET ALL INSPECTORS
export const getAllInspectors = createAsyncThunk(
  'authorizationOfInspectors/getAllInspectors',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'GET',
        headers: getRequestAuthHeader(),
        url: urlGetInspectors,
      })

      if (response.status !== 200) throw new Error('error')
      return response.data
    } catch (e) {
      dispatch(showMessage(ERROR_MESSAGE_OBJ))
      return rejectWithValue()
    }
  },
)

// GET ALL INSPECTORS DETAILS
export const fetchAllInspectorsDetails = createAsyncThunk(
  'authorizationOfInspectors/fetchAllInspectorsDetails',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'GET',
        headers: getRequestAuthHeader(),
        url: urlGetInspectorsDetails,
      })

      if (response.status !== 200) throw new Error('error')
      return response.data
    } catch (e) {
      dispatch(showMessage(ERROR_MESSAGE_OBJ))
      return rejectWithValue()
    }
  },
)

const getInspectorPostInfo = (modalValues) => {
  const { person: personLogin } = store.getState().fuse.userComponente
  const { inspectorSelected, programSelected, startDate, finalDate } = modalValues
  return {
    tutor: personLogin.id,
    inspector: inspectorSelected.id,
    program: programSelected.id,
    startDate: startDate.format('YYYY-MM-DD'),
    finalDate: finalDate?.format('YYYY-MM-DD'),
  }
}

export const getMoreInspectorsData = createAsyncThunk(
  'authorizationOfInspectors/getMoreInspectorsData',
  async (customQueryString, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'GET',
        headers: getRequestAuthHeader(),
        url: `${urlGetInspectors}${customQueryString}`,
      })

      if (response.status !== 200) throw new Error('error')
      // console.log(response.data)
      return response.data
    } catch (e) {
      dispatch(showMessage(ERROR_MESSAGE_OBJ))
      return rejectWithValue()
    }
  },
)

// ? POST INSPECTOR
export const postInspectorThunk = createAsyncThunk(
  'authorizationOfInspectors/postInspector',
  async (modalValues, { dispatch, rejectWithValue }) => {
    try {
      const postInfo = getInspectorPostInfo(modalValues)
      const response = await axios({
        method: 'POST',
        headers: getRequestAuthHeader(),
        url: uulSetInspector,
        data: postInfo,
      })

      if (response.status !== 201) throw new Error('error')
      console.log('🚀 ~ postInspectorThunk:', response.data)
      dispatch(showMessage({ message: 'Inspector posted', variant: 'success' }))
      return response.data
    } catch (e) {
      console.log('🚀 ~ postInspectorThunk ~ e:', e)
      if (e.response.status === 404)
        dispatch(showMessage({ message: e.response.data, variant: 'error' }))
      else dispatch(showMessage({ message: 'Error post inspector', variant: 'error' }))
      return rejectWithValue()
    }
  },
)

// ? EDIT INSPECTOR
export const editInspectorThunk = createAsyncThunk(
  'authorizationOfInspectors/edit_inspector',
  async (modalValues, { dispatch, rejectWithValue }) => {
    try {
      const { sameProgramAndInspectorAsPrev } = modalValues
      let { prevValues } = modalValues
      prevValues = getInspectorPostInfo(prevValues)
      const postInfo = {
        ...getInspectorPostInfo(modalValues),
        sameProgramAndInspectorAsPrev,
        prevValues,
      }
      const response = await axios({
        method: 'PATCH',
        headers: getRequestAuthHeader(),
        url: uulSetInspector,
        data: postInfo,
      })

      if (response.status !== 201) throw new Error('error')
      dispatch(showMessage({ message: 'Inspector posted', variant: 'success' }))
      console.log('🚀 ~ editInspectorThunk ~ response.data:', response.data)
      return response.data
    } catch (e) {
      console.log('🚀 ~ editInspectorThunk ~ e:', e)
      if (e.response.status === 404)
        dispatch(showMessage({ message: e.response.data, variant: 'error' }))
      else dispatch(showMessage({ message: 'Error post inspector', variant: 'error' }))
      return rejectWithValue()
    }
  },
)

// ? POST INSPECTOR JOB
export const postInspectorJobThunk = createAsyncThunk(
  'authorizationOfInspectors/post_inspector',
  async (jobModalState, { dispatch, rejectWithValue }) => {
    try {
      const {
        inspectorSelected,
        tutorSelected,
        programSelected,
        scopeSelected,
        profile,
        order,
        operation,
        evaluatedActivities,
        deviations,
        comments,
        scopesSelected,
        operationPerformedCorrecly,
        jobDateSelected,
      } = jobModalState

      const responseObj = {
        inspector: inspectorSelected.id,
        tutor: tutorSelected.id,
        program: programSelected.id,
        scopes: scopeSelected ?? scopesSelected,
        profile,
        order,
        operation,
        evaluated_activities: evaluatedActivities,
        peration_performed_correctly: operationPerformedCorrecly,
        date: jobDateSelected.format('YYYY-MM-DD'),
        deviations,
        comments,
      }

      const response = await axios({
        method: 'POST',
        url: urlPostInspectorJobs,
        data: responseObj,
        headers: getRequestAuthHeader(),
      })

      dispatch(showMessage(INSPECTOR_JOB_POSTED_CORRECTLY))
      return response.data
    } catch (e) {
      console.log('🚀 ~ postInspectorJobThunk ~ e:', e)
      dispatch(showMessage(ERROR_MESSAGE_OBJ))
      return rejectWithValue()
    }
  },
)

// ? CLOSE INSPECTOR AUTH
export const closeInpectorAuthThunk = createAsyncThunk(
  'authorizationOfInspectors/close_inpector_auth',
  async ({ inspectorRowSelected, finalDate, comment }, { dispatch, rejectWithValue }) => {
    try {
      const { inspector_info, program_info, scope } = inspectorRowSelected
      const final_date = finalDate.format('YYYY-MM-DD')
      const response = await axios({
        method: 'PATCH',
        url: urlCloseInpectorAuth,
        headers: getRequestAuthHeader(),
        data: {
          inspector_id: inspector_info.id,
          program_id: program_info.id,
          scope,
          final_date,
          comment,
        },
      })

      if (response.status !== 200) throw new Error('error')
      dispatch(showMessage(INPECTOR_AUTH_CLOSED_CORRETLY))
      return response.data
    } catch (e) {
      console.log('🚀 ~ closeInpectorAuthThunk ~ e:', e)
      dispatch(showMessage(ERROR_MESSAGE_OBJ))
      return rejectWithValue()
    }
  },
)

// ? GET INSPECTORS CONTINUOUS EVALUATION
export const getInspectorsContinuousEvaluationThunk = createAsyncThunk(
  'authorizationOfInspectors/get_continuous_ev_inspectors',
  async (inspectorsList, { dispatch, rejectWithValue }) => {
    try {
      const queryString = new URLSearchParams({
        inspectorsList: JSON.stringify({
          ids: [...new Set(inspectorsList.map(({ inspector }) => inspector))],
        }),
      }).toString()
      const response = await axios({
        method: 'GET',
        url: `${urlContinuousEvaluation}?${queryString}`,
        headers: getRequestAuthHeader(),
      })

      if (response.status !== 200) throw new Error('error')
      return response.data
    } catch (e) {
      return rejectWithValue()
    }
  },
)

// ? DOWNLOAD INSPECTORS REPORT
export const downloadInspectorsReportThunk = createAsyncThunk(
  'authorizationOfInspectors/download_inspectors_report',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const { inspector_id, inspector_idres, program_name } = data
      const response = await axios({
        method: 'GET',
        url: `${urlDownloadInspectorsReport}/${inspector_id}/${program_name}`,
        headers: getRequestAuthHeader(),
        responseType: 'blob',
      })

      // console.log("🚀 ~ downloadInspectorsReportThunk ~ response:", response, ". Error message:", response.message)
      // if (response.status !== 200){
      //     dispatch(showMessage({ message: response.message, variant: 'error' }))
      // }
      if (response.status !== 200) throw new Error('error')

      // console.log("Primer console")
      // console.log("🚀 ~ downloadInspectorsReportThunk ~ response:", response)
      const url = window.URL.createObjectURL(response.data)
      const a = document.createElement('a')

      a.href = url
      a.download = `TE.HRM.ESAR.08.ES Autorización inicial inspección AD&S_${inspector_idres}-${program_name}.docx`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)

      console.log('Lanzar mensaje de éxito')
      dispatch(showMessage(INSPECTOR_JOB_EXPORT_REPORT_SUCCESS))

      // return response
    } catch (e) {
      // console.log("🚀 ~ downloadInspectorsReportThunk ~ response:", e.response)
      if (e.response.status === 404) {
        dispatch(showMessage({ message: 'There is no jobs to export.', variant: 'error' }))
      } else if (e.response.status === 405) {
        dispatch(showMessage({ message: "Template doesn't exists.", variant: 'error' }))
      } else if (e.response.status === 406) {
        dispatch(
          showMessage({ message: 'An error ocurred when trying to export.', variant: 'error' }),
        )
      } else {
        dispatch(showMessage({ message: 'Error downloading report', variant: 'error' }))
      }
      return rejectWithValue()
    }
  },
)
