import { getCookie } from 'app/js/generalFunctions'
import { DIRECTORATE_URL } from './const'
const getAuth = () => ({ 'Authorization': `Token ${getCookie('token')}` })

export const GET_DIRECTORATE_REQUEST_OBJ = () => ({
    method: "GET",
    url: DIRECTORATE_URL,
    headers: getAuth()
})

export const POST_DIRECTORATE_REQUEST_OBJ = (directorateData) => ({
    method: "POST",
    url: DIRECTORATE_URL,
    data: directorateData,
    headers: getAuth()
})

export const PUT_DIRECTORATE_REQUEST_OBJ = (directorateData) => ({
    method: "PUT",
    url: `${DIRECTORATE_URL}${directorateData.id}`,
    data: directorateData,
    headers: getAuth()
})
