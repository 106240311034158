// ! This file is to get the user permission depends of the module

import { useSelector } from "react-redux";
export function useGetPermissions() {
    const personLoginPermisos = useSelector(state => state.fuse.userComponente.personPermisos);
    //? SUPERUSER
    const personIsSuperuser = useSelector(state => state.fuse.userComponente.person.is_superuser)
    //? SUPERUSER

    const getViewPermission = (moduleName) => {
        if (personIsSuperuser) return true
        if (!personLoginPermisos) return
        if (personLoginPermisos.find((item) => item['name'] === `Can view ${moduleName}`)) {
           return true;
        }
    }

    const getViewAllPermission = (moduleName) => {
        if (personIsSuperuser) return true
        if (!personLoginPermisos) return
        if (personLoginPermisos.find((item) => item['name'] === `Can view all ${moduleName}`)) {
           return true;
        }
    }

    const getAddPermission = (moduleName) =>{
        if (personIsSuperuser) return true
        if (!personLoginPermisos) return
        if (personLoginPermisos.find((item) => item['name'] === `Can add ${moduleName}`)) {
            return true;
        }
    }

    const canEditPermission = (moduleName) =>{
        if (personIsSuperuser) return true
        if (!personLoginPermisos) return
        if (personLoginPermisos.find((item) => item['name'] === `Can change ${moduleName}`)) {
            return true;
        }
    }

    const getDeletePermission = (moduleName) =>{
        if (personIsSuperuser) return true
        if (!personLoginPermisos) return
        if (personLoginPermisos.find((item) => item['name'] === `Can delete ${moduleName}`)) {
            return true;
        }
    }

    const canSeeAllPermission = (moduleName) => {
        if (personIsSuperuser) return true
        if (!personLoginPermisos) return
        if (personLoginPermisos.find((item) => item['name'] === `Can See All ${moduleName}`)) {
            return true;
        }
        return false
    }

    const isSuperuser = () => {
      if (personIsSuperuser) return true
      return false
    }

    return {
      getViewPermission,
      getViewAllPermission,
      getAddPermission,
      canEditPermission,
      getDeletePermission,
      canSeeAllPermission,
      isSuperuser,
    }
}
