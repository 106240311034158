import { Typography, Grid, Chip, Box } from '@mui/material'
import { Fragment } from 'react'

export const ExpandedRowData = ({ headerGroups, data }) => {
  const renderData = (dataName) => {
    // If data is a string with commas, split it into an array
    // if (typeof dataName === 'string' && dataName.includes(',')) {
    //   dataName = dataName.split(',').map((item) => item.trim())
    // }

    if (Array.isArray(dataName)) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1.5,
            py: 0.5,
          }}
        >
          {dataName.map((item, idx) => (
            <Chip
              key={idx}
              label={item}
              variant="outlined"
              sx={{
                borderRadius: 1,
                fontWeight: 500,
                maxWidth: 200,
                '& .MuiChip-label': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              }}
            />
          ))}
        </Box>
      )
    } else if (typeof dataName === 'object' && dataName !== null) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 1,
            // backgroundColor: 'action.hover',
            borderRadius: 2,
          }}
        >
          {Object.entries(dataName).map(([key, value]) => {
            // If value is a string with commas, split it into an array
            if (typeof value === 'string' && value.includes(',')) {
              value = value.split(',').map((item) => item.trim())
            }

            return (
              <Fragment key={key}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: 'text.secondary',
                    // mb: 1,
                    fontSize: '0.85rem',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                  }}
                >
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </Typography>
                {Array.isArray(value) ? (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {value.map((item, idx) => (
                      <Chip
                        key={idx}
                        label={item}
                        variant="outlined"
                        color={`${key === 'added' ? 'success' : 'error'}`}
                        sx={{
                          borderRadius: 1,
                          fontWeight: 500,
                          maxWidth: 200,
                          color:
                            key === 'added'
                              ? 'success.main'
                              : key === 'removed'
                                ? 'error.main'
                                : 'primary.main',
                          '& .MuiChip-label': {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            px: 1,
                          },
                          // '&:hover': {
                          //   transform: 'translateY(-1px)',
                          //   boxShadow: 1,
                          // },
                        }}
                      />
                    ))}
                  </Box>
                ) : (
                  <Chip
                    label={value || 'None'}
                    variant="outlined"
                    color={`${key === 'added' ? 'success' : 'error'}`}
                    sx={{
                      borderRadius: 1,
                      fontWeight: 500,
                      maxWidth: 200,
                      color:
                        key === 'added'
                          ? 'success.main'
                          : key === 'removed'
                            ? 'error.main'
                            : 'primary.main',
                      '& .MuiChip-label': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        px: 1,
                      },
                    }}
                  />
                )}
              </Fragment>
            )
          })}
        </Box>
      )
    } else {
      return dataName.length > 20 ? (
        <Typography
          variant="body2"
          sx={{
            fontWeight: 500,
            textAlign: 'justify',
            textJustify: 'inter-word',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            maxWidth: 220,
            px: 1,
            py: 0.5,
            border: '1px solid #bdbdbd',
            borderRadius: 1,
          }}
        >
          {dataName}
        </Typography>
      ) : (
        <Chip
          label={typeof dataName === 'boolean' ? (dataName ? 'Yes' : 'No') : dataName}
          variant="outlined"
          sx={{
            borderRadius: 1,
            fontWeight: 500,
            maxWidth: 200,
            height: 'auto',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            '& .MuiChip-label': {
              display: 'block',
              textAlign: 'left',
              padding: '4px 8px',
            },
          }}
        />
      )
    }
  }

  const dataToExpand = headerGroups.flatMap((headerGroup) => {
    const expandColumn = headerGroup.headers.find((header) => header.type === 'expand')
    if (!expandColumn || !expandColumn.expandData) return []
    const { expandData } = expandColumn
    return expandData(data).map(({ title, dataName }, index) => ({
      title,
      dataName,
      index,
    }))
  })

  return dataToExpand.map(({ title, dataName, index }) => (
    <Fragment key={index}>
      {dataName === null || dataName === undefined ? null : (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color: 'text.primary',
              mb: 1,
              fontSize: '0.975rem',
              letterSpacing: '0.5px',
              textTransform: 'uppercase',
            }}
          >
            {title}
          </Typography>
          <Box sx={{ mt: 1 }}>{renderData(dataName)}</Box>
        </Grid>
      )}
    </Fragment>
  ))
}
