import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import {
  getFilterStructureBA,
  setFilterAgressoAndVITG,
  setFilterPeopleSearch,
} from 'components/Filter/store/actions'
import {
  ALL_MANAGERS_OPTION,
  ALL_RECORDS_OPTION,
  EMPTY_STRUCTURE_OBJ,
  KEYS_FILTER_STRUCTURE,
  SECTIONS_SELECTED_INITAL_STATE,
  MANAGERS_SELECTED_INITIAL_STATE,
  SECTIONS_PEOPLE_TO_SEARCH,
  useStyles,
  KEYS_FILTER_SECTIONS_NAMES,
  SECTIONS_MANAGERS_EQUIVALENCE,
  SECTIONS_FATHERS,
} from 'components/Filter/utils/filterConsts'
import {
  getSectionsFromCascade,
  setAllMissionsAgressoAndVTG,
  setSectionManagers,
} from 'components/Filter/utils/filterFunctions'
import { getUniqueValuesFromObjArrWithId } from 'app/js/generalFunctions'

const FilterSectionSelect = ({
  label,
  sections,
  setionSelectAndPeopleFunc,
  sectionManagerSelected,
}) => {
  const classes = useStyles()
  const [sectionInFilter, setSectionInFilter] = useState(null)

  const setSection = (section) => {
    setionSelectAndPeopleFunc(section)
    setSectionInFilter(section)
  }

  useEffect(() => {
    if (sectionManagerSelected === -1) {
      setSection(ALL_RECORDS_OPTION)
    } else {
      setSection(null)
    }
  }, [sections, sectionManagerSelected])

  if (!sections?.length) return null

  return (
    <div className="col-12">
      <FormControl
        variant="standard"
        fullWidth
        size="medium"
        classes={{ root: classes.formControl }}
        disabled={!sections.length}
      >
        <Autocomplete
          id="tags-outlined"
          options={sections}
          value={sectionInFilter}
          isOptionEqualToValue={(option, value) => option?.id == value?.id}
          onChange={(e, section) => setSection(section)}
          getOptionLabel={(section) => section.name}
          renderOption={(props, section) => (
            <li {...props} key={section?.id}>
              {section.name}
            </li>
          )}
          filterSelectedOptions
          disableClearable
          renderInput={(params) => (
            <TextField {...params} label={label} placeholderl={label} size="medium" fullWidth />
          )}
        />
      </FormControl>
    </div>
  )
}

const FilterPeopleSelect = ({
  label,
  managers,
  managerSetter,
  fatherSelected,
  fatherManagerSelected,
}) => {
  const classes = useStyles()
  const [managerInPeopleFilter, setManagerInPeopleFilter] = useState(null)

  const setSectionPeople = (person) => {
    managerSetter(person?.id)
    setManagerInPeopleFilter(person)
  }

  useEffect(() => {
    if (fatherSelected === -1) setSectionPeople(ALL_MANAGERS_OPTION)
    else setSectionPeople(null)
  }, [label, fatherManagerSelected, fatherSelected])

  if (!managers?.length) return null

  return (
    <div className="col-12">
      <FormControl
        variant="standard"
        fullWidth
        size="medium"
        classes={{ root: classes.formControl }}
      >
        <Autocomplete
          id="tags-outlined"
          options={managers}
          value={managerInPeopleFilter}
          isOptionEqualToValue={(option, value) => option.id == value.id}
          onChange={(e, value) => setSectionPeople(value)}
          getOptionLabel={(person) =>
            person.last_name
              ? `${person.IDRes} - ${person.first_name} ${person.last_name}`
              : `${person.first_name}`
          }
          renderOption={(props, person) => (
            <li {...props} key={person?.id}>
              {person.IDRes} - {person.first_name} {person.last_name}
            </li>
          )}
          filterSelectedOptions
          disableClearable
          renderInput={(params) => (
            <TextField {...params} label={label} placeholderl={label} size="medium" fullWidth />
          )}
        />
      </FormControl>
    </div>
  )
}

export const NewFilter = ({ noSubmissionsFilter = false }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { filterStructure } = useSelector((state) => state.fuse.filterComponente)

  useEffect(() => {
    if (!Object.keys(filterStructure).length) {
      dispatch(getFilterStructureBA())
    }
  }, [dispatch, filterStructure])

  const [filterSectionsSelected, setfilterSectionsSelected] = useState(
    SECTIONS_SELECTED_INITAL_STATE,
  )
  const { contractSelected, wpSelected, missionSelected, submissionSelected } =
    filterSectionsSelected
  const [managersSection, setManagersSections] = useState(MANAGERS_SELECTED_INITIAL_STATE)
  const { wpManagerSelected, missionsManagerSelected, submissionsManagerSelected } = managersSection
  const [staffForSearch, setStaffForSearch] = useState(SECTIONS_PEOPLE_TO_SEARCH)
  const [missionAgressoAndVTG, setMissionAgressoAndVTG] = useState({ agresso: [], vtg: [] })

  const UPPER_SECTIONS_MANAGERS = {
    submission: [submissionsManagerSelected, missionsManagerSelected, wpManagerSelected, -1],
    mission: [missionsManagerSelected, wpManagerSelected, -1],
    wp: [wpManagerSelected, -1],
  }

  const UPPER_SECTIONS_SELECTED = {
    submission: [missionSelected, wpSelected, contractSelected],
    mission: [wpSelected, contractSelected],
    wp: [contractSelected],
  }

  const SECTIONS_MANAGERS_SELECTED = [
    submissionsManagerSelected,
    missionsManagerSelected,
    wpManagerSelected,
    -1,
  ]

  const filterValues = useMemo(() => {
    if (!Object.keys(filterStructure).length) return EMPTY_STRUCTURE_OBJ
    return filterStructure
  }, [filterStructure])

  const { filter_contracts, filter_wps, filter_missions, filter_submissions } = filterValues

  const firstSectionWithRecords = useMemo(() => {
    if (!Object.keys(filterValues).length) return
    return KEYS_FILTER_STRUCTURE.find((key) => !!filterValues[key].length)
  }, [filterValues])

  const allSectionsStaff = useMemo(() => {
    const hasRecords = KEYS_FILTER_STRUCTURE.find((key) => !!filterValues[key].length)
    if (!hasRecords) return SECTIONS_PEOPLE_TO_SEARCH
    const staffBySection = {}
    for (let key of KEYS_FILTER_STRUCTURE) {
      const sections = filterValues[key]
      const staff = sections.flatMap((section) => [
        ...section.staff.managers,
        ...section.staff.employees,
      ])
      staffBySection[KEYS_FILTER_SECTIONS_NAMES[key]] = getUniqueValuesFromObjArrWithId(staff)
    }
    return staffBySection
  }, [filterValues])

  const setWpManagerSelected = (manager) =>
    setManagersSections((state) => ({ ...state, wpManagerSelected: manager }))
  const setMissionManagerSelected = (manager) =>
    setManagersSections((state) => ({ ...state, missionsManagerSelected: manager }))
  const setSubmissionManagerSelected = (manager) =>
    setManagersSections((state) => ({ ...state, submissionsManagerSelected: manager }))

  const setFilterFirstSection = (sections) =>
    sections.length ? [ALL_RECORDS_OPTION, ...sections] : sections

  const allUpperSectionsAllManagers = (sectionName) => {
    if (sectionName === 'contract') return true
    return UPPER_SECTIONS_MANAGERS[sectionName].every((manager) => manager === -1)
  }

  const allUpperSectionsAllRecords = (sectionName) => {
    if (sectionName === 'contract') return true
    return UPPER_SECTIONS_SELECTED[sectionName].every((manager) => manager === -1)
  }

  const setStaffFunc = (
    sections,
    sectionName,
    searchAllRecords,
    sectionSelected,
    fatherList,
    fatherSelected,
  ) => {
    const removeAllOption = (sections) => sections.filter((section) => section.id !== -1)
    const sectionsWithoutAll = removeAllOption(sections)
    const allUpperManagers = allUpperSectionsAllManagers(sectionName)
    const allUpperRecords = allUpperSectionsAllRecords(sectionName)

    if (!searchAllRecords) {
      const section = sectionsWithoutAll.find((section) => section.id === sectionSelected)
      const sectionStaff = getUniqueValuesFromObjArrWithId([
        ...section.staff.managers,
        ...section.staff.employees,
      ])
      setStaffForSearch({ ...SECTIONS_PEOPLE_TO_SEARCH, [sectionName]: sectionStaff })
    } else if (searchAllRecords && allUpperManagers && allUpperRecords) {
      setStaffForSearch(allSectionsStaff)
    } else if (searchAllRecords && (!allUpperManagers || !allUpperRecords)) {
      const getStaff = (sectionList) =>
        getUniqueValuesFromObjArrWithId(
          sectionList.flatMap((sec) => [...sec.staff.managers, ...sec.staff.employees]),
        )
      const sectionStaff = getStaff(sectionsWithoutAll)
      const indexLastManagerSelected = SECTIONS_MANAGERS_SELECTED.findIndex(
        (manager) => manager > 0,
      )
      let cleanedObj = {}
      if (indexLastManagerSelected !== -1) {
        const sectionsToClean = SECTIONS_MANAGERS_EQUIVALENCE.slice(
          indexLastManagerSelected + 1,
          SECTIONS_MANAGERS_EQUIVALENCE.length,
        ).reverse()
        cleanedObj = Object.fromEntries(sectionsToClean.map((section) => [section, []]))
        const lowerSectionWithManagerSelected =
          SECTIONS_MANAGERS_EQUIVALENCE[indexLastManagerSelected]
        const sectionFatherName = SECTIONS_FATHERS[sectionName]
        const staffFromFather = staffForSearch[lowerSectionWithManagerSelected]
        if (sectionFatherName === lowerSectionWithManagerSelected && !staffFromFather.length) {
          const fatherRecordSelected = fatherList.find((section) => section.id === fatherSelected)
          const fatherSections =
            fatherSelected === -1 ? removeAllOption(fatherList) : [fatherRecordSelected]
          const fatherStaffRecovery = getStaff(fatherSections)
          cleanedObj[SECTIONS_FATHERS[sectionName]] = fatherStaffRecovery
        }
      }
      setStaffForSearch((prev) => ({ ...prev, ...cleanedObj, [sectionName]: sectionStaff }))
    }
  }

  const setContractSelectedAndPeople = (contract) => {
    const searchAllRecords = contract?.id === -1
    setfilterSectionsSelected((state) => ({ ...state, contractSelected: contract?.id }))
    if (!contract?.id) return
    const sectionSelected = contract?.id
    setStaffFunc(contractsFromCascade, 'contract', searchAllRecords, sectionSelected)
  }

  const setWpSelectedAndPeople = (wp) => {
    const searchAllRecords = wp?.id === -1
    const allSelectedButNoValues = searchAllRecords && !wpsFromCascade.length

    if (!wp?.id || allSelectedButNoValues) {
      setfilterSectionsSelected((state) => ({ ...state, wpSelected: null }))
      setStaffForSearch((prev) => ({ ...prev, wp: [] }))
      return
    }
    const sectionSelected = !allSelectedButNoValues ? wp?.id : null
    setfilterSectionsSelected((state) => ({ ...state, wpSelected: sectionSelected }))
    setStaffFunc(
      wpsFromCascade,
      'wp',
      searchAllRecords,
      sectionSelected,
      contractsFromCascade,
      contractSelected,
    )
  }

  const setMissionSelectedAndPeople = (mission) => {
    const searchAllRecords = mission?.id === -1
    const allSelectedButNoValues = searchAllRecords && !missionsFromCascade.length

    if (!mission?.id || allSelectedButNoValues) {
      setMissionAgressoAndVTG({ agresso: [], vtg: [] })
      setfilterSectionsSelected((state) => ({ ...state, missionSelected: null }))
      setStaffForSearch((prev) => ({ ...prev, mission: [] }))
      return
    }
    const sectionSelected = !allSelectedButNoValues ? mission?.id : null
    setfilterSectionsSelected((state) => ({ ...state, missionSelected: sectionSelected }))
    setStaffFunc(
      missionsFromCascade,
      'mission',
      searchAllRecords,
      sectionSelected,
      wpsFromCascade,
      wpSelected,
    )
    if (!sectionSelected) return
    if (!searchAllRecords) {
      const missionsVTG = mission.code_agresso?.split(',') ?? []
      const missionsAgresso = mission.visual_time_group?.split(',') ?? []
      setMissionAgressoAndVTG({ agresso: missionsVTG, vtg: missionsAgresso })
    } else {
      const { agresso, vtg } = setAllMissionsAgressoAndVTG(missionsFromCascade)
      setMissionAgressoAndVTG({ agresso, vtg })
    }
  }

  const setSubmissionSelectedAndPeople = (submission) => {
    const searchAllRecords = submission?.id === -1
    const allSelectedButNoValues = searchAllRecords && !submissionsFromCascade.length

    if (!submission?.id || allSelectedButNoValues) {
      setfilterSectionsSelected((state) => ({ ...state, submissionSelected: null }))
      setStaffForSearch((prev) => ({ ...prev, submission: [] }))
      return
    }
    const sectionSelected = !allSelectedButNoValues ? submission?.id : null
    setfilterSectionsSelected((state) => ({ ...state, submissionSelected: sectionSelected }))
    setStaffFunc(
      submissionsFromCascade,
      'submission',
      searchAllRecords,
      sectionSelected,
      missionsFromCascade,
      missionSelected,
    )
  }

  const contractsFromCascade = useMemo(() => {
    if (!firstSectionWithRecords) return []
    if (firstSectionWithRecords === 'filter_contracts')
      return setFilterFirstSection(filter_contracts)
    return []
  }, [firstSectionWithRecords])

  const wpManagers = useMemo(() => {
    if (!contractSelected) return []
    return setSectionManagers({
      sectionToFindManagers: filter_wps,
      section_father_key: 'id_service',
      filterFatherSelected: contractSelected,
      filterFatherList: contractsFromCascade,
    })
  }, [filterSectionsSelected, contractsFromCascade])

  const wpsFromCascade = useMemo(() => {
    if (firstSectionWithRecords === 'filter_wps') return setFilterFirstSection(filter_wps)
    if (!contractSelected || !wpManagerSelected) return []
    return getSectionsFromCascade({
      sectionList: filter_wps,
      managerSelected: wpManagerSelected,
      fatherSelected: contractSelected,
      fatherCascadeList: contractsFromCascade,
      fatherKey: 'id_service',
    })
  }, [contractSelected, contractsFromCascade, wpManagerSelected, firstSectionWithRecords])

  const missionManagers = useMemo(() => {
    if (!wpSelected) return []
    return setSectionManagers({
      sectionToFindManagers: filter_missions,
      section_father_key: 'id_workPackage',
      filterFatherSelected: wpSelected,
      filterFatherList: wpsFromCascade,
    })
  }, [filterSectionsSelected, wpsFromCascade])

  const missionsFromCascade = useMemo(() => {
    if (firstSectionWithRecords === 'filter_missions') return setFilterFirstSection(filter_missions)
    if (!wpSelected || !missionsManagerSelected) return []
    return getSectionsFromCascade({
      sectionList: filter_missions,
      managerSelected: missionsManagerSelected,
      fatherSelected: wpSelected,
      fatherCascadeList: wpsFromCascade,
      fatherKey: 'id_workPackage',
    })
  }, [wpSelected, wpsFromCascade, missionsManagerSelected, firstSectionWithRecords])

  const submissionManagers = useMemo(() => {
    if (!missionSelected) return []
    return setSectionManagers({
      sectionToFindManagers: filter_submissions,
      section_father_key: 'id_mision',
      filterFatherSelected: missionSelected,
      filterFatherList: missionsFromCascade,
    })
  }, [filterSectionsSelected, missionsFromCascade])

  const submissionsFromCascade = useMemo(() => {
    if (firstSectionWithRecords === 'filter_submissions')
      return setFilterFirstSection(filter_submissions)
    if (!missionSelected || !submissionsManagerSelected) return []
    return getSectionsFromCascade({
      sectionList: filter_submissions,
      managerSelected: submissionsManagerSelected,
      fatherSelected: missionSelected,
      fatherCascadeList: missionsFromCascade,
      fatherKey: 'id_mision',
    })
  }, [missionSelected, missionsFromCascade, submissionsManagerSelected, firstSectionWithRecords])

  useEffect(() => {
    if (!firstSectionWithRecords) return
    dispatch(
      setFilterPeopleSearch({
        firstSectionWithRecords,
        filterSectionsSelected,
        staffForSearch,
      }),
    )
  }, [firstSectionWithRecords, filterSectionsSelected, staffForSearch])

  useEffect(() => {
    dispatch(setFilterAgressoAndVITG(missionAgressoAndVTG))
  }, [missionAgressoAndVTG])

  return (
    <>
      {!Object.keys(filterStructure).length && (
        <div className="col-12" style={{ pointerEvents: 'none' }}>
          <FormControl
            variant="standard"
            fullWidth
            size="medium"
            classes={{ root: classes.formControl }}
            disabled
          >
            <TextField label="Loading filters..." size="medium" fullWidth />
          </FormControl>
        </div>
      )}

      <FilterSectionSelect
        label="Contracts"
        sections={contractsFromCascade}
        setionSelectAndPeopleFunc={setContractSelectedAndPeople}
        sectionManagerSelected={null}
      />

      <FilterPeopleSelect
        label="Work packages managers (N4)"
        managers={wpManagers}
        managerSetter={setWpManagerSelected}
        fatherSelected={contractSelected}
        fatherManagerSelected={null}
      />

      <FilterSectionSelect
        label="Work packages"
        sections={wpsFromCascade}
        setionSelectAndPeopleFunc={setWpSelectedAndPeople}
        sectionManagerSelected={wpManagerSelected}
      />

      <FilterPeopleSelect
        label="Missions managers (N3)"
        managers={missionManagers}
        managerSetter={setMissionManagerSelected}
        fatherSelected={wpSelected}
        fatherManagerSelected={wpManagerSelected}
      />

      <FilterSectionSelect
        label="Missions"
        sections={missionsFromCascade}
        setionSelectAndPeopleFunc={setMissionSelectedAndPeople}
        sectionManagerSelected={missionsManagerSelected}
      />

      {!noSubmissionsFilter && (
        <>
          <FilterPeopleSelect
            label="Submissions managers (N1)"
            managers={submissionManagers}
            managerSetter={setSubmissionManagerSelected}
            fatherSelected={missionSelected}
            fatherManagerSelected={missionsManagerSelected}
          />

          <FilterSectionSelect
            label="Sub missions"
            sections={submissionsFromCascade}
            setionSelectAndPeopleFunc={setSubmissionSelectedAndPeople}
            sectionManagerSelected={submissionsManagerSelected}
          />
        </>
      )}
    </>
  )
}
