import { Dialog, DialogTitle, Typography, Grid, DialogContent, Button, DialogActions } from "@mui/material"
import { useSelector } from "react-redux"
import { useCloseAuthModal, useCloseInspectorAuth } from "../utils/hooks"
import { useStyles } from 'app/js/customHooks/GeneralHooks';
import { DateYearFilterYMD } from "components/GlobalComponents/ModalsFilters/DateFilterYMD";
import { DisabledUserFilter,DisabledFieldRenderName } from "components/GlobalComponents/ModalsFilters/DisabledFields";
import { useState } from "react";
import { SCOPE_NAMES_JOB_MODAL } from "../utils/consts";
import { ModalTexField } from "components/GlobalComponents/ModalsFilters/TextFields";
import dayjs from "dayjs";

export const CloseInspectorAth = () => {
    const classes = useStyles();

    const { inspectorRowSelected, closeAuthModalVisibility, inpectorSelectedJobsInfo } = useSelector(({ fuse }) => fuse.inspectorsComponent)
    const { closeModal } = useCloseAuthModal()
    const {closeInspectorAuth} = useCloseInspectorAuth()
    const { inspector_info, program_info, initial_date, scope } = inspectorRowSelected 
    const [finalDate, setFinalDate] = useState(null)
    const [comment, setComment] = useState('')
    const setFinalDateFunc = (value) => setFinalDate(value)



    

    if(!inspector_info) return null
    return (
        <Dialog open={closeAuthModalVisibility} onClose={closeModal} fullWidth maxWidth="md">

            <DialogTitle classes={{ root: classes.customDialogTitle }}  >
                Close inspector authorization
            </DialogTitle>

            <DialogContent>

                <Grid container columns={17} spacing={1} style={{ marginTop: '1px' }}>


                    <Grid item xs={6}>

                        <DisabledUserFilter optionAndValue={inspector_info} label={'Inspector'} />

                    </Grid>

                    
                    <Grid item xs={2}>

                    <DisabledFieldRenderName   optionAndValue={program_info} label = {'Program'} />
                    </Grid>

                    <Grid item xs={3}>

                        <DisabledFieldRenderName optionAndValue={{name: SCOPE_NAMES_JOB_MODAL[scope], id: 0}} label={'Scope'} />

                    </Grid>


                    <Grid item xs={3}>
                        <DateYearFilterYMD disabled={true} componentContextDate={dayjs(initial_date)}  label='Initial date' />
                    </Grid>

                    <Grid item xs={3}>
                        <DateYearFilterYMD componentContextDate={finalDate} setFatherDate={setFinalDateFunc} label='Final date' />
                    </Grid>

                    <Grid item xs={17}>
                                <ModalTexField label={'Comment'} value={comment} onChangeFunc={(value) => setComment(value)} />
                            </Grid>

                    <Grid item xs={17}>
                        <Typography variant='p'>
                            {inspector_info.IDRes} - {inspector_info.first_name} {inspector_info.last_name},  has {inpectorSelectedJobsInfo.totalJobs} jobs assigned in total for program '{program_info.name}' of which {inpectorSelectedJobsInfo.correctPerformanceJobsNumber} were successfully performanced.
                        </Typography>

                    </Grid>

                </Grid>


            </DialogContent>

            <DialogActions>
            <Button onClick={closeModal}> Close </Button>

                 <Button onClick={()=>closeInspectorAuth(finalDate, comment)}> Save </Button>


            </DialogActions>

        </Dialog>
    )
}