// React and Hooks
import { useEffect, useState, useMemo } from 'react'

// MUI Components
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Divider
} from '@mui/material'

// MUI Icons
import { ManageSearch as ManageSearchIcon } from '@mui/icons-material'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import {
  cambiarVisibilidadModalHistoricoRyOAction,
  cambiarValorSeleccionRyOHistricModalAction,
  cambiarVisibilidadModalHistoricoActionsAction,
} from '../../store/RyO/actions.js'

// Components
import { Table } from 'components/GlobalComponents/tables/Table2.jsx'

// Utilities/Helpers
import { RYO_COLUMNS } from '../../utils/sectionColumns.js'
import { useStyles } from '../../utils/RyOUtils.js'
import { useButtomStyleSelectedRow } from '../../../../../app/js/customHooks/ButtomsHooks.js'
import { RecordHistoryButton } from 'components/GlobalComponents/Buttons/RecordHistoryButton.jsx'


export const ModalRyOHistory = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { getButtomStyleSelectedRow } = useButtomStyleSelectedRow()

  const [RyOHistoryRows, setRyOHistoryRows] = useState([])
  const [buttomColorStyle, setbuttomColorStyle] = useState(null)

  const loading = useSelector((state) => state.fuse.RyOComponente.loading)
  const visibilidadModalHistoricoRyO = useSelector(
    (state) => state.fuse.RyOComponente.visibilidadModalHistoricoRyO,
  )
  const RyOFilaSeleccionada = useSelector((state) => state.fuse.RyOComponente.filaSeleccionadaGrid)
  const RyOHistoryFilaSeleccionada = useSelector(
    (state) => state.fuse.RyOComponente.filaSeleccionadaGridHistory,
  )
  const { noRevRyOICanSee, revRyOICanSee } = useSelector((state) => state.fuse.RyOComponente)

  const cambiarVisibilidadModalHistoricoRyO = (valor) =>
    dispatch(cambiarVisibilidadModalHistoricoRyOAction(valor))

  const cambiarValorSeleccion = (valor) =>
    dispatch(cambiarValorSeleccionRyOHistricModalAction(valor))

  const ryosRevHistory = useMemo(() => {
    if (!visibilidadModalHistoricoRyO || !RyOFilaSeleccionada) return []

    let revisionHistory = []
    let revisionId = Number(
      noRevRyOICanSee.find(({ id }) => id === RyOFilaSeleccionada.id).padreRiskOpportunity,
    )

    if (isNaN(revisionId)) return []

    let revRyOICanSeeForSearch = [...revRyOICanSee]

    const setRyOHistory = (ryoRevList) => {
      for (let index in ryoRevList) {
        const ryo = ryoRevList[index]
        const revRyO = ryo.id === revisionId
        if (revRyO) {
          revisionHistory = [ryo, ...revisionHistory]
          revisionId = parseInt(ryo.padreRiskOpportunity)
          revRyOICanSeeForSearch.splice(index, 1)
          setRyOHistory(revRyOICanSeeForSearch)
        }
      }
    }

    setRyOHistory(revRyOICanSeeForSearch.sort((a, b) => b.id - a.id))

    return revisionHistory
  }, [visibilidadModalHistoricoRyO, RyOFilaSeleccionada])

  function RyOActionsLogButtom() {
    dispatch(cambiarVisibilidadModalHistoricoActionsAction(true))
  }

  useEffect(() => {
    const { buttomColorStyle } = getButtomStyleSelectedRow(RyOHistoryFilaSeleccionada)
    setbuttomColorStyle(buttomColorStyle)
  }, [RyOHistoryFilaSeleccionada])

  return (
    <Dialog
      open={visibilidadModalHistoricoRyO}
      disableScrollLock={false}
      onClose={() => {
        cambiarVisibilidadModalHistoricoRyO(false)
        cambiarValorSeleccion('')
      }}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" component="div">
              {`R&O "${RyOFilaSeleccionada?.risk_description}" revision history`}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                cambiarVisibilidadModalHistoricoRyO(false)
                cambiarValorSeleccion('')
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0,
          m: 0,
          height: '80vh',
        }}
        dividers
      >
        <div className="w-full">
          <section className="flex flex-row items-center justify-between gap-2 my-2">
            <div className="flex flex-row items-center gap-2">
              <div className="border-1 rounded-lg border-transparent p-2 flex items-center gap-2">
                <Tooltip title="R&O action release history" placement="top">
                  <span>
                    <IconButton
                      className='p-0 m-0 text-gray-900 hover:text-gray-800 transition-color duration-75'
                      onClick={() => RyOHistoryFilaSeleccionada && RyOActionsLogButtom()}
                    >
                      <ManageSearchIcon
                        style={{ ...buttomColorStyle }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>

                {RyOFilaSeleccionada && <RecordHistoryButton modelName='rmriskopportunity' recordId={RyOFilaSeleccionada.id} />}
              </div>
            </div>
          </section>
        </div>

        <Table
          rowsProp={ryosRevHistory}
          columnsProp={RYO_COLUMNS}
          loading={loading}
          funcionSetValue={cambiarValorSeleccion}
          headerTitleSectionWithButtons={true}
        />
      </DialogContent>
    </Dialog>
  )
}
