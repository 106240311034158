import { createSlice } from '@reduxjs/toolkit'
import {
  closeInpectorAuthThunk,
  editInspectorThunk,
  getAllInspectors,
  getInspectorBasicInfoThunk,
  getInspectorsContinuousEvaluationThunk,
  getInspectorsThunk,
  postInspectorJobThunk,
  postInspectorThunk,
} from './AuthOfInspectorsThunk'
import {
  getAllInspectorJobs,
  insertScopeNameInInspectorList,
  setInspectorsBasicList,
  setInspectorsDetailList,
} from '../utils/functions'

const INITIAL_STATE = {
  status: {
    getMoreInspectorsData: 'idle',
  },
  error: {
    getMoreInspectorsData: false,
  },
  dataPaginationDetails: {
    count: 0,
    next: null,
    previous: null,
    current: null,
    total_pages: 0,
    number_of_printed_data: 0,
  },
  inspectorBasicList: [],
  inspectorsList: [],
  inspectorDataDetailTable: [],
  loadingInspectorsList: true,
  errorInspectorsList: false,
  noStructureAndNoPermission: false,
  inspectorBasicListRowSelected: null,
  inspectorRowSelected: null,
  createInspectorModalVisibility: false,
  editInspectorModalVisibility: false,
  createJobModalVisibility: false,
  closeAuthModalVisibility: false,
  continuousEvaluationModalVisibility: false,
  detailsAuthTableVisibility: false,
  jobsTableVisibility: false,
  jobRowSelected: null,
  inspectorJobsInJobsTable: [],
  jobModalOnlyViewVisibility: false,
  userCanBeInspector: null,
  userBasicCoursesInfo: null,
  userScope: null,
  belowPeopleInStructure: [],
  inpectorSelectedJobsInfo: { totalJobs: 0, correctPerformanceJobsNumber: 0 },
  usersStructureUpperN0: [],
  continuousEvInfo: { inspectorsIds: [], programsIds: [], programsInfo: [] },
}

const authorizationOfInspectorsSlice = createSlice({
  name: 'authorizationOfInspectors',
  initialState: INITIAL_STATE,
  reducers: {
    setCreateInspectorModalVisibility: (state, { payload }) => {
      state.createInspectorModalVisibility = payload
    },
    setEditInspectorModalVisibility: (state, { payload }) => {
      state.editInspectorModalVisibility = payload
    },
    setJobModalVisibility: (state, { payload }) => {
      state.createJobModalVisibility = payload
    },
    setCloseAuthModalVisibility: (state, { payload }) => {
      state.closeAuthModalVisibility = payload
    },
    setContinuousEvaluationModalVisibility: (state, { payload }) => {
      state.continuousEvaluationModalVisibility = payload
    },
    setDetailAuthTableVisibilityAction: (state, { payload }) => {
      state.detailsAuthTableVisibility = payload
      const { inspectorDataDetailTable } = state
      const jobsObj = payload ? getAllInspectorJobs(inspectorDataDetailTable) : []
      state.inspectorJobsInJobsTable = jobsObj
    },
    setJobsTableVisibility: (state, { payload }) => {
      state.jobsTableVisibility = payload
      state.jobRowSelected = payload ? state.jobRowSelected : null
      state.detailsAuthTableVisibility = !payload
      state.inspectorRowSelected = !payload ? null : state.inspectorRowSelected
    },
    setBelowPeopleInStructure: (state, { payload }) => {
      state.belowPeopleInStructure = payload
    },
    setInspectorRowSelectedAction: (state, { payload }) => {
      if (!payload) {
        state.inspectorRowSelected = null
      } else {
        const { jobs } = payload
        const correctPerformanceJobsNumber = jobs.filter(
          ({ peration_performed_correctly, active }) => peration_performed_correctly && active,
        ).length
        state.inspectorRowSelected = payload
        state.inpectorSelectedJobsInfo = { totalJobs: jobs.length, correctPerformanceJobsNumber }
      }
    },
    setInspectorBasicListRowSelectedAction: (state, { payload }) => {
      const inspectorAuthDetails = payload && setInspectorsDetailList(state.inspectorsList, payload)
      state.inspectorDataDetailTable = inspectorAuthDetails || state.inspectorDataDetailTable
      state.inspectorBasicListRowSelected = payload
    },
    resetUserCanBeInspector: (state) => {
      state.userCanBeInspector = null
    },
    setUsersStructureUpperN0: (state, { payload }) => {
      state.usersStructureUpperN0 = payload
    },
    setJobRowSelected: (state, { payload }) => {
      state.jobRowSelected = payload
    },
    setJobModalOnlyViewVisibility: (state, { payload }) => {
      state.jobModalOnlyViewVisibility = payload
    },
    setLoadingInspectorsListToFalse: (state) => {
      state.loadingInspectorsList = false
      state.errorInspectorsList = true
      state.noStructureAndNoPermission = true
    },
    setInpectorSelectedJobsInfo: (state, { payload }) => {
      state.inpectorSelectedJobsInfo = payload
    },
    clearInspectorBasicList: (state) => {
      state.inspectorBasicList = []
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Inspectors
      .addCase(getInspectorsThunk.pending, (state) => {
        state.inspectorsList = []
        state.loadingInspectorsList = true
        state.errorInspectorsList = false
      })
      .addCase(getInspectorsThunk.fulfilled, (state, { payload }) => {
        const inspectorListWithScopeName = insertScopeNameInInspectorList(payload)
        state.inspectorsList = inspectorListWithScopeName
        state.inspectorBasicList = setInspectorsBasicList(inspectorListWithScopeName)
        state.loadingInspectorsList = false
        state.errorInspectorsList = false
      })
      .addCase(getInspectorsThunk.rejected, (state) => {
        state.inspectorsList = []
        state.loadingInspectorsList = false
        state.errorInspectorsList = true
      })
      // Fetch all inspectors
      .addCase(getAllInspectors.pending, (state) => {
        state.inspectorsList = []
        state.loadingInspectorsList = true
        state.errorInspectorsList = false
      })
      .addCase(getAllInspectors.fulfilled, (state, { payload }) => {
        const inspectorListWithScopeName = insertScopeNameInInspectorList(payload)
        state.inspectorsList = inspectorListWithScopeName
        state.inspectorBasicList = setInspectorsBasicList(inspectorListWithScopeName)
        state.loadingInspectorsList = false
        state.errorInspectorsList = false
      })
      .addCase(getAllInspectors.rejected, (state) => {
        state.inspectorsList = []
        state.loadingInspectorsList = false
        state.errorInspectorsList = true
      })
      // Post Inspector
      .addCase(postInspectorThunk.fulfilled, (state, { payload }) => {
        const inspectorListWithScopeName = insertScopeNameInInspectorList([
          ...state.inspectorsList,
          ...payload,
        ])
        state.inspectorsList = inspectorListWithScopeName
        state.inspectorBasicList = setInspectorsBasicList(inspectorListWithScopeName)
        state.userCanBeInspector = null
        state.userScope = null
        state.userBasicCoursesInfo = null
      })
      // Edit Inspector
      .addCase(editInspectorThunk.fulfilled, (state, { payload }) => {
        let updatedList = state.inspectorsList
        for (let inspectorAuth of payload) {
          const indexToUpdate = updatedList.findIndex(({ id }) => id === inspectorAuth.id)
          updatedList[indexToUpdate] = inspectorAuth
        }
        updatedList = insertScopeNameInInspectorList(updatedList)
        state.inspectorsList = updatedList
        const inspectorDetailsUpdate = setInspectorsDetailList(
          updatedList,
          state.inspectorBasicListRowSelected,
        )
        state.inspectorBasicList = setInspectorsBasicList(updatedList)
        state.inspectorDataDetailTable = inspectorDetailsUpdate
      })
      // Post Inspector Job
      .addCase(postInspectorJobThunk.fulfilled, (state, { payload }) => {
        const { inspetor_auths } = payload
        let jobsUpdatedRowSelected
        inspetor_auths.forEach((authModify) => {
          const authIndex = state.inspectorsList.findIndex(({ id }) => id === authModify.id)
          const authUpdate = { ...state.inspectorsList[authIndex], ...authModify }
          if (authModify.id === state.inspectorRowSelected?.id) {
            jobsUpdatedRowSelected = authUpdate.jobs
            state.inspectorRowSelected = authUpdate
          }
          state.inspectorsList[authIndex] = authUpdate
        })
        const inspectorListWithScopeName = insertScopeNameInInspectorList(state.inspectorsList)
        state.inspectorsList = inspectorListWithScopeName
        const inspectorDetailsUpdate = setInspectorsDetailList(
          inspectorListWithScopeName,
          state.inspectorBasicListRowSelected,
        )
        state.inspectorBasicList = setInspectorsBasicList(inspectorListWithScopeName)
        state.inspectorDataDetailTable = inspectorDetailsUpdate
        if (jobsUpdatedRowSelected) {
          const correctPerformanceJobsNumber = jobsUpdatedRowSelected.filter(
            ({ peration_performed_correctly, active }) => peration_performed_correctly && active,
          ).length
          state.inpectorSelectedJobsInfo = {
            totalJobs: jobsUpdatedRowSelected.length,
            correctPerformanceJobsNumber,
          }
        }
      })
      // Close Inspector Auth
      .addCase(closeInpectorAuthThunk.fulfilled, (state, { payload }) => {
        const inspectorIndex = state.inspectorsList.findIndex(({ id }) => id === payload.id)
        if (inspectorIndex > -1) {
          state.inspectorsList[inspectorIndex] = {
            ...state.inspectorsList[inspectorIndex],
            ...payload,
          }
        }
        state.inspectorDataDetailTable = setInspectorsDetailList(
          state.inspectorsList,
          state.inspectorBasicListRowSelected,
        )
      })
      // Continuous Evaluation
      .addCase(getInspectorsContinuousEvaluationThunk.fulfilled, (state, { payload }) => {
        const { inspectors_ids, programs_ids, programs_info } = payload
        state.continuousEvInfo = {
          inspectorsIds: inspectors_ids,
          programsIds: programs_ids,
          programsInfo: programs_info,
        }
      })
      .addCase(getInspectorsContinuousEvaluationThunk.rejected, (state) => {
        state.continuousEvInfo = INITIAL_STATE.continuousEvInfo
      })
      // Get Inspector Basic Info
      .addCase(getInspectorBasicInfoThunk.fulfilled, (state, { payload }) => {
        const { can_be_inspector_info, inspector_scope } = payload
        state.userCanBeInspector = can_be_inspector_info.can_be_inspector
        state.userScope = inspector_scope
        state.userBasicCoursesInfo = can_be_inspector_info.can_be_inspector
          ? null
          : can_be_inspector_info.courses_data
      })
      .addCase(getInspectorBasicInfoThunk.rejected, (state) => {
        state.userCanBeInspector = null
        state.userScope = null
        state.userBasicCoursesInfo = null
      })
      .addCase(getInspectorBasicInfoThunk.pending, (state) => {
        state.userCanBeInspector = null
        state.userScope = null
        state.userBasicCoursesInfo = null
      })
  },
})

export const {
  setCreateInspectorModalVisibility,
  setEditInspectorModalVisibility,
  setBelowPeopleInStructure,
  setJobsTableVisibility,
  setInspectorRowSelectedAction,
  setJobModalVisibility,
  setCloseAuthModalVisibility,
  setContinuousEvaluationModalVisibility,
  setInpectorSelectedJobsInfo,
  setInspectorBasicListRowSelectedAction,
  setDetailAuthTableVisibilityAction,
  resetUserCanBeInspector,
  setUsersStructureUpperN0,
  setJobRowSelected,
  setJobModalOnlyViewVisibility,
  setLoadingInspectorsListToFalse,
  clearInspectorBasicList,
} = authorizationOfInspectorsSlice.actions

export const dataPaginationDetails = (state) => state.fuse.inspectorsComponent.dataPaginationDetails

export default authorizationOfInspectorsSlice.reducer
