import { useSelector } from 'react-redux'
import './filters.css'
import { useFilterClassName, useHideFilterClickBackground } from './hooks/filterHooks'

export const Filters = ({ children }) => {
  // ? Selectors
  const { filterVisibility } = useSelector((state) => state.fuse.filterComponente)

  // ? Hooks
  const filterClassName = useFilterClassName()

  // ? Filter background function for hide the filter
  const { filterBackgroundFunction } = useHideFilterClickBackground()

  return (
    <>
      <main className={filterClassName} style={{ maxWidth: '450px', width: '100%' }}>
        {children}
      </main>
      {filterVisibility && (
        <div className="filter-background" onClick={(e) => filterBackgroundFunction(e)}></div>
      )}
    </>
  )
}
