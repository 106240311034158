export const MISSIONS_TYPES = {
    SET_CREATE_MODAL: "SET_CREATE_MODAL",
    SET_EDIT_MODAL: "SET_EDIT_MODAL", 
    SET_MISSION_SELECTED: "SET_MISSION_SELECTED",
    POST_MISSION: "POST_MISSION",
    PUT_MISSION: "PUT_MISSION",
    POST_MISSION_SUCCESS: "POST_MISSION_SUCCESS",
    POST_MISSION_ERROR: "POST_MISSION_ERROR",
    GET_VISUAL_TIME_GROUPS: "GET_VISUAL_TIME_GROUPS",
    GET_AGRESSO_CODES: "GET_AGRESSO_CODES",
    GET_ALL_MISSIONS: "GET_ALL_MISSIONS",
    LOADING_MISSIONS: 'LOADING_MISSIONS',
    ERROR_MISSIONS: 'ERROR_MISSIONS'
}

