import { Alert, Typography } from '@mui/material'

export const CenteredDivInfo = ({ infoText, severityType = "error" }) => {
    return (
        <div style={{ height: "70vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
            <Alert severity={severityType} className='mt-20 w-fit mx-auto'>
                <Typography>{infoText}</Typography>
            </Alert>
        </div>
    )
}
