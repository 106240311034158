import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; 
import { getRequestAuthHeader } from "app/js/generalFunctions";
import { ERROR_MESSAGE_OBJ } from "app/js/globalConsts";
import { showMessage } from "app/store/fuse/messageSlice";
import axios from "axios";
import { getHistoryData } from "./utils/utils";
import dayjs from "dayjs";
export const RECORD_HISTORY_URL = process.env.REACT_APP_URL_DJANGO + "/api/get_record_history"

const INITIAL_STATE = {
    recordHistory: [],
    recordHistoryModalVisibility: false,
    loading: false
}

export const getRecordHistoryThunk = createAsyncThunk('getRecordHistory', async({modelName, recordId}, {dispatch, rejectWithValue})=>{

    try{
        const queryString = new URLSearchParams({data: JSON.stringify({modelName, recordId})}).toString()
       
        const response = await axios({
            method: 'GET',
            url: `${RECORD_HISTORY_URL}?${queryString}`,
            headers: getRequestAuthHeader()
        })
    
        if(response.status !== 200) throw new Error('Error getting record history')
        const recordHistory = response.data
        return recordHistory
    }
    catch(e){
        console.error('Error getting record history')
        dispatch(showMessage(ERROR_MESSAGE_OBJ))
        return rejectWithValue()
        
    }

})





const extraReduders = {
    [getRecordHistoryThunk.fulfilled]:(state, {payload: recordHistory})=>{
        if(!recordHistory.length){
            state.recordHistory = []
            state.loading = false
        }
        else{
           
            const recordHistoryFormat = recordHistory.map(recordHistoryData => {
                let {timestamp, action, changes} = recordHistoryData
                timestamp= dayjs(timestamp).format('YYYY-MM-DDTHH:mm:ss').split('T').join(' ')
                const values = getHistoryData(action === 'update' ? changes.updated : changes.values)
                action = `${action.charAt().toUpperCase()}${action.substring(1)}`
                
                return  {...recordHistoryData, timestamp, values, action}
            })
            state.recordHistory = recordHistoryFormat
            state.recordHistoryModalVisibility = true
            state.loading = false
        }
    },
    [getRecordHistoryThunk.rejected]:(state)=>{
        state.recordHistory = []
        state.recordHistoryModalVisibility = false
        state.loading = false
    },
    [getRecordHistoryThunk.pending]:(state)=>{
        state.recordHistoryModalVisibility = true
        state.loading = true
    },
}

export const recordHistorySlice=createSlice({
    name: 'recordHistorySlice',
    initialState: INITIAL_STATE,
    reducers: {
        resetRecordHistory:(state)=>{
            state.recordHistory=[],
            state.recordHistoryModalVisibility= false
        }
    },
    extraReducers: {...extraReduders}
})
export const {resetRecordHistory} = recordHistorySlice.actions
export default recordHistorySlice.reducer
