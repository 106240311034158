import { createContext, useContext } from 'react';
import { dataDeliverablesReducer } from './deliverablesInternalReducer';
export const DataDeliverablesContext = createContext(null)

export const DataDeliverablesProvider = ({ children }) => {
    const { dataDeliverablesState, dataDeliverablesReducerActions } = dataDeliverablesReducer()

    const { setPostModalVisibility,
        setDeliverablesPostInfo,
        setDeliverablesObjRequest, setResearchAfterPost, setFilterValues, resetFilterValues, setPutModalVisibility, setEditQuantityInTableModalVisibility, setEditQuantityInTable, setDataDeliverablesQuantityEdited, setPostDateSelected, setAllDeliverablesView, resetDataDeliverablesQuantityEdited,setRowSelected, setDataDeliverablesSpecialPermissions } = dataDeliverablesReducerActions

    return (
        <DataDeliverablesContext.Provider value={{
            dataDeliverablesState,
            setPostModalVisibility,
            setDeliverablesPostInfo,
            setDeliverablesObjRequest, setResearchAfterPost, setFilterValues, resetFilterValues, setPutModalVisibility, setEditQuantityInTableModalVisibility, setEditQuantityInTable
            , setDataDeliverablesQuantityEdited, setPostDateSelected, setAllDeliverablesView, resetDataDeliverablesQuantityEdited,setRowSelected, setDataDeliverablesSpecialPermissions
        }}>

            {children}

        </DataDeliverablesContext.Provider>
    )
}