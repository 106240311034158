import React, { useEffect, useState, useRef, Fragment } from "react"
import { useTable, useFilters, useGroupBy, useExpanded, usePagination, useSortBy } from "react-table"
import Autocomplete from '@mui/material/Autocomplete'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { es } from 'date-fns/locale'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CustomPagination } from "./Components/CustomPagination"
import {
  exportToExcel,
  selectRowFunc,
  handleFilterChange,
  handleFilterChangeDate,
  rowColorStyle,
  updateEmptyRows,
} from "./utils/functions"
import { TableRows } from "./Components/TableRows"
import dayjs from 'dayjs'

import { styled } from '@mui/system'
import ExpandableRow from './Components/ExpandableRow'

export const Table = ({
  rowsProp: data,
  columnsProp: columns,
  loading,
  funcionSetValue,
  cellMaxWidth,
  tableWidth = "100%",
  selectedRowFunctions = null,
  headerTitleSection = false,
  headerTitleSectionWithButtons = false,
  headerTabs = false,
  headerButtons = true,
}) => {
  const [activeFilters, setActiveFilters] = useState([])
  // console.log("🚀 ~ activeFilters:", activeFilters)
  const {
    // Table properties and methods
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    page,

    // State and state setters
    setData,
    setFilter,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,

    // Pagination state and controls
    state: { pageIndex, pageSize, filters, expanded },
    canPreviousPage,
    canNextPage,
    toggleAllRowsExpanded,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10, filters: activeFilters },
      autoResetPage: false,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
  )

  const numberOfExpandedRows = Object.keys(expanded).filter((key) => expanded[key]).length

  // useState Hooks
  const [selectedRowIndex, setselectedRowIndex] = useState(null)
  const [valorSelect, setValorSelect] = useState(null)
  const [dateValue, setDateValue] = useState(null)
  const [dateValueExtra, setDateValueExtra] = useState(null)
  const [emptyRow, setEmptyRow] = useState()
  const [toggleExpandCollapse, setToggleExpandCollapse] = useState(false)

  // useRef Hooks
  const tableContainerRef = useRef(null)
  const tableMiddleContainerRef = useRef(null)
  const tableRef = useRef(null)
  const tableBodyRef = useRef(null)
  const initialDataLength = useRef(data.length)
  const tableHeadRef = useRef(null)
  const tableFootRef = useRef(null)

  // useEffect Hooks
  useEffect(() => {
    if (data.length !== initialDataLength.current) {
      resetTableRowSelection()
      initialDataLength.current = data.length
    }
  }, [data])

  const { setClearSelectedRow, clearSelectedRow } = selectedRowFunctions || {}
  useEffect(() => {
    if (clearSelectedRow) {
      resetTableRowSelection()
      setClearSelectedRow(false)
    }
  }, [clearSelectedRow])

  useEffect(() => {
    if (tableContainerRef.current && tableBodyRef.current) {
      updateEmptyRows(
        page.length,
        tableBodyRef,
        tableMiddleContainerRef,
        tableHeadRef,
        tableFootRef,
        setEmptyRow,
        columns,
      )
    }
  }, [page.length, tableContainerRef, tableBodyRef])

  // Functions
  const resetTableRowSelection = () => {
    setselectedRowIndex(null)
    funcionSetValue(null)
  }

  const toggleExpandCollapseAllRows = () => {
    setToggleExpandCollapse((prev) => !prev)
    toggleAllRowsExpanded(!toggleExpandCollapse)
  }

  // Props
  const CUSTOM_PAGINATION_PROPS = {
    setValorSelect,
    setDateValue,
    setDateValueExtra,
    columns,
    setFilter,
    tableContainerRef,
    tableMiddleContainerRef,
    pageIndex,
    rows,
    pageSize,
    filters,
    page,
    data,
    resetTableRowSelection,
    selectedRowIndex,
    setselectedRowIndex,
    exportToExcel,
    setPageSize,
    gotoPage,
    tableContainerRef,
    setActiveFilters,
  }

  const hasExpander = () => {
    return headerGroups.some((headerGroup) =>
      headerGroup.headers.some((column) => column.type === 'expand'),
    )
  }

  return (
    <>
      <div
        ref={tableContainerRef}
        className={`overflow-hidden flex flex-col relative`}
        style={{
          height: headerTitleSectionWithButtons
            ? '94%'
            : headerTitleSection
              ? '100%'
              : headerTabs && headerButtons
                ? 'calc(100% - 10% - 2dvh)'
                : 'calc(100% - 5% - 1dvh)',
        }}
      >
        <div
          ref={tableMiddleContainerRef}
          className={`h-full ${
            !page.length || !rows.length ? 'overflow-y-hidden' : 'overflow-y-auto'
          }`}
        >
          <table
            ref={tableRef}
            {...getTableProps()}
            className={`min-w-full h-full border-collapse overflow-auto ${tableWidth}`}
          >
            <thead ref={tableHeadRef}>
              {headerGroups.map((headerGroup, i) => (
                <tr
                  key={i}
                  {...headerGroup.getHeaderGroupProps()}
                  className="sticky top-0 z-10 shadow-[_0px_2px_5px#00000015] bg-white"
                >
                  {headerGroup.headers.map((column, i) => (
                    <th
                      key={i}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{ minWidth: column.width ? column.width : 'auto' }}
                      className={`text-center h-[60px] font-medium text-base ${
                        column.isSorted ? (column.isSortedDesc ? 'bg-gray-200' : 'bg-gray-100') : ''
                      }`}
                    >
                      <p className="m-0">
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ExpandLessIcon />
                          )
                        ) : (
                          ''
                        )}
                      </p>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()} className="h-full" ref={tableBodyRef}>
              {!page.length || !rows.length ? (
                <tr className="h-md">
                  <td>
                    <div className="w-full flex items-center justify-center absolute">No data</div>
                  </td>
                </tr>
              ) : (
                <>
                  {page.map((row, i) => {
                    prepareRow(row)
                    row.visiblePosition = i
                    return (
                      <Fragment key={i}>
                        <tr
                          {...row.getRowProps()}
                          onClick={() =>
                            selectRowFunc({
                              row,
                              funcionSetValue,
                              selectedRowIndex,
                              setselectedRowIndex,
                            })
                          }
                          className={`cursor-pointer h-[60px] ${rowColorStyle(
                            row,
                            selectedRowIndex,
                          )}`}
                        >
                          <TableRows row={row} cellMaxWidth={cellMaxWidth} />
                        </tr>

                        {hasExpander() && (
                          <ExpandableRow
                            row={row}
                            columns={columns}
                            headerGroups={headerGroups}
                            data={data}
                            selectedRowIndex={selectedRowIndex}
                          />
                        )}
                      </Fragment>
                    )
                  })}
                  {emptyRow}
                </>
              )}
            </tbody>

            <tfoot
              className="sticky bottom-0 bg-white shadow-[#00000015_0px_-4px_5px]"
              ref={tableFootRef}
            >
              {footerGroups.map((group, i) => (
                <tr {...group.getFooterGroupProps()} key={i}>
                  {group.headers.map((column) => (
                    <td {...column.getFooterProps()} className="text-center">
                      {column.render('Footer')}
                    </td>
                  ))}
                </tr>
              ))}
              {headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {headerGroup.headers.map((column, j) => (
                    <th {...column.getHeaderProps()} className="text-center">
                      {column.type === 'string' && (
                        <TextField
                          label={column.render('Header')}
                          id={`${column.render('Header')}-${i}-${j}`}
                          value={column.filterValue || ''}
                          size="small"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => {
                            setActiveFilters(prev => {
                              const updatedFilters = prev.filter(filter => filter.id !== column.id)
                              if (e.target.value === '') return updatedFilters
                              return [...updatedFilters, { id: column.id, value: e.target.value }]
                            })

                            handleFilterChange(
                              e.target.value,
                              column.id,
                              setFilter,
                              tableMiddleContainerRef,
                              gotoPage,
                            )
                          }}
                          sx={{
                            '& .MuiInputBase-input': {
                              padding: '8px 8px',
                            },
                          }}
                        />
                      )}

                      {column.type === 'list' && (
                        <Autocomplete
                          id={`tags-outlined-${i}-${j}`}
                          clearOnEscape
                          value={valorSelect || null}
                          getOptionLabel={(option) => option.label || ''}
                          options={(() => {
                            let uniqueOptions = [
                              ...new Set(data.map((obj) => obj[column.render('id')])),
                              ...(column.customMultipleSortable
                                ? column.customMultipleSortable.sortableKeys
                                : []),
                            ]

                            if (Array.isArray(uniqueOptions)) {
                              uniqueOptions = [
                                ...new Set(uniqueOptions.flatMap((option) => option)),
                              ]
                            }

                            return uniqueOptions.map((option) => {
                              const isSortableKey =
                                column.customMultipleSortable?.sortableKeys.includes(option)
                              const group = isSortableKey ? 'Multiple' : 'Single'

                              return {
                                label: option,
                                group: column.customMultipleSortable ? group : '',
                              }
                            })
                          })()}
                          groupBy={(option) => option.group}
                          onChange={(event, value) => {
                            setValorSelect(value)

                            const selectedValue = value ? value.label : null
                            const isSortableKey =
                              column.customMultipleSortable &&
                              column.customMultipleSortable.sortableKeys.includes(selectedValue)
                            setActiveFilters(prev => {
                              const updatedFilters = prev.filter(filter => filter.id !== column.id)
                              if (!selectedValue) return updatedFilters
                              return [
                                ...updatedFilters,
                                {
                                  id: column.id,
                                  value: isSortableKey
                                    ? column.customMultipleSortable[selectedValue]
                                    : selectedValue,
                                },
                              ]
                            });

                            handleFilterChange(
                              isSortableKey
                                ? column.customMultipleSortable[selectedValue]
                                : selectedValue,
                              column.id,
                              setFilter,
                              tableMiddleContainerRef,
                              gotoPage,
                            )
                          }}
                          filterSelectedOptions
                          isOptionEqualToValue={(option, value) => option.label === value.label}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              label={column.render('Header')}
                              size="small"
                              fullWidth
                              sx={{
                                '& .MuiInputBase-input': {
                                  padding: '8px 8px',
                                },
                              }}
                            />
                          )}
                        />
                      )}

                      {column.type === 'date' && (
                        <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={column.render('Header')}
                            size="small"
                            fullWidth
                            id={`date-${i}-${j}`}
                            inputFormat="yyyy-MM-dd"
                            format="yyyy-MM-dd"
                            value={dateValue}
                            onChange={(newValue) => {
                              if (newValue && !isNaN(newValue.getTime())) {
                                const localDate = dayjs(newValue).startOf('day')
                                const formattedDate = localDate.format('YYYY-MM-DD')
                                setActiveFilters(prev => {
                                  const updatedFilters = prev.filter(filter => filter.id !== column.id)
                                  return [
                                    ...updatedFilters,
                                    {
                                      id: column.id,
                                      value: formattedDate,
                                    },
                                  ]
                                });
                                handleFilterChangeDate(
                                  formattedDate,
                                  column.id,
                                  'start',
                                  setFilter,
                                  gotoPage,
                                )
                                setDateValue(formattedDate)
                              } else {
                                handleFilterChangeDate('', column.id, 'start', setFilter, gotoPage)
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                  '& .MuiInputBase-input': {
                                    padding: '8px 8px',
                                  },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}

                      {column.type === 'dateExtra' && (
                        <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={column.render('Header')}
                            size="small"
                            fullWidth
                            id={`dateExtra-${i}-${j}`}
                            inputFormat="yyyy-MM-dd"
                            format="yyyy-MM-dd"
                            value={dateValueExtra}
                            onChange={(newValue) => {
                              if (newValue && !isNaN(newValue.getTime())) {
                                const localDate = dayjs(newValue).startOf('day')
                                const formattedDate = localDate.format('YYYY-MM-DD')
                                setActiveFilters(prev => {
                                  const updatedFilters = prev.filter(filter => filter.id !== column.id)
                                  return [
                                    ...updatedFilters,
                                    {
                                      id: column.id,
                                      value: formattedDate,
                                    },
                                  ]
                                });
                                handleFilterChangeDate(
                                  formattedDate,
                                  column.id,
                                  'end',
                                  setFilter,
                                  gotoPage,
                                )
                                setDateValueExtra(formattedDate)
                              } else {
                                handleFilterChangeDate('', column.id, 'end', setFilter, gotoPage)
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                  '& .MuiInputBase-input': {
                                    padding: '8px 8px',
                                  },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
        </div>
        <div className={`w-full flex items-center justify-center`}>
          <CustomPagination {...CUSTOM_PAGINATION_PROPS} />
        </div>
      </div>
    </>
  )
}
