import React, { useEffect, useState } from 'react'
import { Table } from 'components/GlobalComponents/tables/Table2'
import {
  INSPECTORS_TABLE_COLUMNS,
  INSPECTOR_BASIC_LIST_COLUMNS,
  JOBS_TABLE_COLUMNS,
} from '../utils/consts'
import { useDispatch, useSelector } from 'react-redux'
import { LinearLoader } from 'components/GlobalComponents/loaders/loader'
import { CenteredDivInfo } from 'components/GlobalComponents/CenteredDiv/TextCenteredDiv'
import {
  setInspectorBasicListRowSelectedAction,
  setInspectorRowSelectedAction,
  setJobRowSelected,
  dataPaginationDetails,
} from '../store/AuthOfInspectorsSlice'
import { Box, Alert, Typography, Button, Stack, TextField, Autocomplete, Chip } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useGetPermissions } from 'app/js/customHooks/PermissionsHooks'
import {
  fetchAllInspectorsDetails,
  getAllInspectors,
  getInspectorsThunk,
} from '../store/AuthOfInspectorsThunk'

export const InspectorsTable = () => {
  const dispatch = useDispatch()

  const {
    inspectorsList,
    loadingInspectorsList,
    errorInspectorsList,
    noStructureAndNoPermission,
    inspectorBasicList,
    detailsAuthTableVisibility,
    inspectorDataDetailTable,
    jobsTableVisibility,
    inspectorJobsInJobsTable,
  } = useSelector(({ fuse }) => fuse.inspectorsComponent)

  const paginationDetailsSelector = useSelector(dataPaginationDetails)
  const { getViewAllPermission } = useGetPermissions()
  const canViewAllInspectors = getViewAllPermission('inspectors')

  const [selectedInspectors, setSelectedInspectors] = useState([])
  const [inspectorDetailsOptions, setInspectorDetailsOptions] = useState([])

  useEffect(() => {
    let isMounted = true

    if (canViewAllInspectors) {
      dispatch(fetchAllInspectorsDetails())
        .unwrap()
        .then((data) => {
          if (isMounted) {
            setInspectorDetailsOptions(data)
          }
        })
    }

    return () => {
      isMounted = false
    }
  }, [dispatch, canViewAllInspectors])

  const inspectorOptions = inspectorDetailsOptions.map((item) => ({
    id: item.inspector_id,
    name: `${item.inspector__first_name || ''} ${item.inspector__last_name || ''}`.trim(),
  }))

  const setInspectorSelected = (value) => dispatch(setInspectorRowSelectedAction(value))
  const setInspectorBasicListSelected = (value) =>
    dispatch(setInspectorBasicListRowSelectedAction(value))
  const setJobSelected = (value) => dispatch(setJobRowSelected(value))

  if (loadingInspectorsList && !errorInspectorsList) {
    return <LinearLoader />
  }

  if (!loadingInspectorsList && errorInspectorsList) {
    if (noStructureAndNoPermission) {
      return (
        <CenteredDivInfo infoText="You may not have the required permissions, or there are no inspectors available in your current structure." />
      )
    }
    return <CenteredDivInfo infoText="Error, please try again" />
  }

  const handleGetAllInspectors = () => {
    dispatch(getAllInspectors())
  }

  const handleSearch = () => {
    dispatch(getInspectorsThunk(selectedInspectors))
  }

  const tableConfigs = [
    {
      condition: !!inspectorsList.length && !detailsAuthTableVisibility && !jobsTableVisibility,
      config: {
        rowsProp: inspectorBasicList,
        columnsProp: INSPECTOR_BASIC_LIST_COLUMNS,
        funcionSetValue: setInspectorBasicListSelected,
        apiCustomPagination: paginationDetailsSelector,
      },
    },
    {
      condition: detailsAuthTableVisibility,
      config: {
        rowsProp: inspectorDataDetailTable || inspectorDataDetailTable[0]?.detailedAuthResult || [],
        columnsProp: INSPECTORS_TABLE_COLUMNS,
        funcionSetValue: setInspectorSelected,
      },
    },
    {
      condition: jobsTableVisibility,
      config: {
        rowsProp: inspectorJobsInJobsTable,
        columnsProp: JOBS_TABLE_COLUMNS,
        funcionSetValue: setJobSelected,
      },
    },
  ]

  const activeTable = tableConfigs.find(({ condition }) => condition)

  return activeTable && inspectorBasicList.length ? (
    <Table {...activeTable.config} loading={false} />
  ) : (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 4,
        textAlign: 'center',
      }}
    >
      <Alert
        severity="error"
        sx={{
          width: 'fit-content',
          mb: 4,
          '& .MuiAlert-message': {
            py: 1,
          },
        }}
      >
        <Typography variant="body1">
          No inspectors available in your current structure. Please verify your permissions or
          contact support.
        </Typography>
      </Alert>

      {!!canViewAllInspectors && (
        <Stack spacing={3} alignItems="center" sx={{ width: '100%', maxWidth: 800 }}>
          <Typography variant="body1" color="text.secondary">
            You have required permissions to view all inspectors
          </Typography>

          <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
            <Autocomplete
              multiple
              id="inspectors-search"
              options={inspectorOptions}
              getOptionLabel={(option) => option.name}
              value={selectedInspectors}
              onChange={(event, newValue) => {
                console.log * newValue
                setSelectedInspectors(newValue)
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Inspectors"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Search and select inspectors..."
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <SearchIcon fontSize="small" sx={{ color: 'action.active', mr: 1 }} />
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={index}
                    label={option.name}
                    size="small"
                    sx={{
                      m: 0.5,
                      borderRadius: 1,
                    }}
                  />
                ))
              }
              sx={{ width: '100%' }}
            />

            <Button
              onClick={handleSearch}
              variant="contained"
              color="primary"
              sx={{
                borderRadius: 2,
                px: 4,
                minWidth: 180,
                boxShadow: 'none',
                height: 40,
                alignSelf: 'center',
              }}
            >
              Search Inspectors
            </Button>
          </Stack>

          <Typography variant="body2" color="text.secondary">
            or
          </Typography>

          <Button
            onClick={handleGetAllInspectors}
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: 2,
              px: 4,
              borderWidth: 2,
              '&:hover': { borderWidth: 2 },
            }}
          >
            Show All Inspectors
          </Button>
        </Stack>
      )}
    </Box>
  )
}
