//******************** COMPONENTE PRINCIPAL *****************************************************


// CAMBIAR FILA SELECCIONADA GRID

export const CAMBIAR_VALOR_SELECCION_GRID_RM_RISK_OPPORTUNITY_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_RM_RISK_OPPORTUNITY_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_RM_RISK_OPPORTUNITY_HISTORY_MODAL_EXITO = "CAMBIAR_VALOR_SELECCION_GRID_RM_RISK_OPPORTUNITY_HISTORY_MODAL_EXITO"


//ELIMINAR RM REGISTRO
export const ELIMINAR_RM_REGISTRO = 'ELIMINAR_RM_REGISTRO'
export const ELIMINAR_RM_REGISTRO_EXITO = 'ELIMINAR_RM_REGISTRO_EXITO'
export const ELIMINAR_RM_REGISTRO_ERROR = 'ELIMINAR_RM_REGISTRO_ERROR'

//CONSULTA A API RM_REGISTRO PARA GRID
export const MOSTRAR_RM_REGISTRO = 'MOSTRAR_RM_REGISTRO'
export const MOSTRAR_RM_REGISTRO_EXITO = 'MOSTRAR_RM_REGISTRO_EXITO'
export const MOSTRAR_RM_REGISTRO_ERROR = 'MOSTRAR_RM_REGISTRO_ERROR'

// CAMBIAR VISIBILIDAD PRINCIPAL
export const CAMBIAR_MODAL_VISIBILIDAD_PRINCIPAL = 'CAMBIAR_MODAL_VISIBILIDAD_PRINCIPAL'
export const CAMBIAR_MODAL_VISIBILIDAD_PRINCIPAL_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_PRINCIPAL_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_PRINCIPAL_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_PRINCIPAL_ERROR'


// CAMBIAR ESTADO RM_REGISTRO
export const CAMBIAR_ESTADO_RM_REGISTRO = 'CAMBIAR_ESTADO_RM_REGISTRO'
export const CAMBIAR_ESTADO_RM_REGISTRO_EXITO = 'CAMBIAR_ESTADO_RM_REGISTRO_EXITO'
export const CAMBIAR_ESTADO_RM_REGISTRO_ERROR = 'CAMBIAR_ESTADO_RM_REGISTRO_ERROR'


//RESET STATES RM_REGISTRO
export const RESET_STATES_RM_REGISTRO = 'RESET_STATES_RM_REGISTRO'
export const RESET_STATES_RM_REGISTRO_EXITO = 'RESET_STATES_RM_REGISTRO_EXITO'
export const RESET_STATES_RM_REGISTRO_ERROR = 'RESET_STATES_RM_REGISTRO_ERROR'



// UPDATE RM_REGISTRO
export const UPDATE_RM_REGISTRO = 'UPDATE_RM_REGISTRO'
export const UPDATE_RM_REGISTRO_EXITO = 'UPDATE_RM_REGISTRO_EXITO'
export const UPDATE_RM_REGISTRO_ERROR = 'UPDATE_RM_REGISTRO_ERROR'


//******************** MODAL INSERTAR  *****************************************************

// CAMBIAR VISIBILIDAD
export const CAMBIAR_MODAL_VISIBILIDAD_RO_INSERTAR = 'CAMBIAR_MODAL_VISIBILIDAD_RO_INSERTAR'
export const CAMBIAR_MODAL_VISIBILIDAD_RO_INSERTAR_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_RO_INSERTAR_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_RO_INSERTAR_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_RO_INSERTAR_ERROR'

//INSERTAR NUEVO RM_REGISTRO
export const INSERTAR_RM_REGISTRO_MODAL_INSERTAR = 'INSERTAR_RM_REGISTRO_MODAL_INSERTAR'
export const INSERTAR_RM_REGISTRO_MODAL_INSERTAR_EXITO = 'INSERTAR_RM_REGISTRO_MODAL_INSERTAR_EXITO'
export const INSERTAR_RM_REGISTRO_MODAL_INSERTAR_ERROR = 'INSERTAR_RM_REGISTRO_MODAL_INSERTAR_ERROR'

//CONSULTA RISK MANAGEMENT
export const CONSULTA_RISK_MANAGEMENT_MODAL_INSERTAR = 'CONSULTA_RISK_MANAGEMENT_MODAL_INSERTAR'
export const CONSULTA_RISK_MANAGEMENT_MODAL_INSERTAR_EXITO = 'CONSULTA_RISK_MANAGEMENT_MODAL_INSERTAR_EXITO'
export const CONSULTA_RISK_MANAGEMENT_MODAL_INSERTAR_ERROR = 'CONSULTA_RISK_MANAGEMENT_MODAL_INSERTAR_ERROR'

//CAMBIAR_MODAL_VISIBILIDAD_HISTORICO
export const CAMBIAR_MODAL_VISIBILIDAD_HISTORICO_RYO_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_HISTORICO_RYO_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_HISTORICO_ACTIONS_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_HISTORICO_ACTIONS_EXITO'




//ORGANIGRAMA RISK OPORTUNITYS
export const CONSULTA_RISK_OPORTUNITY_ORGANIGRAMA = 'CONSULTA_RISK_OPORTUNITY_ORGANIGRAMA'
export const CONSULTA_RISK_OPORTUNITY_ORGANIGRAMA_EXITO = 'CONSULTA_RISK_OPORTUNITY_ORGANIGRAMA_EXITO'
export const CONSULTA_RISK_OPORTUNITY_ORGANIGRAMA_ERROR = 'CONSULTA_RISK_OPORTUNITY_ORGANIGRAMA_ERROR'

//ORGANIGRAMA RISK OPORTUNITYS
export const MOSTRAR_RM_REGISTRO_LAST_VERSION = 'MOSTRAR_RM_REGISTRO_LAST_VERSION'
export const MOSTRAR_RM_REGISTRO_LAST_VERSION_EXITO = 'MOSTRAR_RM_REGISTRO_LAST_VERSION_EXITO'
export const MOSTRAR_RM_REGISTRO_LAST_VERSION_ERROR = 'MOSTRAR_RM_REGISTRO_LAST_VERSION_ERROR'

export const SET_EVALUATION = 'SET_EVALUATION'

export const GUARDAR_RYO_ID ="GUARDAR_RYO_ID"

export const ACTION_REQUIRED_MODAL_VISION = "ACTION_REQUIRED_MODAL_VISION" ;

export const ACTION_IS_REQUIRED = "ACTION_IS_REQUIRED"


//******************** SETMYRYOS  *****************************************************

export const SET_MY_RYOS = "SET_MY_RYOS"
export const SET_NOT_MY_RYOS = "SET_NOT_MY_RYOS"
export const SET_MY_NO_REV_RYO = 'SET_MY_NO_REV_RYO'
export const SET_RYO_STRUCTURE = 'SET_RYO_STRUCTURE'
export const UPDATE_RYO_MIX_ACTIONS = 'UPDATE_RYO_MIX_ACTIONS'
