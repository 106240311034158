import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


export const UserCantBeInspector = ({ inspectorSelected }) => {
    const { userBasicCoursesInfo } = useSelector(({ fuse }) => fuse.inspectorsComponent)
    const basicCoursesCodes = ['CAL.010', 'FB.001', 'SIS.032']
    return (
        <div className='pt-3'>
            <Typography color='error' variant='h6'>
                {inspectorSelected.last_name}, {inspectorSelected.first_name} can't be granted as inspector
            </Typography>
            <Typography variant='p'>
                {inspectorSelected.IDRes} - {inspectorSelected.last_name}, {inspectorSelected.first_name}, can't be granted as inspector. To be granted as inspector {inspectorSelected.first_name} needs to have all the following courses completed:
            </Typography>

            <FormGroup className="flex-row">
                {
                    basicCoursesCodes.map(courseCode => (
                        <FormControlLabel key={courseCode} control={<Checkbox checked={userBasicCoursesInfo[courseCode]} />} label={courseCode} />
                    ))
                }

            </FormGroup>
        </div>
    )
}

