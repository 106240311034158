// useNotification.js
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useCallback } from 'react'
import { getNotificationAPIAction, putNotificationAPIAction } from 'components/TabsExcel/PeopleManagement/store/actions'

const useNotification = () => {
    const dispatch = useDispatch()
    const personLogin = useSelector(state => state.fuse.userComponente.person)
    const [loadingState, setLoadingState] = useState({
        getNotifications: false,
        markAll: false,
    })
    const [error, setError] = useState(null)

    const getNotificationAPI = useCallback(async () => {
        if (!personLogin.id) return
        setLoadingState(prev => ({ ...prev, getNotifications: true }))
        try {
            await dispatch(getNotificationAPIAction(personLogin.id))
        } catch (error) {
            setError("Error fetching notifications: " + error.message)
            console.error("Error fetching notifications:", error)
        } finally {
            setTimeout(() => {
                setLoadingState(prev => ({ ...prev, getNotifications: false }))
            }, 1000)
        }
    }, [dispatch, personLogin.id])

    const markNotificationAsRead = useCallback(async (notification) => {
        try {
            await dispatch(putNotificationAPIAction(notification.id, {
                origen_notification_id: notification.origen_notification_id,
                destino_notification_id: notification.destino_notification_id,
                fecha: notification.fecha,
                typeNotification: notification.typeNotification,
                active: false,
                amount_marked: 'single',
            }, personLogin.id))
        } catch (error) {
            setError("Error marking notification as read: " + error.message)
            console.error("Error marking notification as read:", error)
        }
    }, [dispatch, personLogin.id])

    const markAllNotificationsAsRead = useCallback(async (listNotifications) => {
        setLoadingState(prev => ({ ...prev, markAll: true }))
        try {
            await Promise.all(listNotifications.map(notification =>
                dispatch(putNotificationAPIAction(notification.id, {
                    origen_notification_id: notification.origen_notification_id,
                    destino_notification_id: notification.destino_notification_id,
                    fecha: notification.fecha,
                    typeNotification: notification.typeNotification,
                    active: false,
                    amount_marked: 'multiple',
                }, personLogin.id))
            ))
        } catch (error) {
            setError("Error marking all notifications as read: " + error.message)
            console.error("Error marking all notifications as read:", error)
        } finally {
            setLoadingState(prev => ({ ...prev, markAll: false }))
        }
    }, [dispatch, personLogin.id])

    useEffect(() => {
        getNotificationAPI()
        return () => {
        }
    }, [getNotificationAPI])

    return {
        getNotificationAPI,
        markNotificationAsRead,
        markAllNotificationsAsRead,
        loadingState,
        error,
    }
}

export default useNotification
