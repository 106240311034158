import FuseUtils from '@fuse/utils'
import { Navigate } from 'react-router-dom'
import React from 'react'
import FuseLoading from '@fuse/core/FuseLoading'
import Error404Page from 'app/main/404/Error404Page'
import LoginConfig from 'components/Login/LoginConfig'

// Core Components
import { ContractModalView } from 'components/Managment/Contract/main-page/ContractModalView'
import { DepartmentView } from 'components/Managment/Department/main-page/DepartmentView'
import { SkillMatrix } from 'components/TabsExcel/SkillMatrix/SkillMatrix'
import { Training } from 'components/TabsExcel/Training/TrainingMainPage'
import { RequirementsPage } from 'components/Managment/Requirements/RequirementsMainPage'
import { AuthorizationOfInspectors } from 'components/TabsExcel/AuthorizationOfInspectors/AuthorizationInspectorsMainPage'

// Context Providers
import { DeliverablesManagementContextProvider } from 'components/Managment/Deliverables/utils/DeliManagementProvider'
import { DataDeliverablesProvider } from 'components/TabsExcel/Deliverables/utils/deliverablesProvider'
import { ImprovementContextProvider } from 'components/TabsExcel/ImprovementProposals/utils/ImprovementProvider'

// PowerBI Components
const PowerBIComponents = {
  Performance: React.lazy(() => import('components/PowerBI/Performance/Performance')),
  TimeManagement: React.lazy(() => import('components/PowerBI/TimeManagement/TimeManagement')),
  SalesVolume: React.lazy(() => import('components/PowerBI/SalesVolume/SalesVolume')),
  Audits: React.lazy(() => import('components/PowerBI/Audits/Audits')),
  Summary: React.lazy(() => import('components/PowerBI/Summary/Summary')),
  Management: React.lazy(() => import('components/Managment/PowerBi_management/PowerBi_management')),
}

// Management Components
const ManagementComponents = {
  Divisions: React.lazy(() => import('components/Managment/Divisions/division')),
  Programs: React.lazy(() => import('components/Managment/Programs/programa')),
  Users: React.lazy(() => import('components/Managment/Users/Users')),
  Customers: React.lazy(() => import('components/Managment/Customers/Customers')),
  MainApp: React.lazy(() => import('components/Managment/App/MainApp')),
  Process: React.lazy(() => import('components/Managment/Process/Process')),
  Kpi: React.lazy(() => import('components/Managment/Kpi/Kpi')),
  Category: React.lazy(() => import('components/Managment/Category/Category')),
  Department: React.lazy(() => import('components/Gestion/Departamentos/departamentos')),
  Certifications: React.lazy(() => import('components/Managment/Rules/Rules')),
  Log: React.lazy(() => import('components/Managment/Log/Log')),
  LogPersona: React.lazy(() => import('components/Managment/LogCambiosPersonas/LogCambioPersonas')),
  Permisos: React.lazy(() => import('components/Managment/Permisos/Permisos')),
}

// Feature Components
const FeatureComponents = {
  Home: React.lazy(() => import('components/Home/home')),
  RiskManagement: React.lazy(() => import('components/TabsExcel/RiskManagement/main-page/RiskManagementView')),
  PeopleManagement: React.lazy(() => import('components/TabsExcel/PeopleManagement/PeopleManagement')),
  SearchUser: React.lazy(() => import('components/TabsExcel/PeopleManagement/Items/SearchUser/SearchUser')),
  Deliverables: React.lazy(() => import('components/Managment/Deliverables/Deliverables_management.jsx')),
  DataDeliverables: React.lazy(() => import('components/TabsExcel/Deliverables/Deliverables')),
  DataKpi: React.lazy(() => import('components/TabsExcel/DataKpi/DataKpi')),
  ImprovementProposals: React.lazy(() => import('components/TabsExcel/ImprovementProposals/main-page/ImprovementProposalsView')),
}

const routeConfigs = [LoginConfig]

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
  { path: 'pages/modules/authorizationOfInspectors', name: 'authorizationOfInspectors', element: <AuthorizationOfInspectors /> },
  { path: 'pages/management/contract', name: 'Contracts', element: <ContractModalView /> },
  { path: 'pages/management/department', name: 'Departments', element: <DepartmentView /> },
  { path: 'pages/modules/skillMatrix', name: 'Skill matrix', element: <SkillMatrix /> },
  { path: 'pages/modules/training', name: 'Trainng', element: <Training /> },
  { path: 'pages/gestiones/divisiones', name: 'Divisiones', element: <ManagementComponents.Divisions /> },
  { path: '/home', name: 'Home', element: <FeatureComponents.Home /> },
  { path: '/', name: 'Home', element: <FeatureComponents.Home /> },
  { path: 'pages/gestiones/programas', name: 'Programas', element: <ManagementComponents.Programs /> },
  { path: 'pages/tabsExcel/Riskmanagement', name: 'Risk Management', element: <FeatureComponents.RiskManagement /> },
  { path: 'pages/tabsExcel/peoplemanagement', name: 'People Management', element: <FeatureComponents.PeopleManagement /> },
  {
    path: 'pages/tabsExcel/improvementProposals',
    name: 'Improvement Proposals',
    element: (
      <ImprovementContextProvider>
        <FeatureComponents.ImprovementProposals />
      </ImprovementContextProvider>
    ),
  },
  {
    path: 'pages/tabsExcel/dataDeliverables',
    name: 'DataDeliverables',
    element: (
      <DataDeliverablesProvider>
        <FeatureComponents.DataDeliverables />
      </DataDeliverablesProvider>
    ),
  },
  {
    path: 'pages/management/deliverables',
    name: 'Deliverables',
    element: (
      <DeliverablesManagementContextProvider>
        <FeatureComponents.Deliverables />
      </DeliverablesManagementContextProvider>
    ),
  },
  { path: 'pages/tabsExcel/dataKpi', name: 'Data KPI', element: <FeatureComponents.DataKpi /> },
  { path: 'pages/gestiones/users', name: 'users', element: <ManagementComponents.Users /> },
  { path: 'pages/gestiones/process', name: 'process', element: <ManagementComponents.Process /> },
  { path: 'pages/gestiones/kpi', name: 'KPI', element: <ManagementComponents.Kpi /> },
  { path: 'pages/gestiones/customers', name: 'customers', element: <ManagementComponents.Customers /> },
  { path: 'pages/gestiones/categories', name: 'categories', element: <ManagementComponents.Category /> },
  { path: 'pages/management/requirements', name: 'requirements', element: <RequirementsPage /> },
  { path: 'pages/gestiones/department', name: 'department', element: <ManagementComponents.Department /> },
  { path: 'pages/gestiones/rules', name: 'rules', element: <ManagementComponents.Certifications /> },
  { path: 'pages/gestiones/log', name: 'log', element: <ManagementComponents.Log /> },
  { path: 'pages/gestiones/logPerson', name: 'logPerson', element: <ManagementComponents.LogPersona /> },
  { path: 'pages/gestiones/permissionsGroup', name: 'permissionsGroup', element: <ManagementComponents.Permisos /> },
  { path: 'pages/powerBI/performance', name: 'Performance', element: <PowerBIComponents.Performance /> },
  { path: 'pages/powerBI/TimeManagement', name: 'Time Management', element: <PowerBIComponents.TimeManagement /> },
  { path: 'pages/powerBI/SalesVolume', name: 'Sales Volume', element: <PowerBIComponents.SalesVolume /> },
  { path: 'pages/powerBI/Summary', name: 'Power Bi summary', element: <PowerBIComponents.Summary /> },
  { path: 'pages/gestiones/PowerBiManagement', name: 'Power Bi Management', element: <PowerBIComponents.Management /> },
  { path: 'pages/powerBI/Audits', name: 'FOD Audits', element: <PowerBIComponents.Audits /> },
  { path: 'pages/tabsExcel/staffManagement', name: 'powerBI', element: <FeatureComponents.SearchUser /> },
  { path: 'loading', element: <FuseLoading /> },
  { path: '404', element: <Error404Page /> },
  { path: '*', element: <Navigate to="404" /> },
]

export default routes;