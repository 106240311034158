import {

    GET_CONJUNTO,
    GET_CONJUNTO_EXITO,
    GET_CONJUNTO_ERROR,

    POST_CONJUNTO,
    POST_CONJUNTO_EXITO,
    POST_CONJUNTO_ERROR,

    PUT_CONJUNTO,
    PUT_CONJUNTO_EXITO,
    PUT_CONJUNTO_ERROR,

    CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO,
    CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO_EXITO,
    CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO,
    CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO_ERROR,

    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO_EXITO,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO_ERROR,


} from './types';
import axios from 'axios'
import {getCookie} from 'app/js/generalFunctions'
import { showMessage } from 'app/store/fuse/messageSlice'


//Constantes
const urlConjuntoApi = process.env.REACT_APP_URL_DJANGO + "/api/set/"

//************************ MOSTRAR PROGRAMAS API **********************************************

export function mostrarConjuntosAPIAction() {

    return async (dispatch) => {
        dispatch (mostrarConjuntosAPI(true))

        
            await axios({
                method: "GET",
                url: urlConjuntoApi,
                headers: {
                    'Authorization': `Token ${getCookie('token')}` 
                        }
                
                    })
                     .then(response=>{
                        dispatch (mostrarConjuntosAPIExito(response.data)) 
                    })
                    .catch(error => {
                        console.log(error.response)
                        dispatch (mostrarConjuntosAPIError(true))
                    })

          }
}

const mostrarConjuntosAPI = () => ({
    type: GET_CONJUNTO,
    payload: true

})

const mostrarConjuntosAPIExito = programas => ({
    type: GET_CONJUNTO_EXITO,
    payload: programas

})

const mostrarConjuntosAPIError = estado => ({
  type: GET_CONJUNTO_ERROR,
  payload: estado
})


//************************POST CONJUNTOS**********************************************

export function crearConjuntoActionAPIAction(conjunto) {

    return  (dispatch) => {
        dispatch (crearConjunto())

            
             axios({
                    method: "POST",
                    url: urlConjuntoApi,
                    data: conjunto,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (crearConjuntoExito(response.data))
                 dispatch(
                    showMessage({
                        message: "Created Asembly",
                        variant: "success"
                    })
                 )

                 dispatch(mostrarConjuntosAPIAction())
             })
             .catch(error => {
                console.log(error.response)
                dispatch (crearConjuntoError(true))

                dispatch(
                    showMessage({
                        message: "Error when creating Assembly",
                        variant: "error"
                    })
                )
            })

    }
}

const crearConjunto = (programa) => ({
    type: POST_CONJUNTO,
    payload: programa

})

const crearConjuntoExito = estado => ({
    type: POST_CONJUNTO_EXITO,
    payload: estado

})

const crearConjuntoError = estado => ({
    type:  POST_CONJUNTO_ERROR,
    payload: estado
})

//************************PUT CONJUNTO**********************************************


export function updateConjuntoActionAPIAction(id, json) {

    return async (dispatch) => {
        dispatch (updateConjunto())

             axios({
                    method: "PUT",
                    url: urlConjuntoApi + id,
                    data: json,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (updateConjuntoExito(response.data))

                 if (response.data['active'] == false) {
                    dispatch(
                        showMessage({
                            message: "Delete Assembly",
                            variant: "success"
                        })
                    )
                }
                else {

                    dispatch(
                        showMessage({
                            message: "Updated Assembly",
                            variant: "success"
                        })
                    )
                }
                

                 dispatch(mostrarConjuntosAPIAction())

             })
             .catch(error => {
                console.log(error.response)
                dispatch (updateConjuntoError(true))

                dispatch(
                    showMessage({
                        message: "Error when updating Asembly",
                        variant: "error"
                    })
                )
            })

    }
}

const updateConjunto = (rm_accion) => ({
    type: PUT_CONJUNTO,
    payload: rm_accion

})

const updateConjuntoExito = estado => ({
    type: PUT_CONJUNTO_EXITO,
    payload: estado

})

const updateConjuntoError = estado => ({
    type:  PUT_CONJUNTO_ERROR,
    payload: estado
})


//************************CAMBIAR VISIBILIDAD ACTION**********************************************

export function cambiarValorVisibilidadAction(valorNuevo) {

    return (dispatch) => {
        dispatch (cambiarValorVisibilidad())

        try {
            dispatch (cambiarValorVisibilidadExito(valorNuevo))

        } catch (error) {

            dispatch (cambiarValorVisibilidadError(true))
        }

    }

}

const cambiarValorVisibilidad = () => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO,
    payload: true

})

const cambiarValorVisibilidadExito = valorNuevo => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO_EXITO,
    payload: valorNuevo

})

const cambiarValorVisibilidadError = estado => ({
  type: CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO_ERROR,
  payload: estado
})

//************************ CAMBIAR SELECCION GRID ACTION**********************************************

export function cambiarValorSeleccionAction(valorNuevo) {

    return (dispatch) => {
        dispatch (cambiarValorSeleccion())

        try {
            dispatch (cambiarValorSeleccionExito(valorNuevo))

        } catch (error) {

            dispatch (cambiarValorSeleccionError(true))
        }

    }

}

const cambiarValorSeleccion = () => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO,
    payload: false

})

const cambiarValorSeleccionExito = valorNuevo => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO_EXITO,
    payload: valorNuevo

})

const cambiarValorSeleccionError = estado => ({
  type: CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO_ERROR,
  payload: estado
})

//************************CAMBIAR VISIBILIDAD MODAL INSERTAR**********************************************

export function cambiarVisibilidadModalInsertarConjuntoAction(valorNuevo, modo) {

    return (dispatch) => {
        dispatch (cambiarVisibilidadModalInsertar())

        try {
            dispatch (cambiarVisibilidadModalInsertarExito(valorNuevo, modo))

        } catch (error) {

            dispatch (cambiarVisibilidadModalInsertarError(true))
        }
    }
}

const cambiarVisibilidadModalInsertar = () => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO,
    payload: true

})

const cambiarVisibilidadModalInsertarExito = (valorNuevo, modo) => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO_EXITO,
    payload: valorNuevo,
    modo: modo
})

const cambiarVisibilidadModalInsertarError = estado => ({
  type:  CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO_ERROR,
  payload: estado
})
