//******************** COMPONENTE PRINCIPAL *****************************************************


// CAMBIAR FILA SELECCIONADA GRID
export const CAMBIAR_VALOR_SELECCION_GRID_DATA_KPI = 'CAMBIAR_VALOR_SELECCION_GRID_DATA_KPI'
export const CAMBIAR_VALOR_SELECCION_GRID_DATA_KPI_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_DATA_KPI_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_DATA_KPI_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_DATA_KPI_ERROR'

//CONSULTA A API IMPROVEMENT_PROPOSALS PARA GRID
export const MOSTRAR_DATA_KPI = 'MOSTRAR_DATA_KPI'
export const MOSTRAR_DATA_KPI_EXITO = 'MOSTRAR_DATA_KPI_EXITO'
export const MOSTRAR_DATA_KPI_ERROR = 'MOSTRAR_DATA_KPI_ERROR'

//UPDATE IMPROVEMENT_PROPOSALS
export const UPDATE_DATA_KPI = 'UPDATE_DATA_KPI'
export const UPDATE_DATA_KPI_EXITO = 'UPDATE_DATA_KPI_EXITO'
export const UPDATE_DATA_KPI_ERROR = 'UPDATE_DATA_KPI_ERROR'


//******************** MODAL INSERTAR  *****************************************************

// CAMBIAR VISIBILIDAD
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DATA_KPI = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DATA_KPI'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DATA_KPI_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DATA_KPI_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DATA_KPI_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DATA_KPI_ERROR'

//INSERTAR NUEVO RISK_MANAGER
export const INSERTAR_DATA_KPI = 'INSERTAR_DATA_KPI'
export const INSERTAR_DATA_KPI_EXITO = 'INSERTAR_DATA_KPI_EXITO'
export const INSERTAR_DATA_KPI_ERROR = 'INSERTAR_DATA_KPI_ERROR'

export const ELIMINAR_DATA_KPI = 'ELIMINAR_DATA_KPI'
export const ELIMINAR_DATA_KPI_EXITO = 'ELIMINAR_DATA_KPI_EXITO'
export const ELIMINAR_DATA_KPI_ERROR = 'ELIMINAR_DATA_KPI_ERROR'

export const SAVE_KPI_SELECT = 'SAVE_KPI_SELECT'
export const SAVE_KPI_SELECT_EXITO = 'SAVE_KPI_SELECT_EXITO'
export const SAVE_KPI_SELECT_ERROR = 'SAVE_KPI_SELECT_ERROR'

export const GET_SUMMARY_ACTUAL = 'GET_SUMMARY_ACTUAL'
export const GET_SUMMARY_ACTUAL_EXITO = 'GET_SUMMARY_ACTUAL_EXITO'
export const GET_SUMMARY_ACTUAL_ERROR = 'GET_SUMMARY_ACTUAL_ERROR'

export const GET_SUMMARY_SEMANAL_ACTUAL = 'GET_SUMMARY_SEMANAL_ACTUAL'
export const GET_SUMMARY_SEMANAL_ACTUAL_EXITO = 'GET_SUMMARY_SEMANAL_ACTUAL_EXITO'
export const GET_SUMMARY_SEMANAL_ACTUAL_ERROR = 'GET_SUMMARY_SEMANAL_ACTUAL_ERROR'

export const GET_SUMMARY_ESPECIAL_ACTUAL = 'GET_SUMMARY_ESPECIAL_ACTUAL'
export const GET_SUMMARY_ESPECIAL_ACTUAL_EXITO = 'GET_SUMMARY_ESPECIAL_ACTUAL_EXITO'
export const GET_SUMMARY_ESPECIAL_ACTUAL_ERROR = 'GET_SUMMARY_ESPECIAL_ACTUAL_ERROR'

export const OBTENER_VALORES_GRAFICOS_OQD = 'OBTENER_VALORES_GRAFICOS_OQD'
export const OBTENER_VALORES_GRAFICOS_OQD_EXITO = 'OBTENER_VALORES_GRAFICOS_OQD_EXITO'
export const OBTENER_VALORES_GRAFICOS_OQD_ERROR = 'OBTENER_VALORES_GRAFICOS_OQD_ERROR'

export const OBTENER_VALORES_GRAFICOS_OTD = 'OBTENER_VALORES_GRAFICOS_OTD'
export const OBTENER_VALORES_GRAFICOS_OTD_EXITO = 'OBTENER_VALORES_GRAFICOS_OTD_EXITO'
export const OBTENER_VALORES_GRAFICOS_OTD_ERROR = 'OBTENER_VALORES_GRAFICOS_OTD_ERROR'

export const SOLITUD_EXPORTACION_KPI = 'SOLITUD_EXPORTACION_KPI'
export const SOLITUD_EXPORTACION_KPI_EXITO = 'SOLITUD_EXPORTACION_KPI_EXITO'
export const SOLITUD_EXPORTACION_KPI_ERROR = 'SOLITUD_EXPORTACION_KPI_ERROR'

export const CAMBIAR_VALOR_ESTADO_EXPORTACION = 'CAMBIAR_VALOR_ESTADO_EXPORTACION'
export const CAMBIAR_VALOR_ESTADO_EXPORTACION_EXITO = 'CAMBIAR_VALOR_ESTADO_EXPORTACION_EXITO'
export const CAMBIAR_VALOR_ESTADO_EXPORTACION_ERROR = 'CAMBIAR_VALOR_ESTADO_EXPORTACION_ERROR'
export const GET_ALL_DATA_KPI = "GET_ALL_DATA_KPI"
export const GET_ALL_DATA_KPI_EXITO = "GET_ALL_DATA_KPI_EXITO"
export const GET_ALL_DATA_KPI_ERROR = "GET_ALL_DATA_KPI_ERROR"

export const SET_NEXT_DATE_CONTROL = "SET_NEXT_DATE_CONTROL"
export const SET_DATA_KPI_STRUCTURE = 'SET_DATA_KPI_STRUCTURE'
export const INSERT_NEW_DATA_KPI_IN_ALL_DATA_KPI = 'INSERT_NEW_DATA_KPI_IN_ALL_DATA_KPI'
export const UPDATE_ALL_DATA_KPI = 'UPDATE_ALL_DATA_KPI'
