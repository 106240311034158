import { createSlice } from '@reduxjs/toolkit'
import { fetchAnnouncements, fetchCourses } from './trainingThunks'
import { CourseLinearProgress } from 'components/TabsExcel/SkillMatrix/Components/CourseLinearProgress'
import { MoodleTrainingTags } from 'components/TabsExcel/SkillMatrix/Components/Tags'

const formatTrainingData = (data) =>
  data.map((obj) => ({
    ...obj,
    registered_courses_names: <MoodleTrainingTags tagsList={obj.registered_courses_names} />,
    progress: <CourseLinearProgress value={obj.progress} />,
  }))

const initialState = {
  status: {
    courses: 'idle',
    announcements: 'idle',
  },
  error: {
    courses: null,
    announcements: null,
  },
  missionSelectedId: null,
  courses: [],
  announcements: [],
}

export const trainingSlice = createSlice({
  name: 'training',
  initialState,
  reducers: {
    setMissionSelectedStore: (state, action) => {
      state.missionSelectedId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourses.pending, (state) => {
        state.status.courses = 'pending'
        state.error.courses = null
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        state.status.courses = 'fulfilled'
        state.courses = formatTrainingData(action.payload)
      })
      .addCase(fetchCourses.rejected, (state, action) => {
        state.status.courses = 'rejected'
        state.error.courses = action.payload
      })
      .addCase(fetchAnnouncements.pending, (state) => {
        state.status.announcements = 'pending'
        state.error.announcements = null
      })
      .addCase(fetchAnnouncements.fulfilled, (state, action) => {
        state.status.announcements = 'fulfilled'
        state.announcements = formatTrainingData(action.payload)
      })
      .addCase(fetchAnnouncements.rejected, (state, action) => {
        state.status.announcements = 'rejected'
        state.error.announcements = action.payload
      })
  },
})

export const { setMissionSelectedStore } = trainingSlice.actions

export const courses = (state) => state.fuse.training.courses
export const coursesError = (state) => state.fuse.training.error.courses
export const coursesStatus = (state) => state.fuse.training.status.courses

export const announcements = (state) => state.fuse.training.announcements
export const announcementsError = (state) => state.fuse.training.error.announcements
export const announcementsStatus = (state) => state.fuse.training.status.announcements

export default trainingSlice.reducer
