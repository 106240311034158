import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_DEPARTMENTS_LIST_REQUEST_OBJ, POST_DEPARTMENT_REQUEST_OBJ, PUT_DEPARTMENT_REQUEST_OBJ } from './utils/functions'
import axios from 'axios'
import { ERROR_TRY_AGAIN, SUCESS_MESSAGE_CREATED_DEPARTMENT, SUCESS_MESSAGE_EDITED_DEPARTMENT } from './utils/const'
import { showMessage } from 'app/store/fuse/messageSlice'
import { useDispatchError } from "app/js/customHooks/GeneralHooks";

export const getDepartments = createAsyncThunk(
    'departments/getDepartments',
    async (_, { rejectWithValue }) => {
        try {
            const config = GET_DEPARTMENTS_LIST_REQUEST_OBJ()

            const response = await axios(config)

            if (response.status !== 200) throw new Error(`Error: Received status code ${response.status}`)

            return response.data
        } catch (error) {
            return rejectWithValue(
                error || 'Failed to fetch data'
            )
        }
    }
)

export const postDepartment = createAsyncThunk(
    'departments/postDepartment',
    async (departmentData, { rejectWithValue, dispatch }) => {
        const { name_direccion_departamental } = departmentData
        try {
            const config = POST_DEPARTMENT_REQUEST_OBJ(departmentData)

            const response = await axios(config)

            if (response.status !== 201) throw new Error(`Error: Received status code ${response.status}`)

            dispatch(showMessage(SUCESS_MESSAGE_CREATED_DEPARTMENT))

            const data = { ...response.data, name_direccion_departamental }
            return data
        } catch (error) {
            useDispatchError(dispatch, error, ERROR_TRY_AGAIN)
            // dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(error || 'Failed to create department')
        }
    }
)

export const putDepartment = createAsyncThunk(
    'departments/putDepartment',
    async (departmentData, { rejectWithValue, dispatch }) => {
        const { id, name_direccion_departamental } = departmentData
        try {
            const config = PUT_DEPARTMENT_REQUEST_OBJ(departmentData)

            const response = await axios(config)

            if (response.status !== 200) throw new Error(`Error: Received status code ${response.status}`)

            dispatch(showMessage(SUCESS_MESSAGE_EDITED_DEPARTMENT))

            const data = { ...response.data, name_direccion_departamental }
            return data
        } catch (error) {
            useDispatchError(dispatch, error, ERROR_TRY_AGAIN)
            // dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(error || 'Failed to update department')
        }
    }
)
