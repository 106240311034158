import { createAsyncThunk } from '@reduxjs/toolkit'
import { showMessage } from 'app/store/fuse/messageSlice'
import axios from 'axios'
import {
    ERROR_MESSAGE_CREATE_SUBMISSION,
    ERROR_MESSAGE_EDIT_SUBMISSION,
    SUCESS_MESSAGE_CREATE_SUBMISSION,
    SUCESS_MESSAGE_EDIT_SUBMISSION
} from './utils/consts'
import {
    GET_ALL_DEPARTMENT_SUBMISSIONS_REQUEST_OBJ,
    POST_SUBMISSION_REQUEST_OBJ,
    PUT_SUBMISSION_REQUEST_OBJ
} from './utils/functions'
import { useDispatchError } from 'app/js/customHooks/GeneralHooks'

// Get all technicians
export const getAllDepartmentSubMissions = createAsyncThunk(
    'technicians/getAllDepartmentSubMissions',
    async (_, { rejectWithValue }) => {
        try {
            const config = GET_ALL_DEPARTMENT_SUBMISSIONS_REQUEST_OBJ()

            const response = await axios(config)

            if (response.status !== 200) {
                throw new Error(`Error: Received status code ${response.status}`)
            }

            return response.data
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || 'Failed to fetch department submissions'
            )
        }
    }
)

export const updateDepartmentSubMission = createAsyncThunk(
    'technicians/updateDepartmentSubMission',
    async (submissionData, { rejectWithValue, dispatch }) => {
        const { id, data, extraObjectData } = submissionData
        try {
            const config = PUT_SUBMISSION_REQUEST_OBJ(id, data)
            const response = await axios(config)

            if (response.status !== 200) {
                dispatch(showMessage(ERROR_MESSAGE_EDIT_SUBMISSION))
                return rejectWithValue(`Error: Received status code ${response.status}`)
            }

            dispatch(showMessage(SUCESS_MESSAGE_EDIT_SUBMISSION))

            const { esDepartamento, id_mision_name } = extraObjectData

            return { ...response.data, esDepartamento, id_mision_name }
        } catch (error) {
            useDispatchError(dispatch, error, ERROR_MESSAGE_EDIT_SUBMISSION)
            return rejectWithValue(
                error.response?.data?.message || 'Failed to update department submission'
            )
        }
    }
)

export const createDepartmentSubMission = createAsyncThunk(
    'technicians/createDepartmentSubMission',
    async (submissionData, { rejectWithValue, dispatch }) => {
        const { data, extraObjectData } = submissionData
        try {
            const config = POST_SUBMISSION_REQUEST_OBJ(data)
            const response = await axios(config)

            if (response.status !== 201) {
                dispatch(showMessage(ERROR_MESSAGE_CREATE_SUBMISSION))
                throw new Error(`Error: Received status code ${response.status}`)
            }

            dispatch(showMessage(SUCESS_MESSAGE_CREATE_SUBMISSION))

            const { esDepartamento, id_mision_name } = extraObjectData

            return { ...response.data, esDepartamento, id_mision_name }
        } catch (error) {
            useDispatchError(dispatch, error, ERROR_MESSAGE_CREATE_SUBMISSION)
            return rejectWithValue(
                error.response?.data?.message || 'Failed to post department submission'
            )
        }
    }
)
