import {
  // COMPONENTE PRINCIPAL ------------------

  CAMBIAR_VALOR_SELECCION_GRID_RM_RISK_OPPORTUNITY_EXITO,
  CAMBIAR_VALOR_SELECCION_GRID_RM_RISK_OPPORTUNITY_HISTORY_MODAL_EXITO,
  ELIMINAR_RM_REGISTRO,
  ELIMINAR_RM_REGISTRO_EXITO,
  ELIMINAR_RM_REGISTRO_ERROR,
  MOSTRAR_RM_REGISTRO,
  MOSTRAR_RM_REGISTRO_EXITO,
  MOSTRAR_RM_REGISTRO_ERROR,
  CAMBIAR_MODAL_VISIBILIDAD_PRINCIPAL,
  CAMBIAR_MODAL_VISIBILIDAD_PRINCIPAL_EXITO,
  CAMBIAR_MODAL_VISIBILIDAD_PRINCIPAL_ERROR,
  CAMBIAR_ESTADO_RM_REGISTRO,
  CAMBIAR_ESTADO_RM_REGISTRO_EXITO,
  CAMBIAR_ESTADO_RM_REGISTRO_ERROR,
  UPDATE_RM_REGISTRO,
  UPDATE_RM_REGISTRO_EXITO,
  UPDATE_RM_REGISTRO_ERROR,
  RESET_STATES_RM_REGISTRO,
  RESET_STATES_RM_REGISTRO_EXITO,
  RESET_STATES_RM_REGISTRO_ERROR,

  // MODAL INSERTAR  -----------------------
  CAMBIAR_MODAL_VISIBILIDAD_RO_INSERTAR,
  CAMBIAR_MODAL_VISIBILIDAD_RO_INSERTAR_EXITO,
  CAMBIAR_MODAL_VISIBILIDAD_RO_INSERTAR_ERROR,
  INSERTAR_RM_REGISTRO_MODAL_INSERTAR,
  INSERTAR_RM_REGISTRO_MODAL_INSERTAR_EXITO,
  INSERTAR_RM_REGISTRO_MODAL_INSERTAR_ERROR,
  CONSULTA_RISK_MANAGEMENT_MODAL_INSERTAR,
  CONSULTA_RISK_MANAGEMENT_MODAL_INSERTAR_EXITO,
  CONSULTA_RISK_MANAGEMENT_MODAL_INSERTAR_ERROR,
  CAMBIAR_MODAL_VISIBILIDAD_HISTORICO_RYO_EXITO,
  CAMBIAR_MODAL_VISIBILIDAD_HISTORICO_ACTIONS_EXITO,
  CONSULTA_RISK_OPORTUNITY_ORGANIGRAMA,
  CONSULTA_RISK_OPORTUNITY_ORGANIGRAMA_EXITO,
  CONSULTA_RISK_OPORTUNITY_ORGANIGRAMA_ERROR,
  MOSTRAR_RM_REGISTRO_LAST_VERSION,
  MOSTRAR_RM_REGISTRO_LAST_VERSION_EXITO,
  MOSTRAR_RM_REGISTRO_LAST_VERSION_ERROR,
  SET_EVALUATION,
  GUARDAR_RYO_ID,
  ACTION_IS_REQUIRED,
  ACTION_REQUIRED_MODAL_VISION,
  SET_MY_RYOS,
  SET_NOT_MY_RYOS,
  SET_MY_NO_REV_RYO,
  SET_RYO_STRUCTURE,
  UPDATE_RYO_MIX_ACTIONS,
} from './types'
import axios from 'axios'
import { getCookie } from 'app/js/generalFunctions'
import { showMessage } from 'app/store/fuse/messageSlice'
import store from 'app/store/index'
import { bool } from 'prop-types'

//Constantes
const urlRyOApi = process.env.REACT_APP_URL_DJANGO + '/api/rm_risk_opportunity/'
const urlRiskManagementApi = process.env.REACT_APP_URL_DJANGO + '/api/risk_management/'

//************************ UPDATE MIX ACTIONS **********************************************
export const handleUpdateRyOMixActions = (mixActions) => {
  return async (dispatch) => {
    dispatch(updateRyOMixActions(mixActions))
  }
}

const updateRyOMixActions = (mixActions) => async (dispatch) =>
  dispatch({ type: UPDATE_RYO_MIX_ACTIONS, payload: mixActions })
//************************ UPDATE MIX ACTIONS **********************************************

//************************ CONSULTA RISK OPORTUNITY ASOCIADOS MODAL **********************************************

export function consultaRiskOpportunityOrganigramaAPIAction(idRisk) {
  return async (dispatch) => {
    dispatch(consultaRiskOpportunityOrganigramaAPI())

    await axios({
      method: 'GET',
      url: urlRyOApi + 'organigrama/' + idRisk,
      headers: {
        Authorization: `Token ${getCookie('token')}`,
      },
    })
      .then((response) => {
        dispatch(consultaRiskOpportunityOrganigramaAPIExito(response.data))
      })
      .catch((error) => {
        console.log(error.response)
        dispatch(consultaRiskOpportunityOrganigramaAPIError(true))
      })
  }
}

const consultaRiskOpportunityOrganigramaAPI = () => ({
  type: CONSULTA_RISK_OPORTUNITY_ORGANIGRAMA,
  payload: true,
})

const consultaRiskOpportunityOrganigramaAPIExito = (riskOportunity) => ({
  type: CONSULTA_RISK_OPORTUNITY_ORGANIGRAMA_EXITO,
  payload: riskOportunity,
})

const consultaRiskOpportunityOrganigramaAPIError = (estado) => ({
  type: CONSULTA_RISK_OPORTUNITY_ORGANIGRAMA_ERROR,
  payload: estado,
})

//************************CAMBIAR VISIBILIDAD MODAL INSERTAR**********************************************

export function cambiarVisibilidadModalInsertarRyOAction(valorNuevo) {
  return (dispatch) => {
    dispatch(cambiarVisibilidadModalInsertarRyO())

    try {
      dispatch(cambiarVisibilidadModalInsertarRyOExito(valorNuevo))
    } catch (error) {
      dispatch(cambiarVisibilidadModalInsertarRyOError(true))
    }
  }
}

const cambiarVisibilidadModalInsertarRyO = () => ({
  type: CAMBIAR_MODAL_VISIBILIDAD_RO_INSERTAR,
  payload: true,
})

const cambiarVisibilidadModalInsertarRyOExito = (valorNuevo) => ({
  type: CAMBIAR_MODAL_VISIBILIDAD_RO_INSERTAR_EXITO,
  payload: valorNuevo,
})

const cambiarVisibilidadModalInsertarRyOError = (estado) => ({
  type: CAMBIAR_MODAL_VISIBILIDAD_RO_INSERTAR_ERROR,
  payload: estado,
})

//************************CAMBIAR VISIBILIDAD MODAL PRINCIPAL**********************************************

export function cambiarVisibilidadModalPrincipalAction(valorNuevo) {
  return (dispatch) => {
    dispatch(cambiarVisibilidadModalPrincipal())

    try {
      dispatch(cambiarVisibilidadModalPrincipalExito(valorNuevo))
    } catch (error) {
      dispatch(cambiarVisibilidadModalPrincipalError(true))
    }
  }
}

const cambiarVisibilidadModalPrincipal = () => ({
  type: CAMBIAR_MODAL_VISIBILIDAD_PRINCIPAL,
  payload: true,
})

const cambiarVisibilidadModalPrincipalExito = (valorNuevo) => ({
  type: CAMBIAR_MODAL_VISIBILIDAD_PRINCIPAL_EXITO,
  payload: valorNuevo,
})

const cambiarVisibilidadModalPrincipalError = (estado) => ({
  type: CAMBIAR_MODAL_VISIBILIDAD_PRINCIPAL_ERROR,
  payload: estado,
})

//************************ CAMBIAR ESTADO RM_REGISTRO  **********************************************

export function cambiarEstadoRyOAction(nombreEstado, valorNuevo) {
  return (dispatch) => {
    dispatch(cambiarEstadoRyO())

    try {
      dispatch(cambiarEstadoRyOExito(nombreEstado, valorNuevo))
    } catch (error) {
      dispatch(cambiarEstadoRyOError(true))
    }
  }
}

const cambiarEstadoRyO = () => ({
  type: CAMBIAR_ESTADO_RM_REGISTRO,
  payload: true,
})

const cambiarEstadoRyOExito = (nombreEstado, valorNuevo) => ({
  type: CAMBIAR_ESTADO_RM_REGISTRO_EXITO,
  nombre: nombreEstado,
  payload: valorNuevo,
})

const cambiarEstadoRyOError = (estado) => ({
  type: CAMBIAR_ESTADO_RM_REGISTRO_ERROR,
  payload: estado,
})

//************************ CAMBIAR SELECCION GRID ACTION**********************************************

export function cambiarValorSeleccionRyOAction(valorNuevo) {
  return (dispatch) => {
    dispatch(cambiarValorSeleccionRyOExito(valorNuevo))
  }
}

const cambiarValorSeleccionRyOExito = (valorNuevo) => ({
  type: CAMBIAR_VALOR_SELECCION_GRID_RM_RISK_OPPORTUNITY_EXITO,
  payload: valorNuevo,
})
//************************ GET ID RYO WHERE THERE IS NO FILA SELECCIONADA**********************************************
// instead of saving the id we are saving the whole item
export function saveRyOIdAction(rowItem) {
  return (dispatch) => {
    dispatch(saveRyOId(rowItem))
  }
}

const saveRyOId = (rowItem) => ({
  type: GUARDAR_RYO_ID,
  payload: rowItem,
})

//************************ CAMBIAR SELECCION HISTORY MODAL GRID ACTION**********************************************

export function cambiarValorSeleccionRyOHistricModalAction(valorNuevo) {
  return (dispatch) => {
    dispatch(cambiarValorSeleccionRyOHistricModalExito(valorNuevo))
  }
}

const cambiarValorSeleccionRyOHistricModalExito = (valorNuevo) => ({
  type: CAMBIAR_VALOR_SELECCION_GRID_RM_RISK_OPPORTUNITY_HISTORY_MODAL_EXITO,
  payload: valorNuevo,
})

//************************ ELIMINAR RM REGISTRO **********************************************

export function eliminarRyOAction(id) {
  return async (dispatch) => {
    dispatch(eliminarRyO())

    await axios({
      method: 'DELETE',
      url: urlRyOApi + id,
      headers: {
        Authorization: `Token ${getCookie('token')}`,
      },
    })
      .then((response) => {
        dispatch(eliminarRyOExito(false))

        dispatch(
          showMessage({
            message: 'Correctly removed',
            variant: 'success',
          }),
        )

        if (
          store.getState().fuse.riskManagementComponente.filaSeleccionadaGrid != '' &&
          store.getState().fuse.riskManagementComponente.filaSeleccionadaGrid != undefined
        ) {
          dispatch(
            mostrarRyOAPIAction(
              store.getState().fuse.riskManagementComponente.filaSeleccionadaGrid,
            ),
          )
          dispatch(
            mostrarRyOLastVersionAPIAction(
              store.getState().fuse.riskManagementComponente.filaSeleccionadaGrid,
            ),
          )
        } else {
          dispatch(
            mostrarRyOAPIAction(
              store.getState().fuse.riskManagementComponente.ultimoIdCreado['id'],
            ),
          )
          dispatch(
            mostrarRyOLastVersionAPIAction(
              store.getState().fuse.riskManagementComponente.ultimoIdCreado['id'],
            ),
          )
        }
      })

      .catch((error) => {
        console.log(error.response)
        dispatch(eliminarRyOError(false))
        dispatch(
          showMessage({
            message: 'Error al eliminar',
            variant: 'error',
          }),
        )
      })
  }
}

const eliminarRyO = (id) => ({
  type: ELIMINAR_RM_REGISTRO,
  payload: id,
})

const eliminarRyOExito = (estado) => ({
  type: ELIMINAR_RM_REGISTRO_EXITO,
  payload: estado,
})

const eliminarRyOError = (estado) => ({
  type: ELIMINAR_RM_REGISTRO_ERROR,
  payload: estado,
})

//************************ MOSTRAR RM_REGISTRO API **********************************************

export function mostrarRyOLastVersionAPIAction(id_risk_management_value) {
  return async (dispatch) => {
    dispatch(mostrarRyOLastVersionAPI())

    await axios({
      method: 'GET',
      url: urlRyOApi + 'lastVersion/' + id_risk_management_value,
      headers: {
        Authorization: `Token ${getCookie('token')}`,
      },
    })
      .then((response) => {
        dispatch(mostrarRyOLastVersionAPIExito(response.data))
      })
      .catch((error) => {
        console.log(error.response)
        dispatch(mostrarRyOLastVersionAPIError(true))
      })
  }
}

const mostrarRyOLastVersionAPI = () => ({
  type: MOSTRAR_RM_REGISTRO_LAST_VERSION,
  payload: true,
})

const mostrarRyOLastVersionAPIExito = (rm_risks) => ({
  type: MOSTRAR_RM_REGISTRO_LAST_VERSION_EXITO,
  payload: rm_risks,
})

const mostrarRyOLastVersionAPIError = (estado) => ({
  type: MOSTRAR_RM_REGISTRO_LAST_VERSION_ERROR,
  payload: estado,
})

export function mostrarRyOAPIAction() {
  return async (dispatch) => {
    dispatch(mostrarRyOAPI())

    await axios({
      method: 'GET',
      // url: urlRyOApi + "?id_risk_management=" + id_risk_management_value ,
      url: urlRyOApi,
      headers: {
        Authorization: `Token ${getCookie('token')}`,
      },
    })
      .then((response) => {
        // dispatch(cambiarEstadoRyOAction(response.data[response.data.length-1]["id"]));
        dispatch(mostrarRyOAPIExito(response.data))
      })
      .catch((error) => {
        console.log(error.response)
        dispatch(mostrarRyOAPIError(true))
      })
  }
}

const mostrarRyOAPI = () => ({
  type: MOSTRAR_RM_REGISTRO,
  payload: true,
})

const mostrarRyOAPIExito = (rm_risks) => ({
  type: MOSTRAR_RM_REGISTRO_EXITO,
  payload: rm_risks,
})

const mostrarRyOAPIError = (estado) => ({
  type: MOSTRAR_RM_REGISTRO_ERROR,
  payload: estado,
})

//************************CAMBIAR VISIBILIDAD MODAL HISTORICO RYO**********************************************

export function cambiarVisibilidadModalHistoricoRyOAction(valorNuevo) {
  return (dispatch) => {
    dispatch(cambiarVisibilidadModalHistoricoRyOExito(valorNuevo))
  }
}
const cambiarVisibilidadModalHistoricoRyOExito = (valorNuevo) => ({
  type: CAMBIAR_MODAL_VISIBILIDAD_HISTORICO_RYO_EXITO,
  payload: valorNuevo,
})

//************************CAMBIAR VISIBILIDAD MODAL HISTORICO ACTIONS **********************************************

export function cambiarVisibilidadModalHistoricoActionsAction(valorNuevo) {
  return (dispatch) => {
    dispatch(cambiarVisibilidadModalHistoricoActionsExito(valorNuevo))
  }
}

const cambiarVisibilidadModalHistoricoActionsExito = (valorNuevo) => ({
  type: CAMBIAR_MODAL_VISIBILIDAD_HISTORICO_ACTIONS_EXITO,
  payload: valorNuevo,
})

//************************ INSERTA RM_REGISTRO MODAL INSERTAR**********************************************

export function insertarRyOModalInsertarAction(rm_risk) {
  return async (dispatch) => {
    // dispatch(insertarRyOModalInsertar())
    try {
      const request = await axios({
        method: 'POST',
        url: urlRyOApi,
        data: rm_risk,
        headers: {
          Authorization: `Token ${getCookie('token')}`,
        },
      })
      dispatch(
        showMessage({
          message: rm_risk?.rev > 1 ? 'Re-evaluated risk successfully created with ID: ' + request.data.id : 'Risk successfully created',
          variant: 'success',
        }),
      )
      return request.data
    } catch (e) {
      console.log(e)
      dispatch(
        showMessage({
          message: 'Error creating risk / opportunity',
          variant: 'error',
        }),
      )
      return null
    }
  }
}

const insertarRyOModalInsertar = (rm_risk) => ({
  type: INSERTAR_RM_REGISTRO_MODAL_INSERTAR,
  payload: rm_risk,
})

const insertarRyOModalInsertarExito = (idCreado) => ({
  type: INSERTAR_RM_REGISTRO_MODAL_INSERTAR_EXITO,
  payload: idCreado,
})

const insertarRyOModalInsertarError = (estado) => ({
  type: INSERTAR_RM_REGISTRO_MODAL_INSERTAR_ERROR,
  payload: estado,
})

//************************ CONSULTA RISK MANAGEMENT MODAL **********************************************

export function consultaRiskManagementAction() {
  return async (dispatch) => {
    dispatch(consultaRiskManagement())

    await axios({
      method: 'GET',
      url: urlRiskManagementApi,
      headers: {
        Authorization: `Token ${getCookie('token')}`,
      },
    })
      .then((response) => {
        dispatch(consultaRiskManagementActionExito(response.data))
      })
      .catch((error) => {
        console.log(error.response)
        dispatch(consultaRiskManagementActionError(true))
      })
  }
}

const consultaRiskManagement = () => ({
  type: CONSULTA_RISK_MANAGEMENT_MODAL_INSERTAR,
  payload: true,
})

const consultaRiskManagementActionExito = (programas) => ({
  type: CONSULTA_RISK_MANAGEMENT_MODAL_INSERTAR_EXITO,
  payload: programas,
})

const consultaRiskManagementActionError = (estado) => ({
  type: CONSULTA_RISK_MANAGEMENT_MODAL_INSERTAR_ERROR,
  payload: estado,
})

//************************ UPDATE RM_REGISTRO **********************************************

export function updateRyOAction(id, json) {
  return async (dispatch) => {
    dispatch(updateRyO())
    if (
      !(
        store.getState().fuse.tasksAccionesComponente.filaSeleccionadaGridAcciones != '' &&
        store.getState().fuse.tasksAccionesComponente.filaSeleccionadaGridAcciones != undefined
      )
    ) {
      axios({
        method: 'PUT',
        url: urlRyOApi + id,
        data: json,
        headers: {
          Authorization: `Token ${getCookie('token')}`,
        },
      })
        .then((response) => {
          dispatch(updateRyOExito(response.data))

          if (response.data['active'] == true) {
            dispatch(
              showMessage({
                message: 'Updated Registro',
                variant: 'success',
              }),
            )
          } else {
            dispatch(
              showMessage({
                message: 'Delete R&O',
                variant: 'success',
              }),
            )
          }
        })
        .catch((error) => {
          console.error(error.response)
          dispatch(updateRyOError(true))

          dispatch(
            showMessage({
              message: 'Error when updating Registro',
              variant: 'error',
            }),
          )
        })
    }
  }
}

const updateRyO = () => ({
  type: UPDATE_RM_REGISTRO,
  payload: true,
})

const updateRyOExito = (estado) => ({
  type: UPDATE_RM_REGISTRO_EXITO,
  payload: estado,
})

const updateRyOError = (estado) => ({
  type: UPDATE_RM_REGISTRO_ERROR,
  payload: estado,
})

//************************ RESET ESTADOS  **********************************************

export function resetEstadosRyOAction() {
  return (dispatch) => {
    dispatch(resetEstadosRyO())

    try {
      dispatch(resetEstadosRyOExito(true))
    } catch (error) {
      dispatch(resetEstadosRyOError(true))
    }
  }
}

const resetEstadosRyO = () => ({
  type: RESET_STATES_RM_REGISTRO,
  payload: true,
})

const resetEstadosRyOExito = (estado) => ({
  type: RESET_STATES_RM_REGISTRO_EXITO,
  payload: estado,
})

const resetEstadosRyOError = (estado) => ({
  type: RESET_STATES_RM_REGISTRO_ERROR,
  payload: estado,
})

export function reevaluateRyOAction(state) {
  return (dispatch) => {
    dispatch(isReevaluated(state))
  }
}

const isReevaluated = (state) => ({
  type: SET_EVALUATION,
  payload: state,
})

export function visibilidadModalActionRequired(bool, dismissed = false) {
  return (dispatch) => {
    dispatch(setVisibilidadModalActionRequired(bool, dismissed))
  }
}

const setVisibilidadModalActionRequired = (bool, dismissed) => ({
  type: ACTION_REQUIRED_MODAL_VISION,
  payload: { isVisible: bool, dismissed },
})

export function actionIsRequiredAction(bool) {
  return (dispatch) => {
    dispatch(setActionIsRequired(bool))
  }
}

const setActionIsRequired = (bool) => ({
  type: ACTION_IS_REQUIRED,
  payload: bool,
})

//************************ SET_MY_RYOS  **********************************************

export const setMyNoRevRyOs = (ryos) => {
  return (dispatch) => {
    dispatch({ type: SET_MY_NO_REV_RYO, payload: ryos })
  }
}

export const setMyRyoOAction = (myRyOs) => {
  return (dispatch) => {
    dispatch(setMyRyOs(myRyOs))
  }
}

const setMyRyOs = (myRyOs) => ({
  type: SET_MY_RYOS,
  payload: myRyOs,
})

export const setNotMyRyOsAction = (notMyRyOs) => ({
  type: SET_NOT_MY_RYOS,
  payload: notMyRyOs,
})

export const setRyOStructure = (ryos) => {
  return (dispatch) => {
    dispatch({
      type: SET_RYO_STRUCTURE,
      payload: ryos,
    })
  }
}
