import { width } from '@mui/system'
import ExpandToggleCell from 'components/GlobalComponents/tables/Components/ExpandToggleCell'

export const FMEA_COLUMNS = [
  { Header: 'ID', accessor: 'id', sortable: true, type: 'string', width: 45 },
  { Header: 'Title', accessor: 'title', sortable: true, type: 'string' },
  { Header: 'Code', accessor: 'code', sortable: true, type: 'string' },
  { Header: 'Revision date', accessor: 'date', sortable: true, type: 'date', width: 120 },
  {
    Header: 'Manager',
    id: 'manager',
    accessor: ({ manager_info }) => `${manager_info?.last_name}, ${manager_info?.first_name}`,
    sortable: true,
    type: 'string',
  },
  { Header: 'Mission', accessor: 'mision_name', sortable: true, type: 'list' },
]

export const RYO_COLUMNS = [
  {
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <span {...getToggleAllRowsExpandedProps()} className="text-xl">
        {isAllRowsExpanded ? '↓' : '→'}
      </span>
    ),
    id: 'expander',
    type: 'expand',
    width: 10,
    disableSortBy: true,
    enableFiltering: false,
    Cell: ExpandToggleCell,
    expandData: (row) => {
      return [
        { title: 'Effect', dataName: row.glitch_effect },
        { title: 'Cause', dataName: row.cause_failure },
        { title: 'Current controls', dataName: row.current_controls },
        { title: 'Priorization', dataName: row.priorization },
        { title: 'Remarks', dataName: row.observations },
        { title: 'Category', dataName: row.categorizacion_name },
        { title: 'Severity', dataName: row.gravity },
        { title: 'Frequency', dataName: row.idea },
        { title: 'Detection', dataName: row.detection },
      ]
    },
  },
  { Header: 'ID', accessor: 'id', sortable: true, type: 'string', width: 45 },
  { Header: 'FMEA', accessor: 'amfe_name', sortable: true, type: 'string' },
  { Header: 'Risk description', accessor: 'risk_description', sortable: true, type: 'string' },
  { Header: 'Opportunity description', accessor: 'opportunity_description', sortable: true, type: 'string' },
  { Header: 'Detection date', accessor: 'd_detection', sortable: true, type: 'date', width: 120 },
  { Header: 'NPR', accessor: 'npr', sortable: true, type: 'string', width: 80 },
  {
    Header: 'Actions',
    accessor: 'mix_actions',
    sortable: true,
    type: 'string',
    width: 120,
  },
  { Header: 'Revision', accessor: 'rev', sortable: true, type: 'string', width: 45 },
]

export const RYO_ACTIONS_COLUMNS = [
  { Header: 'ID', accessor: 'id', sortable: true, type: 'string', width: 45 },
  { Header: 'R&O ID', accessor: 'id_record', sortable: true, type: 'string', width: 65 },
  { Header: 'Proporsal', accessor: 'proposal', sortable: true, type: 'string' },
  { Header: 'Responsible', accessor: 'responsablesName', sortable: true, type: 'list' },
  { Header: 'Date planned', accessor: 'd_planned', sortable: true, type: 'date', width: 120 },
  { Header: 'Date closed', accessor: 'd_closed', sortable: true, type: 'date', width: 120 },
  { Header: 'Observations', accessor: 'observations', sortable: true, type: 'string' },
]
