import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hideMessage } from 'app/store/fuse/messageSlice'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'

function SlideTransition(props) {
  return <Slide {...props} direction="down" />
}

function FuseMessage() {
  const dispatch = useDispatch()
  const state = useSelector(({ fuse }) => fuse.message.state)
  const options = useSelector(({ fuse }) => fuse.message.options)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(hideMessage())
  }

  return (
    <Snackbar
      open={state}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={SlideTransition}
    >
      <Alert
        onClose={handleClose}
        severity={options.variant || 'error'}
        sx={{ width: '100%', borderRadius: 2, paddingBlock: '13px' }}
      >
        <Typography>{options.message || ''}</Typography>
      </Alert>
    </Snackbar>
  )
}

export default React.memo(FuseMessage)
