import { createAsyncThunk } from '@reduxjs/toolkit'
import { showMessage } from 'app/store/fuse/messageSlice'
import axios from 'axios'
import { getJobInDepartmentSubmission } from '../../InsertEditModal/features/insertEditModalThunks'
import { ERROR_TRY_AGAIN, SUCCESS_REMOVED } from './utils/consts'
import { GET_PERSONEL_IN_SUBMISSION_REQUEST_OBJ, PUT_JOB_IN_TECHNICIAN_REQUEST_OBJ } from './utils/functions'

export const getStaffInTechnician = createAsyncThunk(
    'jobsModalSlice/getStaffInSubmissionAction',
    async (technicianId, { rejectWithValue, dispatch }) => {
        const { id, staffInSubmissionToSwitch = null } = technicianId;
        try {
            const config = GET_PERSONEL_IN_SUBMISSION_REQUEST_OBJ(id)

            const response = await axios(config)

            if (response.status !== 200) {
                dispatch(showMessage(ERROR_TRY_AGAIN))
                throw new Error(`Error: Received status code ${response.status}`)
            }

             if (staffInSubmissionToSwitch !== null) return { data: response.data, type: staffInSubmissionToSwitch ? 'switch' : 'selected' }
            return response.data
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Failed to get staff in submission')
        }
    }
)

export const putJobInTechnician = createAsyncThunk(
    'jobsModalSlice/putJobInTechnician',
    async (jobData, { rejectWithValue, dispatch }) => {
        try {
            const config = PUT_JOB_IN_TECHNICIAN_REQUEST_OBJ(jobData)

            const response = await axios(config)

            if (response.status !== 200) {
                dispatch(showMessage(ERROR_TRY_AGAIN))
                throw new Error(`Error: Received status code ${response.status}`)
            }

            dispatch(getJobInDepartmentSubmission({ id: jobData.sub_mision, jobsFromSubmissionToSwitch: false }))

            dispatch(showMessage(SUCCESS_REMOVED))

            return response.data
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Failed to put job in technician')
        }
    }
)
