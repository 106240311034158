export const AREA_URL = process.env.REACT_APP_URL_DJANGO + "/api/workpackage/"
export const DEPARTAMENTAL_AREA_URL = process.env.REACT_APP_URL_DJANGO + "/api/workpackage/departamentos/"

export const SUCESS_MESSAGE_CREATED_AREA = {
    message: "Created area",
    variant: "success"
}

export const SUCESS_MESSAGE_EDITED_AREA = {
    message: "Edited area",
    variant: "success"
}

export const ERROR_TRY_AGAIN = {
    message: "Error, try again",
    variant: "error"
}
