import { toggleQuickPanel } from './store/stateSlice'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, Badge, Box, styled } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'

const AnimatedBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'hasNotifications' })(
  ({ hasNotifications }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    animation: hasNotifications ? 'shake 1.5s ease-in-out infinite' : 'none',
    '@keyframes shake': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '25%': {
        transform: 'rotate(15deg)',
      },
      '50%': {
        transform: 'rotate(-15deg)',
      },
      '75%': {
        transform: 'rotate(15deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
  }),
)

const QuickPanelToggleButton = () => {
  const dispatch = useDispatch()
  const { listUnreadNotifications } = useSelector(
    (state) => state.fuse.peopleManagementComponente.listNotificactions,
  )

  const notificationCount = listUnreadNotifications?.length || 0
  const hasNotifications = notificationCount > 0

  return (
    <IconButton className="w-40 h-40" onClick={() => dispatch(toggleQuickPanel())} size="large">
      <Badge
        badgeContent={notificationCount}
        color={hasNotifications ? 'warning' : 'primary'}
        sx={{
          '& .MuiBadge-dot': {
            width: '12px',
            height: '12px',
          },
          '& .MuiBadge-badge': {
            minWidth: '18px',
            height: '18px',
            fontSize: '0.75rem',
            top: hasNotifications ? '3px' : '0',
          },
        }}
      >
        <AnimatedBox hasNotifications={hasNotifications}>
          <NotificationsIcon />
        </AnimatedBox>
      </Badge>
    </IconButton>
  )
}

export default QuickPanelToggleButton
