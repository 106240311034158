import React from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const ExpandToggleCell = ({ row }) => {
    const handleToggle = (e) => {
        e.stopPropagation()
        row.toggleRowExpanded()
    }

    return (
        <button onClick={handleToggle} aria-label="Expand Row">
            {row.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </button>
    )
}

export default ExpandToggleCell
