import { Table } from "components/GlobalComponents/tables/Table2";
import { INSPECTORS_TABLE_COLUMNS, INSPECTOR_BASIC_LIST_COLUMS, JOBS_TABLE_COLUMNS } from "../utils/consts";
import { useDispatch, useSelector } from 'react-redux';
import { LinearLoader } from "components/GlobalComponents/loaders/loader";
import { CenteredDivInfo } from "components/GlobalComponents/CenteredDiv/TextCenteredDiv";
import { setInspectorBasicListRowSelectedAction, setInspectorRowSelectedAction, setJobRowSelected } from "../store/AuthorizationOfInspectorsSlice";


export const InspectorsTable=()=>{
    const dispach = useDispatch()
    const { inspectorsList, loadingInspectorsList, errorInspectorsList, inpsectorBasicList, detailsAuthTableVisibility, inpectorDataDetailTable, jobsTableVisibility, inspectorRowSelected,inspectorJobsInJobsTable , inspectorBasicListRowSelected} = useSelector(({ fuse }) => fuse.inspectorsComponent)
    console.log("🚀 ~ InspectorsTable ~ inspectorBasicListRowSelected:", inspectorBasicListRowSelected)
    // console.log("🚀 ~ InspectorsTable ~ inspectorRowSelected:", inspectorRowSelected)
    // console.log("🚀 ~ InspectorsTable ~ inpsectorBasicList:", inpsectorBasicList)
    // console.log("🚀 ~ InspectorsTable ~ inpectorDataDetailTable:", inpectorDataDetailTable)
    const setInspectorSelected = (value)=> {  dispach(setInspectorRowSelectedAction(value)) }
    const setInpectorBasicListSelected =(value)=>  {  dispach(setInspectorBasicListRowSelectedAction(value)) }
    const setJobSelected =(value)=>{dispach(setJobRowSelected(value))}


    return(
        <>
        {!loadingInspectorsList && !!errorInspectorsList && <CenteredDivInfo infoText={'Error, please, try again'} />}
        {!!loadingInspectorsList && !errorInspectorsList && <LinearLoader />}

        {!!inspectorsList.length && !loadingInspectorsList && !errorInspectorsList && !detailsAuthTableVisibility && !jobsTableVisibility && <Table rowsProp={inpsectorBasicList} columnsProp={INSPECTOR_BASIC_LIST_COLUMS} loading={false}  funcionSetValue={setInpectorBasicListSelected}  />}
        {detailsAuthTableVisibility && <Table rowsProp={inpectorDataDetailTable} columnsProp={INSPECTORS_TABLE_COLUMNS} loading={false}  funcionSetValue={setInspectorSelected}  />}
        {jobsTableVisibility && <Table rowsProp={inspectorJobsInJobsTable} columnsProp={JOBS_TABLE_COLUMNS} loading={false}  funcionSetValue={setJobSelected}  />}
        </>
        
    )
}