import { Table } from 'components/GlobalComponents/tables/Table2'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogContent, DialogTitle, Button, Grid, Typography } from '@mui/material'
import {
  resetRecordHistory,
  setCurrentRecordId,
} from 'app/store/general_states/record_history/record_history_slice'
import { getUserStringsInfo } from 'app/js/generalFunctions'
import ExpandToggleCell from '../tables/Components/ExpandToggleCell'
import { LinearLoader } from '../loaders/loader'

export const RecordHistoryModal = () => {
  const dispatch = useDispatch()
  const closeModal = () => {
    dispatch(resetRecordHistory())
    dispatch(setCurrentRecordId(null))
  }

  const { recordHistoryModalVisibility, recordHistory, loading } = useSelector(
    ({ fuse }) => fuse.recordHistory,
  )

  const TABLE_COLUMNS = [
    {
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <span {...getToggleAllRowsExpandedProps()} className="text-xl">
          {isAllRowsExpanded ? '↓' : '→'}
        </span>
      ),
      id: 'expander',
      type: 'expand',
      width: 10,
      disableSortBy: true,
      enableFiltering: false,
      Cell: ExpandToggleCell,
      expandData: (row) =>
        row?.values && Object.keys(row.values).length > 0
          ? Object.keys(row.values).map((valueKey) => {
            const updatedValue = row.values[valueKey]
            return { title: valueKey, dataName: updatedValue }
          })
          : [],
    },
    {
      Header: 'User',
      accessor: ({ user_info }) => getUserStringsInfo(user_info),
      type: 'string',
      sortable: true,
    },
    { Header: 'Action', accessor: 'action', type: 'string', sortable: true },
    { Header: 'Date', accessor: 'timestamp', type: 'string', sortable: true },
  ]

  return (
    <Dialog open={recordHistoryModalVisibility} onClose={closeModal} fullWidth maxWidth="lg">
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" component="div">
              Record history
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={closeModal}> Close </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <>
        <DialogContent
          sx={{
            p: 0,
            m: 0,
            height: '80vh',
          }}
          dividers
        >
          {loading ? (
            <LinearLoader />
          ) : (
            <Table
              rowsProp={recordHistory}
              columnsProp={TABLE_COLUMNS}
              funcionSetValue={() => { }}
              headerTitleSection={true}
            />
          )}
        </DialogContent>
      </>
    </Dialog>
  )
}
