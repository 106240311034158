import { useSelector, useDispatch } from "react-redux";
import {
    cambiarEstadoRyOAction,
    cambiarVisibilidadModalInsertarRyOAction,
    cambiarVisibilidadModalHistoricoRyOAction,
} from '../../store/RyO/actions'

import {
    cambiarVisibilidadModalEliminarAnfeAction,
} from '../../store/Anfe/actions'

import {
    cambiarEstadoRyOTasksAction,
    changeActionsEditMode
} from '../../store/actions/actions.js'


// ? Create RyO buttom
export function useCreateButtom() {
    const dispatch = useDispatch();
    const createButtom =()=>{
        dispatch(cambiarVisibilidadModalInsertarRyOAction(true));
        dispatch(cambiarEstadoRyOAction('modo', 'crear'));
    }
    return {createButtom}
}

// ? Edit RyO buttom
export function useEditButtom() {
    const dispatch = useDispatch();
    const editButtom =()=>{
        dispatch(cambiarVisibilidadModalInsertarRyOAction(true));
        dispatch(cambiarEstadoRyOAction('modo', 'editar'));
    }
    return {editButtom}
}

// ? Delete RyO buttom
export function useDeleteButtom() {
    const dispatch = useDispatch();
    const deleteButtom =()=> {
        dispatch(cambiarVisibilidadModalEliminarAnfeAction(true));
    }
    return { deleteButtom }
}

// ? Show revs history buttom
export function useHistoryButtom() {
    const dispatch = useDispatch();
    const historyButtom =()=> {
        dispatch(cambiarVisibilidadModalHistoricoRyOAction(true))
    }
    return {historyButtom}
}

// ? Show actions modal buttom
export function useActionsButtom(){
    const dispatch = useDispatch();
    const actionsButtom =()=>{
        dispatch(cambiarEstadoRyOTasksAction('visibilidad', true));
        dispatch(changeActionsEditMode(false))
        dispatch(cambiarEstadoRyOAction('modo', ''));
    }
    return { actionsButtom }
}
