import { multiFilter } from "components/GlobalComponents/tables/utils/functions"


export const SCOPE_CODES_NAMES = {
    ['QIN.002']: 'Inspección básica',
    ['QIN.003']: 'Inspección mecánica',
    ['QIN.004']: 'Inspección de instalaciones flúdicas',
    ['QIN.005']: 'Inspección de instalaciones y reparaciones eléctricas y equipos',
    ['QIN.006']: 'Inspección cierre de zona',
    ['QIN.012']: 'Inspección pintura'
}

export const SCOPES_BASIC_NAMES = {
    ['QIN.002']: 'Basic',
    ['QIN.003']: 'Mec',
    ['QIN.004']: 'Fluid',
    ['QIN.005']: 'Elec',
    ['QIN.006']: 'Zone',
    ['QIN.012']: 'Paint'
}

export const SCOPE_NAMES_JOB_MODAL = {
    ['QIN.002']: 'Scope 1 | Basic',
    ['QIN.003']: 'Scope 2 | Mec',
    ['QIN.004']: 'Scope 3 | Fluid',
    ['QIN.005']: 'Scope 4 | Elec',
    ['QIN.006']: 'Scope 5 | Zone',
    ['QIN.012']: 'Scope 6 | Paint'
}


export const AUTH_STATES_BY_DATE = {
    onGoing: 'On going',
    pending: 'Pending',
    inForce: 'Active',
    soonExpires: 'Soon expires',
    expired: 'Expired'
}


export const INSPECTORS_TABLE_COLUMNS = [
    // {Header:'Tutor', accessor:(({tutor_info})=> `${tutor_info.IDRes} - ${tutor_info.last_name}, ${tutor_info.first_name}`),sortable: true, type: 'string', id:'tutor'},
    // { Header: 'Inspector', accessor: (({ inspector_info }) => `${inspector_info.IDRes} - ${inspector_info.last_name}, ${inspector_info.first_name}`), sortable: true, type: 'string', id: 'inspector' },
    // { Header: 'Program', accessor: (({ program_info }) => `${program_info.name}`), sortable: true, type: 'string', width: 30, id: 'program' },


    { Header: 'Scope', accessor: 'tableScope', sortable: true, type: 'list', chip: Object.values(SCOPE_NAMES_JOB_MODAL).map(scopeCode => ({ label: scopeCode, color: 'success' })) },
    {
        Header: 'State', accessor: 'state', sortable: true, type: 'list', chip: [
            { label: AUTH_STATES_BY_DATE.expired, color: 'error' },
            { label: AUTH_STATES_BY_DATE.onGoing, color: 'info' },
            { label: AUTH_STATES_BY_DATE.soonExpires, color: 'warning' },
            { label: AUTH_STATES_BY_DATE.pending, color: 'primary' },
            { label: AUTH_STATES_BY_DATE.inForce, color: 'success' },

        ],
    },
    { Header: 'Initial auth start', accessor: 'initial_date', sortable: true, type: 'string' },
    { Header: 'Initial auth end', accessor: 'final_date', sortable: true, type: 'string' },
    { Header: 'Last job register', accessor: ({ jobs }) => jobs.at(-1)?.date, sortable: true, type: 'string', id: 'lastJob' },
]

export const INSPECTOR_BASIC_LIST_COLUMS = [
    { Header: 'Inspector', accessor: (({ inspector_info }) => `${inspector_info.IDRes} - ${inspector_info.last_name}, ${inspector_info.first_name}`), sortable: true, type: 'string', id: 'inspector' },
    { Header: 'Program', accessor: (({ program_info }) => `${program_info.name}`), sortable: true, type: 'string', width: 30, id: 'program' },
    {
        Header: 'Scopes',
        accessor: 'scopes',
        filter: multiFilter,
        sortable: true,
        type: 'list',
        chip: Object.keys(SCOPES_BASIC_NAMES).map(scope => ({ label: SCOPES_BASIC_NAMES[scope], color: 'success' })),
    }]

export const JOBS_TABLE_COLUMNS = [
    // {Header: 'Profile', accessor:'profile',sortable: true, type: 'string'},
    // {Header: 'Order', accessor:'order',sortable: true, type: 'string'},
    // {Header: 'Operation', accessor:'operation',sortable: true, type: 'string'},
    // {Header: 'Evaluated activities', accessor:'evaluated_activities',sortable: true, type: 'string'},
    // {Header: 'Deviations', accessor:'deviations',sortable: true, type: 'string'},
    // {Header: 'Comments', accessor:'comments',sortable: true, type: 'string'},
    { Header: 'Tutor', accessor: (({ tutor_info }) => `${tutor_info.IDRes} - ${tutor_info.last_name}, ${tutor_info.first_name}`), sortable: true, type: 'string', id: 'tutor' },
    { Header: 'Ok', accessor: ({ peration_performed_correctly }) => peration_performed_correctly ? 'Yes' : 'No', sortable: true, type: 'string', id: 'operationAchieved', width: 50 },
    { Header: 'Date', accessor: 'date', sortable: true, type: 'string', width: 50 },
    {
        Header: 'Scopes',
        accessor: 'scopesTable',
        sortable: true,
        type: 'list',
        filter: multiFilter,
        chip: Object.values(SCOPE_NAMES_JOB_MODAL).map(scope => ({ label: scope, color: 'success' })),
        width: 300,
    },
]



export const SCOPES_WITH_RELATED_OBJ = {
    ['QIN.002']: ['QIN.002'],
    ['QIN.003']: ['QIN.002', 'QIN.003'],
    ['QIN.004']: ['QIN.002', 'QIN.003', 'QIN.004'],
    ['QIN.005']: ['QIN.002', 'QIN.003', 'QIN.005'],
    ['QIN.006']: ['QIN.002', 'QIN.003', 'QIN.004', 'QIN.005', 'QIN.006'],
    ['QIN.012']: ['QIN.002', 'QIN.012'],
}

export const JOB_MODAL_STATES_KEYS = {
    tutorSelected: 'tutorSelected',
    profile: 'profile',
    order: 'order',
    operation: 'operation',
    evaluatedActivities: 'evaluatedActivities',
    deviations: 'deviations',
    comments: 'comments',
    operationPerformedCorrecly: 'operationPerformedCorrecly',
    operationPerformedInCorrecly: 'operationPerformedInCorrecly',
    jobDateSelected: 'jobDateSelected'
}



export const JOB_MODAL_STATIC_INITIAL_STATES = {
    // tutorSelected: null,
    profile: '',
    order: '',
    operation: '',
    evaluatedActivities: '',
    operationPerformedCorrecly: false,
    operationPerformedInCorrecly: false,
    jobDateSelected: null,
    deviations: '',
    comments: '',

}

export const CONTINUOUS_EV_MODAL_STATIC_INITIAL_STATE = {
    ...JOB_MODAL_STATIC_INITIAL_STATES,

    scopesSelected: {
        ['QIN.002']: false,
        ['QIN.003']: false,
        ['QIN.004']: false,
        ['QIN.005']: false,
        ['QIN.006']: false,
        ['QIN.012']: false,
    },

}

export const PREVIOUS_SCOPES_CONDITIONS = {
    ['QIN.003']: ['QIN.002'],
    ['QIN.004']: ['QIN.002', 'QIN.003'],
    ['QIN.005']: ['QIN.002', 'QIN.003'],
    ['QIN.006']: ['QIN.002', 'QIN.003', 'QIN.004', 'QIN.005'],
    ['QIN.012']: ['QIN.002'],

}


export const INPECTOR_AUTH_CLOSED_CORRETLY = { message: `Inspector's authorization successfully closed`, variant: 'success' }

export const INSPECTOR_JOB_POSTED_CORRECTLY = { message: `Inspector's job successfully posted`, variant: 'success' }