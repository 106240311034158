import { useDispatch } from "react-redux"
import { COMPLETE_ALL_FIELDS_MESSAGE } from "../globalConsts"
import { showMessage } from "app/store/fuse/messageSlice"
import { makeStyles } from "@material-ui/core/styles";




export const useCompleteAllFieldsMessage =()=>{
    const dispatch = useDispatch()
    const completeAllFieldsMessage =()=>  dispatch(showMessage(COMPLETE_ALL_FIELDS_MESSAGE))
    return completeAllFieldsMessage
}

export const useDispatchError = (dispatch, error, sectionError) => {
    let codeAlredyExistError
    const errorResponseData = error.response?.data
    if(errorResponseData){
        const codeKeyError = Object.keys(errorResponseData)?.[0]
        codeAlredyExistError = errorResponseData?.[codeKeyError]?.[0]?.includes('already exists')
    }

    codeAlredyExistError ?
        dispatch(showMessage({ variant: 'error', message: 'Section code alredy exists' }))
        :
        dispatch(showMessage(sectionError))
}


export const useStyles = makeStyles({

    customDialogTitle: {
            backgroundColor: 'rgb(37, 47, 62)',
            color: 'rgb(255, 255, 255)',
            marginBottom: '0.5em'
    },

    alertDialogTitle: {
            backgroundColor: '#f44336;',
            color: 'rgb(255, 255, 255)',
            marginBottom: '0.5em'
    }

});
