export const DEPARTMENT_TABS_VALUES = {
    directorate: "directorate",
    departments: "departments",
    area: "area",
    team: "team",
    technician: "technician"
}
export const DEPARTMENT_TAB_LABEL = {
    directorate: "Departmental Directorate",
    departments: "Departments",
    area: "Area",
    team: "Team",
    technician: "Technician"
}

export const DEFAULT_TAB_VALUE_PROPS = {
    textColor: "secondary",
    indicatorColor: "secondary",
    ["aria-label"]: "secondary tabs example"
}
