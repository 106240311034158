import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import { styled, alpha } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import FuseNavBadge from '../../FuseNavBadge';
import FuseNavItem from '../../FuseNavItem';

/**
 * Styled root element for the vertical collapse nav list.
 */
const Root = styled('ul')(({ theme, ...props }) => ({
  padding: 0,
  '&.open': {},
  '& > .fuse-list-item': {
    height: 40,
    width: '100%',
    borderRadius: '6px',
    margin: '0 0 4px 0',
    paddingRight: 12,
    paddingLeft: props.itempadding > 80 ? 80 : props.itempadding,
    color: alpha(theme.palette.text.primary, 0.7),
    '&:hover': {
      color: theme.palette.text.primary,
    },
    '& > .fuse-list-item-icon': {
      marginRight: 12,
      color: 'inherit',
    },
  },
}));

/**
 * Checks whether the nav item should be opened by checking if the current location URL
 * is present in the item’s children.
 */
function needsToBeOpened(location, item) {
  return location && isUrlInChildren(item, location.pathname);
}

/**
 * Recursively checks whether a URL is found among the children of a nav item.
 */
function isUrlInChildren(parent, url) {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i += 1) {
    const child = parent.children[i];

    // If the child has children, check them recursively
    if (child.children && isUrlInChildren(child, url)) {
      return true;
    }

    // Check if the child's URL matches or is contained within the given URL
    if (child.url === url || url.includes(child.url)) {
      return true;
    }
  }
  return false;
}

/**
 * Finds a permission object in the permissions list that matches the given name.
 */
function findPermission(permissionName, permissionsList) {
  return permissionsList.find((perm) => perm.name === permissionName);
}

/**
 * Applies permission flags on children items recursively. If the user does not have
 * permission to view a given child (or all of its sub-children), a `noPermission` flag
 * is set.
 *
 * @param {Object} params
 * @param {Object} params.item - The nav item whose children are to be checked.
 * @param {Array} params.permissionList - List of permissions for the first-level children.
 * @param {Array} [params.childrenPermissionsList] - Optional list of permissions for sub-children.
 * @param {Array} params.permissions - The user's permissions.
 * @param {boolean} params.isSuperuser - Flag indicating if the user is a superuser.
 */
function applyChildPermissions({
  item,
  permissionList,
  childrenPermissionsList,
  permissions,
  isSuperuser,
}) {
  // Superusers bypass all permission checks
  if (isSuperuser) {
    return;
  }

  // Iterate through first-level children
  for (let i = 0; i < item.children.length; i++) {
    const child = item.children[i];
    const havePermission = findPermission(permissionList[i], permissions);

    // If no permission and no nested children, flag the child
    if (!havePermission && !child.children) {
      child.noPermission = true;
    }

    // If the child has its own children and a childrenPermissionsList was provided,
    // check sub-children permissions.
    if (child.children && childrenPermissionsList) {
      for (let j = 0; j < child.children.length; j++) {
        const subChild = child.children[j];
        const childrenHavePermission = findPermission(childrenPermissionsList[j], permissions);
        if (!childrenHavePermission) {
          subChild.noPermission = true;
        }
      }
      if (child.children.every((subChild) => subChild.noPermission)) {
        child.noPermission = true;
      }
    }
  }
  if (item.children.every((child) => child.noPermission)) {
    item.noPermission = true;
  }
}

/**
 * Dispatches permission rules based on the nav item title. Clones the item and applies
 * permission flags to its children based on predefined permission lists.
 *
 * @param {Object} newItem - Deep clone of the original nav item.
 * @param {Array} permissions - The user's permissions.
 * @param {boolean} isSuperuser - Indicates if the user is a superuser.
 * @returns {Object} The updated nav item.
 */
function applyPermissionsToItem(newItem, permissions, isSuperuser) {
  // Helper: prefix permission names with "Can view"
  const addCanViewToName = (permissionsName) =>
    permissionsName.map((name) => `Can view ${name}`);

  if (permissions.length > 0) {
    switch (newItem.title) {
      case 'Modules':
        applyChildPermissions({
          item: newItem,
          permissionList: addCanViewToName([
            'risk management',
            '',
            'improvement',
            'deliverables_data',
            'data kpi',
            'skill matrix',
            'training',
            'inspector',
          ]),
          childrenPermissionsList: Array(2).fill(addCanViewToName(['sub mision'])[0]),
          permissions,
          isSuperuser,
        });
        break;

      case 'Management':
        applyChildPermissions({
          item: newItem,
          permissionList: [
            ...addCanViewToName([
              'division',
              'service',
              'direccion departamental',
              'deliverables',
              'process',
              'categorizacion',
              'kpi',
              'aplication',
              'log accion',
              'rules',
            ]),
            'Can Management Power BI',
          ],
          permissions,
          isSuperuser,
        });
        break;

      case 'User management':
        applyChildPermissions({
          item: newItem,
          permissionList: addCanViewToName([
            'user',
            'customer',
            'log cambios personas',
            'group',
          ]),
          permissions,
          isSuperuser,
        });
        break;

      case 'Business analytics':
        applyChildPermissions({
          item: newItem,
          permissionList: addCanViewToName([
            'BA Performance',
            'BA Time Management',
            'BA Audits',
            'BA Sales Volume',
            'BA Summary',
          ]),
          permissions,
          isSuperuser,
        });
        break;

      default:
      // No specific permission rules applied
    }
  }
  return newItem;
}

/**
 * Vertical collapse navigation item component.
 */
function FuseNavVerticalCollapse(props) {
  const { item, nestedLevel, onItemClick } = props;
  const location = useLocation();
  const [open, setOpen] = useState(() => needsToBeOpened(location, item));
  const itempadding = nestedLevel > 0 ? 28 + nestedLevel * 16 : 12;

  // Retrieve user permissions and superuser flag from Redux
  const personLoginPermisos = useSelector(
    (state) => state.fuse.userComponente.personPermisos,
    shallowEqual
  );
  const personIsSuperuser = useSelector(
    (state) => state.fuse.userComponente.person.is_superuser
  );

  // Open the collapse if the URL matches one of its children.
  useEffect(() => {
    if (needsToBeOpened(location, item) && !open) {
      setOpen(true);
    }
  }, [location.pathname]);

  // Memoize the permission-applied item so that permissions are recalculated
  // only when the item or the permissions change.
  const itemsWithPermissions = useMemo(() => {
    const newItem = _.cloneDeep(item);
    return applyPermissionsToItem(newItem, personLoginPermisos, personIsSuperuser);
  }, [item, personLoginPermisos, personIsSuperuser]);

  // If the item (or all of its children) is flagged as "noPermission", render nothing.
  if (itemsWithPermissions?.noPermission) return null;

  return (
    <Root className={clsx(open && 'open')} itempadding={itempadding}>
      <ListItem
        component={item.url ? NavLinkAdapter : 'li'}
        button
        className="fuse-list-item"
        onClick={() => setOpen(!open)}
        to={item.url}
        end={item.end}
        role="button"
      >
        {item.icon && (
          <Icon
            className={clsx('fuse-list-item-icon text-20 shrink-0', item.iconClass)}
            color="action"
          >
            {item.icon}
          </Icon>
        )}

        <ListItemText
          className="fuse-list-item-text"
          primary={item.title}
          classes={{ primary: 'text-13 font-medium' }}
        />

        {item.badge && <FuseNavBadge className="mx-4" badge={item.badge} />}

        <IconButton
          disableRipple
          className="w-40 h-40 -mx-12 p-0 focus:bg-transparent hover:bg-transparent"
          onClick={(ev) => ev.preventDefault()}
          size="large"
        >
          <Icon className="text-16 arrow-icon" color="inherit">
            {open ? 'expand_less' : 'expand_more'}
          </Icon>
        </IconButton>
      </ListItem>

      {itemsWithPermissions.children && (
        <Collapse in={open} className="collapse-children">
          {itemsWithPermissions.children.map((_item) => (
            <FuseNavItem
              key={_item.id}
              type={`vertical-${_item.type}`}
              item={_item}
              nestedLevel={nestedLevel + 1}
              onItemClick={onItemClick}
              noPermission={_item.noPermission}
            />
          ))}
        </Collapse>
      )}
    </Root>
  );
}

FuseNavVerticalCollapse.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.array,
    url: PropTypes.string,
    badge: PropTypes.any,
    iconClass: PropTypes.string,
    end: PropTypes.bool,
  }),
  nestedLevel: PropTypes.number,
  onItemClick: PropTypes.func,
};

const NavVerticalCollapse = FuseNavVerticalCollapse;
export default NavVerticalCollapse;
