import { findIndexSectionById } from "app/js/generalFunctions"
import { MISSIONS_TYPES } from "./types"

const INITIAL_STATE = {
    createModal: false,
    editModal: false,
    missionSelected: null,
    visualTimeGroupsList: [],
    agressoCodesList:[],
    allMissionsList: [],
    departamentalMissionsList: [],
    noDepartamentalMissionsList: [],
    loadingMissions: false,
}

export default function (state = INITIAL_STATE, action) {
    const { SET_CREATE_MODAL, SET_EDIT_MODAL, SET_MISSION_SELECTED, GET_VISUAL_TIME_GROUPS, GET_AGRESSO_CODES, GET_ALL_MISSIONS, POST_MISSION, PUT_MISSION, LOADING_MISSIONS } = MISSIONS_TYPES


    const { type, payload } = action

    const MISSIONS_ACTIONS = {

        [SET_CREATE_MODAL]:(state, action)=> ({
            ...state,
            createModal: payload
        }),

        [SET_EDIT_MODAL]:(state, action)=> ({
            ...state,
            editModal: payload
        }),

        [SET_MISSION_SELECTED]:(state, action)=> ({
            ...state,
            missionSelected: payload
        }),

        [GET_VISUAL_TIME_GROUPS]:(state, action)=>({
            ...state,
            visualTimeGroupsList: payload
        }),

        [GET_AGRESSO_CODES]:(state, action)=>({
            ...state,
            agressoCodesList: payload
        }),

        [LOADING_MISSIONS]:(state)=>{
            return {...state, loadingMissions: true}
        },

        [MISSIONS_TYPES.ERROR_MISSIONS]:(state)=>{
            return {...state, loadingMissions: false}
        },

        [GET_ALL_MISSIONS]:(state, action)=>{
            const dptoAndNodpto = payload.reduce((acc, mission) =>{
                if(mission.esDepartamento) acc.dpto = [...acc.dpto, mission]
                else acc.noDpto = [...acc.noDpto, mission]
                return acc;
            }, {dpto: [], noDpto: []})
            return{
                ...state,
                allMissionsList: payload,
                noDepartamentalMissionsList: dptoAndNodpto.noDpto,
                departamentalMissionsList: dptoAndNodpto.dpto,
                loadingMissions: false
            }
        },

        [POST_MISSION]: (state, action) => {

            const formattedData = {
                ...action.payload,
                manager_name: action.payload.staff.managers.map(manager => manager.first_name + ' ' + manager.last_name).join(', ')
            }

            return{
                ...state,
                noDepartamentalMissionsList: [formattedData, ...state.noDepartamentalMissionsList],
                allMissionsList: [formattedData, ...state.allMissionsList],
            }
        },

        [PUT_MISSION]:(state, action)=>{
            const newNoDptoList = state.noDepartamentalMissionsList.with(action.payload.indexValue, action.payload);
            const indexInMissionList = findIndexSectionById({sectionList: state.allMissionsList, sectionToFindInList: action.payload})
            const newAllList = state.allMissionsList.with(indexInMissionList, action.payload)
            return{
                ...state,
                noDepartamentalMissionsList: newNoDptoList,
                allMissionsList: newAllList
            }
        },

    }
    return MISSIONS_ACTIONS[type] ? MISSIONS_ACTIONS[type](state, action) : state
}
