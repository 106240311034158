import { useDispatch } from "react-redux"
import { FILTER_ACTION_TYPE } from "./types"
import axios from 'axios'
import {getCookie} from 'app/js/generalFunctions'
import { STATES_OBJ_KEY_NAMES } from "components/Managment/PowerBi_management/utils/consts"
import { showMessage } from "app/store/fuse/messageSlice"
import { KEYS_FILTER_STRUCTURE, NO_SUBMISSIONS_MESSAGE, SECTIONS_SELECTED_KEYS_EQUIVALENT_FILTER_KEYS, SECTIONS_SELECTED_OBJ_KEYS } from "../utils/filterConsts"
import store from "app/store"

const URL_FILTER_BA = `${process.env.REACT_APP_URL_DJANGO}/api/filter_info`



export function setFirstRenderAction(bool) {
    return async (dispatch) => {
        dispatch(setFirstRender(bool))
    }
}

const setFirstRender = (bool) => ({
    type: FILTER_ACTION_TYPE.SET_FIRST_RENDER,
    payload: bool
})

export function setvisibilityAction(bool) {
    return async (dispatch) => {
        dispatch(setvisibility(bool))
    }
}

const setvisibility = (bool) => ({
    type: FILTER_ACTION_TYPE.SHOW_FILTER,
    payload: bool
})

export const setInitialDateAction =(date)=>{
    return async (dispatch)=>{
        dispatch(setInitialDate(date))
    }
}

const setInitialDate = (date) =>({
    type: FILTER_ACTION_TYPE.SET_INITIAL_DATE,
    payload: date
})

export const setFinalDateAction =(date)=>{
    return async dispatch => dispatch(setFinalDate(date))
}

const setFinalDate = (date) =>({
    type: FILTER_ACTION_TYPE.SET_FINAL_DATE,
    payload: date
})

export const setYearsIntervalSelectedAction =(yearsArray)=>{
    return async dispatch => dispatch(setYearsIntervalSelected(yearsArray))
}

const setYearsIntervalSelected = (yearsArray) =>({
    type: FILTER_ACTION_TYPE.SET_YEARS_INTERVAL_SELECTED,
    payload: yearsArray
})

export const setChartOrTableViewStateAction =(state)=>{
    return async dispatch => dispatch(setChartOrTableViewState(state))
}

const setChartOrTableViewState = (state) =>({
    type: FILTER_ACTION_TYPE.SET_CHART_OR_TABLE_VIEW_STATE,
    payload: state
})

export const setXAxisValuesAction =(bool)=>{
    return async dispatch => dispatch(setXAxisValues(bool))
}

const setXAxisValues = (bool) =>({
    type: FILTER_ACTION_TYPE.SET_X_AXIS_VALUES,
    payload: bool
})


export const setMissionSelectedContractsAction =(contracts)=>{
    return async dispatch => dispatch(setMissionSelectedContracts(contracts))
}

const setMissionSelectedContracts = (contracts) =>({
    type: FILTER_ACTION_TYPE.SET_MISSION_SELECTED_CONTRACTS,
    payload: contracts
})

export const setResetValuesAction =()=>{
    return async dispatch => dispatch(setResetValues())
}

const setResetValues = () =>({
    type: FILTER_ACTION_TYPE.RESET_VALUES,
})

export const setSaveMissionsIMustSeeAction =(missions)=>{
    return async dispatch => dispatch(setSaveMissionsIMustSee(missions))
}

const setSaveMissionsIMustSee = (missions) =>({
    type: FILTER_ACTION_TYPE.SAVE_MISSIONS_I_MUST_SEE,
    payload: missions
})

export const setSaveSubMissionsIMustSeeAction = (Submissions) => {
    return async dispatch => {
        dispatch(setSaveSubMissionsIMustSee(Submissions))
        !Submissions.length && dispatch(showMessage({
            message: NO_SUBMISSIONS_MESSAGE,
            variant: "error"
        }))
    }
}

const setSaveSubMissionsIMustSee = (Submissions) =>({
    type: FILTER_ACTION_TYPE.SAVE_SUB_MISSIONS_I_MUST_SEE,
    payload: Submissions
})

export const setMissionSelectedAction =(id)=>{
    return async dispatch => {
        dispatch(setMissionSelected(id));
    }
}

const setMissionSelected = (id) =>({
    type: FILTER_ACTION_TYPE.SET_MISSION_SELECTED,
    payload: id
})

export const setSubMissionSelectedAction =(id)=>{
    return async dispatch => dispatch(setSubMissionSelected(id))
}

const setSubMissionSelected = (id) =>({
    type: FILTER_ACTION_TYPE.SET_SUB_MISSION_SELECTED,
    payload: id
})

export const setAgressoCodesAndVTGroups=({agresso, vtg})=>{
    return dispatch=> dispatch({type:FILTER_ACTION_TYPE.SET_AGRESSO_AND_VTG, payload: {agresso: agresso, vtg: vtg}})
}



export const setMissionManagerSelectedAction =(id)=>{
    return async dispatch => dispatch(setMissionManagerSelected(id))
}

const setMissionManagerSelected = (id) =>({
    type: FILTER_ACTION_TYPE.SET_MISSION_MANAGER_SELECTED,
    payload: id
})

export const setMissionManagerSelectedIdResAction=(IDRes)=>{
return dispatch=> dispatch({type:FILTER_ACTION_TYPE.SET_MANAGER_SELECTED_IDRES, payload: IDRes})
}

export const knowIfPersonLoginIsSiteManagerOrCanSeeAllBAAction=({isN4, canSeeAllBA, personLoginId, personLoginIDRes})=>{
    return dispatch => dispatch(knowIfPersonLoginIsSiteManagerOrCanSeeAllBA({isN4, canSeeAllBA, personLoginId, personLoginIDRes}))
}

const knowIfPersonLoginIsSiteManagerOrCanSeeAllBA=({isN4, canSeeAllBA, personLoginId, personLoginIDRes})=>({
    type: FILTER_ACTION_TYPE.SET_PERSON_LOGIN_IS_N4_AND_CAN_SEE_ALL_BA,
    payload: {isN4, canSeeAllBA, personLoginId, personLoginIDRes}
})

// !!!!!!!
export const setPersonLoginCanSeeAllBAAction =()=>{
    return dispatch => dispatch(setPersonLoginCanSeeAllBA())
}

const setPersonLoginCanSeeAllBA = () =>({
    type: FILTER_ACTION_TYPE.PERSON_LOGIN_CAN_SEE_ALL_BA,
})

export const setPersonLoginIsSiteManagerAction =()=>{
    return dispatch => dispatch(setPersonLoginIsSiteManager())
}

const setPersonLoginIsSiteManager = () =>({
    type: FILTER_ACTION_TYPE.SET_PERSON_LOGIN_IS_SITE_MANAGER,
})
// !!!!!!!

export const setMissionsManagersFromSiteManagerWPAction =(managers)=>{
    return async dispatch => dispatch(setMissionsManagersFromSiteManagerWP(managers))
}

const setMissionsManagersFromSiteManagerWP = (managers) =>({
    type: FILTER_ACTION_TYPE.SET_MISSIONS_MANAGERS_FROM_SITE_MANAGER_WP,
    payload: managers
})

export const setMissionsAndSubmissionsPeopleAction = (peopleInfo) => {

    return async (dispatch) => {
        dispatch(setPeopleInMissionAndSubmission(peopleInfo))
    }

}

const setPeopleInMissionAndSubmission=(peopleObj)=>({
    type: FILTER_ACTION_TYPE.SET_PEOPLE_IN_MISSION_AND_SUBMISSION,
    payload: peopleObj
})


export const getFilterStructureBA =()=>{
    return async (dispatch) =>{

        try{
            const request = await axios({
                method: "GET",
                url: `${URL_FILTER_BA}/true`,
                headers: { 'Authorization': `Token ${getCookie('token')}`},
            })

            if(request.status !== 200) throw new Error('Bad request')
            const filterStructure = request.data.BA_filter_structure

            // console.log("🚀 filter structure:", filterStructure)
            dispatch({
                type: FILTER_ACTION_TYPE.GET_FILTER_STRUCTURE,
                payload: filterStructure
            })
        }
        catch(e){
            console.log(e)
        }

    }
}



export const setFilterPeopleSearch = ({firstSectionWithRecords, filterSectionsSelected, staffForSearch }) => {
    return (dispatch) => {

        const firstSectionWithRecordsKeyTransform = SECTIONS_SELECTED_KEYS_EQUIVALENT_FILTER_KEYS[firstSectionWithRecords]

        const indexOfFirstSectionWithRecord = SECTIONS_SELECTED_OBJ_KEYS.indexOf(firstSectionWithRecordsKeyTransform)
        const { length: filterKeysLength } = SECTIONS_SELECTED_OBJ_KEYS.length
        const sectionsMustBeCompleted = SECTIONS_SELECTED_OBJ_KEYS.slice(indexOfFirstSectionWithRecord, filterKeysLength)


        const sectionsAreCompleted = sectionsMustBeCompleted.every(section => !!filterSectionsSelected[section])




        const staffObjKeys = Object.keys(staffForSearch)

        const sttaffToSearchIDRes = new Set()
        for (let key of staffObjKeys) {
            // console.log("🚀 ~ return ~ staffForSearch:", staffForSearch)
            // console.log("🚀 ~ return ~ key:", key)
            // console.log("🚀 ~ return ~ staffForSearch[key]:", staffForSearch[key])

            staffForSearch[key].forEach(person => {
                if (person.IDRes && !sttaffToSearchIDRes.has(person.IDRes)) {
                    sttaffToSearchIDRes.add(person.IDRes)
                }
            })

        }
        dispatch({
            payload: { filterSectionsSelected, staffForSearch: [...sttaffToSearchIDRes],sectionsAreCompleted },
            type: FILTER_ACTION_TYPE.SET_PEOPLE_SEARCH
        })
    }
}

export const setFilterSearchButtom =(buttomState)=>{
    return dispatch => dispatch({type: FILTER_ACTION_TYPE.SET_SERACH_BUTTOM, payload: buttomState})
}

export const setFilterAgressoAndVITG=(agreesoAndVTG)=>{
    return dispatch=>{
        const {agresso, vtg} = agreesoAndVTG
        dispatch({
            type: FILTER_ACTION_TYPE.SET_MISSION_AGREESO_AND_VTG,
            payload: {agresso, vtg}
        })
    }
}

export const setAuditPersonIDRes =(personIDRes)=>{
    return dispatch => {
        dispatch({
            type: FILTER_ACTION_TYPE.SET_AUDIT_PERSON_IDRES,
            payload: personIDRes
        })
    }
}

export const setSummaryRequestDone =(bool)=>{
    return dispatch=>{
        dispatch({
            type: FILTER_ACTION_TYPE.SET_SUMMARY_REQUES_DONE,
            payload: bool
        })
    }
}
