//******************** COMPONENTE PRINCIPAL *****************************************************
//OBTENER ULTIMO ID (INACTIVO O NO) DE RM_RISK_OPPORTUNITY
export const OBTENER_ULTIMO_ID_RO = 'OBTENER_ULTIMO_ID_RO'
export const OBTENER_ULTIMO_ID_RO_EXITO = 'OBTENER_ULTIMO_ID_RO_EXITO'
export const OBTENER_ULTIMO_ID_RO_ERROR = 'OBTENER_ULTIMO_ID_RO_ERROR'

//CAMBIAR VISIBILIDAD MODAL EDITAR ACCION
export const CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION = 'CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION'
export const CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION_EXITO = 'CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION_EXITO'
export const CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION_ERROR = 'CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION_ERROR'

export const CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR = 'CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR'
export const CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR_ERROR'

//EDITAR ACCION
export const UPDATE_RM_ACCION = 'UPDATE_RM_ACCION'
export const UPDATE_RM_ACCION_EXITO = 'UPDATE_RM_ACCION_EXITO'
export const UPDATE_RM_ACCION_ERROR = 'UPDATE_RM_ACCION_ERROR'

// CAMBIAR FILA SELECCIONADA GRID
export const CAMBIAR_VALOR_SELECCION_ANFE_GRID = 'CAMBIAR_VALOR_SELECCION_ANFE_GRID'
export const CAMBIAR_VALOR_SELECCION_ANFE_GRID_EXITO = 'CAMBIAR_VALOR_SELECCION_ANFE_GRID_EXITO'
export const CAMBIAR_VALOR_SELECCION_ANFE_GRID_ERROR = 'CAMBIAR_VALOR_SELECCION_ANFE_GRID_ERROR'

//ELIMINAR PROGRAMA
export const ELIMINAR_ANFE = 'ELIMINAR_ANFE'
export const ELIMINAR_ANFE_EXITO = 'ELIMINAR_ANFE_EXITO'
export const ELIMINAR_ANFE_ERROR = 'ELIMINAR_ANFE_ERROR'


//CONSULTA A API RISK_MANAGEMENT PARA GRID
export const MOSTRAR_ANFE = 'MOSTRAR_ANFE'
export const MOSTRAR_ANFE_EXITO = 'MOSTRAR_ANFE_EXITO'
export const MOSTRAR_ANFE_ERROR = 'MOSTRAR_ANFE_ERROR'


//RESET STATE insertadoRiskManagement
export const CAMBIAR_STATE_INSERTADO_ANFE = 'CAMBIAR_STATE_INSERTADO_ANFE'
export const CAMBIAR_STATE_INSERTADO_ANFE_EXITO = 'CAMBIAR_STATE_INSERTADO_ANFE_EXITO'
export const CAMBIAR_STATE_INSERTADO_ANFE_ERROR = 'CAMBIAR_STATE_INSERTADO_ANFE_ERROR'

//CAMBIAR ESTADOS DINÁMICOS
export const CAMBIAR_ESTADO_ANFE = 'CAMBIAR_ESTADO_ANFE'
export const CAMBIAR_ESTADO_ANFE_EXITO = 'CAMBIAR_ESTADO_ANFE_EXITO'
export const CAMBIAR_ESTADO_ANFE_ERROR = 'CAMBIAR_ESTADO_ANFE_ERROR'

//UPDATE RISK_MANAGEMENT
export const UPDATE_ANFE = 'UPDATE_ANFE'
export const UPDATE_ANFE_EXITO = 'UPDATE_ANFE_EXITO'
export const UPDATE_ANFE_ERROR = 'UPDATE_ANFE_ERROR'


//******************** MODAL INSERTAR  *****************************************************

// CAMBIAR VISIBILIDAD
export const CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR = 'CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR'
export const CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR_ERROR'

//INSERTAR NUEVO RISK_MANAGER
export const INSERTAR_ANFE_MODAL_INSERTAR = 'INSERTAR_ANFE_MODAL_INSERTAR'
export const INSERTAR_ANFE_MODAL_INSERTAR_EXITO = 'INSERTAR_ANFE_MODAL_INSERTAR_EXITO'
export const INSERTAR_ANFE_MODAL_INSERTAR_ERROR = 'INSERTAR_ANFE_MODAL_INSERTAR_ERROR'

//CONSULTA LAS SUBDIVISIONES
export const CONSULTA_SUBDIVISIONES_MODAL_INSERTAR = 'CONSULTA_SUBDIVISIONES_MODAL'
export const CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_EXITO = 'CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_EXITO'
export const CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_ERROR = 'CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_ERROR'

// GUARDAR MIS ANFES (YO COMO MANAGER O EMPLEADO)
export const SET_MY_ANFES = 'SET_MY_ANFES'
export const SET_NOT_MY_ANFES = 'SET_NOT_MY_ANFES'
export const SET_ANFES_STRUCTURE = 'SET_ANFES_STRUCTURE'
export const UPDATE_ANFE_FROM_STRUCTURE = 'UPDATE_ANFE_FROM_STRUCTURE'
export const UPDATE_ANFE_FROM_STRUCTURE_EXITO = 'UPDATE_ANFE_FROM_STRUCTURE_EXITO'
export const UPDATE_ANFE_FROM_STRUCTURE_ERROR = 'UPDATE_ANFE_FROM_STRUCTURE_ERROR'
export const ADD_ANFE_TO_STRUCTURE = 'ADD_ANFE_TO_STRUCTURE'
export const DELETE_ANFE_FROM_STRUCTURE = 'DELETE_ANFE_FROM_STRUCTURE'
