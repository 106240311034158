import { getCookie } from 'app/js/generalFunctions'
import { DEPARTMENTS_URL } from './const'
const getAuth = () => ({ 'Authorization': `Token ${getCookie('token')}` })

export const GET_DEPARTMENTS_LIST_REQUEST_OBJ = () => ({
    method: "GET",
    url: DEPARTMENTS_URL,
    headers: getAuth()
})

export const POST_DEPARTMENT_REQUEST_OBJ = (departmentData) => ({
    method: "POST",
    url: DEPARTMENTS_URL,
    headers: getAuth(),
    data: departmentData
})

export const PUT_DEPARTMENT_REQUEST_OBJ = (departmentData) => ({
    method: "PUT",
    url: `${DEPARTMENTS_URL}${departmentData.id}`,
    headers: getAuth(),
    data: departmentData
})
