import { getCookie } from 'app/js/generalFunctions'
import { AREA_URL, DEPARTAMENTAL_AREA_URL } from './const'
const getAuth = () => ({ 'Authorization': `Token ${getCookie('token')}` })

export const GET_AREAS_REQUEST_OBJ = () => ({
    method: "GET",
    url: DEPARTAMENTAL_AREA_URL,
    headers: getAuth()
})

export const POST_AREA_REQUEST_OBJ=(data) =>({
    method: "POST",
    url: AREA_URL,
    data: data,
    headers: getAuth()
})

export const PUT_AREA_REQUEST_OBJ = (id, data) => ({
    method: "PUT",
    url: `${AREA_URL}${id}`,
    data: data,
    headers: getAuth()
})
