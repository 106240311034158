export const IMPROVEMENT_ACTION_PLAN_URL = process.env.REACT_APP_URL_DJANGO + "/api/improvement_actions/"

export const SUCESS_MESSAGE_CREATED_ACTION_PLAN = {
    message: "Action plan created successfully",
    variant: "success"
}

export const SUCESS_MESSAGE_EDITED_ACTION_PLAN = {
    message: "Action plan updated successfully",
    variant: "success"
}

export const SUCESS_MESSAGE_TOGGLE_STATUS_ACTION_PLAN = {
    message: "Action plan completed successfully",
    variant: "success"
}

export const SUCESS_MESSAGE_DELETED_ACTION_PLAN = {
    message: "Action plan deleted successfully",
    variant: "success"
}

export const ERROR_TRY_AGAIN = {
    message: "Error, try again",
    variant: "error"
}
