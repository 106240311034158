import { createSlice } from '@reduxjs/toolkit';
import { getProfilesDataThunk } from './requirementsThunks';

const INITIAL_STATE = {
    profilesSection: {
        profilesList: [],
        loading: true,
        error: false,
    }
}


const {getProfiles} = {

    getProfiles: ()=>({
        [getProfilesDataThunk.pending]: (state, action) => {
            state.profilesSection = {
                profilesList: [],
                loading: true,
                error: false,
            }
        },
        [getProfilesDataThunk.fulfilled]: (state, action) => {
            state.profilesSection = {
                profilesList: action.payload,
                loading: false,
                error: false,
            }
        },
        [getProfilesDataThunk.rejected]: (state, action) => {
            state.profilesSection = {
                profilesList: [],
                loading: false,
                error: true,
            }
        },

    })
}



const requirementsSlice= createSlice({
    name: 'requirementsSlice',
    initialState: INITIAL_STATE,

    extraReducers: {
        ...getProfiles()
      },
})

export default requirementsSlice.reducer;
