// ! This file is for get the buttom styles. It depends of the person permissions (the display) and selected row of the section (the color)
import { useGetPermissions } from "./PermissionsHooks";

// ? Get display style states depends of permissions
export function useButtonPermissionStyle(){
    const {getAddPermission, canEditPermission,  getDeletePermission} = useGetPermissions();

    const getButtomPermissionStyle=(moduleName) => {
        const canAdd = getAddPermission(moduleName);
        const canEdit = canEditPermission(moduleName);
        // const canEdit = false
        const canDelete = getDeletePermission(moduleName);
        
        const styleFunction = (hasPermission) => !!hasPermission ? { display: "inline", cursor: "pointer" } : { display: "none" }
        const styleObj ={
            canAddPermissionStyle: styleFunction(canAdd),
            canEditPermissionStyle: styleFunction(canEdit),
            canDeletePermissionStyle: styleFunction(canDelete),
        }
        return styleObj
    }

    return {getButtomPermissionStyle}
}


// ? Get buttom styles states depends of select row
export function useButtomStyleSelectedRow(){
    const getButtomStyleSelectedRow=(row)=>{
        const buttomColor = row ? "rgb(17, 24, 39)" : "rgb(112, 112, 112)";
        const buttomColorStyle = { color: `${buttomColor}`, cursor: "pointer" };
        return {buttomColorStyle}
    }
    return {getButtomStyleSelectedRow}
}
