import React, { useEffect, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Typography from '@mui/material/Typography'
import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import reducer from './store'
import withReducer from 'app/store/withReducer'
import { toggleQuickPanel } from './store/stateSlice'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import DoneIcon from '@mui/icons-material/Done'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import IconButton from "@mui/material/IconButton"
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import RefreshIcon from '@mui/icons-material/Refresh'
import { descargarAdjuntoNotificacionAPIAction } from '../../../../components/Managment/Notifications/store/actions'
import useNotification from 'components/GlobalHooks/Notifications/reload_notifications'

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: 340,
    backgroundColor: theme.palette.text.primary,
  },
}))

function QuickPanel(props) {
  const dispatch = useDispatch()
  const state = useSelector(({ quickPanel }) => quickPanel.state)
  const listNotificactions = useSelector(state => state.fuse.peopleManagementComponente.listNotificactions)
  const archivoDescargado = useSelector(state => state.fuse.notificacionesComponente.archivoDescargado)

  const {
    getNotificationAPI,
    markNotificationAsRead,
    markAllNotificationsAsRead,
    loadingState
  } = useNotification()

  const { getNotifications: isLoadingGetNotifications, markAll: isLoadingMarkAllNotificationsAsRead } = loadingState

  const descargarAdjuntoNotificacionAPI = (idNotificacion) => dispatch(descargarAdjuntoNotificacionAPIAction(idNotificacion))

  useEffect(() => {
    if (archivoDescargado) {
      const fileName = "nuevo.xlsx"
      const byteCharacters = atob(archivoDescargado)
      const byteNumbers = new Uint8Array(byteCharacters.length)
      for (let i = 0;i < byteCharacters.length;i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const blob = new Blob([byteNumbers], { type: "application/vnd.ms-excel" })
      const url = URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }
  }, [archivoDescargado])

  const moreThanOneNotification = useMemo(() => listNotificactions.length > 1, [listNotificactions])

  return (
    <StyledSwipeableDrawer
      open={state}
      onOpen={() => { }}
      anchor="right"
      onClose={() => dispatch(toggleQuickPanel())}
      disableSwipeToOpen
    >
      <div className="text-white w-full text-center p-2">
        <div className="absolute left-5 top-3">
          {moreThanOneNotification && (
            <IconButton
              className={`w-28 h-28 text-white ${isLoadingMarkAllNotificationsAsRead ? 'bg-pink-500/40 animate-spin' : 'hover:bg-white/40'}`}
              onClick={() => markAllNotificationsAsRead(listNotificactions)}
              size="large"
              disabled={isLoadingMarkAllNotificationsAsRead}
            >
              <DoneAllIcon />
            </IconButton>
          )}
        </div>
        <h3 className="text-lg font-semibold">Notifications</h3>
        <div className="absolute right-5 top-3">
          <IconButton
            className={`w-28 h-28 text-white ${isLoadingGetNotifications ? 'animate-spin' : 'hover:bg-white/40'}`}
            onClick={getNotificationAPI}
            size="large"
            disabled={isLoadingGetNotifications}
          >
            <RefreshIcon />
          </IconButton>
        </div>
        <Divider className="w-full bg-white" />
      </div>

      {listNotificactions.length === 0 ? (
        <Typography variant="body1" className="text-center text-white">
          No notifications available. Press the refresh button to check for new notifications.
        </Typography>
      ) : (
          <div className="flex-1 overflow-y-auto p-2 pb-4">
            {listNotificactions.map((notification, index) => (
              <Card key={index} className="flex bg-white m-2">
                <Box className="flex flex-col">
                  <CardHeader
                    avatar={
                      <Avatar className="bg-black" aria-label="recipe">
                        {notification.origen_notification_first_name[0]}{notification.origen_notification_last_name[0]}
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings" onClick={() => markNotificationAsRead(notification)}>
                        <DoneIcon />
                      </IconButton>
                    }
                    title={`${notification.origen_notification_first_name} ${notification.origen_notification_last_name}`}
                    subheader={notification.fecha}
                  />
                  <CardContent className="mt-[-20px]">
                    <div dangerouslySetInnerHTML={{ __html: notification.observations }} />
                    {notification.archivo && (
                      <Button variant="outlined" onClick={() => descargarAdjuntoNotificacionAPI(notification.id)}>
                        Download
                      </Button>
                    )}
                  </CardContent>
                </Box>
              </Card>
            ))}
          </div>
      )}
    </StyledSwipeableDrawer>
  )
}

export default withReducer('quickPanel', reducer)(memo(QuickPanel))
