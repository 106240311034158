import { React, lazy, Suspense, useEffect, useMemo, useState } from 'react'

import FusePageCarded from '@fuse/core/FusePageCarded';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {CONTRACT_TABS_VALUES, CONTRACT_TAB_LABEL, DEFAULT_TAB_VALUE_PROPS} from './utils/consts.js'
import { LinearLoader } from 'components/GlobalComponents/loaders/loader.jsx';

const ContractService =  lazy(() => import('../ContractService/ContractService.jsx'))
const WorkPackage = lazy(()=> import('../WorkPackage/WorkPackage.jsx'))
const Missions = lazy(()=>import('../Missions/Missions.jsx'))
const Submissions  = lazy(() => import('../Submissions/Submissions.jsx'))


export const ContractModalView = () => {
    const [tabvalue, setTabValue] = useState(CONTRACT_TABS_VALUES.contractsServices)

    const modalView = {

        [CONTRACT_TABS_VALUES.contractsServices]:
            <Suspense fallback={<LinearLoader />}>
                <ContractService />
            </Suspense>,

        [CONTRACT_TABS_VALUES.workPagckages]:
            <Suspense fallback={<LinearLoader />}>
                <WorkPackage />
            </Suspense>,

        [CONTRACT_TABS_VALUES.missions]:
            <Suspense fallback={<LinearLoader />}>
                <Missions />
            </Suspense>,

        [CONTRACT_TABS_VALUES.submissions]:
            <Suspense fallback={<LinearLoader />}>
                <Submissions />
            </Suspense>
    }



    return (
        <FusePageCarded
        content={
                <Box className='h-md w-full overflow-none'>
                    <section className='w-full overflow-hidden' style={{ height: 'calc(100dvh - 17%)' }}>
                        <Tabs
                            value={tabvalue}
                            onChange={(e, value) => setTabValue(value)}
                            {...DEFAULT_TAB_VALUE_PROPS}
                        >
                            <Tab value={CONTRACT_TABS_VALUES.contractsServices} label={CONTRACT_TAB_LABEL.contractsServices} />
                            <Tab value={CONTRACT_TABS_VALUES.workPagckages} label={CONTRACT_TAB_LABEL.workPagckages} />
                            <Tab value={CONTRACT_TABS_VALUES.missions} label={CONTRACT_TAB_LABEL.missions} />
                            <Tab value={CONTRACT_TABS_VALUES.submissions} label={CONTRACT_TAB_LABEL.submissions} />
                        </Tabs>
                        {modalView[tabvalue]}
                    </section>
            </Box>
        }
        />
    )
}
