import * as global from 'global.js'
import { createSlice } from "@reduxjs/toolkit"
import { getDepartments, postDepartment, putDepartment } from './departmentsThunks'

const initialState = {
    status: {
        getDepartments: 'idle',
    },
    error: null,
    departments: [],
    selectedDepartmentRow: null,
    modalVisibilityAction: { action: false, mode: null },
    modalData: {
        nameData: '',
        managersData: [],
        processData: null,
        codeData: '',
        departmentalDirectorateData: null,
        descriptionData: ''
    },
    managerRoles: [global.rolN5, global.rolN6, global.rolN7],
    usersManagers: [],
}

const departmentsSlice = createSlice({
    name: 'departments',
    initialState,
    reducers: {
        setSelectedDepartmentRow: (state, action) => {
            state.selectedDepartmentRow = action.payload
        },
        setModalVisibilityAction: (state, action) => {
            state.modalVisibilityAction = action.payload
        },
        setModalData: (state, action) => {
            state.modalData = { ...state.modalData, ...action.payload }
        },
        resetModalData: (state) => {
            state.modalData = initialState.modalData
        },
        filterUsersManagers: (state, action) => {
            state.usersManagers = action.payload.filter(user =>
                state.managerRoles.includes(user.rolUser)
            );
        },
    },
    extraReducers: (builder) => {
        // Handling getDepartments
        builder
            .addCase(getDepartments.pending, (state) => {
                state.status.getDepartments = 'pending'
                state.error = null
            })
            .addCase(getDepartments.rejected, (state, action) => {
                state.status.getDepartments = 'rejected'
                state.error = action.error.message
            })
            .addCase(getDepartments.fulfilled, (state, action) => {
                state.status.getDepartments = 'fulfilled'
                state.departments = action.payload
            })

        // Handling postDepartment
        builder
            .addCase(postDepartment.pending, (state) => {
                state.status.postDepartment = 'pending'
                state.error = null
            })
            .addCase(postDepartment.rejected, (state, action) => {
                state.status.postDepartment = 'rejected'
                state.error = action.error.message
            })
            .addCase(postDepartment.fulfilled, (state, action) => {
                state.status.postDepartment = 'fulfilled'
                state.departments = [...state.departments, action.payload]
            })

        // Handling putDepartment
        builder
            .addCase(putDepartment.pending, (state) => {
                state.status.putDepartment = 'pending'
                state.error = null
            })
            .addCase(putDepartment.rejected, (state, action) => {
                state.status.putDepartment = 'rejected'
                state.error = action.error.message
            })
            .addCase(putDepartment.fulfilled, (state, action) => {
                state.status.putDepartment = 'fulfilled'
                const index = state.departments.findIndex(department => department.id === action.payload.id)
                if (index !== -1) {
                    state.departments[index] = action.payload
                }
            })
    }
})

export const {
    setSelectedDepartmentRow,
    setModalVisibilityAction,
    setModalData,
    resetModalData,
    filterUsersManagers
} = departmentsSlice.actions

export const departments = state => state.fuse.departments.departments
export const departmentsStatus = state => state.fuse.departments.status.getDepartments
export const selectedDepartmentRow = state => state.fuse.departments.selectedDepartmentRow
export const modalVisibilityAction = state => state.fuse.departments.modalVisibilityAction
export const modalData = state => state.fuse.departments.modalData
export const usersManagers = state => state.fuse.departments.usersManagers

export default departmentsSlice.reducer
