import {
  Typography,
  Grid,
} from '@mui/material'
import { Fragment } from 'react'

export const ExpandedRowData = ({ headerGroups, data }) => {
  const dataToExpand = headerGroups.flatMap((headerGroup) => {
    const expandColumn = headerGroup.headers.find((header) => header.type === 'expand')
    if (!expandColumn || !expandColumn.expandData) return []
    const { expandData } = expandColumn
    return expandData(data).map(({ title, dataName }, index) => ({ title, dataName, index }))
  })


  return dataToExpand.map(({ title, dataName, index }) => (
    <Fragment key={index}>
      {!dataName === null || dataName === undefined ? null : (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2">{dataName}</Typography>
        </Grid>
      )}
    </Fragment>
  ))
}
