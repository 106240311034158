//******************** COMPONENTE PRINCIPAL *****************************************************


// CAMBIAR FILA SELECCIONADA GRID
export const CAMBIAR_VALOR_SELECCION_GRID = 'CAMBIAR_VALOR_SELECCION_GRID'
export const CAMBIAR_VALOR_SELECCION_GRID_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_ERROR'

//ELIMINAR PROGRAMA
export const ELIMINAR_IMPROVEMENT_PROPOSALS = 'ELIMINAR_IMPROVEMENT_PROPOSALS'
export const ELIMINAR_IMPROVEMENT_PROPOSALS_EXITO = 'ELIMINAR_IMPROVEMENT_PROPOSALS_EXITO'
export const ELIMINAR_IMPROVEMENT_PROPOSALS_ERROR = 'ELIMINAR_IMPROVEMENT_PROPOSALS_ERROR'

// MOSTRAR ALL IMPROVEMENT_PROPOSALS
export const MOSTRAR_ALL_IMPROVEMENT_PROPOSALS = 'MOSTRAR_ALL_IMPROVEMENT_PROPOSALS'
export const MOSTRAR_ALL_IMPROVEMENT_PROPOSALS_EXITO = 'MOSTRAR_ALL_IMPROVEMENT_PROPOSALS_EXITO'
export const MOSTRAR_ALL_IMPROVEMENT_PROPOSALS_ERROR = 'MOSTRAR_ALL_IMPROVEMENT_PROPOSALS_ERROR'

//CONSULTA A API IMPROVEMENT_PROPOSALS PARA GRID
export const MOSTRAR_IMPROVEMENT_PROPOSALS = 'MOSTRAR_IMPROVEMENT_PROPOSALS'
export const MOSTRAR_IMPROVEMENT_PROPOSALS_EXITO = 'MOSTRAR_IMPROVEMENT_PROPOSALS_EXITO'
export const MOSTRAR_IMPROVEMENT_PROPOSALS_ERROR = 'MOSTRAR_IMPROVEMENT_PROPOSALS_ERROR'


//RESET STATE insertadoRiskManagement
export const CAMBIAR_STATE_INSERTADORISKMANAGEMENT = 'CAMBIAR_STATE_INSERTADORISKMANAGEMENT'
export const CAMBIAR_STATE_INSERTADORISKMANAGEMENT_EXITO = 'CAMBIAR_STATE_INSERTADORISKMANAGEMENT_EXITO'
export const CAMBIAR_STATE_INSERTADORISKMANAGEMENT_ERROR = 'CAMBIAR_STATE_INSERTADORISKMANAGEMENT_ERROR'

//CAMBIAR ESTADOS DINÁMICOS
export const CAMBIAR_ESTADO_IMPROVEMENT_PROPOSALS = 'CAMBIAR_ESTADO_IMPROVEMENT_PROPOSALS'
export const CAMBIAR_ESTADO_IMPROVEMENT_PROPOSALS_EXITO = 'CAMBIAR_ESTADO_IMPROVEMENT_PROPOSALS_EXITO'
export const CAMBIAR_ESTADO_IMPROVEMENT_PROPOSALS_ERROR = 'CAMBIAR_ESTADO_IMPROVEMENT_PROPOSALS_ERROR'

//UPDATE IMPROVEMENT_PROPOSALS
export const UPDATE_IMPROVEMENT_PROPOSALS = 'UPDATE_IMPROVEMENT_PROPOSALS'
export const UPDATE_IMPROVEMENT_PROPOSALS_EXITO = 'UPDATE_IMPROVEMENT_PROPOSALS_EXITO'
export const UPDATE_IMPROVEMENT_PROPOSALS_ERROR = 'UPDATE_IMPROVEMENT_PROPOSALS_ERROR'


//******************** MODAL INSERTAR  *****************************************************

// CAMBIAR VISIBILIDAD
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_ERROR'

//INSERTAR NUEVO RISK_MANAGER
export const INSERTAR_IMPROVEMENT_PROPOSALS_MODAL_INSERTAR = 'INSERTAR_IMPROVEMENT_PROPOSALS_MODAL_INSERTAR'
export const INSERTAR_IMPROVEMENT_PROPOSALS_MODAL_INSERTAR_EXITO = 'INSERTAR_IMPROVEMENT_PROPOSALS_MODAL_INSERTAR_EXITO'
export const INSERTAR_IMPROVEMENT_PROPOSALS_MODAL_INSERTAR_ERROR = 'INSERTAR_IMPROVEMENT_PROPOSALS_MODAL_INSERTAR_ERROR'

//CONSULTA LAS SUBDIVISIONES
export const CONSULTA_SUBDIVISIONES_MODAL_INSERTAR = 'CONSULTA_SUBDIVISIONES_MODAL'
export const CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_EXITO = 'CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_EXITO'
export const CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_ERROR = 'CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_ERROR'



// ********************* EDIT IMPROVEMENT LIST API ********************************************
export const EDIT_IMPROVEMENT_LIST = 'EDIT_IMPROVEMENT_LIST'
export const INSERT_NEW_IMPROVEMENT = 'INSERT_NEW_IMPROVEMENT'
export const UPDATE_MIX_ACTIONS = 'UPDATE_MIX_ACTIONS'
export const UPDATE_MFT_FROM_IMPROVEMENT = 'UPDATE_MFT_FROM_IMPROVEMENT'
