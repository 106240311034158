import {

    // COMPONENTE PRINCIPAL ------------------

    CAMBIAR_VALOR_VISIBILIDAD_PROGRAMA,
    CAMBIAR_VALOR_VISIBILIDAD_PROGRAMA_EXITO,
    CAMBIAR_VALOR_VISIBILIDAD_PROGRAMA_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA,
    CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA_ERROR,

    ELIMINAR_PROGRAMA,
    ELIMINAR_PROGRAMA_EXITO,
    ELIMINAR_PROGRAMA_ERROR,

    MOSTRAR_PROGRAMAS_API,
    MOSTRAR_PROGRAMAS_EXITO,
    MOSTRAR_PROGRAMAS_ERROR,

    // MODAL INSERTAR  -----------------------

    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA_EXITO,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA_ERROR,

    INSERTAR_PROGRAMA_MODAL_INSERTAR,
    INSERTAR_PROGRAMA_MODAL_INSERTAR_EXITO,
    INSERTAR_PROGRAMA_MODAL_INSERTAR_ERROR,

    ACTUALIZAR_PROGRAMA_MODAL_INSERTAR,
    ACTUALIZAR_PROGRAMA_MODAL_INSERTAR_EXITO,
    ACTUALIZAR_PROGRAMA_MODAL_INSERTAR_ERROR,

    CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA,
    CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA_EXITO,
    CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA_ERROR


} from './types';
import axios from 'axios'
import { getCookie } from 'app/js/generalFunctions'
import { showMessage } from 'app/store/fuse/messageSlice'

//Constantes
const urlProgramaApi = process.env.REACT_APP_URL_DJANGO + "/api/program/"
const urlSubdivisionApi = process.env.REACT_APP_URL_DJANGO + "/api/subdivision/"


//************************CAMBIAR VISIBILIDAD ACTION**********************************************

export function cambiarValorVisibilidadAction(valorNuevo) {

    return (dispatch) => {
        dispatch(cambiarValorVisibilidad())

        try {
            dispatch(cambiarValorVisibilidadExito(valorNuevo))

        } catch (error) {

            dispatch(cambiarValorVisibilidadError(true))
        }

    }

}

const cambiarValorVisibilidad = () => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_PROGRAMA,
    payload: true

})

const cambiarValorVisibilidadExito = valorNuevo => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_PROGRAMA_EXITO,
    payload: valorNuevo

})

const cambiarValorVisibilidadError = estado => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_PROGRAMA_ERROR,
    payload: estado
})

//************************ CAMBIAR SELECCION GRID ACTION**********************************************

export function cambiarValorSeleccionAction(valorNuevo) {

    return (dispatch) => {
        dispatch(cambiarValorSeleccion())

        try {
            dispatch(cambiarValorSeleccionExito(valorNuevo))

        } catch (error) {

            dispatch(cambiarValorSeleccionError(true))
        }

    }

}

const cambiarValorSeleccion = () => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA,
    payload: false

})

const cambiarValorSeleccionExito = valorNuevo => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA_EXITO,
    payload: valorNuevo

})

const cambiarValorSeleccionError = estado => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA_ERROR,
    payload: estado
})


//************************ ELIMINAR PROGRAMA **********************************************

export function eliminarProgramaAction(id) {

    return (dispatch) => {
        dispatch(eliminarPrograma())


        axios.delete(urlProgramaApi + id)
            .then((response) => {
                dispatch(eliminarProgramaExito(response.data))
            })
            .catch(error => {
                console.log(error.response)
                dispatch(eliminarProgramaError(true))
            })


    }
}

const eliminarPrograma = (id) => ({
    type: ELIMINAR_PROGRAMA,
    payload: id

})

const eliminarProgramaExito = estado => ({
    type: ELIMINAR_PROGRAMA_EXITO,
    payload: estado

})

const eliminarProgramaError = estado => ({
    type: ELIMINAR_PROGRAMA_ERROR,
    payload: estado
})



//************************ MOSTRAR PROGRAMAS API **********************************************

export function mostrarProgramasAPIAction() {

    return async (dispatch) => {
        dispatch(mostrarProgramasAPI(true))


        await axios({
            method: "GET",
            url: urlProgramaApi,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(mostrarProgramasAPIExito(response.data))
            })
            .catch(error => {
                console.log(error.response)
                dispatch(mostrarProgramasAPIError(true))


            })

    }
}

const mostrarProgramasAPI = () => ({
    type: MOSTRAR_PROGRAMAS_API,
    payload: true

})

const mostrarProgramasAPIExito = programas => ({
    type: MOSTRAR_PROGRAMAS_EXITO,
    payload: programas

})

const mostrarProgramasAPIError = estado => ({
    type: MOSTRAR_PROGRAMAS_ERROR,
    payload: estado
})

//************************CAMBIAR VISIBILIDAD MODAL INSERTAR**********************************************

export function cambiarVisibilidadModalInsertarAction(valorNuevo, modoApertura) {

    return (dispatch) => {
        dispatch(cambiarVisibilidadModalInsertar())

        try {
            dispatch(cambiarVisibilidadModalInsertarExito(valorNuevo, modoApertura))

        } catch (error) {

            dispatch(cambiarVisibilidadModalInsertarError(true))
        }
    }
}

const cambiarVisibilidadModalInsertar = () => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA,
    payload: true

})

const cambiarVisibilidadModalInsertarExito = (valorNuevo, modoApertura) => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA_EXITO,
    payload: valorNuevo,
    visibilidadModal: modoApertura

})

const cambiarVisibilidadModalInsertarError = estado => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA_ERROR,
    payload: estado
})

//************************ INSERTAR PROGRAMA MODAL INSERTAR**********************************************

export function insertarProgramaModalInsertarAction(programa) {

    return (dispatch) => {
        dispatch(insertarProgramaModalInsertar())


        axios({
            method: "POST",
            url: urlProgramaApi,
            data: programa,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(insertarProgramaModalInsertarExito(response.data))

                dispatch(
                    showMessage({
                        message: "Successfully created",
                        variant: "success"
                    })
                )

                dispatch(mostrarProgramasAPIAction())
            })
            .catch(error => {
                console.log(error.response)
                dispatch(insertarProgramaModalInsertarError(true))

                dispatch(
                    showMessage({
                        message: "Error creating",
                        variant: "error"
                    })
                )
            })

    }
}

const insertarProgramaModalInsertar = (programa) => ({
    type: INSERTAR_PROGRAMA_MODAL_INSERTAR,
    payload: programa

})

const insertarProgramaModalInsertarExito = estado => ({
    type: INSERTAR_PROGRAMA_MODAL_INSERTAR_EXITO,
    payload: estado

})

const insertarProgramaModalInsertarError = estado => ({
    type: INSERTAR_PROGRAMA_MODAL_INSERTAR_ERROR,
    payload: estado
})

//************************ INSERTAR PROGRAMA MODAL INSERTAR**********************************************

export function actualizarProgramaModalInsertarAction(id, programa) {

    return (dispatch) => {
        dispatch(actualizarProgramaModalInsertar())


        axios({
            method: "PUT",
            url: urlProgramaApi + id,
            data: programa,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(actualizarProgramaModalInsertarExito(response.data))

                if (response.data['active'] == false) {
                    dispatch(
                        showMessage({
                            message: "Delete Program",
                            variant: "success"
                        })
                    )
                }
                else {

                    dispatch(
                        showMessage({
                            message: "Updated Program",
                            variant: "success"
                        })
                    )
                }

                dispatch(mostrarProgramasAPIAction())
            })
            .catch(error => {
                console.log(error.response)
                dispatch(actualizarProgramaModalInsertarError(true))

                dispatch(
                    showMessage({
                        message: "Error when updating Program",
                        variant: "error"
                    })
                )
            })

    }
}

const actualizarProgramaModalInsertar = (programa) => ({
    type: ACTUALIZAR_PROGRAMA_MODAL_INSERTAR,
    payload: programa

})

const actualizarProgramaModalInsertarExito = estado => ({
    type: ACTUALIZAR_PROGRAMA_MODAL_INSERTAR_EXITO,
    payload: estado

})

const actualizarProgramaModalInsertarError = estado => ({
    type: ACTUALIZAR_PROGRAMA_MODAL_INSERTAR_ERROR,
    payload: estado
})

//************************ CONSULTA SUBDIVISIONES MODAL **********************************************

export function consultaSubdivisionesAction() {

    return async (dispatch) => {
        dispatch(consultaSubdivisiones(true))


        await axios({
            method: "GET",
            url: urlSubdivisionApi,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(consultaSubdivisionesExito(response.data))
            })
            .catch(error => {
                console.log(error.response)
                dispatch(consultaSubdivisionesError(true))
            })

    }
}

const consultaSubdivisiones = () => ({
    type: CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA,
    payload: true

})

const consultaSubdivisionesExito = programas => ({
    type: CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA_EXITO,
    payload: programas

})

const consultaSubdivisionesError = estado => ({
    type: CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA_ERROR,
    payload: estado
})


