import { Autocomplete, TextField } from '@mui/material';

export const DisabledUserFilter = ({ optionAndValue, label }) => (
    <Autocomplete
        id="tags-outlined"
        options={[optionAndValue]}
        value={optionAndValue}
        getOptionLabel={(option) => `${option?.IDRes} - ${option?.first_name}  ${option?.last_name}`}
        renderOption={(props, option) => <li {...props} key={option?.id}>{`${option?.IDRes} - ${option?.first_name}  ${option?.last_name}`}</li>}
        disabled={true}
        renderInput={(params) => (
            <TextField
                {...params}
                label={label}
                placeholder={label}
                size="small"
                fullWidth={true}
            />
        )}
    />
)

export const DisabledFieldRenderName=({ optionAndValue, label })=>(
    <Autocomplete
    id="tags-outlined"
    options={[optionAndValue]}
    value={optionAndValue}
    getOptionLabel={(option) => option?.name}
    renderOption={(props, option) => <li {...props} key={option?.id}>{option?.name}</li>}
    disabled={true}
    renderInput={(params) => (
        <TextField
            {...params}
            label={label}
            placeholder={label}
            size="small"
            fullWidth={true}
        />
    )}
/>
)