import { createSlice } from '@reduxjs/toolkit'
import * as global from 'global'
import {
    getTeams,
    postTeam,
    putTeam,
    getDepartamentalWorkPackages,
    getSites,
    getVisualTimeGroups,
    getAgressoCodes
} from './teamThunks'

const initialState = {
    status: {
        getAgressoCodes: 'idle',
        getVisualTimeGroups: 'idle',
        getSites: 'idle',
        getTeams: 'idle',
        postTeam: 'idle',
        putTeam: 'idle'
    },
    error: null,
    teams: [],
    selectedTeamRow: null,
    modalVisibilityAction: { action: false, mode: null },
    modalData: {
        areaData: {},
        codeData: '',
        agressoCodesData: [],
        visualTimeGroupData: [],
        siteData: [],
        nameData: '',
        managersData: [],
        employeesData: [],
        descriptionData: ''
    },
    agressoCodes: [],
    visualTimeGroups: [],
    sites: [],
    managerRoles: [global.rolN3, global.rolN4, global.rolN5, global.rolN6, global.rolN7],
    employeeRoles: [global.rolN2],
    usersManagers: [],
    usersEmployees: []
}

const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        setSelectedTeamRow: (state, action) => {
            state.selectedTeamRow = action.payload
        },
        setModalVisibilityAction: (state, action) => {
            state.modalVisibilityAction = action.payload
        },
        filterUsersManagers: (state, action) => {
            const type = action.payload
            state.usersManagers = type.filter(user =>
                state.managerRoles.includes(user.rolUser)
            );
        },
        filterUsersEmployees: (state, action) => {
            const type = action.payload
            const roles = [...state.employeeRoles, ...state.managerRoles]
            state.usersEmployees = type.filter(user =>
                roles.includes(user.rolUser)
            );
        },
        setModalData: (state, action) => {
            state.modalData = { ...state.modalData, ...action.payload };
        },
        resetModalData: (state) => {
            state.modalData = initialState.modalData
        },
    },
    extraReducers: (builder) => {
        // Handling getTeams
        builder
            .addCase(getTeams.pending, (state) => {
                state.status.getTeams = 'pending'
                state.error = null
            })
            .addCase(getTeams.rejected, (state, action) => {
                state.status.getTeams = 'rejected'
                state.error = action.error.message
            })
            .addCase(getTeams.fulfilled, (state, action) => {
                state.status.getTeams = 'fulfilled'
                state.teams = action.payload
            })

        // Handling postTeam
        builder
            .addCase(postTeam.pending, (state) => {
                state.status.postTeam = 'pending'
                state.error = null
            })
            .addCase(postTeam.rejected, (state, action) => {
                state.status.postTeam = 'rejected'
                state.error = action.error.message
            })
            .addCase(postTeam.fulfilled, (state, action) => {
                state.status.postTeam = 'fulfilled'
                state.teams = [...state.teams, action.payload]
            })

        // Handling putTeam
        builder
            .addCase(putTeam.pending, (state) => {
                state.status.putTeam = 'pending'
                state.error = null
            })
            .addCase(putTeam.rejected, (state, action) => {
                state.status.putTeam = 'rejected'
                state.error = action.error.message
            })
            .addCase(putTeam.fulfilled, (state, action) => {
                state.status.putTeam = 'fulfilled'
                const index = state.teams.findIndex(team => team.id === action.payload.id)
                if (index !== -1) {
                    state.teams[index] = action.payload
                }
            })

        // Handling getAgressoCodes
        builder
            .addCase(getAgressoCodes.pending, (state) => {
                state.status.getAgressoCodes = 'pending'
                state.error = null
            })
            .addCase(getAgressoCodes.rejected, (state, action) => {
                state.status.getAgressoCodes = 'rejected'
                state.error = action.error.message
            })
            .addCase(getAgressoCodes.fulfilled, (state, action) => {
                state.status.getAgressoCodes = 'fulfilled'
                state.agressoCodes = action.payload
            })

        // Handling getVisualTimeGroups
        builder
            .addCase(getVisualTimeGroups.pending, (state) => {
                state.status.getVisualTimeGroups = 'pending'
                state.error = null
            })
            .addCase(getVisualTimeGroups.rejected, (state, action) => {
                state.status.getVisualTimeGroups = 'rejected'
                state.error = action.error.message
            })
            .addCase(getVisualTimeGroups.fulfilled, (state, action) => {
                state.status.getVisualTimeGroups = 'fulfilled'
                state.visualTimeGroups = action.payload
            })

        // Handling getSites
        builder
            .addCase(getSites.pending, (state) => {
                state.status.getSites = 'pending'
                state.error = null
            })
            .addCase(getSites.rejected, (state, action) => {
                state.status.getSites = 'rejected'
                state.error = action.error.message
            })
            .addCase(getSites.fulfilled, (state, action) => {
                state.status.getSites = 'fulfilled'
                state.sites = action.payload
            })
    }
})

export const {
    setSelectedTeamRow,
    setModalVisibilityAction,
    filterUsersManagers,
    filterUsersEmployees,
    setModalData,
    resetModalData,
} = teamSlice.actions

export const selectedTeamRow = state => state.fuse.team.selectedTeamRow
export const modalVisibilityAction = state => state.fuse.team.modalVisibilityAction
export const teams = state => state.fuse.team.teams
export const teamsStatus = state => state.fuse.team.status.getTeams
export const agressoCodes = state => state.fuse.team.agressoCodes
export const visualTimeGroups = state => state.fuse.team.visualTimeGroups
export const sites = state => state.fuse.team.sites
export const usersManagers = state => state.fuse.team.usersManagers
export const usersEmployees = state => state.fuse.team.usersEmployees
export const modalData = state => state.fuse.team.modalData

export default teamSlice.reducer
