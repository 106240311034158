import { React } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'

import { verModalDetallesLogRiskAPIAction } from '../store/actions'

import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'components/GlobalComponents/tables/Table2'

export default function DetallesLogRisk() {
  const dispatch = useDispatch()

  const loading = useSelector((state) => state.fuse.logRiskComponente.loading)
  const verModalInsertarObservaciones = useSelector(
    (state) => state.fuse.logRiskComponente.visibilidadDiaologoDetallesLogRisk,
  )
  const modoModalInsertarObservaciones = useSelector(
    (state) => state.fuse.logRiskComponente.modoDialogoDetallesLogRisk,
  )
  const logRiskListAPI = useSelector((state) => state.fuse.logRiskComponente.logRiskListAPI)

  const verModalDetallesLogRiskAPI = (modo, valor) =>
    dispatch(verModalDetallesLogRiskAPIAction(modo, valor))

  const columnasDataTable = [
    { Header: 'ID', accessor: 'id', sortable: true, type: 'string', width: 45 },
    { Header: 'Action', accessor: 'accion', sortable: true, type: 'string', width: 80 },
    { Header: 'Person', accessor: 'emailPersona', sortable: true, type: 'list' },
    { Header: 'Date', accessor: 'fecha_accion', sortable: true, type: 'date', width: 120 },
    { Header: 'Time', accessor: 'hora_accion', sortable: true, type: 'string', width: 100 },
    { Header: 'Description', accessor: 'description', sortable: true, type: 'string' },
  ]

  const RYO_ACTIONS_COLUMNS = [
    { Header: 'ID', accessor: 'id', sortable: true, type: 'string', width: 45 },
    { Header: 'Name Action', accessor: 'accion_name', sortable: true, type: 'string' },
    { Header: 'Action', accessor: 'accion', sortable: true, type: 'string', width: 80 },
    { Header: 'Person', accessor: 'emailPersona', sortable: true, type: 'list' },
    { Header: 'Date', accessor: 'fecha_accion', sortable: true, type: 'date', width: 120 },
    { Header: 'Time', accessor: 'hora_accion', sortable: true, type: 'string', width: 100 },
    { Header: 'Description', accessor: 'description', sortable: true, type: 'string' },
  ]

  const columsProps =
    modoModalInsertarObservaciones == 'R&O actions' ? RYO_ACTIONS_COLUMNS : columnasDataTable

  return (
    <>
      <Dialog
        open={verModalInsertarObservaciones}
        fullWidth
        maxWidth="xl"
        onClose={() => {
          verModalDetallesLogRiskAPI(false, '')
        }}
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6" component="div">
                Version history of {modoModalInsertarObservaciones}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={() => verModalDetallesLogRiskAPI(false, '')}>
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="p-0 m-0" dividers>
          <Table
            rowsProp={logRiskListAPI}
            columnsProp={columsProps}
            loading={loading}
            funcionSetValue={() => {}}
            headerTitleSection={true}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
