import './styles/tags.css'
import { NOT_AVALIABLE_COURSES } from '../utils/consts'

const setClassName =(tag)=> !NOT_AVALIABLE_COURSES.includes(tag) ? "tag-item" : "tag-item-red"

export const MoodleTrainningTags = ({ tagsList, skillMatrix = true }) => (
    <div className='tags-scontainer'>
        {tagsList.map((tag, i )=> <span className={skillMatrix ? setClassName(tag) : 'tag-border'} key={`${tag}${i}}`}>{tag}</span>)}
    </div>
)


