
import { FILTER_ACTION_TYPE } from "./types"
import { ALL_AGRESSO_CODES_STRING, ALL_VT_GROUPS_STRING } from "../utils/filterConsts"
import dayjs from "dayjs";

const initialState = {
    firstRender: true,
    filterVisibility: false,

    missionsIMustSee: [],
    subMissionsIMustSee: [],
    filterMissionSelected: -1,
    filterSubMissionSelected: -1,

    filterManagerSelected: null,
    filterManagerSelectedIDRes: null,
    personLoginIsSiteManager: null,
    personLoginCanSeeAllBA: null,

    missionsAndSubmissionsStringId: null,
    missionsStringId: null,


    agressoCodeSelected: ALL_AGRESSO_CODES_STRING,
    visualTimeGroupSelected: ALL_VT_GROUPS_STRING,
    xAxisValues: false,
    initialDate: dayjs(`01-01-${dayjs().year()}`),
    finalDate: dayjs(),
    filterYearsSelected: [],


    managersFromSiteManagerMissions: [],
    chartOrTableViewState: "Graphics",
    missionVisualTimeGroups: [],
    missionAgressoCodes: [],
    agressoAndVTGroupsStrId: "",
    peopleInMissionAndSubmission: {},



    filterStructure: {},
    
    filterSectionsSelected: {
        subdivisionSelected: null,
        wpSelected: null,
        missionSelected: null,
        submissionSelected: null
    },
    staffForSearch: [],
    sectionsAreCompleted: false,
    launchSearch: false,
    filterAgressoCodes: [],
    filterVTGs: [],
    auditPersonIDRes: null,
    summaryRequestDone: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case FILTER_ACTION_TYPE.SET_FIRST_RENDER:
            return {
                ...state,
                firstRender: action.payload
            }
        case FILTER_ACTION_TYPE.SHOW_FILTER:
            return {
                ...state,
                filterVisibility: action.payload
            }
        case FILTER_ACTION_TYPE.SET_INITIAL_DATE:
            return {
                ...state,
                initialDate: action.payload
            }
        case FILTER_ACTION_TYPE.SET_FINAL_DATE:
            return {
                ...state,
                finalDate: action.payload
            }
        case FILTER_ACTION_TYPE.SET_YEARS_INTERVAL_SELECTED:
            return {
                ...state,
                filterYearsSelected: action.payload
            }
        case FILTER_ACTION_TYPE.SET_CHART_OR_TABLE_VIEW_STATE:
            return {
                ...state,
                chartOrTableViewState: action.payload
            }
        case FILTER_ACTION_TYPE.SET_X_AXIS_VALUES:
            return {
                ...state,
                xAxisValues: action.payload
            }

        case FILTER_ACTION_TYPE.SET_PERSON_LOGIN_IS_N4_AND_CAN_SEE_ALL_BA:
            const setPersonLoginAsManagerIfNotN4AndCantSeeAllBA = (action.payload.isN4 || action.payload.canSeeAllBA) ? -1 : action.payload.personLoginId 
            const setPersonLoginIDResIfNotN4AndCantSeeAllBA = (action.payload.isN4 || action.payload.canSeeAllBA) ? null : action.payload.personLoginIDRes 
            return {
                ...state,
                personLoginIsSiteManager: action.payload.isN4,
                personLoginCanSeeAllBA: action.payload.canSeeAllBA,
                filterManagerSelected: setPersonLoginAsManagerIfNotN4AndCantSeeAllBA,
                filterManagerSelectedIDRes: setPersonLoginIDResIfNotN4AndCantSeeAllBA
            }


        case FILTER_ACTION_TYPE.SAVE_MISSIONS_I_MUST_SEE:
            const missionsIMustSee = action.payload;
            const { length: missionsLength } = missionsIMustSee
            const missionSelect = missionsLength === 1 ? missionsIMustSee[0].id : -1

            const missionsIdsInSaveMission = missionsIMustSee.map(m => m.id)
            const missionIdString = {
                missionsIds: missionsIdsInSaveMission,
                missionSelected: missionSelect
            }
            const missionsStringIdInMissionIMustSee = JSON.stringify(missionIdString)



            return {
                ...state,
                missionsIMustSee: missionsIMustSee,
                filterMissionSelected: missionSelect,
                missionsStringId: missionsStringIdInMissionIMustSee
            }


        case FILTER_ACTION_TYPE.SAVE_SUB_MISSIONS_I_MUST_SEE:

            const subMissionsIMustSee = action.payload;

            const { length: subsLength } = subMissionsIMustSee

            const submissionSelected = subsLength === 1 && state.filterMissionSelected !== -1 ? subMissionsIMustSee[0].id : -1
            const missionsIdsInSubsIMustSee = state.missionsIMustSee.map(m => m.id)
            const submissionsIdsInSubsIMustSee = subMissionsIMustSee.map(m => m.id)

            const objIdInSubsMustSee = {
                missionsIds: missionsIdsInSubsIMustSee,
                subMissionsIds: submissionsIdsInSubsIMustSee,
                missionSelected: state.filterMissionSelected,
                submissionSelected: submissionSelected
            }
            const stringIdInSubsMustSee = JSON.stringify(objIdInSubsMustSee)

            return {
                ...state,
                subMissionsIMustSee: subMissionsIMustSee,
                filterSubMissionSelected: submissionSelected,
                missionsAndSubmissionsStringId: stringIdInSubsMustSee
            };

        case FILTER_ACTION_TYPE.SET_MISSION_SELECTED:


            const missionsIdsInMissionSelected = state.missionsIMustSee.map(m => m.id)

            const missionIdStringInMissionSelected = {
                missionsIds: missionsIdsInMissionSelected,
                missionSelected: action.payload,
            }
            const missionsStringIdInMissionSelected = JSON.stringify(missionIdStringInMissionSelected)

            return {
                ...state,
                filterMissionSelected: action.payload,
                missionsStringId: missionsStringIdInMissionSelected
            }

        case FILTER_ACTION_TYPE.SET_SUB_MISSION_SELECTED:
            const missionsIdsInSetMission = state.missionsIMustSee.map(m => m.id)
            const submissionsIdsInSetMission = state.subMissionsIMustSee.map(s => s.id)

            const objIdInSelectMission = {
                missionsIds: missionsIdsInSetMission,
                subMissionsIds: submissionsIdsInSetMission,
                missionSelected: state.filterMissionSelected,
                submissionSelected: action.payload,
            }

            const strinIdInSelectSubMission = JSON.stringify(objIdInSelectMission)


            return {
                ...state,
                filterSubMissionSelected: action.payload,
                missionsAndSubmissionsStringId: strinIdInSelectSubMission
            }


        case FILTER_ACTION_TYPE.SET_AGRESSO_AND_VTG:
            const {agresso, vtg} = action.payload
            let agressoAndVTGroupsStrId
            if(!agresso.length) agressoAndVTGroupsStrId = null
            else if(!vtg.length) agressoAndVTGroupsStrId = null
            else agressoAndVTGroupsStrId = `${agresso.join()}${vtg.join()}`
            return {
                ...state,
                missionAgressoCodes: agresso,
                missionVisualTimeGroups: vtg,
                agressoAndVTGroupsStrId
            }
        case FILTER_ACTION_TYPE.SET_VT_GROUPS_FROM_MISSION_SELECTED:
            return {
                ...state,
                missionVisualTimeGroups: action.payload
            }
        case FILTER_ACTION_TYPE.SET_VT_GROUP_SELECTED:
            return {
                ...state,
                visualTimeGroupSelected: action.payload
            }
        case FILTER_ACTION_TYPE.SET_MISSION_MANAGER_SELECTED:
            const setAllMissionAndSubmission = action.payload === -1
            const setAllMissions = setAllMissionAndSubmission ? -1 : state.filterMissionSelected
            const setAllSubmissions = setAllMissionAndSubmission ? -1 : state.filterSubMissionSelected

            return {
                ...state,
                filterManagerSelected: action.payload,
                filterMissionSelected: setAllMissions,
                filterSubMissionSelected: setAllSubmissions,
            }

        case FILTER_ACTION_TYPE.SET_MANAGER_SELECTED_IDRES:

            return {
                ...state,
                filterManagerSelectedIDRes: action.payload,
            }



        // !!!!!!!!!!!
        // case FILTER_ACTION_TYPE.SET_PERSON_LOGIN_IS_SITE_MANAGER:
        //     return {
        //         ...state,
        //         personLoginIsSiteManager: true
        //     }
        //     case FILTER_ACTION_TYPE.PERSON_LOGIN_CAN_SEE_ALL_BA:
        //         return {
        //             ...state,
        //             personLoginCanSeeAllBA: true
        //         }
        // !!!!!!!!!!!
        case FILTER_ACTION_TYPE.SET_MISSIONS_MANAGERS_FROM_SITE_MANAGER_WP:
            return {
                ...state,
                managersFromSiteManagerMissions: action.payload
            }

        case FILTER_ACTION_TYPE.SET_PEOPLE_IN_MISSION_AND_SUBMISSION:
            return {
                ...state,
                peopleInMissionAndSubmission: action.payload
            }
            case FILTER_ACTION_TYPE.RESET_VALUES:
                return initialState;
                
        case FILTER_ACTION_TYPE.GET_FILTER_STRUCTURE:
            return {...state, filterStructure: action.payload};


        case FILTER_ACTION_TYPE.SET_PEOPLE_SEARCH:

            const {filterSectionsSelected, staffForSearch, sectionsAreCompleted} = action.payload
            return {
                ...state,
                filterSectionsSelected, staffForSearch, sectionsAreCompleted
            }

        case FILTER_ACTION_TYPE.SET_SERACH_BUTTOM:

            return {
                ...state,
                launchSearch: action.payload
            }

        case FILTER_ACTION_TYPE.SET_MISSION_AGREESO_AND_VTG:
            return {
                ...state,
                filterAgressoCodes: [...new Set(action.payload.agresso)].filter(record => !!record),
                filterVTGs: [...new Set(action.payload.vtg)].filter(record => !!record),
            }

        case FILTER_ACTION_TYPE.SET_AUDIT_PERSON_IDRES:

            return {
                ...state,
                auditPersonIDRes: action.payload
            }

        case FILTER_ACTION_TYPE.SET_SUMMARY_REQUES_DONE:
            return {
                ...state,
                summaryRequestDone: action.payload
            }

        default:
            return state
    }
}

