import { createContext } from 'react'
import { useImprovementReducer } from './internalReducer'

export const improvementContext = createContext(null)

export const ImprovementContextProvider = ({ children }) => {
  const {
    improvementState,
    setPeopleModalVisibility,
    setCompleteImprovementModalVisibility,
    setPeopleModalValues,
    setInsertModalVisibility,
    setInsertModalEditMode,
    setImprovementSelectedRow,
    setImprovementActionPlanSelectedRow,
    setActionPlanTableModalVisibility,
    setInserModalValues,
    resetImprovementModalsValues,
    setModalValuesInEditMode,
    setCompleteModalValues,
    setAcceptDeclineModalVisibility,
    setEditAcceptedImprovementModalVisibility,
    setActionPlanModalVisibility,
    setCompleteModalAllValues,
    setAcceptDeclineValues,
    setMftTeam,
    setActionSelectedRow,
  } = useImprovementReducer()

  const {
    insertModalVisibility,
    peopleModalVisibility,
    peopleModalValues,
    improvementSelectedRow,
    improvementActionPlanSelectedRow,
    actionPlanTableModalVisibility,
    insertModalInEditMode,
    insertModalValues,
    completeImprovementModalVisibility,
    completeModalValues,
    acceptDeclineModalVisibility,
    editAcceptedImprovementModalVisibility,
    actionPlanModalVisibility,
    acceptDeclineModalValues,
    actionSelectedRow,
  } = improvementState

  return (
    <improvementContext.Provider
      value={{
        insertModalVisibility,
        peopleModalVisibility,
        peopleModalValues,
        improvementSelectedRow,
        improvementActionPlanSelectedRow,
        actionPlanTableModalVisibility,
        insertModalInEditMode,
        insertModalValues,
        completeImprovementModalVisibility,
        completeModalValues,
        acceptDeclineModalVisibility,
        editAcceptedImprovementModalVisibility,
        actionPlanModalVisibility,
        acceptDeclineModalValues,
        mftTeam: acceptDeclineModalValues.mftTeam,
        // Force a null value if actionSelectedRow is undefined
        actionSelectedRow: actionSelectedRow ?? null,
        setPeopleModalVisibility,
        setCompleteImprovementModalVisibility,
        setPeopleModalValues,
        setInsertModalVisibility,
        setInsertModalEditMode,
        setImprovementSelectedRow,
        setImprovementActionPlanSelectedRow,
        setActionPlanTableModalVisibility,
        setInserModalValues,
        resetImprovementModalsValues,
        setModalValuesInEditMode,
        setCompleteModalValues,
        setAcceptDeclineModalVisibility,
        setEditAcceptedImprovementModalVisibility,
        setActionPlanModalVisibility,
        setCompleteModalAllValues,
        setAcceptDeclineValues,
        setMftTeam,
        setActionSelectedRow,
      }}
    >
      {children}
    </improvementContext.Provider>
  )
}
