import { useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import WorkIcon from '@mui/icons-material/Work';
import AddchartIcon from '@mui/icons-material/Addchart';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from '@mui/icons-material/Edit'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


import { useAddInitialAuthJobIconVisibility, useKnowIfPersonLoginCanAddInspectors, useCloseInspectorAuthModalVisibility, useSetDetailAuthTableVisibility, useCloseAuthViewIcon, useContinuousEvIconView, useOpenJobsTable, useCloseJobsTable, useEditInspectorButtomVisibility } from '../utils/hooks.js';
import { setContinuousEvaluationModalVisibility, setCreateInspectorModalVisibility, setEditInspectorModalVisibility, setJobModalVisibility, setJobModalOnlyViewVisibility, setInspectorBasicListRowSelectedAction } from '../store/AuthorizationOfInspectorsSlice.js';


export const InspectorsTopButtoms = () => {

  const dispatch = useDispatch()
  const { inspectorBasicListRowSelected, detailsAuthTableVisibility, inspectorRowSelected, jobsTableVisibility, jobRowSelected } = useSelector(({ fuse }) => fuse.inspectorsComponent)
  // const 

  const { canAddInspectors } = useKnowIfPersonLoginCanAddInspectors()
  const { editButtomVisibility } = useEditInspectorButtomVisibility()
  const { addInitialAuthJobIconVisibility } = useAddInitialAuthJobIconVisibility()
  const closeAuthIconView = useCloseAuthViewIcon()
  const openCreateInspectorModal = () => {
    dispatch(setInspectorBasicListRowSelectedAction(null))
    dispatch(setCreateInspectorModalVisibility(true))
  }
  
  const openEditInspectorModal = () => dispatch(setEditInspectorModalVisibility(true))
  const openJobModal = () => dispatch(setJobModalVisibility(true))
  const openJobOnlyViewModal = () => { dispatch(setJobModalOnlyViewVisibility(true)) }

  const { setDetailAuthTableVisibility } = useSetDetailAuthTableVisibility()
  const openContinuousEvaluationModal = () => dispatch(setContinuousEvaluationModalVisibility(true))
  const { opencloseInspectorAuthModal } = useCloseInspectorAuthModalVisibility()
  const { continuousEvIconView } = useContinuousEvIconView()
  const { openJobTable } = useOpenJobsTable()
  const { closeJobTable } = useCloseJobsTable()

  const addInpectorButtomVisibilityCondition = !!canAddInspectors && !detailsAuthTableVisibility && !jobsTableVisibility
  const editInpectorButtomVisibilityCondition = !!canAddInspectors && !detailsAuthTableVisibility && !jobsTableVisibility && editButtomVisibility
  const insoetorHeadingVisibility = !!detailsAuthTableVisibility || !!jobsTableVisibility
  //   * Component
  return (
    <section className='flex flex-row items-center justify-between gap-2 my-2 relative'>
      {
        insoetorHeadingVisibility  && (
          <div className='absolute w-full flex flex-row items-center justify-between gap-2 my-2'>

            <Typography className='w-full text-center' variant="h6">{inspectorBasicListRowSelected.inspector_info.last_name}, {inspectorBasicListRowSelected.inspector_info.first_name} ({inspectorBasicListRowSelected.program_info.name})</Typography>

          </div>


        )
      }

      < div className='flex flex-row items-center justify-between gap-2 my-2 z-10 bg-transparent'>
        <div className="border-1 rounded-lg border-transparent p-2 flex items-center gap-2">
          {
            !!detailsAuthTableVisibility && (
              <Tooltip title="Back to inspectors basic info" placement="top">
                <ArrowBackIosIcon variant="outlined" onClick={() => setDetailAuthTableVisibility(false)} />
              </Tooltip>
            )
          }

          {
            !!jobsTableVisibility && (
              <Tooltip title="Back to inspector auth info" placement="top">
                <ArrowBackIosIcon variant="outlined" onClick={closeJobTable} />
              </Tooltip>
            )
          }

          {
            addInpectorButtomVisibilityCondition && (
              <Tooltip title="New inspector" placement="top">
                <AddCircleIcon variant="outlined" onClick={openCreateInspectorModal} />
              </Tooltip>
            )
          }

          {/* {
            editInpectorButtomVisibilityCondition && (
              <Tooltip title="New inspctor" placement="top">
                <EditIcon variant="outlined" onClick={openEditInspectorModal} />
              </Tooltip>
            )
          } */}


          {
            !!inspectorBasicListRowSelected && !detailsAuthTableVisibility && !jobsTableVisibility && (
              <Tooltip title="View auth details" placement="top">
                <ManageSearchIcon variant="outlined" onClick={() => setDetailAuthTableVisibility(true)} />
              </Tooltip>
            )
          }


          {
            !!addInitialAuthJobIconVisibility && (
              <Tooltip title="New job" placement="top">
                <WorkIcon variant="outlined" onClick={openJobModal} />
              </Tooltip>
            )
          }


          {
            !!continuousEvIconView && (
              <Tooltip title="New continuous evaluation job" placement="top">
                <AddchartIcon variant="outlined" onClick={openContinuousEvaluationModal} />
              </Tooltip>
            )
          }


          {
            !!closeAuthIconView && (
              <Tooltip title="Close authorization" placement="top">
                <FactCheckIcon variant="outlined" onClick={opencloseInspectorAuthModal} />
              </Tooltip>
            )
          }




          {!!detailsAuthTableVisibility && !jobsTableVisibility &&
            (
              <Tooltip title="View inspector jobs" placement="top">
                <ContentPasteSearchIcon variant="outlined" onClick={openJobTable} />
              </Tooltip>
            )
          }

          {!!jobRowSelected &&
            (
              <Tooltip title="View inspector jobs" placement="top">
                <RemoveRedEyeIcon variant="outlined" onClick={openJobOnlyViewModal} />
              </Tooltip>
            )
          }
        </div>


      </div>

    </section>
  );

}

