import { Autocomplete, TextField } from '@mui/material';

export const UsersAutoComplete = ({ options, value, setValueFunc, label, disabled=false }) => (
    <Autocomplete
        id="tags-outlined"
        options={options}
        value={value}
        getOptionLabel={(option) => `${option?.IDRes} - ${option?.first_name}  ${option?.last_name}`}
        renderOption={(props, option) => <li {...props} key={option?.id}>{`${option?.IDRes} - ${option?.first_name}  ${option?.last_name}`}</li>}
        onChange={(e, value)=> setValueFunc(value)}
        disabled= {disabled}
        renderInput={(params) => (
            <TextField
                {...params}
                label={label}
                placeholder={label}
                size="small"
                fullWidth={true}
            />
        )}
    />
)

export const AutoCompleteByName = ({ options, value, setValueFunc, label, disabled=false }) => (
    <Autocomplete
        id="tags-outlined"
        options={options}
        value={value}
        getOptionLabel={(option) => option?.name}
        renderOption={(props, option) => <li {...props} key={option?.id}>{option.name}</li>}
        onChange={(e, value)=> setValueFunc(value)}
        disabled= {disabled}

        renderInput={(params) => (
            <TextField
                {...params}
                label={label}
                placeholder={label}
                size="small"
                fullWidth={true}
            />
        )}
    />
)