export const COURSES_TAB_LABELS = {
  courses: 'Courses',
  announcements: 'Announcements',
}
export const COURSES_TAB_VALUES = {
  courses: 'Courses',
  announcements: 'Announcements',
}

export const CONTAINER_STYLES = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

export const COURSES_COLUMNS = [
  { Header: 'Employee Name', accessor: 'employee', sortable: true, type: 'string', width: '150px' },
  { Header: 'Done', accessor: 'done_courses_count', sortable: true, type: 'string', width: '20px' },
  {
    Header: 'Pending',
    accessor: 'registered_courses_count',
    sortable: true,
    type: 'string',
    width: '20px',
  },
  {
    Header: 'Pending courses',
    accessor: 'registered_courses_names',
    sortable: false,
    type: 'html',
    width: '250px',
  },
  { Header: 'Progress', accessor: 'progress', sortable: false, type: 'html', width: '200px' },
]

export const ANNOUNCEMENTS_COLUMNS = [
  { Header: 'Employee Name', accessor: 'employee', sortable: true, type: 'string', width: '150px' },
  { Header: 'Done', accessor: 'done_courses_count', sortable: true, type: 'string', width: '20px' },
  {
    Header: 'Pending',
    accessor: 'registered_courses_count',
    sortable: true,
    type: 'string',
    width: '20px',
  },
  {
    Header: 'Pending courses',
    accessor: 'registered_courses_names',
    sortable: false,
    type: 'html',
    width: '250px',
  },
  { Header: 'Progress', accessor: 'progress', sortable: false, type: 'html', width: '200px' },
]
