import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequestAuthHeader } from 'app/js/generalFunctions';
import axios from 'axios';

const urlProfiles = process.env.REACT_APP_URL_DJANGO + "/api/get_requirements_roles"


export const getProfilesDataThunk = createAsyncThunk('getProfilesData', async () => {
    const response = await axios({
        url: urlProfiles,
        method: 'GET',
        headers: getRequestAuthHeader()
    })
    const data = await response.data;
  
    return data;
  });