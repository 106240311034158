import { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Autocomplete,
  TextField,
} from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@mui/material/CircularProgress'

const useStyles = makeStyles({
  customDialogTitle: {
    backgroundColor: 'rgb(37, 47, 62)',
    color: 'rgb(255, 255, 255)',
    marginBottom: '2em',
  },
})

const ConfirmationModal = ({
  open,
  onClose,
  onDecline,
  closeOutter,
  onConfirm,
  confirmColor,
  declineColor,
  title,
  contentTitle,
  content,
  secondaryContent,
  confirmText,
  declineText,
  softDeclineText,
  selector = { status: false, data: [], label: '', loading: false },
  setSelectedOption,
  selectedOption,
}) => {
  const customClass = useStyles()
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle className="uppercase" classes={{ root: customClass.customDialogTitle }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <>
          <Typography className="text-xl">
            <b>{contentTitle}</b>
          </Typography>
          <Typography className="text-base pt-2">{content}</Typography>
          {secondaryContent && (
            <Typography className="text-center text-base text-red-400 mt-28 px-5 py-3 border-2 border-red-200 rounded-md">
              {secondaryContent}
            </Typography>
          )}
          {selector?.status && (
            <Autocomplete
              className="my-5"
              multiple
              limitTags={2}
              id="tags-outlined"
              options={selector.data}
              getOptionLabel={(option) => `${option.code_proceso} - ${option.nombre_proceso}`}
              value={selectedOption}
              onChange={(event, value) => setSelectedOption(value)}
              filterSelectedOptions
              renderOption={(props, option) => (
                <li {...props}>
                  <div>
                    <p className="font-medium">
                      {option.code_proceso + ' - ' + option.nombre_proceso}
                    </p>
                    <p className="ml-2">
                      ·{' '}
                      {option?.manager_info?.IDRes +
                        ' - ' +
                        option?.manager_info?.first_name +
                        ' ' +
                        option?.manager_info?.last_name}
                    </p>
                  </div>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={selector.label}
                  placeholder={selector.label}
                  size="small"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {selector.loading ? (
                          <CircularProgress className="mr-24" color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
        </>
      </DialogContent>
      <DialogActions>
        {confirmText && (
          <Button
            variant="outlined"
            color={confirmColor || 'primary'}
            onClick={() => {
              onConfirm()
              onClose()
            }}
          >
            {typeof confirmText === 'string'
              ? confirmText.charAt(0).toUpperCase() + confirmText.slice(1)
              : confirmText}
          </Button>
        )}
        {declineText && (
          <Button
            variant="outlined"
            color={declineColor || 'primary'}
            onClick={() => {
              onClose()
              onDecline()
            }}
          >
            {declineText}
          </Button>
        )}
        {softDeclineText && (
          <Button variant="outlined" color={declineColor || 'primary'} onClick={onClose}>
            {softDeclineText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationModal
