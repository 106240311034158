import { createSlice } from '@reduxjs/toolkit'
import { peopleMovementsLogs } from 'app/js/generalRequests'
import { getStaffInTechnician, putJobInTechnician } from './jobsModalThunks'

const initialState = {
    status: 'idle',
    error: null,
    data: null,
    staffInTechnicianSelected: [],
    staffInTechnicianToSwitch: []
};

const jobsModalSlice = createSlice({
    name: 'jobsModalSlice',
    initialState,
    reducers: {
        sendLogs: (state, action) => {
            const { id: userId, subMision_id } = action.payload;
            const staff = {
                managers: [{ id: userId }],
                employees: []
            };
            peopleMovementsLogs({
                moduleId: subMision_id,
                moduleTableName: "subMision_relacionada",
                staff
            });
        },
        addNewStaffInTechnician: (state, action) => {
            state.staffInTechnicianSelected.push(action.payload);
            jobsModalSlice.caseReducers.sendLogs(state, action);
        },
        editStaffInTechnician: (state, action) => {
            const index = state.staffInTechnicianSelected.findIndex(staff => staff.id === action.payload.id);
            if (index !== -1) {
                state.staffInTechnicianSelected[index] = action.payload;
            }
            jobsModalSlice.caseReducers.sendLogs(state, action);
        },
        removeStaffInTechnician: (state, action) => {
            state.staffInTechnicianSelected = state.staffInTechnicianSelected.filter(staff => staff.id !== action.payload);
            jobsModalSlice.caseReducers.sendLogs(state, action);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStaffInTechnician.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(getStaffInTechnician.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                if (action.payload.type === 'selected') {
                    state.staffInTechnicianSelected = action.payload.data;
                } else if (action.payload.type === 'switch') {
                    state.staffInTechnicianToSwitch = action.payload.data;
                }
            })
            .addCase(getStaffInTechnician.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = action.error.message;
            })
            .addCase(putJobInTechnician.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(putJobInTechnician.fulfilled, (state, action) => {
                state.status = 'fulfilled';
            })
            .addCase(putJobInTechnician.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = action.error.message;
            });
    }
});

export const staffInTechnicianSelected = (state) => state.fuse.jobsModalSlice.staffInTechnicianSelected;
export const staffInTechnicianToSwitch = (state) => state.fuse.jobsModalSlice.staffInTechnicianToSwitch;

export const {
    addNewStaffInTechnician,
    editStaffInTechnician,
    removeStaffInTechnician
} = jobsModalSlice.actions;

export default jobsModalSlice.reducer;
