import { createSlice } from '@reduxjs/toolkit'
import { getJobInDepartmentSubmission } from './insertEditModalThunks'

const initialState = {
    status: 'idle',
    error: null,
    data: null,
    peopleInTechnicianSelected: [],
    peopleInTechnicianToSwitch: [],
}

const insertEditModalSlice = createSlice({
    name: 'insertEditTechnicianModal',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getJobInDepartmentSubmission.pending, (state, action) => {
                state.status = 'pending'
            })
            .addCase(getJobInDepartmentSubmission.rejected, (state, action) => {
                state.status = 'rejected'
                state.error = action.error.message
            })
            .addCase(getJobInDepartmentSubmission.fulfilled, (state, action) => {
                state.status = 'fulfilled'
                if (action.payload.type === 'selected') {
                    state.peopleInTechnicianSelected = action.payload.data
                } else if (action.payload.type === 'switch') {
                    state.peopleInTechnicianToSwitch = action.payload.data
                }
            })
    }
})

export default insertEditModalSlice.reducer

export const peopleInTechnicianSelected = state => state.fuse.insertEditTechnicianModal.peopleInTechnicianSelected
export const peopleInTechnicianToSwitch = state => state.fuse.insertEditTechnicianModal.peopleInTechnicianToSwitch

