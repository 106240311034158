// React and Redux
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// MUI Components
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  List,
  Tab,
  Tabs,
} from '@mui/material'

// Local Imports
import {
  actionIsRequiredAction,
  cambiarEstadoRyOAction,
  cambiarVisibilidadModalInsertarRyOAction,
  cambiarVisibilidadModalPrincipalAction,
} from '../../store/RyO/actions'
import { useActionsButtom } from '../../hooks/RyO/RyOTopButtomsHooks'
import {
  useCanHaveChildremControl,
  useCreateRyO,
  useEditRyo,
  useHandleMissionChange,
  useHandleSecMissionsChangeInCreate,
  useHandleSecMissionsChangeInEdit,
  useInputsControl,
  useIsEditMode,
  useNprCalc,
  useNprControl,
} from '../../hooks/RyO/RyOMainSectionHooks.js'
import {
  encabezadoTitulo,
  buttonLabel,
  nprChartFunction,
  nprChartOptions,
  rowsDetection,
  rowsFrequency,
  rowsSeverity,
  useStyles,
} from '../../utils/RyOUtils.js'
import { getIdsFromArr } from 'app/js/generalFunctions'
import RODescription from './insertRyOModalTabs/RODescription'
import Ecc from './insertRyOModalTabs/Ecc'
import { RyOActions } from '../../features/actionPlan/ryoActionPlanSlice'

const ModalInsertarRyO = ({ handleClearRowSelected }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  // ? Component states
  const [riesgoActual, setRiesgoActual] = useState('')
  const [valuesRyO, setvaluesRyO] = useState(null)
  const [opportunityActual, setopportunityActual] = useState('')
  const [fechaDeteccionActual, setFechaDeteccionActual] = useState(null)
  const [efectoFalloActual, setEfectoFalloActual] = useState('')
  const [causaFalloActual, setCausaFalloActual] = useState('')
  const [controlesActual, setControlesActual] = useState('')
  const [gravedadActual, setGravedadActual] = useState()
  const [ocurrenciaActual, setOcurrenciaActual] = useState()
  const [deteccionActual, setDeteccionActual] = useState()
  const [nprActual, setNprActual] = useState('Severity, frequency and detection are required')
  const [priorizacionActual, setPriorizacionActual] = useState('NPR is necessary')
  const [observacionesActual, setObservacionesActual] = useState('')
  const [categoriaActual, setCategoriaActual] = useState()
  const [rulesActual, setRulesActual] = useState()
  const [processSelectedState, setprocessSelectedState] = useState()
  const [subProcessRelatedState, setsubProcessRelatedState] = useState(null)
  const [subProcessSelectedState, setsubProcessSelectedState] = useState()
  const [revalorando, setRevalorando] = useState(false)
  const [porcentajeNPR, setPorcentajeNPR] = useState([0])
  const [hiddenGrafico, setHiddenGrafico] = useState(true)
  const [tomarAccion, setTomarAccion] = useState('No action necessary')
  const [alertaTipo, setAlertaTipo] = useState('info')
  const [valorTabRO, setvalorTabRO] = useState('RODescription')
  const [missionSelected, setmissionSelected] = useState(null)
  const [secMissionsSelected, setSecMissionsSelected] = useState([])
  const [secMissionPrevEditMode, setsecMissionPrevEditMode] = useState([])
  const [missionSelectedCantHaveChildren, setmissionSelectedCantHaveChildren] = useState(null)
  const [saveIsDisable, setsaveIsDisable] = useState(true)
  const [ayudaNPR, setAyudaNPR] = useState(false)
  const [ayudaSeverity, setAyudaSeverity] = useState(false)
  const [ayudaFrequency, setAyudaFrequency] = useState(false)
  const [ayudaDetection, setAyudaDetection] = useState(false)
  const [recordatorioClose, setRecordatorioClose] = useState(false)
  const [nprChart, setnprChart] = useState(null)
  const [RyOFilaSeleccionada, setRyOFilaSeleccionada] = useState(null)
  const [newSecMissionsAddInEditMode, setNewSecMissionsAddInEditMode] = useState([])
  const [ryoAldredyHaveAction, setryoAldredyHaveAction] = useState(null)

  const resetForm = () => {
    setRiesgoActual('')
    setvaluesRyO(null)
    setopportunityActual('')
    setFechaDeteccionActual(null)
    setEfectoFalloActual('')
    setCausaFalloActual('')
    setControlesActual('')
    setGravedadActual()
    setOcurrenciaActual()
    setDeteccionActual()
    setNprActual('Severity, frequency and detection are required')
    setPriorizacionActual('NPR is necessary')
    setObservacionesActual('')
    setCategoriaActual()
    setRulesActual()
    setprocessSelectedState()
    setsubProcessRelatedState(null)
    setsubProcessSelectedState()
    setRevalorando(false)
    setPorcentajeNPR([0])
    setHiddenGrafico(true)
    setTomarAccion('No action necessary')
    setAlertaTipo('info')
    setvalorTabRO('RODescription')
    setmissionSelected(null)
    setSecMissionsSelected([])
    setsecMissionPrevEditMode([])
    setmissionSelectedCantHaveChildren(null)
    setsaveIsDisable(true)
    setAyudaNPR(false)
    setAyudaSeverity(false)
    setAyudaFrequency(false)
    setAyudaDetection(false)
    setRecordatorioClose(false)
    setnprChart(null)
    setRyOFilaSeleccionada(null)
    setNewSecMissionsAddInEditMode([])
    setryoAldredyHaveAction(null)
  }

  // ? Component selectors
  const visibilidadModalInsertarRO = useSelector(
    (state) => state.fuse.RyOComponente.visibilidadModalInsertar,
  )
  const modoRyO = useSelector((state) => state.fuse.RyOComponente.modo)
  const { anfeListAPI, myAnfesList } = useSelector((state) => state.fuse.anfeComponente)

  const actionIsRequired = useSelector((state) => state.fuse.RyOComponente.actionIsRequired)
  const filaSeleccionadaGridRyOStore = useSelector(
    (state) => state.fuse.RyOComponente.filaSeleccionadaGrid,
  )
  const idRyOWhenIsNoSelectedrow = useSelector(
    (state) => state.fuse.RyOComponente.idRyOWhenNoSelectedRow?.id,
  )
  const categoriaListAPI = useSelector((state) => state.fuse.categoriaComponent.categoriaListAPI)
  const tipoCategoriasAPI = useSelector((state) => state.fuse.categoriaComponent.tipoCategoriasAPI)
  const rulesListAPI = useSelector((state) => state.fuse.rulesComponent.rulesListAPI)
  const processListAPI = useSelector((state) => state.fuse.processComponent.processListAPI)
  const subProcessListAPI = useSelector((state) => state.fuse.subProcessComponent.subProcessListAPI)
  const personLoginId = useSelector((state) => state.fuse.userComponente.person)?.id
  const { departamentalAndNoDepartamentalMissionsInfo } = useSelector(
    (state) => state.fuse.userComponente,
  )
  const listMisionAndDepartmentAPI = useSelector(
    (state) => state.fuse.misionComponent.listAllMisionAPI,
  )
  const rmAccionesListAPI = useSelector(RyOActions)

  useEffect(() => {
    if (!visibilidadModalInsertarRO) return
    const fila = filaSeleccionadaGridRyOStore || idRyOWhenIsNoSelectedrow
    setRyOFilaSeleccionada(fila)
  }, [visibilidadModalInsertarRO, idRyOWhenIsNoSelectedrow, filaSeleccionadaGridRyOStore])

  // ? Component dispatchs
  const cambiarVisibilidadModalInsertar = () => {
    resetForm()
    dispatch(cambiarVisibilidadModalInsertarRyOAction(false))
    dispatch(cambiarEstadoRyOAction('modo', ''))
    handleClearRowSelected()
  }

  // ? Custom Hooks functions declaration
  const { inputControl } = useInputsControl()
  const { isEditMode } = useIsEditMode()
  const { createRyO } = useCreateRyO()
  const { editRyo } = useEditRyo()
  const { actionsButtom } = useActionsButtom()
  const { nprCalc } = useNprCalc()
  const { nprControl } = useNprControl()
  const { handleMissionChangeHook } = useHandleMissionChange()
  const { handleSecMissionsChangeInCreate } = useHandleSecMissionsChangeInCreate()
  const { handleSecMissionsChangeInEdit } = useHandleSecMissionsChangeInEdit()
  const { canHaveChildremControl } = useCanHaveChildremControl()

  // ? Sates RyO object
  const satesRyoObj = {
    riesgoActual: riesgoActual,
    opportunityActual: opportunityActual,
    fechaDeteccionActual: fechaDeteccionActual,
    efectoFalloActual: efectoFalloActual,
    causaFalloActual: causaFalloActual,
    controlesActual: controlesActual,
    gravedadActual: gravedadActual,
    ocurrenciaActual: ocurrenciaActual,
    deteccionActual: deteccionActual,
    nprActual: nprActual,
    priorizacionActual: priorizacionActual,
    observacionesActual: observacionesActual,
    categoriaActual: categoriaActual,
    processSelectedState: processSelectedState,
    subProcessSelectedState: subProcessSelectedState,
    missionSelected: missionSelected,
    secMissionsSelected: secMissionsSelected,
    rulesActual: rulesActual,
  }

  // ? Inputs control for save buttom
  const inputControlResponse = inputControl(satesRyoObj)
  useEffect(() => {
    inputControlResponse ? setsaveIsDisable(false) : setsaveIsDisable(true)
  }, [inputControlResponse])

  // ? Edit mode control
  useEffect(() => {
    const values = isEditMode(visibilidadModalInsertarRO, modoRyO, RyOFilaSeleccionada)
    setvaluesRyO(values)
  }, [visibilidadModalInsertarRO, modoRyO, RyOFilaSeleccionada])

  // ? Set input values. Empty if is create mode, fill if is edit or rev mode
  useEffect(() => {
    if (!valuesRyO) return
    setCategoriaActual(valuesRyO.categoriaActual)
    setCausaFalloActual(valuesRyO.causaFalloActual)
    setControlesActual(valuesRyO.controlesActual)
    setDeteccionActual(valuesRyO.deteccionActual)
    setEfectoFalloActual(valuesRyO.efectoFalloActual)
    setFechaDeteccionActual(valuesRyO.fechaDeteccionActual)
    setGravedadActual(valuesRyO.gravedadActual)
    setmissionSelected(valuesRyO.missionSelected)
    setNprActual(valuesRyO.nprActual)
    setObservacionesActual(valuesRyO.observacionesActual)
    setOcurrenciaActual(valuesRyO.ocurrenciaActual)
    setopportunityActual(valuesRyO.opportunityActual)
    setPriorizacionActual(valuesRyO.priorizacionActual)
    setprocessSelectedState(valuesRyO.processSelectedState)
    setRiesgoActual(valuesRyO.riesgoActual)
    setRulesActual(valuesRyO.rulesActual)
    setSecMissionsSelected(
      missionsWithAnfes.filter((mission) => valuesRyO.secMissionsSelected.includes(mission.id)) ||
      [],
    )
    setsecMissionPrevEditMode(valuesRyO.secMissionsSelected)
    setsubProcessSelectedState(valuesRyO.subProcessSelectedState)
  }, [valuesRyO])

  // ? Know if ryo selected have missions
  useEffect(() => {
    if (!visibilidadModalInsertarRO) return
    const findRyoAldredyHaveAction = rmAccionesListAPI.find(
      (action) => action.id_record === RyOFilaSeleccionada?.id,
    )
    setryoAldredyHaveAction(!!findRyoAldredyHaveAction)
  }, [RyOFilaSeleccionada, visibilidadModalInsertarRO])

  // ? Save. Create / Edit function
  async function callCreateHook() {
    const copy = { ...satesRyoObj }
    copy.subProcessSelectedState = copy.subProcessSelectedState ?? subProcessSelectedState

    if (modoRyO === 'editar' || modoRyO === 'rev') {
      copy.secMissionsSelected = copy.secMissionsSelected ?? newSecMissionsAddInEditMode
      editRyo((typeof RyOFilaSeleccionada === 'object' ? RyOFilaSeleccionada.id : RyOFilaSeleccionada), copy, modoRyO)
      setNewSecMissionsAddInEditMode([])
    } else {
      copy.secMissionsSelected = copy.secMissionsSelected ?? secMissionsSelected
      createRyO(copy)
    }
    cambiarVisibilidadModalInsertar()
    setnprChart(null)
    setvalorTabRO('RODescription')

    // *  Show actions modal if npr > 100 and ryo have no actions

    if (satesRyoObj.nprActual > 100 && !ryoAldredyHaveAction && modoRyO !== 'rev') actionsButtom()
  }

  // ? Npr calc
  useEffect(() => {
    const { npr } = nprCalc(gravedadActual, ocurrenciaActual, deteccionActual, setNprActual)
    setNprActual(npr)
  }, [gravedadActual, ocurrenciaActual, deteccionActual])

  // ? Priorization calc
  useEffect(() => {
    const { priorizacionActual, tomarAccion, alertaTipo, porcentajeNPR, hiddenGrafico } =
      nprControl(nprActual)
    setPriorizacionActual(priorizacionActual)
    setTomarAccion(tomarAccion)
    setAlertaTipo(alertaTipo)
    setPorcentajeNPR(porcentajeNPR)
    setHiddenGrafico(hiddenGrafico)
  }, [nprActual])

  // ? Subprocess control
  useEffect(() => {
    if (processSelectedState) {
      const subProcessRelated = subProcessListAPI?.filter(
        (subProcess) => subProcess?.id_proceso === processSelectedState,
      )
      subProcessRelated.length
        ? setsubProcessRelatedState(subProcessRelated)
        : setsubProcessRelatedState(null)
      if (modoRyO === 'crear' || !subProcessRelated.length) setsubProcessSelectedState(null)
    }
  }, [processSelectedState])

  // ? Missions with anfes control
  const missionsWithAnfes = useMemo(() => {
    if (!myAnfesList.length) return []
    const myAnfesListIMissionsIds = myAnfesList.map(({ mision }) => mision)
    const missions = departamentalAndNoDepartamentalMissionsInfo?.filter((mission) =>
      myAnfesListIMissionsIds.includes(mission.id),
    )

    return missions
  }, [myAnfesList, departamentalAndNoDepartamentalMissionsInfo])

  // ? Childrem control. If the mission is alredy a childrem it cant have childrem
  useEffect(() => {
    const { misionSelectedHasFather } = canHaveChildremControl({
      visibilidadModalInsertarRO: visibilidadModalInsertarRO,
      missionSelected: missionSelected,
    })
    setmissionSelectedCantHaveChildren(misionSelectedHasFather ?? misionSelectedHasFather)
  }, [visibilidadModalInsertarRO, missionSelected])

  // ? Rev mode control
  useEffect(() => {
    modoRyO === 'rev' ? setRevalorando(true) : setRevalorando(false)
    modoRyO === 'rev' ? setvalorTabRO('ECC') : setvalorTabRO('RODescription')
  }, [modoRyO])

  // ? Input mission selected control
  const handleMissionChange = (mission) => {
    const { missionSelected, newSecMissions } = handleMissionChangeHook(
      mission,
      secMissionsSelected,
    )

    setmissionSelected(missionSelected ? missionSelected.id : null)
    setSecMissionsSelected(newSecMissions)
  }

  // ? Input related missions selected control
  const handleSecMissionsChange = (newValue) => {
    if (modoRyO === 'crear') {
      const { secMissionsSelectedResult } = handleSecMissionsChangeInCreate({
        newValue,
        secMissionsSelected,
      })
      setSecMissionsSelected(secMissionsSelectedResult)
    } else if (modoRyO === 'editar') {
      const { secMissionsSelectedResult, newValuesInEditMode } = handleSecMissionsChangeInEdit({
        newValue,
        secMissionPrevEditMode,
      })
      setSecMissionsSelected(newValuesInEditMode)
      setNewSecMissionsAddInEditMode(newValuesInEditMode)
    }
  }

  // ? Npr chart
  useEffect(() => {
    if (nprActual > 0 && nprActual <= 1000) {
      setnprChart(
        nprChartFunction({
          npr: nprActual,
          color:
            nprActual < 80
              ? 'rgb(109, 213, 255)'
              : nprActual > 80 && nprActual <= 100
                ? 'rgb(255, 173, 66)'
                : 'rgb(255, 0, 0)',
        }),
      )
    }
  }, [nprActual])

  // ? Change tab value function
  const changeTabValue = (event, newValue) => setvalorTabRO(newValue)

  const roDescriptionProps = {
    revalorando,
    riesgoActual,
    setRiesgoActual,
    opportunityActual,
    setopportunityActual,
    categoriaActual,
    setCategoriaActual,
    fechaDeteccionActual,
    setFechaDeteccionActual,
    processSelectedState,
    setprocessSelectedState,
    subProcessRelatedState,
    setsubProcessRelatedState,
    subProcessSelectedState,
    setsubProcessSelectedState,
    rulesActual,
    setRulesActual,
    missionsWithAnfes,
    missionSelected,
    setmissionSelected,
    handleMissionChange,
    missionSelectedCantHaveChildren,
    setmissionSelectedCantHaveChildren,
    secMissionsSelected,
    handleSecMissionsChange,
    tipoCategoriasAPI,
    categoriaListAPI,
    processListAPI,
    subProcessListAPI,
    rulesListAPI,
    modoRyO,
    observacionesActual,
    setObservacionesActual,
  }

  const eccProps = {
    revalorando,
    efectoFalloActual,
    setEfectoFalloActual,
    causaFalloActual,
    setCausaFalloActual,
    controlesActual,
    setControlesActual,
    gravedadActual,
    setGravedadActual,
    ayudaSeverity,
    setAyudaSeverity,
    rowsSeverity,
    ocurrenciaActual,
    setOcurrenciaActual,
    ayudaFrequency,
    setAyudaFrequency,
    rowsFrequency,
    deteccionActual,
    setDeteccionActual,
    ayudaDetection,
    setAyudaDetection,
    rowsDetection,
    alertaTipo,
    nprActual,
    priorizacionActual,
    nprChartOptions,
    nprChart,
  }

  // ? Return the component
  return (
    <Dialog
      open={visibilidadModalInsertarRO}
      disableScrollLock={false}
      onClose={() => setRecordatorioClose(true)}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle classes={{ root: classes.customDialogTitle }}>
        {encabezadoTitulo({ modoRyO })}
      </DialogTitle>
      <Tabs
        value={valorTabRO}
        onChange={changeTabValue}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value="RODescription" label="R&O Description" />
        <Tab value="ECC" label="Effect/Cause/Controls" />
      </Tabs>

      <DialogContent className="px-5">
        {valorTabRO === 'RODescription' && <RODescription {...roDescriptionProps} />}
        {valorTabRO === 'ECC' && <Ecc {...eccProps} />}
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setRecordatorioClose(true)
          }}
        >
          {'Close without saving'}
        </Button>

        <Button variant="outlined" disabled={saveIsDisable} onClick={callCreateHook}>
          {buttonLabel({ modoRyO, alertaTipo, ryoAldredyHaveAction })}
        </Button>
      </DialogActions>

      <Dialog
        open={recordatorioClose}
        onClose={() => setRecordatorioClose(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <List sx={{ pt: 0 }} style={{ margin: '20px' }}>
          Changes will not be saved, are you sure to close?
        </List>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setRecordatorioClose(false)
              cambiarVisibilidadModalInsertar()
              dispatch(cambiarVisibilidadModalPrincipalAction(false))
              dispatch(cambiarEstadoRyOAction('modo', ''))
              setnprChart(null)
            }}
          >
            Yes
          </Button>
          <Button variant="outlined" onClick={() => setRecordatorioClose(false)}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  )
}

export default ModalInsertarRyO
