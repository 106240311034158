import { useReducer } from "react"

const INITIAL_STATE = {
    dataDeliverableRowSelected : null,
    postDeliverableModalVisibility: false,
    putDeliverableModalVisibility: false,
    editQuantityInTableModalVisibility: false,
    editQuantityInTable: false,
    deliverablesPostInfo: null,
    deliverablesObjRequest: null,
    researchAfterPost: false,
    filterValues: { missionSelected: null, dateSelected: null },
    dataDeliverablesQuantityEdited: {},
    postDateSelected: null,
    allDeliverablesView: false,
}

const DATA_DELIVERABLES_ACTIONS_TYPES = {
    SET_ROW_SELECTED: 'SET_ROW_SELECTED',
    SET_POST_MODAL_VISIBILITY: 'SET_POST_MODAL_VISIBILITY',
    SET_PUT_MODAL_VISIBILITY: 'SET_PUT_MODAL_VISIBILITY',
    SET_EDIT_QUANTITY_IN_TABLE_MODAL_VISIBILITY: 'SET_EDIT_QUANTITY_IN_TABLE_MODAL_VISIBILITY',
    SET_EDIT_QUANTITY_IN_TABLE: 'SET_EDIT_QUANTITY_IN_TABLE',
    SET_DELIVERABLES_POST_INFO: 'SET_DELIVERABLES_POST_INFO',
    SET_DATA_DELIVERABLE_OBJ_REQUEST: 'SET_DATA_DELIVERABLE_OBJ_REQUEST',
    RESEARCH_AFTER_POST: 'RESEARCH_AFTER_POST',
    SET_FILTER_VALUES: 'SET_FILTER_VALUES',
    RESET_FLTERS_VALUES: 'RESET_FLTERS_VALUES',
    SET_DATA_DELIVERABLES_QUANTITY_EDITED: 'SET_DATA_DELIVERABLES_QUANTITY_EDITED',
    RESET_DELIVERABLES_QUANTITY_EDITED: 'RESET_DELIVERABLES_QUANTITY_EDITED',
    SET_DATA_DELI_POST_DATE_SELECTED: 'SET_DATA_DELI_POST_DATE_SELECTED',
    SET_ALL_DELIVERABLES_VIEW: 'SET_ALL_DELIVERABLES_VIEW',
}

const { SET_POST_MODAL_VISIBILITY, SET_DELIVERABLES_POST_INFO, SET_DATA_DELIVERABLE_OBJ_REQUEST, RESEARCH_AFTER_POST, SET_FILTER_VALUES, RESET_FLTERS_VALUES, SET_PUT_MODAL_VISIBILITY, SET_EDIT_QUANTITY_IN_TABLE_MODAL_VISIBILITY, SET_EDIT_QUANTITY_IN_TABLE, SET_DATA_DELIVERABLES_QUANTITY_EDITED, RESET_DELIVERABLES_QUANTITY_EDITED, SET_DATA_DELI_POST_DATE_SELECTED, SET_ALL_DELIVERABLES_VIEW, SET_ROW_SELECTED } = DATA_DELIVERABLES_ACTIONS_TYPES

const DATA_DELIVERABLES_ACTIONS = {

    [SET_ROW_SELECTED]: (state, payload) => {
        return { ...state, dataDeliverableRowSelected: payload }
    },

    [SET_POST_MODAL_VISIBILITY]: (state, payload) => {
        return { ...state, postDeliverableModalVisibility: payload }
    },

    [SET_PUT_MODAL_VISIBILITY]: (state, payload) => {
        return { ...state, putDeliverableModalVisibility: payload }
    },

    [SET_EDIT_QUANTITY_IN_TABLE_MODAL_VISIBILITY]: (state, payload) => {
        return { ...state, editQuantityInTableModalVisibility: payload }
    },

    [SET_EDIT_QUANTITY_IN_TABLE]: (state, payload) => {
        return { ...state, editQuantityInTable: payload }
    },

    [SET_DATA_DELIVERABLE_OBJ_REQUEST]: (state, payload) => {
        if (!payload) return { ...state, deliverablesObjRequest: null, deliverablesPostInfo: null }
        return { ...state, deliverablesObjRequest: payload }

    },

    [SET_DELIVERABLES_POST_INFO]: (state, payload) => {
        return { ...state, deliverablesPostInfo: payload }
    },

    [RESEARCH_AFTER_POST]: (state, payload) => {
        return { ...state, researchAfterPost: payload }
    },

    [SET_FILTER_VALUES]: (state, { key, value }) => {
        return { ...state, filterValues: { ...state.filterValues, [key]: value } }
    },

    [RESET_FLTERS_VALUES]: (state) => {
        return { ...state, filterValues: INITIAL_STATE.filterValues }
    },

    [SET_DATA_DELIVERABLES_QUANTITY_EDITED]: (state, { dataDeliId, newQuantity }) => {
        return { ...state, dataDeliverablesQuantityEdited: { ...state.dataDeliverablesQuantityEdited, [dataDeliId]: newQuantity } }
    },

    [RESET_DELIVERABLES_QUANTITY_EDITED]: (state) => {
        return { ...state, dataDeliverablesQuantityEdited: {} }
    },

    [SET_DATA_DELI_POST_DATE_SELECTED]: (state, payload) => {
        return { ...state, postDateSelected: payload }
    },

    [SET_ALL_DELIVERABLES_VIEW]: (state, payload) => {
        return { ...state, allDeliverablesView: payload }
    },

}

const useDataDeliverablesReducer = (state, action) => {
    const { type, payload } = action
    return DATA_DELIVERABLES_ACTIONS[type] ? DATA_DELIVERABLES_ACTIONS[type](state, payload) : state
}

export const dataDeliverablesReducer = () => {
    const [dataDeliverablesState, dataDeliverablesDispatch] = useReducer(useDataDeliverablesReducer, INITIAL_STATE)

    const dataDeliverablesReducerActions = {
        setPostModalVisibility: (modalVisibility) => dataDeliverablesDispatch({
            type: SET_POST_MODAL_VISIBILITY,
            payload: modalVisibility
        }),
        setPutModalVisibility: (modalVisibility) => dataDeliverablesDispatch({
            type: SET_PUT_MODAL_VISIBILITY,
            payload: modalVisibility
        }),
        setEditQuantityInTableModalVisibility: (modalVisibility) => dataDeliverablesDispatch({
            type: SET_EDIT_QUANTITY_IN_TABLE_MODAL_VISIBILITY,
            payload: modalVisibility
        }),
        setEditQuantityInTable: (bool) => dataDeliverablesDispatch({
            type: SET_EDIT_QUANTITY_IN_TABLE,
            payload: bool
        }),
        setDeliverablesPostInfo: (postInfo) => dataDeliverablesDispatch({
            type: SET_DELIVERABLES_POST_INFO,
            payload: postInfo
        }),
        setDeliverablesObjRequest: (objRequest) => dataDeliverablesDispatch({
            type: SET_DATA_DELIVERABLE_OBJ_REQUEST,
            payload: objRequest
        }),
        setResearchAfterPost: (bool) => dataDeliverablesDispatch({
            type: RESEARCH_AFTER_POST,
            payload: bool
        }),
        setFilterValues: (key, value) => dataDeliverablesDispatch({
            type: SET_FILTER_VALUES,
            payload: { key, value }
        }),
        resetFilterValues: () => dataDeliverablesDispatch({
            type: RESET_FLTERS_VALUES,
        }),
        setDataDeliverablesQuantityEdited: ({ dataDeliId, newQuantity }) => dataDeliverablesDispatch({
            type: SET_DATA_DELIVERABLES_QUANTITY_EDITED,
            payload: { dataDeliId, newQuantity }
        }),
        resetDataDeliverablesQuantityEdited: () => dataDeliverablesDispatch({
            type: RESET_DELIVERABLES_QUANTITY_EDITED,
        }),

        setPostDateSelected: (date) => dataDeliverablesDispatch({
            type: SET_DATA_DELI_POST_DATE_SELECTED,
            payload: date
        }),
        setAllDeliverablesView: (bool) => dataDeliverablesDispatch({
            type: SET_ALL_DELIVERABLES_VIEW,
            payload: bool
        }),
        setRowSelected: (row)=> dataDeliverablesDispatch({
            type: SET_ROW_SELECTED,
            payload: row
        })
    }



    return { dataDeliverablesState, dataDeliverablesReducerActions }

}