import { useReducer } from "react"

const INITIAL_STATE = {
    missionSelected: null,
    compareModalVisibility: false,
    compareModalsFilters: {
        compareMissionSelected: null,
        firstDate: null,
        lastDate: null
    }
}

const ACTIONS_TYPES = {
    SET_MISSION_SELECTED: 'SET_MISSION_SELECTED',
    SET_COMPARE_MODAL_VISIBILITY: 'SET_COMPARE_MODAL_VISIBILITY',
    SE_COMPARE_MODAL_FILTER: 'SE_COMPARE_MODAL_FILTER',
}

const {SET_MISSION_SELECTED, SET_COMPARE_MODAL_VISIBILITY, SE_COMPARE_MODAL_FILTER} = ACTIONS_TYPES

const REDUCER_ACTIONS = {
    SET_MISSION_SELECTED: (state, payload) => ({...state, missionSelected: payload}),
    SET_COMPARE_MODAL_VISIBILITY: (state, payload) => ({...state, compareModalVisibility: payload}),
    SE_COMPARE_MODAL_FILTER: (state, {key, value}) => {
        const newValue = {[key]: value}
        const newCompareModalsFilters = {...state.compareModalsFilters, ...newValue}
        return {...state, compareModalsFilters: newCompareModalsFilters}
    },

}


const deliManagementReducer=(state, action)=>{
    const {type, payload} = action
    return REDUCER_ACTIONS[type] ? REDUCER_ACTIONS[type](state, payload) : state 
}

export const useDeliManagementReducer=()=>{
    const [deliManagementState, dispatch] = useReducer(deliManagementReducer, INITIAL_STATE)


    const reducerActions = {
        
        setMissionSelected: (missionSelected)=> dispatch({
            type: SET_MISSION_SELECTED,
            payload: missionSelected
        }),

        setCompareModalVisibility: (bool)=> dispatch({
            type: SET_COMPARE_MODAL_VISIBILITY,
            payload: bool
        }),

        setCompareModalFilters: (key, value)=> dispatch({
            type: SE_COMPARE_MODAL_FILTER,
            payload: {key, value}
        }),
    }

    return {deliManagementState, reducerActions}
}