export const DEPARTMENTS_URL = process.env.REACT_APP_URL_DJANGO + "/api/departamento/"

export const SUCESS_MESSAGE_CREATED_DEPARTMENT = {
    message: "Department created successfully",
    variant: "success"
}

export const SUCESS_MESSAGE_EDITED_DEPARTMENT = {
    message: "Department updated successfully",
    variant: "success"
}

export const ERROR_TRY_AGAIN = {
    message: "Error, try again",
    variant: "error"
}
