import { getIdsFromArr } from 'app/js/generalFunctions';
import {
  OBTENER_ULTIMO_ID_RO,
  OBTENER_ULTIMO_ID_RO_EXITO,
  OBTENER_ULTIMO_ID_RO_ERROR,
  // COMPONENTE PRINCIPAL ------------------
  UPDATE_RM_ACCION,
  UPDATE_RM_ACCION_EXITO,
  UPDATE_RM_ACCION_ERROR,
  CAMBIAR_VALOR_SELECCION_ANFE_GRID,
  CAMBIAR_VALOR_SELECCION_ANFE_GRID_EXITO,
  CAMBIAR_VALOR_SELECCION_ANFE_GRID_ERROR,
  ELIMINAR_ANFE,
  ELIMINAR_ANFE_EXITO,
  ELIMINAR_ANFE_ERROR,
  MOSTRAR_ANFE,
  MOSTRAR_ANFE_EXITO,
  MOSTRAR_ANFE_ERROR,
  CAMBIAR_STATE_INSERTADO_ANFE,
  CAMBIAR_STATE_INSERTADO_ANFE_EXITO,
  CAMBIAR_STATE_INSERTADO_ANFE_ERROR,
  UPDATE_ANFE,
  UPDATE_ANFE_EXITO,
  UPDATE_ANFE_ERROR,

  // MODAL EDITAR ACCION
  CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION,
  CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION_EXITO,
  CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION_ERROR,

  // MODAL INSERTAR  -----------------------
  CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR,
  CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR_EXITO,
  CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR_ERROR,
  INSERTAR_ANFE_MODAL_INSERTAR,
  INSERTAR_ANFE_MODAL_INSERTAR_EXITO,
  INSERTAR_ANFE_MODAL_INSERTAR_ERROR,

  // MODAL ELIMINAR
  CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR,
  CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR_EXITO,
  CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR_ERROR,
  CONSULTA_SUBDIVISIONES_MODAL_INSERTAR,
  CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_EXITO,
  CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_ERROR,
  CAMBIAR_ESTADO_ANFE,
  CAMBIAR_ESTADO_ANFE_EXITO,
  CAMBIAR_ESTADO_ANFE_ERROR,
  SET_MY_ANFES,
  SET_NOT_MY_ANFES,
  SET_ANFES_STRUCTURE,
  UPDATE_ANFE_FROM_STRUCTURE,
  UPDATE_ANFE_FROM_STRUCTURE_EXITO,
  UPDATE_ANFE_FROM_STRUCTURE_ERROR,
  ADD_ANFE_TO_STRUCTURE,
  DELETE_ANFE_FROM_STRUCTURE,
} from './types'

// cada reducer tiene su propio state
const initialState = {
  anfeListAPI: [],
  myAnfesList: [],
  notMyAnfesList: [],
  anfesICanSee: [],
  // subdivisionesListAPI: [],
  ultimoIdCreado: '',
  filaSeleccionadaGridAnfe: '',
  visibilidadModalEditarAccion: false,
  visibilidadModalInsertar: false,
  visibilidadModalEditar: false,
  visibilidadModalEliminar: false,
  visibilidadModalExito: '',
  origenEliminar: '',
  insertadO_ANFE: false,
  resetStatesLocal: false,
  error: null,
  loading: false,
  loadingUpdateAnfe: false,
  modoModalAnfe: '',
  loadingGetFmea: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    //**********CAMBIAR ESTADO RISK MANAGEMENT **********************************/
    case OBTENER_ULTIMO_ID_RO:
      return {
        ...state,
        loading: action.payload,
      }

    case OBTENER_ULTIMO_ID_RO_EXITO:
      return {
        ...state,
        loading: false,
        ultimoIdCreado: action.payload,
      }

    case OBTENER_ULTIMO_ID_RO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CAMBIAR_ESTADO_ANFE:
      return {
        ...state,
        loading: action.payload,
      }

    case CAMBIAR_ESTADO_ANFE_EXITO:
      return {
        ...state,
        modoModalAnfe: action.payload,
      }

    case CAMBIAR_ESTADO_ANFE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    //**********FILA SELECIONADA GRID **********************************/

    case CAMBIAR_VALOR_SELECCION_ANFE_GRID:
      return {
        ...state,
        loading: action.payload,
      }

    case CAMBIAR_VALOR_SELECCION_ANFE_GRID_EXITO:
      return {
        ...state,
        loading: false,
        filaSeleccionadaGridAnfe: action.payload,
      }

    case CAMBIAR_VALOR_SELECCION_ANFE_GRID_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    //*********CAMBIAR STATE INSERTADO_ANFE **********************************/

    case CAMBIAR_STATE_INSERTADO_ANFE:
      return {
        ...state,
        loading: action.payload,
      }

    case CAMBIAR_STATE_INSERTADO_ANFE_EXITO:
      return {
        ...state,
        loading: false,
        insertadO_ANFE: action.payload,
      }

    case CAMBIAR_STATE_INSERTADO_ANFE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    //********** ELIMINAR RISK MANAGEMENT **********************************/

    case ELIMINAR_ANFE:
      return {
        ...state,
        loading: action.payload,
      }

    case ELIMINAR_ANFE_EXITO:
      return {
        ...state,
        loading: action.payload,
        error: null,
      }

    case ELIMINAR_ANFE_ERROR:
      return {
        ...state,
        loading: action.payload,
        error: true,
      }

    //********** MOSTRAR RISK_MANAGEMENT API**********************************/

    case MOSTRAR_ANFE:
      return {
        ...state,
        loadingGetFmea: action.payload,
      }

    case MOSTRAR_ANFE_EXITO:
      return {
        ...state,
        loadingGetFmea: false,
        error: null,
        anfeListAPI: action.payload,
      }

    case MOSTRAR_ANFE_ERROR:
      return {
        ...state,
        loadingGetFmea: false,
        error: action.payload,
      }
    //**********VISIBILIDAD MODAL EDITAR ACCION*****************************/
    case CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION:
      return {
        ...state,
        loading: action.payload,
      }

    case CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION_EXITO:
      return {
        ...state,
        loading: false,
        visibilidadModalEditarAccion: action.payload,
      }

    case CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    //**********VISIBILIDAD MODAL INSERTAR**********************************/

    case CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR:
      return {
        ...state,
        loading: action.payload,
      }

    case CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR_EXITO:
      return {
        ...state,
        loading: false,
        visibilidadModalInsertar: action.payload,
      }

    case CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    //**********VISIBILIDAD MODAL ELIMINAR**********************************/

    case CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR:
      return {
        ...state,
        loading: action.payload,
      }

    case CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR_EXITO:
      return {
        ...state,
        loading: false,
        visibilidadModalEliminar: action.payload,
      }

    case CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    //********** INSERTAR RISK_MANAGEMENT API MODAL INSERTAR**********************************/

    case INSERTAR_ANFE_MODAL_INSERTAR:
      return {
        ...state,
        loading: action.payload,
      }

    case INSERTAR_ANFE_MODAL_INSERTAR_EXITO:
      return {
        ...state,
        loading: false,
        ultimoIdCreado: action.payload,
        insertadO_ANFE: true,
        error: null,
      }

    case INSERTAR_ANFE_MODAL_INSERTAR_ERROR:
      return {
        ...state,
        loading: action.payload,
        error: true,
      }

    //********** MUESTRA SUBDIVISIONES MODAL **********************************/

    case CONSULTA_SUBDIVISIONES_MODAL_INSERTAR:
      return {
        ...state,
        loading: action.payload,
      }

    // case CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_EXITO:
    //     return {
    //         ...state,
    //         loading: false,
    //         error: null,
    //         subdivisionesListAPI: action.payload

    //     }

    case CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    //********** UPDATE RM_ACCION *******************************************/

    case UPDATE_RM_ACCION:
      return {
        ...state,
        loading: action.payload,
      }

    case UPDATE_RM_ACCION_EXITO:
      return {
        ...state,
        loading: false,
        error: null,
      }

    case UPDATE_RM_ACCION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    //********** UPDATE RMANFE **********************************/

    case UPDATE_ANFE:
      return {
        ...state,
        loading: action.payload,
      }

    case UPDATE_ANFE_EXITO:
      return {
        ...state,
        loading: false,
        error: null,
      }

    case UPDATE_ANFE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case SET_ANFES_STRUCTURE:
      const { myAnfesList, notMyAnfesList, anfesICanSee } = action.payload
      return {
        ...state,
        myAnfesList,
        notMyAnfesList,
        anfesICanSee,
      }

    //********** UPDATE ANFE FROM STRUCTURE **********************************/

    case UPDATE_ANFE_FROM_STRUCTURE:
      return {
        ...state,
        loadingUpdateAnfe: action.payload,
      }

    case UPDATE_ANFE_FROM_STRUCTURE_EXITO:
      const updateAnfeInList = (list, id, updatedAnfe) => {
        return list.map((anfe) => (anfe.id === id ? updatedAnfe : anfe))
      }

      const { id } = action.payload

      return {
        ...state,
        anfeListAPI: updateAnfeInList(state.anfeListAPI, id, action.payload),
        myAnfesList: updateAnfeInList(state.myAnfesList, id, action.payload),
        anfesICanSee: updateAnfeInList(state.anfesICanSee, id, action.payload),
        loadingUpdateAnfe: false,
      }

    case UPDATE_ANFE_FROM_STRUCTURE_ERROR:
      return {
        ...state,
        loadingUpdateAnfe: false,
        error: action.payload,
      }

    case ADD_ANFE_TO_STRUCTURE:
      return {
        ...state,
        anfeListAPI: [...state.anfeListAPI, action.payload],
        myAnfesList: [...state.myAnfesList, action.payload],
        anfesICanSee: [...state.anfesICanSee, action.payload],
      }

    case DELETE_ANFE_FROM_STRUCTURE:
      return {
        ...state,
        anfeListAPI: state.anfeListAPI.filter((anfe) => anfe.id !== action.payload.id),
        myAnfesList: state.myAnfesList.filter((anfe) => anfe.id !== action.payload.id),
        anfesICanSee: state.anfesICanSee.filter((anfe) => anfe.id !== action.payload.id),
      }

    // //********** SET_MY_ANFES **********************************/
    // case SET_MY_ANFES:
    //     return {
    //         ...state,
    //         myAnfesList: action.payload,
    //         myAnfesListIds: getIdsFromArr(action.payload)
    //     }
    // //********** SET_NO_MY_ANFES **********************************/
    // case SET_NOT_MY_ANFES:
    //     return {
    //         ...state,
    //         notMyAnfesList: action.payload
    //     }

    default:
      return state
  }
}
