import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Tooltip from '@mui/material/Tooltip'
import AddTaskIcon from '@mui/icons-material/AddTask'
import Divider from '@mui/material/Divider'
import {
  useCreateButtom,
  useEditButtom,
  useDeleteButtom,
  useHistoryButtom,
  useActionsButtom,
} from '../../hooks/RyO/RyOTopButtomsHooks.js'
import {
  useButtonPermissionStyle,
  useButtomStyleSelectedRow,
} from '../../../../../app/js/customHooks/ButtomsHooks.js'
import { getIdsFromArr } from 'app/js/generalFunctions.js'
import { IconButton } from '@mui/material'
import { RecordHistoryButton } from 'components/GlobalComponents/Buttons/RecordHistoryButton'

export function BotonesSuperioresRyO() {
  // Store selectors
  const RyOFilaSeleccionada = useSelector((state) => state.fuse.RyOComponente.filaSeleccionadaGrid)
  const { myRyOsList, noRevRyOICanSee } = useSelector((state) => state.fuse.RyOComponente)

  // Buttoms actions
  const { createButtom } = useCreateButtom()
  const { editButtom } = useEditButtom()
  const { deleteButtom } = useDeleteButtom()
  const { historyButtom } = useHistoryButtom()
  const { actionsButtom } = useActionsButtom()
  const { getButtomPermissionStyle } = useButtonPermissionStyle()
  const { getButtomStyleSelectedRow } = useButtomStyleSelectedRow()

  // Buttons styles states
  const [canAddRyOButtom, setCanAddRyOButtom] = useState(null)
  const [canEditButtom, setcanEditButtom] = useState(null)
  const [canDeleteButtom, setcanDeleteButtom] = useState(null)
  const [canAddActionButtom, setCanAddActionButtom] = useState(null)
  const [buttomColorStyle, setbuttomColorStyle] = useState(null)

  // Get buttom styles states depends of permissions
  useEffect(() => {
    const { canAddPermissionStyle, canEditPermissionStyle, canDeletePermissionStyle } =
      getButtomPermissionStyle('rm risk opportunity')
    const canAddActionsPermissionStyle = getButtomPermissionStyle('rm action').canAddPermissionStyle
    setCanAddRyOButtom(canAddPermissionStyle)
    setcanEditButtom(canEditPermissionStyle)
    setcanDeleteButtom(canDeletePermissionStyle)
    setCanAddActionButtom(canAddActionsPermissionStyle)
  }, [])

  const selectedRyOIsMine = useMemo(() => {
    return noRevRyOICanSee.length > 0 && getIdsFromArr(myRyOsList).includes(RyOFilaSeleccionada?.id)
  }, [RyOFilaSeleccionada, noRevRyOICanSee, myRyOsList])

  const disabledEditButtom = useMemo(() => {
    return RyOFilaSeleccionada?.rev > 1
  }, [RyOFilaSeleccionada])

  return (
    <div className="w-full">
      <section className="flex flex-row items-center justify-between gap-2 my-2 h-[35px]">
        <div className="flex flex-row items-center gap-2">
          <div className="border-1 rounded-lg border-transparent px-2 flex items-center gap-2">
            <Tooltip title="New" placement="top">
              <span>
                <IconButton
                  className='p-0 m-0 text-gray-900 hover:text-gray-800 transition-color duration-75'
                  onClick={createButtom}
                >
                  <AddCircleIcon
                    style={{ ...canAddRyOButtom }}
                  />
                </IconButton>
              </span>
            </Tooltip>
            {selectedRyOIsMine && (
              <>
                <Tooltip title="Edit" placement="top">
                  <span>
                    <IconButton
                      className='p-0 m-0 text-gray-900 hover:text-gray-800 transition-color duration-75'
                      disabled={disabledEditButtom}
                      onClick={RyOFilaSeleccionada && editButtom}
                    >
                      <EditIcon
                        style={{ ...canEditButtom }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title="Delete" placement="top">
                  <span>
                    <IconButton
                      className='p-0 m-0 text-gray-900 hover:text-gray-800 transition-color duration-75'
                      onClick={RyOFilaSeleccionada && deleteButtom}
                    >
                      <DeleteIcon
                        style={{ ...canDeleteButtom }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title="Actions" placement="top">
                  <span>
                    <IconButton
                      className='p-0 m-0 text-gray-900 hover:text-gray-800 transition-color duration-75'
                      onClick={RyOFilaSeleccionada && actionsButtom}
                    >
                      <AddTaskIcon
                        style={{ ...canAddActionButtom }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title="Historical R&O" placement="top">
                  <span>
                    <IconButton
                      className='p-0 m-0 text-gray-900 hover:text-gray-800 transition-color duration-75'
                      onClick={RyOFilaSeleccionada && historyButtom}
                    >
                      <SettingsBackupRestoreIcon
                        style={{ ...buttomColorStyle }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            )}
            {RyOFilaSeleccionada && <RecordHistoryButton modelName='rmriskopportunity' recordId={RyOFilaSeleccionada.id} />}

          </div>
        </div>
      </section>
      <Divider />
    </div>
  )
}
