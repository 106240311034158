import React, { useEffect, useState, useRef, Fragment } from "react"
import { useTable, useFilters, useGroupBy, useExpanded, usePagination, useSortBy } from "react-table"
import { CSSTransition } from 'react-transition-group'
import Autocomplete from '@mui/material/Autocomplete'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { es } from 'date-fns/locale'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CustomPagination } from "./Components/CustomPagination"
import {
  exportToExcel,
  selectRowFunc,
  handleFilterChange,
  handleFilterChangeDate,
  rowColorStyle,
  updateEmptyRows,
  ExpandTableInfo,
  renderExpandedContent
} from "./utils/functions"
import { TableRows } from "./Components/TableRows"
import dayjs from 'dayjs'

import { styled } from '@mui/system'
import ExpandableRow from './Components/ExpandableRow'

export const Table = ({
  rowsProp: data,
  columnsProp: columns,
  loading,
  funcionSetValue,
  cellMaxWidth,
  tableWidth = "100%",
  selectedRowFunctions = null,
  headerTitleSection = false,
  headerTabs = false,
  headerButtons = true,
}) => {
  const {
    // Table properties and methods
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    page,

    // State and state setters
    setData,
    setFilter,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,

    // Pagination state and controls
    state: { pageIndex, pageSize, filters },
    canPreviousPage,
    canNextPage
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      autoResetPage: false
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination
    )

  // useState Hooks
  const [selectedRowIndex, setselectedRowIndex] = useState(null)
  const [valorSelect, setValorSelect] = useState(null)
  const [dateValue, setDateValue] = useState(null)
  const [dateValueExtra, setDateValueExtra] = useState(null)
  const [emptyRow, setEmptyRow] = useState()

  // useRef Hooks
  const tableContainerRef = useRef(null)
  const tableMiddleContainerRef = useRef(null)
  const tableRef = useRef(null)
  const tableBodyRef = useRef(null)
  const initialDataLength = useRef(data.length)

  // useEffect Hooks
  useEffect(() => {
    if (data.length !== initialDataLength.current) {
      resetTableRowSelection()
      initialDataLength.current = data.length
    }
  }, [data])

  const { setClearSelectedRow, clearSelectedRow } = selectedRowFunctions || {}
  useEffect(() => {
    if (clearSelectedRow) {
      resetTableRowSelection()
      setClearSelectedRow(false)
    }
  }, [clearSelectedRow])

  useEffect(() => {
    if (tableContainerRef.current && tableBodyRef.current) {
      updateEmptyRows(page.length, tableBodyRef, tableMiddleContainerRef, setEmptyRow, columns)
    }
  }, [page.length, tableContainerRef, tableBodyRef])

  // Functions
  const resetTableRowSelection = () => {
    setselectedRowIndex(null)
    funcionSetValue(null)
  }

  // Props
  const CUSTOM_PAGINATION_PROPS = { setValorSelect, setDateValue, setDateValueExtra, columns, setFilter, tableContainerRef, tableMiddleContainerRef, pageIndex, rows, pageSize, filters, page, data, resetTableRowSelection, selectedRowIndex, setselectedRowIndex, exportToExcel, setPageSize, gotoPage, tableContainerRef }

  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '0',
    zIndex: 1,
    padding: '4px 10px',
    fontWeight: 'bold',
  }))

  const GroupItems = styled('ul')({
    padding: 0,
  })

  return (
    <>
      <div
        ref={tableContainerRef}
        className={`overflow-hidden flex flex-col relative`}
        style={{
          height: headerTitleSection
            ? '20%'
            : headerTabs && headerButtons
              ? 'calc(100% - 11% - 1dvh)'
              : 'calc(100% - 5% - 1dvh)'
        }}
      >
        <div
          ref={tableMiddleContainerRef}
          className={`h-full ${!page.length || !rows.length
            ? 'overflow-y-hidden'
            : (page.length <= 6
              ? 'overflow-y-hidden'
              : 'overflow-y-auto'
            )}`}
        >
          <table ref={tableRef} {...getTableProps()} className={`min-w-full h-full border-collapse overflow-auto ${tableWidth}`}>
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <tr key={i} {...headerGroup.getHeaderGroupProps()} className="sticky top-0 z-10 shadow-[_0px_2px_5px#00000015] bg-white">
                  {headerGroup.headers.map((column, i) => (
                    <th key={i} {...column.getHeaderProps(column.getSortByToggleProps())} style={{ minWidth: column.width ? column.width : "auto" }} className={`text-center h-[60px] font-medium text-base ${column.isSorted ? (column.isSortedDesc ? 'bg-gray-200' : 'bg-gray-100') : ''}`}>
                      <p className="m-0">
                        {column.render("Header")}
                        {column.isSorted ? (column.isSortedDesc ? <ExpandMoreIcon /> : <ExpandLessIcon />) : ''}
                      </p>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="h-full" ref={tableBodyRef}>
              {(!page.length || !rows.length) ? (
                <tr>
                  <td>
                    <div className="w-full flex items-center justify-center absolute">
                      No data
                    </div>
                  </td>
                </tr>
              ) : (
                  <>
                    {page.map((row, i) => {
                      prepareRow(row)
                      return (
                        <Fragment key={i}>
                          <tr key={i} {...row.getRowProps()}
                            onClick={() => selectRowFunc({
                              row,
                              funcionSetValue,
                              selectedRowIndex,
                              setselectedRowIndex
                            })}
                            className={`cursor-pointer border-b-2 ${row.isExpanded ? 'border-transparent' : 'border-grey-100'} transition-all duration-300 h-[60px] ${rowColorStyle(row, selectedRowIndex)}`}>
                            <TableRows row={row} cellMaxWidth={cellMaxWidth} />
                          </tr>
                          <ExpandableRow row={row} columns={columns} headerGroups={headerGroups} data={data} selectedRowIndex={selectedRowIndex} />
                        </Fragment>
                      )
                    })}
                    {emptyRow}
                </>
              )}
            </tbody>

            <tfoot className='sticky bottom-0 bg-white shadow-[#00000015_0px_-1px_5px]'>
              {footerGroups.map((group, i) => (
                <tr {...group.getFooterGroupProps()} key={i}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()} className="text-center">{column.render('Footer')}</td>
                  ))}
                </tr>
              ))}
              {headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className="text-center">

                      {
                        column.type === "string" && (
                        <TextField
                          label={column.render("Header")}
                          id={column.render("Header")}
                          value={column.filterValue || ""}
                          size="small"
                          fullWidth
                          onChange={(e) => handleFilterChange(e.target.value, column.id, setFilter, tableMiddleContainerRef, gotoPage)}
                        />
                        )
                      }

                      {
                        column.type === "list" && (
                          <Autocomplete
                            id="tags-outlined"
                            clearOnEscape
                            value={valorSelect || null}
                            getOptionLabel={(option) => option.label || ''}
                            options={(() => {
                              let uniqueOptions = [
                                ...new Set(data.map(obj => obj[column.render("id")])),
                                ...(column.customMultipleSortable ? column.customMultipleSortable.sortableKeys : [])
                              ]

                              if (Array.isArray(uniqueOptions)) {
                                uniqueOptions = [...new Set(uniqueOptions.flatMap(option => option))]
                              }

                              return uniqueOptions.map(option => {
                                const isSortableKey = column.customMultipleSortable?.sortableKeys.includes(option)
                                const group = isSortableKey ? 'Multiple' : 'Single'

                                return {
                                  label: option,
                                  group: column.customMultipleSortable ? group : '',
                                }
                              })
                            })()}
                            groupBy={(option) => option.group}
                            onChange={(event, value) => {
                              setValorSelect(value)

                              const selectedValue = value ? value.label : null
                              const isSortableKey = column.customMultipleSortable && column.customMultipleSortable.sortableKeys.includes(selectedValue)

                              handleFilterChange(isSortableKey ? column.customMultipleSortable[selectedValue] : selectedValue, column.id, setFilter, tableMiddleContainerRef, gotoPage)
                            }}
                            filterSelectedOptions
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            renderInput={(params) => (
                              <TextField {...params} label={column.render("Header")} size="small" fullWidth />
                            )}
                          />
                        )
                      }

                      {
                        column.type === "date" && (
                          <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label={column.render("Header")}
                              size="small"
                              fullWidth
                              id="dateExtra"
                              inputFormat="yyyy-MM-dd"
                              format="yyyy-MM-dd"
                              value={dateValue}
                              onChange={(newValue) => {
                                if (newValue && !isNaN(newValue.getTime())) {
                                  const localDate = dayjs(newValue).startOf('day')
                                  const formattedDate = localDate.format('YYYY-MM-DD')
                                  handleFilterChangeDate(formattedDate, column.id, "start", setFilter, gotoPage)
                                  setDateValue(formattedDate)
                                } else {
                                  handleFilterChangeDate("", column.id, "start", setFilter, gotoPage)
                                }
                              }}
                              renderInput={(params) => <TextField {...params} size="small" />}
                            />
                          </LocalizationProvider>
                        )
                      }

                      {
                        column.type === "dateExtra" && (
                          <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label={column.render("Header")}
                              size="small"
                              fullWidth
                              id="dateExtra"
                              inputFormat="yyyy-MM-dd"
                              format="yyyy-MM-dd"
                              value={dateValueExtra}
                              onChange={(newValue) => {
                                if (newValue && !isNaN(newValue.getTime())) {
                                  const localDate = dayjs(newValue).startOf('day')
                                  const formattedDate = localDate.format('YYYY-MM-DD')
                                  handleFilterChangeDate(formattedDate, column.id, "end", setFilter, gotoPage)
                                  setDateValueExtra(formattedDate)
                                } else {
                                  handleFilterChangeDate("", column.id, "end", setFilter, gotoPage)
                                }
                              }}
                              renderInput={(params) => <TextField {...params} size="small" />}
                            />
                          </LocalizationProvider>
                        )
                      }

                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
        </div>
        <div className={`w-full flex items-center justify-center`}>
          <CustomPagination {...CUSTOM_PAGINATION_PROPS} />
        </div>
      </div>
    </>
  )
}
