import { useState } from 'react'
import {
  FormControl,
  TextField,
  Autocomplete,
  Alert,
  Grid,
  Chip,
  Typography,
} from '@mui/material'
import { useHandleMissionChange } from 'components/TabsExcel/RiskManagement/hooks/RyO/RyOMainSectionHooks'

// Date Related
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { es } from 'date-fns/locale'
import dayjs from 'dayjs'

const RODescription = ({
  revalorando,
  riesgoActual,
  setRiesgoActual,
  opportunityActual,
  setopportunityActual,
  categoriaActual,
  setCategoriaActual,
  fechaDeteccionActual,
  setFechaDeteccionActual,
  processSelectedState,
  setprocessSelectedState,
  subProcessRelatedState,
  setsubProcessRelatedState,
  subProcessSelectedState,
  setsubProcessSelectedState,
  rulesActual,
  setRulesActual,
  missionsWithAnfes,
  missionSelected,
  setmissionSelected,
  handleMissionChange,
  missionSelectedCantHaveChildren,
  setmissionSelectedCantHaveChildren,
  secMissionsSelected,
  handleSecMissionsChange,
  tipoCategoriasAPI,
  categoriaListAPI,
  processListAPI,
  subProcessListAPI,
  rulesListAPI,
  modoRyO,
  observacionesActual,
  setObservacionesActual,
}) => {

  return (
    <>
      <Alert severity="info" variant="outlined" className={`rounded-md py-2 mb-5 ${revalorando ? '' : 'hidden'}`}>
        You are reevaluating the R&O as all actions created have been completed.
      </Alert>

      <Grid container spacing={1}>
        <Grid item container spacing={1} xs={12}>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={revalorando}
              multiline
              label="Risk description"
              id="riesgo-description"
              value={riesgoActual}
              size="small"
              fullWidth
              rows={4}
              onChange={(e) => setRiesgoActual(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              disabled={revalorando}
              multiline
              label="Opportunity description"
              id="opportunity"
              value={opportunityActual}
              size="small"
              fullWidth
              rows={4}
              onChange={(e) => setopportunityActual(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={1} xs={12}>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                fullWidth
                id='categoria-autocomplete'
                name='categoria-autocomplete'
                options={tipoCategoriasAPI.flatMap((elemento) => {
                  return categoriaListAPI
                    .filter((item) => item.tipo === elemento)
                    .map((subCategory) => ({
                      id: subCategory.id,
                      title: subCategory.titulo,
                      group: elemento,
                    }))
                })}
                groupBy={(option) => option.group}
                getOptionLabel={(option) => option.title}
                value={
                  categoriaActual
                    ? {
                      id: categoriaActual,
                      title: categoriaListAPI.find((cat) => cat.id === categoriaActual)?.titulo,
                    }
                    : null
                }
                onChange={(event, newValue) => setCategoriaActual(newValue ? newValue.id : '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Category"
                    id='categoria-text'
                    name='categoria-text'
                    disabled={revalorando}
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderGroup={(params) => (
                  <li key={params.key} className='pt-10 first:pt-0'>
                    <Typography variant="body1" fontWeight="bold" className='px-4 bg-[#252f3e] text-white'>
                      {params.group}
                    </Typography>
                    <ul className='pl-4'>{params.children}</ul>
                  </li>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Detection date"
                id='fechaDeteccion-datepicker'
                name='fechaDeteccion-datepicker'
                value={fechaDeteccionActual || null}
                onChange={(newValue) => {
                  if (newValue && !isNaN(newValue.getTime())) {
                    const localDate = dayjs(newValue).startOf('day')
                    const formattedDate = localDate.format('YYYY-MM-DD')
                    setFechaDeteccionActual(formattedDate)
                  } else {
                    setFechaDeteccionActual(null)
                  }
                }}
                renderInput={(params) => <TextField
                  id='fechaDeteccion-text'
                  name='fechaDeteccion-text'
                  {...params} fullWidth
                />}
                disabled={revalorando}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid item container spacing={1} xs={12}>
          <Grid item container spacing={1} xs={12} md={6}>
            <Grid item xs={12} sm={subProcessRelatedState ? 6 : 12}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  fullWidth
                  id='proceso-autocomplete'
                  name='proceso-autocomplete'
                  options={processListAPI.filter((process) => process.active)}
                  getOptionLabel={(option) => option.nombre_proceso}
                  value={
                    processListAPI.find((process) => process.id === processSelectedState) || null
                  }
                  onChange={(event, newValue) => {
                    const selectedProcessId = newValue ? newValue.id : ''
                    setprocessSelectedState(selectedProcessId)

                    const relatedSubProcesses = subProcessListAPI?.filter(
                      (subProcess) => subProcess?.id_proceso === selectedProcessId,
                    )

                    if (relatedSubProcesses?.length) {
                      setsubProcessRelatedState(relatedSubProcesses)
                    } else {
                      setsubProcessRelatedState(null)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id='proceso-text'
                      name='proceso-text'
                      variant="outlined"
                      label="Process"
                      disabled={revalorando}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            {subProcessRelatedState && (
              <Grid item xs={6} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    fullWidth
                    id='subproceso-autocomplete'
                    name='subproceso-autocomplete'
                    options={subProcessRelatedState.filter((subprocess) => subprocess.active)}
                    getOptionLabel={(option) => option.nombre_sub_proceso}
                    value={
                      subProcessRelatedState.find(
                        (subprocess) => subprocess.id === subProcessSelectedState,
                      ) || null
                    }
                    onChange={(event, newValue) =>
                      setsubProcessSelectedState(newValue ? newValue.id : '')
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id='subproceso-text'
                        name='subproceso-text'
                        variant="outlined"
                        label="Subprocess"
                        disabled={revalorando}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                fullWidth
                options={rulesListAPI}
                getOptionLabel={(option) => `${option.code} - ${option.name}`}
                id='rules-autocomplete'
                name='rules-autocomplete'
                value={rulesListAPI.find((rule) => rule.id === rulesActual) || null}
                onChange={(event, newValue) => setRulesActual(newValue ? newValue.id : '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id='rules-text'
                    name='rules-text'
                    variant="outlined"
                    label="Rules / Regulations affected"
                    disabled={revalorando}
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item container spacing={1} xs={12}>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                fullWidth
                options={missionsWithAnfes || []}
                getOptionLabel={(option) => option.name}
                id='mission-autocomplete'
                name='mission-autocomplete'
                value={missionsWithAnfes.find((mission) => mission.id === missionSelected) || null}
                onChange={(event, newValue) => handleMissionChange(newValue || null)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id='mission-text'
                    name='mission-text'
                    variant="outlined"
                    label="Main Mission / Department"
                    disabled={revalorando}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                multiple
                options={missionsWithAnfes?.filter((mission) => mission.id !== missionSelected) || []}
                getOptionLabel={(option) => option.name}
                id='secMissions-autocomplete'
                name='secMissions-autocomplete'
                value={secMissionsSelected || []}
                onChange={(event, newValue) => handleSecMissionsChange(newValue)}
                renderTags={(selected, getTagProps) =>
                  selected.map((option, index) => (
                    <Chip key={option.id} label={option.name} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    id='secMissions-text'
                    name='secMissions-text'
                    {...params}
                    variant="outlined"
                    label="Affected FMEAs"
                  />
                )}
                disabled={!missionSelected || revalorando || missionSelectedCantHaveChildren}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterSelectedOptions
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <TextField
              id="observaciones"
              name='observaciones'
              label="Remarks"
              multiline
              rows={3}
              name='observaciones'
              value={observacionesActual}
              size="small"
              fullWidth
              onChange={(e) => setObservacionesActual(e.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

export default RODescription
