import { CSSTransition } from 'react-transition-group'
import { useState } from 'react'
import Grid from '@mui/material/Grid'
import { renderExpandedContent, rowColorStyle } from '../utils/functions'

const ExpandableRow = ({ row, columns, headerGroups, data, selectedRowIndex }) => {
    const duration = 300

    const defaultStyle = {
        transition: `max-height ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
        maxHeight: 0,
        opacity: 0,
        overflow: 'hidden',
    }

    const transitionStyles = {
        entering: { maxHeight: '500px', opacity: 1 },
        entered: { maxHeight: '500px', opacity: 1 },
        exiting: { maxHeight: 0, opacity: 0 },
        exited: { maxHeight: 0, opacity: 0 },
    }

    return (
        <tr className={`${!row.isExpanded && 'p-0 m-0 h-[0px]'}`}>
            <td colSpan={columns.length + 1} className={`transition-all duration-500 ${row.isExpanded ? 'pb-2' : ''} p-0`}>
                <CSSTransition
                    in={row.isExpanded}
                    timeout={duration}
                    unmountOnExit
                >
                    {state => (
                        <div
                            className={`shadow-[_0px_3px_5px#00000015] ${rowColorStyle(row, selectedRowIndex)}`}
                            style={{
                                ...defaultStyle,
                                ...transitionStyles[state],
                            }}
                        >
                            <Grid container spacing={2} columns={12} className="w-full pb-24 m-auto border-b-2 border-gray-200 bg-transparent">
                                {renderExpandedContent(headerGroups, data[row.index])}
                            </Grid>
                        </div>
                    )}
                </CSSTransition>
            </td>
        </tr>
    )
}
export default ExpandableRow
