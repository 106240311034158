import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { getCookie } from 'app/js/generalFunctions'

const TRAINING_URL = `${process.env.REACT_APP_URL_DJANGO}/api/training/`
const ANNOUNCEMENTS_URL = `${process.env.REACT_APP_URL_DJANGO}/api/announcements/`

const getHeaders = () => ({ Authorization: `Token ${getCookie('token')}` })

export const fetchCourses = createAsyncThunk(
  'training/fetchCourses',
  async (staffForSearch, { rejectWithValue }) => {
    try {
      const staffQueryString = new URLSearchParams({ staff: staffForSearch.join(',') })
      const { data } = await axios.get(`${TRAINING_URL}?${staffQueryString}`, {
        headers: getHeaders(),
      })
      return data
    } catch (e) {
      console.error(e)
      return rejectWithValue([])
    }
  },
)

export const fetchAnnouncements = createAsyncThunk(
  'announcements/fetchAnnouncements',
  async (staffForSearch, { rejectWithValue }) => {
    try {
      const staffQueryString = new URLSearchParams({ staff: staffForSearch.join(',') })
      const { data } = await axios.get(`${ANNOUNCEMENTS_URL}?${staffQueryString}`, {
        headers: getHeaders(),
      })
      return data
    } catch (e) {
      console.error(e)
      return rejectWithValue([])
    }
  },
)
