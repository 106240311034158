import { useEffect } from 'react'
import Pagination from '@mui/material/Pagination'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import DownloadIcon from '@mui/icons-material/Download'
import ClearIcon from '@mui/icons-material/Clear'
import { deleteFilterTable, scrollToTop } from "../utils/functions"

export const CustomPagination = ({
  setValorSelect,
  setDateValue,
  setDateValueExtra,
  setFilter,
  tableContainerRef,
  tableMiddleContainerRef,
  pageIndex,
  rows,
  pageSize,
  filters,
  page,
  columns,
  data,
  resetTableRowSelection,
  selectedRowIndex,
  setselectedRowIndex,
  exportToExcel,
  setPageSize,
  gotoPage,
  setActiveFilters
}) => {
  const DELETE_FILTER_TABLE_PARAMS = { columns, setValorSelect, setDateValue, setDateValueExtra, setFilter, tableContainerRef, tableMiddleContainerRef, setActiveFilters }
  const pageCount = Math.ceil(rows.length / pageSize)

  const recordTextNoFilters = `Total records: ${data.length}`
  const recordsWithFiltersText = `Records: ${rows.length} of ${data.length}`
  const recordsText = filters.length ? recordsWithFiltersText : recordTextNoFilters

  const handleUnselectRow = () => {
    resetTableRowSelection()
    setselectedRowIndex(null)
  }

  useEffect(() => {
    if (filters.length) {
      handleUnselectRow()
    }
  }, [filters.length])


  return (
    <div className="w-full flex flex-row items-center justify-between p-4 bg-white border-b border-gray-200 rounded-lg shadow-[0px_10px_30px_2px_#00000010]">
      <div className="flex items-center gap-2">
        <span className="text-gray-700 mr-4">{recordsText}</span>

        {filters.length > 0 && (
          <Button
            size="small"
            variant="contained"
            color="warning"
            onClick={() => deleteFilterTable(DELETE_FILTER_TABLE_PARAMS)}
          >
            Delete filter
          </Button>
        )}
      </div>

      <div className="flex items-center gap-4">
        {selectedRowIndex !== null && (
          <Tooltip title="Unselect record" placement="top">
            <ClearIcon
              variant="outlined"
              onClick={() => { handleUnselectRow() }}
              className='cursor-pointer text-gray-600 hover:text-gray-800 transition-colors'
            />
          </Tooltip>
        )}

        {filters.length > 0 && (
          <Tooltip title="Download filtered table" placement="top">
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => exportToExcel({ columns, data: rows.map(row => row.original) })}
            >
              Download filtered data
            </Button>
          </Tooltip>
        )}

        <Tooltip title="Download table" placement="top">
          <DownloadIcon
            variant="outlined"
            onClick={() => exportToExcel({ columns, data })}
            className='cursor-pointer text-gray-600 hover:text-gray-800 transition-colors'
          />
        </Tooltip>

        <FormControl variant="standard" className="min-w-48">
          <Select
            labelId="page-size-select-label"
            id="page-size-select"
            name="pageSize"
            value={pageSize}
            label="Page Size"
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="px-2 py-2"
          >
            {[10, 30, 50, 100].map(size => (
              <MenuItem key={size} value={size}>{size}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Pagination
          className="flex-shrink-0"
          color="primary"
          count={pageCount}
          page={pageIndex + 1}
          onChange={(event, value) => {
            gotoPage(value - 1)
            scrollToTop(tableMiddleContainerRef)
          }}
          sx={{
            '& ul': {
              flexWrap: 'nowrap'
            }
          }}
        />
      </div>
    </div>
  )
}
