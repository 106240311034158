import { Tooltip, IconButton } from '@mui/material'
import { useDispatch } from "react-redux"
import HistoryIcon from '@mui/icons-material/History'
import { getRecordHistoryThunk } from 'app/store/general_states/record_history/record_history_slice.js'


export const RecordHistoryButton = ({ modelName, recordId }) => {
    const dispatch = useDispatch()
    const searchHistory = () => { dispatch(getRecordHistoryThunk({ modelName, recordId })) }

    return (
        <Tooltip
            title="Record history"
            placement="top"
            className='p-0 m-0 text-gray-900 hover:text-gray-800 transition-color duration-75'
        >
            <IconButton aria-label="" onClick={searchHistory}>
                <HistoryIcon />
            </IconButton>
        </Tooltip>
    )
}
