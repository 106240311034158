import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { showMessage } from 'app/store/fuse/messageSlice'
import {
  GET_ACTIONS_BY_RO_ID,
  POST_ACTIONS_RO,
  PUT_ACTIONS_RO,
  DELETE_ACTIONS_RO,
  GET_ALL_RO_ACTIONS,
  PATCH_FMEA_REVIEW_DATE,
} from '../utils/functions'
import {
  ERROR_TRY_AGAIN,
  SUCESS_MESSAGE_CREATED_ACTION_PLAN,
  SUCESS_MESSAGE_DELETED_ACTION_PLAN,
  SUCESS_MESSAGE_EDITED_ACTION_PLAN,
  SUCESS_MESSAGE_TOGGLE_STATUS_ACTION_PLAN,
} from '../utils/const'
import { updateAnfeFromStructureAction } from '../../store/Anfe/actions'

export const fetchAllRyOActions = createAsyncThunk(
  'riskOpportunityActionPlan/fetchAllRyOActions',
  async (_, { rejectWithValue }) => {
    try {
      const config = GET_ALL_RO_ACTIONS()

      const response = await axios(config)

      if (response.status !== 200) throw new Error('Error retrieving data')

      return response.data
    } catch (error) {
      return rejectWithValue(
        error.message || 'Failed to fetch data'
      )
    }
  }
)

export const fetchRyOActionPlansByRyO = createAsyncThunk(
  'riskOpportunityActionPlan/fetchRyOActionPlansByRyO',
  async (id, { rejectWithValue }) => {
    try {
      const config = GET_ACTIONS_BY_RO_ID(id)

      const response = await axios(config)

      if (response.status !== 200) throw new Error('Error retrieving data')

      return response.data
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to fetch data')
    }
  },
)

export const postRyOActionPlan = createAsyncThunk(
  'riskOpportunityActionPlan/postRyOActionPlan',
  async (actionData, { rejectWithValue, dispatch }) => {
    const { data, extraData } = actionData
    try {
      const config = POST_ACTIONS_RO(data)

      const response = await axios(config)

      if (response.status !== 201) throw new Error('Error creating action plan')

      dispatch(showMessage(SUCESS_MESSAGE_CREATED_ACTION_PLAN))

      return { ...response.data, ...extraData }
    } catch (error) {
      dispatch(showMessage(ERROR_TRY_AGAIN))
      return rejectWithValue(error.message || 'Failed to create data')
    }
  },
)

export const putRyOActionPlan = createAsyncThunk(
  'riskOpportunityActionPlan/putRyOActionPlan',
  async (actionData, { rejectWithValue, dispatch }) => {
    const { id, data, extraData } = actionData
    try {
      const config = PUT_ACTIONS_RO(id, data)

      const response = await axios(config)

      if (response.status !== 200) throw new Error('Error saving action plan')

      if (!data.completed) {
        dispatch(showMessage(SUCESS_MESSAGE_EDITED_ACTION_PLAN))
      } else {
        dispatch(showMessage(SUCESS_MESSAGE_TOGGLE_STATUS_ACTION_PLAN))
      }

      return { ...response.data, ...extraData }
    } catch (error) {
      dispatch(showMessage(ERROR_TRY_AGAIN))
      return rejectWithValue(error.message || 'Failed to save data')
    }
  },
)

export const deleteRyOActionPlan = createAsyncThunk(
  'riskOpportunityActionPlan/deleteRyOActionPlan',
  async (actionData, { rejectWithValue, dispatch }) => {
    const { id } = actionData
    try {
      const config = DELETE_ACTIONS_RO(id)

      const response = await axios(config)

      if (response.status !== 204) throw new Error('Error deleting action plan')

      dispatch(showMessage(SUCESS_MESSAGE_DELETED_ACTION_PLAN))

      return actionData
    } catch (error) {
      dispatch(showMessage(ERROR_TRY_AGAIN))
      return rejectWithValue(error.message || 'Failed to delete data')
    }
  },
)

export const updateFmeaReviewDate = createAsyncThunk(
  'riskOpportunityActionPlan/updateFmeaReviewDate',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const config = PATCH_FMEA_REVIEW_DATE(id)

      const response = await axios(config)

      if (response.status !== 200) throw new Error('Error saving action plan')

      dispatch(updateAnfeFromStructureAction(response.data))
      return response.data
    } catch (error) {
      dispatch(showMessage(ERROR_TRY_AGAIN))
      return rejectWithValue(error.message || 'Failed to save data')
    }
  },
)
