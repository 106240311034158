import { getUsersStringsInfo, getUserStringsInfo } from 'app/js/generalFunctions'
import { useReducer } from 'react'

const INITIAL_STATE = {
  improvementSelectedRow: null,
  improvementActionPlanSelectedRow: null,
  insertModalVisibility: false,
  insertModalInEditMode: false,
  peopleModalVisibility: false,
  completeImprovementModalVisibility: false,
  acceptDeclineModalVisibility: false,
  editAcceptedImprovementModalVisibility: false,
  actionPlanModalVisibility: false,
  actionPlanTableModalVisibility: false,
  actionSelectedRow: null,
  acceptDeclineModalValues: {
    improvementAccepted: null,
    declineReason: null,
    mftTeam: {
      manager: null,
      team: [],
    },
  },
  completeModalValues: {
    category: null,
    cost: null,
    benefits: null,
  },
  peopleModalValues: {
    missionManagers: [],
    applicantImprovement: null,
    membersImprovement: [],
    departmentsManagers: [],
    improvementActionMembers: [],
  },
  insertModalValues: {
    title: null,
    submission: null,
    mainUser: null,
    secondaryUser: [],
    currentSituation: null,
    futureSituation: null,
    observations: null,
  },
}

const ACTIONS_TYPES = {
  SET_INSERT_MODAL_VISIBILITY: 'SET_INSERT_MODAL_VISIBILITY',
  SET_INSERT_MODAL_EDIT_MODE: 'SET_INSERT_MODAL_EDIT_MODE',
  SET_ALL_VALUES_COMPLETE_MODAL: 'SET_ALL_VALUES_COMPLETE_MODAL',
  SET_PEOPLE_MODAL_VISIBILITY: 'SET_PEOPLE_MODAL_VISIBILITY',
  SET_COMPLETE_IMPROVEMENT_MODAL_VISIBILITY: 'SET_COMPLETE_IMPROVEMENT_MODAL_VISIBILITY',
  SET_ACCEPT_DECLINE_MODAL_VISIBILITY: 'SET_ACCEPT_DECLINE_MODAL_VISIBILITY',
  SET_EDIT_ACCEPTED_IMPROVEMENT_MODAL_VISIBILITY: 'SET_EDIT_ACCEPTED_IMPROVEMENT_MODAL_VISIBILITY',
  SET_ACTION_PLAN_MODAL_VISIBILITY: 'SET_ACTION_PLAN_MODAL_VISIBILITY',
  SET_ACTION_PLAN_TABLE_MODAL_VISIBILITY: 'SET_ACTION_PLAN_TABLE_MODAL_VISIBILITY',
  SET_IMPROVEMENT_SELECTED_ROW: 'SET_IMPROVEMENT_SELECTED_ROW',
  SET_IMPROVEMENT_ACTION_PLAN_SELECTED_ROW: 'SET_IMPROVEMENT_ACTION_PLAN_SELECTED_ROW',
  SET_PEOPLE_MODAL_VALUES: 'SET_PEOPLE_MODAL_VALUES',
  SET_INSERT_MODAL_VALUES: 'SET_INSERT_MODAL_VALUES',
  SET_MODAL_VALUES_IN_EDIT_MODE: 'SET_MODAL_VALUES_IN_EDIT_MODE',
  RESET_MODALS_VALUES: 'RESET_MODALS_VALUES',
  SET_COMPLETE_MODAL_VALUES: 'SET_COMPLETE_MODAL_VALUES',
  SET_ACCEPT_DECLINE_VALUES: 'SET_ACCEPT_DECLINE_VALUES',
  SET_MFT_TEAM: 'SET_MFT_TEAM',
  SET_ACTION_SELECTED_ROW: 'SET_ACTION_SELECTED_ROW',
}

const IMPROVEMENT_ACTIONS = {
  [ACTIONS_TYPES.SET_INSERT_MODAL_VISIBILITY]: (state, payload) => ({
    ...state,
    insertModalVisibility: payload,
  }),
  [ACTIONS_TYPES.SET_INSERT_MODAL_EDIT_MODE]: (state, payload) => ({
    ...state,
    insertModalInEditMode: payload,
  }),
  [ACTIONS_TYPES.SET_PEOPLE_MODAL_VISIBILITY]: (state, payload) => ({
    ...state,
    peopleModalVisibility: payload,
  }),
  [ACTIONS_TYPES.SET_COMPLETE_IMPROVEMENT_MODAL_VISIBILITY]: (state, payload) => ({
    ...state,
    completeImprovementModalVisibility: payload,
  }),
  [ACTIONS_TYPES.SET_ACCEPT_DECLINE_MODAL_VISIBILITY]: (state, payload) => ({
    ...state,
    acceptDeclineModalVisibility: payload,
  }),
  [ACTIONS_TYPES.SET_EDIT_ACCEPTED_IMPROVEMENT_MODAL_VISIBILITY]: (state, payload) => ({
    ...state,
    editAcceptedImprovementModalVisibility: payload,
  }),
  [ACTIONS_TYPES.SET_ACTION_PLAN_MODAL_VISIBILITY]: (state, payload) => ({
    ...state,
    actionPlanModalVisibility: payload,
  }),
  [ACTIONS_TYPES.SET_ACTION_PLAN_TABLE_MODAL_VISIBILITY]: (state, payload) => ({
    ...state,
    actionPlanTableModalVisibility: payload,
  }),
  [ACTIONS_TYPES.SET_IMPROVEMENT_SELECTED_ROW]: (state, payload) => ({
    ...state,
    improvementSelectedRow: payload,
  }),
  [ACTIONS_TYPES.SET_IMPROVEMENT_ACTION_PLAN_SELECTED_ROW]: (state, payload) => ({
    ...state,
    improvementActionPlanSelectedRow: payload,
  }),
  [ACTIONS_TYPES.SET_PEOPLE_MODAL_VALUES]: (state, payload) => {
    const {
      missionManagersPayload,
      applicantImprovementPayload,
      membersImprovementPayload,
      departmentsManagersPayload,
      improvementActionMembersPayload,
    } = payload

    return {
      ...state,
      peopleModalValues: {
        missionManagers: getUsersStringsInfo(missionManagersPayload),
        applicantImprovement: getUserStringsInfo(applicantImprovementPayload),
        membersImprovement: getUsersStringsInfo(membersImprovementPayload),
        departmentsManagers: getUsersStringsInfo(departmentsManagersPayload),
        improvementActionMembers: getUsersStringsInfo(improvementActionMembersPayload),
      },
    }
  },
  [ACTIONS_TYPES.SET_INSERT_MODAL_VALUES]: (state, payload) => {
    const { value, inputName } = payload
    return {
      ...state,
      insertModalValues: { ...state.insertModalValues, [inputName]: value },
    }
  },
  [ACTIONS_TYPES.SET_COMPLETE_MODAL_VALUES]: (state, payload) => {
    const { value, inputName } = payload
    return {
      ...state,
      completeModalValues: { ...state.completeModalValues, [inputName]: value },
    }
  },
  [ACTIONS_TYPES.RESET_MODALS_VALUES]: (state) => ({
    ...state,
    insertModalValues: INITIAL_STATE.insertModalValues,
    completeModalValues: INITIAL_STATE.completeModalValues,
    acceptDeclineModalValues: INITIAL_STATE.acceptDeclineModalValues,
  }),
  [ACTIONS_TYPES.SET_MODAL_VALUES_IN_EDIT_MODE]: (state, payload) => {
    const {
      titulo,
      submission_info,
      main_user_info,
      secundary_users_info,
      situacion_actual,
      situacion_futura,
      observations,
    } = payload
    return {
      ...state,
      insertModalValues: {
        title: titulo,
        submission: submission_info,
        mainUser: main_user_info,
        secondaryUser: secundary_users_info,
        currentSituation: situacion_actual,
        futureSituation: situacion_futura,
        observations: observations,
      },
    }
  },
  [ACTIONS_TYPES.SET_ALL_VALUES_COMPLETE_MODAL]: (state, payload) => {
    const { category, ...otherValues } = payload
    return {
      ...state,
      completeModalValues: { category, ...otherValues },
    }
  },
  [ACTIONS_TYPES.SET_ACCEPT_DECLINE_VALUES]: (state, payload) => {
    const { key, value } = payload
    return {
      ...state,
      acceptDeclineModalValues: {
        ...state.acceptDeclineModalValues,
        [key]: value,
      },
    }
  },
  [ACTIONS_TYPES.SET_MFT_TEAM]: (state, payload) => {
    const { key, value } = payload
    return {
      ...state,
      acceptDeclineModalValues: {
        ...state.acceptDeclineModalValues,
        mftTeam: { ...state.acceptDeclineModalValues.mftTeam, [key]: value },
      },
    }
  },
  [ACTIONS_TYPES.SET_ACTION_SELECTED_ROW]: (state, payload) => ({
    ...state,
    actionSelectedRow: payload ?? null,
  }),
}

const improvementReducer = (state, action) => {
  const { type, payload } = action
  return IMPROVEMENT_ACTIONS[type] ? IMPROVEMENT_ACTIONS[type](state, payload) : state
}

export const useImprovementReducer = () => {
  const [improvementState, dispatch] = useReducer(improvementReducer, INITIAL_STATE)

  const setInsertModalVisibility = (bool) =>
    dispatch({ type: ACTIONS_TYPES.SET_INSERT_MODAL_VISIBILITY, payload: bool })

  const setInsertModalEditMode = (bool) =>
    dispatch({ type: ACTIONS_TYPES.SET_INSERT_MODAL_EDIT_MODE, payload: bool })

  const setPeopleModalVisibility = (bool) =>
    dispatch({ type: ACTIONS_TYPES.SET_PEOPLE_MODAL_VISIBILITY, payload: bool })

  const setCompleteImprovementModalVisibility = (bool) =>
    dispatch({ type: ACTIONS_TYPES.SET_COMPLETE_IMPROVEMENT_MODAL_VISIBILITY, payload: bool })

  const setAcceptDeclineModalVisibility = (bool) =>
    dispatch({ type: ACTIONS_TYPES.SET_ACCEPT_DECLINE_MODAL_VISIBILITY, payload: bool })

  const setEditAcceptedImprovementModalVisibility = (bool) =>
    dispatch({ type: ACTIONS_TYPES.SET_EDIT_ACCEPTED_IMPROVEMENT_MODAL_VISIBILITY, payload: bool })

  const setActionPlanModalVisibility = (bool) =>
    dispatch({ type: ACTIONS_TYPES.SET_ACTION_PLAN_MODAL_VISIBILITY, payload: bool })

  const setActionPlanTableModalVisibility = (bool) =>
    dispatch({ type: ACTIONS_TYPES.SET_ACTION_PLAN_TABLE_MODAL_VISIBILITY, payload: bool })

  const setImprovementSelectedRow = (rowObj) =>
    dispatch({ type: ACTIONS_TYPES.SET_IMPROVEMENT_SELECTED_ROW, payload: rowObj ?? null })

  const setImprovementActionPlanSelectedRow = (rowObj) =>
    dispatch({
      type: ACTIONS_TYPES.SET_IMPROVEMENT_ACTION_PLAN_SELECTED_ROW,
      payload: rowObj ?? null,
    })

  const setPeopleModalValues = (peopleModalValues) =>
    dispatch({ type: ACTIONS_TYPES.SET_PEOPLE_MODAL_VALUES, payload: peopleModalValues })

  const setInserModalValues = ({ value, inputName }) =>
    dispatch({ type: ACTIONS_TYPES.SET_INSERT_MODAL_VALUES, payload: { value, inputName } })

  const setCompleteModalValues = ({ value, inputName }) =>
    dispatch({ type: ACTIONS_TYPES.SET_COMPLETE_MODAL_VALUES, payload: { value, inputName } })

  const setCompleteModalAllValues = ({ category, cost, benefits }) =>
    dispatch({
      type: ACTIONS_TYPES.SET_ALL_VALUES_COMPLETE_MODAL,
      payload: { category, cost, benefits },
    })

  const setAcceptDeclineValues = (payload) =>
    dispatch({ type: ACTIONS_TYPES.SET_ACCEPT_DECLINE_VALUES, payload })

  const setMftTeam = (payload) => dispatch({ type: ACTIONS_TYPES.SET_MFT_TEAM, payload })

  const setModalValuesInEditMode = (rowSelected) =>
    dispatch({ type: ACTIONS_TYPES.SET_MODAL_VALUES_IN_EDIT_MODE, payload: rowSelected })

  const resetImprovementModalsValues = () => dispatch({ type: ACTIONS_TYPES.RESET_MODALS_VALUES })

  const setActionSelectedRow = (rowObj) =>
    dispatch({ type: ACTIONS_TYPES.SET_ACTION_SELECTED_ROW, payload: rowObj ?? null })

  return {
    improvementState,
    setPeopleModalVisibility,
    setCompleteImprovementModalVisibility,
    setAcceptDeclineModalVisibility,
    setEditAcceptedImprovementModalVisibility,
    setActionPlanModalVisibility,
    setPeopleModalValues,
    setInsertModalVisibility,
    setInsertModalEditMode,
    setImprovementSelectedRow,
    setImprovementActionPlanSelectedRow,
    setActionPlanTableModalVisibility,
    setInserModalValues,
    resetImprovementModalsValues,
    setModalValuesInEditMode,
    setCompleteModalValues,
    setCompleteModalAllValues,
    setAcceptDeclineValues,
    setMftTeam,
    setActionSelectedRow,
  }
}
