import { column } from 'stylis'
import * as XLSX from 'xlsx'
import {
  Typography,
  Grid,
} from '@mui/material'

export const scrollToTop = (containerRef) => {
  if (containerRef.current) containerRef.current.scrollTop = 0
}

export const rowColorStyle = (row, selectedRowIndex) => {
  if (row.index === selectedRowIndex) {
    return `${row.isExpanded ? 'bg-blue-100 border-blue-100' : 'bg-blue-100'}`
  }
  return "even:bg-gray-50 odd:bg-white"
}

export const exportToExcel = ({ columns, data }) => {
  const firstRowWithHiddenData = data.find(row => row.hasOwnProperty('hidden_data'))
  const genericHiddenData = firstRowWithHiddenData?.hidden_data || null

  const { headers, accessors } = columns.reduce((acc, { Header, accessor }) => {
    acc.headers.push(Header)
    acc.accessors.push(accessor)
    return acc
  }, { headers: [], accessors: [] })

  const rowData = data.map(row => accessors.map(accessor => row[accessor]))
  const hiddenDataRows = genericHiddenData ? genericHiddenData.map(row => [...row]) : []
  const tableData = [...hiddenDataRows, headers, ...rowData]
  const ws = XLSX.utils.aoa_to_sheet(tableData)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
  XLSX.writeFile(wb, 'table_data.xlsx')
}

export const selectRowFunc = ({ row, funcionSetValue, selectedRowIndex, setselectedRowIndex }) => {
  if (row === null || !funcionSetValue)
    setselectedRowIndex(null)
  else {
    if (selectedRowIndex === row.index) {
      funcionSetValue(null)
      setselectedRowIndex(null)
    } else if (row.original?.id) {
      setselectedRowIndex(row.index)
      const selectedValue = row.original
      const indexValue = row.index
      funcionSetValue({ ...selectedValue, indexValue })
    }
  }
}

export const deleteFilterTable = ({ setValorSelect, setDateValue, setDateValueExtra, columns, setFilter, tableContainerRef, tableMiddleContainerRef }) => {
  setValorSelect(null)
  setDateValue(null)
  setDateValueExtra(null)

  columns.forEach(column => {
    if (column['type'] !== 'expand') {
      if (typeof column['accessor'] !== 'function') {
        setFilter(column['accessor'], undefined)
      } else {
        setFilter(column['id'], undefined)
      }
    }
  })

  scrollToTop(tableMiddleContainerRef)
};


export const handleFilterChangeDate = (filterValue, columnId, tipoRegistro, setFilter, gotoPage) => {
  if (filterValue !== "") {
    if (tipoRegistro === "start") {
      setFilter(columnId, filterValue)
      gotoPage(0)
    }
    if (tipoRegistro === "end") {
      setFilter(columnId, filterValue)
      gotoPage(0)
    }
  }
}

export const updateEmptyRows = (rowsLength, tableBodyRef, tableMiddleContainerRef, setEmptyRow, columns) => {
  if (!tableBodyRef.current) return

  const rowHeight = 60
  const totalHeight = rowHeight * 10
  const totalRowHeight = rowsLength * rowHeight

  if (!rowsLength || totalRowHeight >= totalHeight) {
    setEmptyRow(null)
    return
  }

  const diffHeight = Math.round(totalHeight - totalRowHeight)
  const minHeight = 20

  setEmptyRow(
    diffHeight > minHeight ? (
      <tr style={{ height: `${diffHeight}px` }} data-testid="empty-row">
        <td colSpan={columns.length}></td>
      </tr>
    ) : null
  )
}

export const handleFilterChange = (selectedValue, columnId, setFilter, tableContainerRef, gotoPage) => {
  setFilter(columnId, selectedValue || undefined)
  scrollToTop(tableContainerRef)
  gotoPage(0)
}

export const multiFilter = (rows, accessor, filterValue) => {
  if (!filterValue) return rows

  if (Array.isArray(filterValue)) {
    
    return rows.filter(row => filterValue.includes(row.original[accessor]))
  }


  return rows.filter(row => {
    const rowAccesor = row.original[accessor]
    if(!Array.isArray(rowAccesor)) return rowAccesor === filterValue
    else return  rowAccesor.includes(filterValue)
  })
}

export const getChipsProps = (cell, chip) => {
  const { value } = cell
  const isChipArray = Array.isArray(value)
  const propCondition = label => !isChipArray ? label === value : value.includes(label)
  const customCSS = chip.find(({ label }) => propCondition(label))?.customCSS ?? ''
  const color = chip.find(({label}) => propCondition(label))?.color ?? 'primary'
  const variant = chip.find(({label}) =>  propCondition(label))?.variant ?? 'outlined'
  const size = chip.find(({label}) =>  propCondition(label))?.size ?? 'small'

  const isCustomCSS = Boolean(customCSS);
  return {  value, customCSS, color, variant, size, isCustomCSS }
}

export const renderExpandedContent = (headerGroups, data) => {
  return headerGroups.map(headerGroup => {
    const expandColumn = headerGroup.headers.find(header => header.type === 'expand')
    if (expandColumn && expandColumn.expandData) {
      return expandColumn.expandData.map((item, index) => {
        const { title, dataName } = item

        let dataBody
        if (Array.isArray(dataName)) {
          dataBody = dataName.reduce((obj, key) => obj && obj[key], data)
        } else {
          dataBody = data[dataName]
        }

        return (
          dataBody && (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
              <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                {title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {dataBody}
              </Typography>
            </Grid>
          )
        )
      })
    }
    return null
  })
}
