//UPDATE RISK_MANAGEMENT
export const MOSTRAR_KPI = 'MOSTRAR_KPI'
export const MOSTRAR_KPI_EXITO = 'MOSTRAR_KPI_EXITO'
export const MOSTRAR_KPI_ERROR = 'MOSTRAR_KPI_ERROR'

//OPEN DIALOGO NUEVO USUARIO
export const CAMBIAR_MODAL_NEW_KPI = 'CAMBIAR_MODAL_NEW_KPI'
export const CAMBIAR_MODAL_NEW_KPI_EXITO = 'CAMBIAR_MODAL_NEW_KPI_EXITO'
export const CAMBIAR_MODAL_NEW_KPI_ERROR = 'CAMBIAR_MODAL_NEW_KPI_ERROR'

//SESION KPI
export const CAMBIAR_VALOR_SELECCION_GRID_KPI = 'CAMBIAR_VALOR_SELECCION_GRID_KPI'
export const CAMBIAR_VALOR_SELECCION_GRID_KPI_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_KPI_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_KPI_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_KPI_ERROR'

//UPDATE KPI
export const PUT_KPI = 'PUT_KPI'
export const PUT_KPI_EXITO = 'PUT_KPI_EXITO'
export const PUT_KPI_ERROR = 'PUT_KPI_ERROR'

//INSERTAR KPI
export const INSERTAR_NEW_KPI = 'INSERTAR_NEW_KPI'
export const INSERTAR_NEW_KPI_EXITO = 'INSERTAR_NEW_KPI_EXITO'
export const INSERTAR_NEW_KPI_ERROR = 'INSERTAR_NEW_KPI_ERROR'

//INSERTAR KPI
export const MOSTRAR_ALL_KPI = 'MOSTRAR_ALL_KPI'
export const MOSTRAR_ALL_KPI_EXITO = 'MOSTRAR_ALL_KPI_EXITO'
export const MOSTRAR_ALL_KPI_ERROR = 'MOSTRAR_ALL_KPI_ERROR'

// SET KPI STRUCTURE
export const SET_KPI_STRUCTURE_INIT = 'SET_KPI_STRUCTURE_INIT'
export const SET_KPI_STRUCTURE = 'SET_KPI_STRUCTURE'
export const SET_KPI_STRUCTURE_ERROR = 'SET_KPI_STRUCTURE_ERROR'
