import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { rolN3, rolN4 } from 'global.js';
import { getIdsFromArr } from "app/js/generalFunctions";
import { getInspectorsThunk, getInspectorBasicInfoThunk, getProgramsFromStructureThunk, postInspectorThunk, postInspectorJobThunk, getInspectorsContinuousEvaluationThunk, closeInpectorAuthThunk, editInspectorThunk } from "../store/AuthOfInspectorsThunk";
import {  setBelowPeopleInStructure, setCloseAuthModalVisibility, setContinuousEvaluationModalVisibility, setCreateInspectorModalVisibility, setDetailAuthTableVisibilityAction, setEditInspectorModalVisibility, setInspectorBasicListRowSelectedAction, setInspectorRowSelectedAction, setJobModalOnlyViewVisibility, setJobModalVisibility, setJobsTableVisibility, setUsersStructureUpperN0 } from "../store/AuthorizationOfInspectorsSlice";
import { showMessage } from "app/store/fuse/messageSlice";
import { INSPECTOR_AUTH_ALREDY_CLOSED, NO_ENOUGHT_CORRECT_JOBS_MESSAGE, NO_ENOUGHT_TIME_OPEN_JOB_MESSAGE, INSPECTOR_DOESNT_HAVE_SCOPE_COURSE, checkPreviousScopes, INPECTOR_NO_JOBS_MESSAGE } from "./functions";
import dayjs from "dayjs";
import { COMPLETE_ALL_FIELDS_MESSAGE, ERROR_MESSAGE_OBJ } from "app/js/globalConsts";
import { useGetPermissions } from "app/js/customHooks/PermissionsHooks";


// ! Main page

export const useGetBelowPeopleInStructure = () => {
    const { person: personLogin, departamentalAndNoDepartamentalWpsInfo: wpInfo, departamentalAndNoDepartamentalMissionsInfo: missionsInfo, departamentalAndNoDepartamentalSubMissionsInfo: subsInfo } = useSelector(({ fuse }) => fuse.userComponente)
    const dispatch = useDispatch()
    
    useEffect(() => {
        const getPossiblesInspectors = async () => {
            // Ç Si es manager devuelve employees
            // Ç Si es employee no devuelve nada
            // Ç Si no es manager ni imployee, esta en una categoria superior y devuelve todo
            if (!missionsInfo.length && !subsInfo.length && !wpInfo.length) return;
            const mergeInfoLists = [...wpInfo, ...missionsInfo, ...subsInfo];
            const allPeople = mergeInfoLists.map(({ staff }) => {
                const userIsManager = getIdsFromArr(staff.managers).includes(personLogin.id)
                const userIsEmployee = getIdsFromArr(staff.employees).includes(personLogin.id)
                if(userIsManager) return staff.employees
                else if(userIsEmployee) return []
                else return [...staff.managers, ...staff.employees]
            }).flat();
            const uniqueArray = [...new Map(allPeople.map(item => [item.id, item])).values()];
            dispatch(setBelowPeopleInStructure(uniqueArray))
        }
        getPossiblesInspectors()
    }, [missionsInfo, subsInfo, wpInfo]);
}

export const useGetInspectorsList =()=>{
    const {  belowPeopleInStructure, inspectorsList } = useSelector(({ fuse }) => fuse.inspectorsComponent)
    const dispatch = useDispatch()
    useEffect(()=>{
        if(!belowPeopleInStructure.length) return
        !inspectorsList.length && dispatch(getInspectorsThunk(belowPeopleInStructure))
    },[belowPeopleInStructure])
}

// export const useGetContinuousEvaluationInspectors =()=>{
//     const dispatch = useDispatch()
//     const {inspectorsList} =  useSelector(({ fuse }) => fuse.inspectorsComponent)

//     useEffect(()=>{
//         if(!inspectorsList.length) return
//         console.log("🚀 ~ useEffect ~ inspectorsList:", inspectorsList)
//         dispatch(getInspectorsContinuousEvaluationThunk(inspectorsList))
//     },[inspectorsList])
// }

// ! Top buttoms

export const useKnowIfPersonLoginCanAddInspectors = () => {
    const canAddInspectors = useGetPermissions('inspector')
    return {canAddInspectors}
}

export const useEditInspectorButtomVisibility =()=>{
    const { person: {id: personLoginId} } = useSelector(({ fuse }) => fuse.userComponente)
    const { inspectorBasicListRowSelected } = useSelector(({ fuse }) => fuse.inspectorsComponent)
    const [editButtomVisibility, setEditButtomVisibility] = useState(null)
    useEffect(()=>{
        if(!inspectorBasicListRowSelected) setEditButtomVisibility(false)
        else {
            const {tutor_info: {id: tutorId}} = inspectorBasicListRowSelected
            if(personLoginId !== tutorId) setEditButtomVisibility(false)
            else setEditButtomVisibility(true)
        }
    },[inspectorBasicListRowSelected])
    return {editButtomVisibility}

}

export const useSetDetailAuthTableVisibility =()=>{
    const dispatch = useDispatch()

    const setDetailAuthTableVisibility=(visibility)=>{
        dispatch(setDetailAuthTableVisibilityAction(visibility))
        !visibility && (dispatch(setInspectorBasicListRowSelectedAction(null)), dispatch(setInspectorRowSelectedAction(null)))
    }
    return {setDetailAuthTableVisibility}
}

export const useCloseAuthViewIcon = () => {
    const { inspectorRowSelected, jobsTableVisibility, inpectorDataDetailTable } = useSelector(({ fuse }) => fuse.inspectorsComponent)
    const [closeAuthIconView, setcloseAuthIconView] = useState(false)
    const { addInitialAuthJobIconVisibility } = useAddInitialAuthJobIconVisibility()

    useEffect(() => {
        if (!inspectorRowSelected || !addInitialAuthJobIconVisibility || jobsTableVisibility) {setcloseAuthIconView(false); return}
        const { final_date } = inspectorRowSelected
        setcloseAuthIconView(!final_date)
    }, [inspectorRowSelected, addInitialAuthJobIconVisibility])

    return closeAuthIconView
}

// Ç Comprobaciones: >= 3 meses proceso abierto | >= 3 trabajos OK | Scopes anteriores OK | Cursos scope OK
export const useCloseInspectorAuthModalVisibility = () => {
    const dispatch = useDispatch()
    const { inspectorRowSelected, inpectorSelectedJobsInfo, inspectorsList } = useSelector(({ fuse }) => fuse.inspectorsComponent)

    const opencloseInspectorAuthModal = ()=>{

        const {inspector: inspectorSelectedId, program: programSelectedId, inspector_info, program_info, initial_date, final_date, scope: scopeSelected, scope_course} = inspectorRowSelected
        const inspectorAuthAllRows = inspectorsList.filter(({inspector, program}) => inspector === inspectorSelectedId && program === programSelectedId)

        const {correctPerformanceJobsNumber} = inpectorSelectedJobsInfo
        if (correctPerformanceJobsNumber < 3) {
            dispatch(showMessage(NO_ENOUGHT_CORRECT_JOBS_MESSAGE(inspector_info, program_info, correctPerformanceJobsNumber)))
            return
        }

        const authMonthsOpen = dayjs().diff(dayjs(initial_date), 'month')
        if(authMonthsOpen<3){
            dispatch(showMessage(NO_ENOUGHT_TIME_OPEN_JOB_MESSAGE(inspector_info, program_info, authMonthsOpen)))
            return
        }

        if(!scope_course){
            dispatch(showMessage(INSPECTOR_DOESNT_HAVE_SCOPE_COURSE(inspector_info, scopeSelected)))
            return
        }
        
        let inspectorAuthAllRowsByScope = {}
        inspectorAuthAllRows.forEach(({scope, final_date})=> inspectorAuthAllRowsByScope[scope]= !!final_date)
        const [previousScopesCheck, errorMessage] =  checkPreviousScopes(scopeSelected, inspectorAuthAllRowsByScope)
        if(!previousScopesCheck){
            dispatch(showMessage({message: errorMessage, variant: 'error'}))
            return
        }

        dispatch(setCloseAuthModalVisibility(true))
    }

    return { opencloseInspectorAuthModal}
}


// export const useContinuousEvIconView = () => {
//     const { continuousEvInfo: { inspectorsIds: inspectorsIdsWithContinuousEv, programsIds: programsIdsContEv }, inspectorBasicListRowSelected, detailsAuthTableVisibility, jobsTableVisibility } = useSelector(({ fuse }) => fuse.inspectorsComponent)
//     const [continuousEvIconView, setContinuousEvIconView] = useState(false)
//     useEffect(() => {
//         if (!inspectorsIdsWithContinuousEv.length || !inspectorBasicListRowSelected || !detailsAuthTableVisibility || jobsTableVisibility) setContinuousEvIconView(false)
//         else {
//             const inspectorSelectedId = inspectorBasicListRowSelected.inspector_info.id
//             const programSelectedId = inspectorBasicListRowSelected.program_info.id
//             const inspectorSelectedMatchContEv = inspectorsIdsWithContinuousEv.includes(inspectorSelectedId) && programsIdsContEv.includes(programSelectedId)
//             setContinuousEvIconView(inspectorSelectedMatchContEv ? true : false)
//         }
//     }, [inspectorsIdsWithContinuousEv, detailsAuthTableVisibility, inspectorBasicListRowSelected])
//     return { continuousEvIconView }
// }

export const useContinuousEvIconView = () => {
    // const { continuousEvInfo: { inspectorsIds: inspectorsIdsWithContinuousEv, programsIds: programsIdsContEv }, inspectorBasicListRowSelected, detailsAuthTableVisibility, jobsTableVisibility, inpectorDataDetailTable } = useSelector(({ fuse }) => fuse.inspectorsComponent)
    const {  detailsAuthTableVisibility, jobsTableVisibility, inpectorDataDetailTable } = useSelector(({ fuse }) => fuse.inspectorsComponent)
    const [continuousEvIconView, setContinuousEvIconView] = useState(false)
    useEffect(() => {
        if ( !detailsAuthTableVisibility || jobsTableVisibility) setContinuousEvIconView(false)
        else {
            const moreThanOneAuthFinish = inpectorDataDetailTable.filter(({final_date})=> !!final_date).length > 1
            setContinuousEvIconView(moreThanOneAuthFinish ? true : false)
        }
    }, [detailsAuthTableVisibility, inpectorDataDetailTable])
    return { continuousEvIconView }
}


export const useAddInitialAuthJobIconVisibility=()=>{
    const { person } = useSelector(({ fuse }) => fuse.userComponente)
    // const { continuousEvIconView } = useContinuousEvIconView()
    const {  inspectorRowSelected, detailsAuthTableVisibility, jobsTableVisibility, inpectorDataDetailTable } = useSelector(({ fuse }) => fuse.inspectorsComponent)
    const userLoginId = person.id
    const [addInitialAuthJobIconVisibility, setAddInitialAuthJobIconVisibility] = useState(false)
    const allAthsClosed = useMemo(()=> inpectorDataDetailTable.every(({final_date})=> !!final_date) ,[inpectorDataDetailTable])

    useEffect(()=>{

        if(!inspectorRowSelected || !detailsAuthTableVisibility  || jobsTableVisibility || allAthsClosed){
            setAddInitialAuthJobIconVisibility(false)
            return
        }
        // const canAddJob =  inspectorRowSelected.tutor === userLoginId
        setAddInitialAuthJobIconVisibility(true)

    }, [inspectorRowSelected, detailsAuthTableVisibility])
    return {addInitialAuthJobIconVisibility}
}

export const useOpenJobsTable=()=>{
    const dispatch = useDispatch()
    const {inspectorBasicListRowSelected,inspectorJobsInJobsTable} = useSelector(({ fuse }) => fuse.inspectorsComponent)
    const openJobTable = useCallback(()=>{
        if(!inspectorBasicListRowSelected) return
        const {inspector_info, program_info} = inspectorBasicListRowSelected
        if(!inspectorJobsInJobsTable.length) {dispatch(showMessage(INPECTOR_NO_JOBS_MESSAGE(inspector_info, program_info))); return}
        dispatch(setJobsTableVisibility(true))
    },[inspectorJobsInJobsTable, inspectorBasicListRowSelected])
    return {openJobTable}

}

export const useCloseJobsTable =()=>{
    const dispatch = useDispatch()
    const closeJobTable = ()=>{
        dispatch(setJobsTableVisibility(false))
    }
    return {closeJobTable}
}



// ! Create inspector

export const useGetPrograms = () => {
    const dispatch = useDispatch()

    const { departamentalAndNoDepartamentalWpsInfo: wpInfo, departamentalAndNoDepartamentalWpsIds: wpsIds, departamentalAndNoDepartamentalMissionsInfo: missionsInfo, departamentalAndNoDepartamentalMissionsIds: missionsIds, departamentalAndNoDepartamentalSubMissionsInfo: subsInfo} = useSelector(({ fuse }) => fuse.userComponente)
    const[programsList, setProgramsList] = useState([])

    useEffect(() => {
        if (!missionsInfo.length && !subsInfo.length && !wpInfo.length) return;

        const getPrograms =async()=>{
            const missionsIdsFromSubmissions = subsInfo.map(({ id_mision }) => id_mision)
            const allMissionsIds = [...new Set([...missionsIds, ...missionsIdsFromSubmissions])]
            const wpsIdsFromMissions = missionsInfo.map(({ id_workPackage }) => id_workPackage)
            const allWpsIds = [...new Set([...wpsIds, ...wpsIdsFromMissions])]
            const programsRequest = await dispatch(getProgramsFromStructureThunk({allWpsIds, allMissionsIds}))
            setProgramsList(programsRequest.payload)
        }
        getPrograms()
    }, [missionsInfo, subsInfo]);
    return {programsList}
}

export const useGetPossibleInspectorBassicInfo =(inspectorSelected)=>{
    const dispatch = useDispatch()
    useEffect(()=>{
        if(!inspectorSelected) return
        dispatch(getInspectorBasicInfoThunk(inspectorSelected.id))
    },[inspectorSelected])
    
}

export const useCloseCreateInspectorModal =()=>{
    const dispatch = useDispatch()
    const closeModal = () => {dispatch(setCreateInspectorModalVisibility(false)); dispatch(setEditInspectorModalVisibility(false))}
    return {closeModal}
}

export const useSaveInspector =({ modalValues })=>{
    const dispatch = useDispatch()
    const {closeModal} = useCloseCreateInspectorModal()
    
    const saveInspector =async()=> {
        // {  dispach(setInspectorBasicListRowSelectedAction(value)) }
        // await dispach(setInspectorBasicListRowSelectedAction(null))
        const {inspectorSelected, programSelected, startDate} = modalValues
        if(!inspectorSelected || !programSelected || !startDate){
            dispatch(showMessage(COMPLETE_ALL_FIELDS_MESSAGE))
            return
        }
        const postInspectorResponse = await dispatch(postInspectorThunk(modalValues))
        if(postInspectorResponse.error) return
        closeModal()
    }
    return {saveInspector}
}


export const useEditInspector =({ modalValues }) => {
    const dispatch = useDispatch()
    const { inspectorBasicListRowSelected } = useSelector(({ fuse }) => fuse.inspectorsComponent)
    if(!inspectorBasicListRowSelected) return {editInspector: ()=>{}}
    const { inspectorBasicListRowSelected: {program_info, inspector_info} } = useSelector(({ fuse }) => fuse.inspectorsComponent)
    
    const {closeModal} = useCloseCreateInspectorModal()
    
    const editInspector =async()=> {
        const {inspectorSelected, programSelected, startDate} = modalValues
        if(!inspectorSelected || !programSelected || !startDate){
            dispatch(showMessage(COMPLETE_ALL_FIELDS_MESSAGE))
            return
        }
        const sameProgramAndInspectorAsPrev = program_info.id === programSelected.id && inspector_info.id === inspectorSelected.id
        const editInspectorResponse = await dispatch(editInspectorThunk({...modalValues, sameProgramAndInspectorAsPrev}))
        if(editInspectorResponse.error) return
        closeModal()
    }
    return {editInspector}
}

// ! Continuous evaluation modal

export const useCloseContinuousEvModal =()=>{
    const dispatch = useDispatch()
    const closeModal = () => {
        dispatch(setContinuousEvaluationModalVisibility(false))
        dispatch(setJobModalOnlyViewVisibility(false))
    }
    return {closeModal}

}

// ! Job modal 

export const useCloseJobModal=()=>{
    const dispatch = useDispatch()
    const closeModal = () => dispatch(setJobModalVisibility(false))
    return {closeModal}
}

// ! Job modal / Continuous evaluation job modal

export const usePostInspectorJob=()=>{
    const { createJobModalVisibility } = useSelector(({ fuse }) => fuse.inspectorsComponent)
    const dispatch = useDispatch()
    const {closeModal: closeJobModal} = useCloseJobModal()
    const {closeModal: closeContEvJobModal} = useCloseContinuousEvModal()

    const postInspectorJob =async(jobModalState)=> {
        const { inspectorSelected, tutorSelected, programSelected, scopeSelected, profile, order, operation, evaluatedActivities, deviations, comments, scopesSelected, operationPerformedCorrecly,  operationPerformedInCorrecly, jobDateSelected } = jobModalState
        const modalScopes = scopeSelected ?? scopesSelected
        const someScopeSelected = Object.entries(modalScopes).some(([scopeKey, value])=> !!value)
        if( !inspectorSelected || !tutorSelected || !programSelected || !someScopeSelected || !profile || !order || !operation || !evaluatedActivities || !deviations || !comments  || (!operationPerformedCorrecly && ! operationPerformedInCorrecly) || !jobDateSelected){
            dispatch(showMessage(COMPLETE_ALL_FIELDS_MESSAGE))
            return
        }
        const postJobResponse = await dispatch(postInspectorJobThunk(jobModalState))
        if(postJobResponse.error) return
        createJobModalVisibility ? closeJobModal() : closeContEvJobModal()
        
    }
    return {postInspectorJob}
}




// ! Close auth modal

export const useCloseAuthModal=()=>{
    const dispatch = useDispatch()
    const closeModal = () => dispatch(setCloseAuthModalVisibility(false))
    return {closeModal}
}

export const useCloseInspectorAuth=()=>{
    const dispatch = useDispatch()
    const {closeModal} = useCloseAuthModal()
    const { inspectorRowSelected } = useSelector(({ fuse }) => fuse.inspectorsComponent)

    const closeInspectorAuth =async(finalDate, comment)=>{
        if(!finalDate) {dispatch(showMessage(COMPLETE_ALL_FIELDS_MESSAGE)); return}
        const closeAuthResponse = await dispatch(closeInpectorAuthThunk({inspectorRowSelected, finalDate, comment}))
        if(!closeAuthResponse.error) closeModal()
 
    }
    return {closeInspectorAuth}
}


export const useGetUserStructureUpperN0=()=>{
    const dispatch = useDispatch()
    const { usersListAPI } = useSelector(({ fuse }) => fuse.userComponente)
    useEffect(()=>{
        if(!usersListAPI.length) return
        const usersUpperN0 =  usersListAPI.filter(({rolUser})=> rolUser!=='Technician')
        dispatch(setUsersStructureUpperN0(usersUpperN0))
    },[usersListAPI])
}