export const TABS_VALUES = {
  division: 'division',
  subDivision: 'subDivision',
  program: 'program',
  assembly: 'assembly',
  subAssembly: 'subAssembly',
}
export const TAB_LABEL = {
  division: 'Division',
  subDivision: 'Sub Division',
  program: 'Program',
  assembly: 'Assembly',
  subAssembly: 'Sub Assembly',
}

export const DEFAULT_TAB_VALUE_PROPS = {
  textColor: 'secondary',
  indicatorColor: 'secondary',
  ['aria-label']: 'secondary tabs example',
}
