import { column } from 'stylis'
import * as XLSX from 'xlsx'
import {
  Typography,
  Grid,
} from '@mui/material'

export const scrollToTop = (containerRef) => {
  if (containerRef.current) containerRef.current.scrollTop = 0
}

export const rowColorStyle = (row, selectedRowIndex) => {
  // Check if the row is selected
  if (row.index === selectedRowIndex) {
    return `${row.isExpanded ? 'bg-green-50' : 'bg-green-50'}`
  }

  const nprValue = parseInt(row.original.npr)
  if (nprValue > 100) {
    return row.visiblePosition % 2 === 0 ? 'bg-[#ffb8b8]' : 'bg-red-100'
  } else if (nprValue >= 90 && nprValue <= 100) {
    return row.visiblePosition % 2 === 0 ? 'bg-[#fde68a]' : 'bg-yellow-100'
  }

  return row.visiblePosition % 2 === 0 ? 'bg-gray-100' : 'bg-white'
}

export const exportToExcel = ({ columns, data }) => {
  const firstRowWithHiddenData = data.find((row) => row.hasOwnProperty('hidden_data'))
  const genericHiddenData = firstRowWithHiddenData?.hidden_data || null

  const filterExpander = columns.filter(column => column.id !== 'expander')

  const { headers, accessors } = filterExpander.reduce(
    (acc, { Header, accessor }) => {
      acc.headers.push(Header)
      acc.accessors.push(accessor)
      return acc
    },
    { headers: [], accessors: [] },
  )

  const rowData = data.map((row) =>
    accessors.map((accessor) => {
      const value = typeof accessor === 'function' ? accessor(row) : row[accessor]
      return Array.isArray(value) ? value.join(', ') : value
    })
  )
  const hiddenDataRows = genericHiddenData ? genericHiddenData.map((row) => [...row]) : []
  const tableData = [...hiddenDataRows, headers, ...rowData]
  const ws = XLSX.utils.aoa_to_sheet(tableData)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
  XLSX.writeFile(wb, 'table_data.xlsx')
}

export const selectRowFunc = ({ row, funcionSetValue, selectedRowIndex, setselectedRowIndex }) => {
  if (row === null || !funcionSetValue) setselectedRowIndex(null)
  else {
    if (selectedRowIndex === row.index) {
      funcionSetValue(null)
      setselectedRowIndex(null)
    } else if (row.original?.id) {
      setselectedRowIndex(row.index)
      const selectedValue = row.original
      const indexValue = row.index
      funcionSetValue({ ...selectedValue, indexValue })
    }
  }
}

export const deleteFilterTable = ({
  setValorSelect,
  setDateValue,
  setDateValueExtra,
  columns,
  setFilter,
  tableContainerRef,
  tableMiddleContainerRef,
  setActiveFilters
}) => {
  setValorSelect(null)
  setDateValue(null)
  setDateValueExtra(null)
  setActiveFilters([])

  columns.forEach((column) => {
    if (column['type'] !== 'expand') {
      if (typeof column['accessor'] !== 'function') {
        setFilter(column['accessor'], undefined)
      } else {
        setFilter(column['id'], undefined)
      }
    }
  })

  scrollToTop(tableMiddleContainerRef)
}

export const handleFilterChangeDate = (
  filterValue,
  columnId,
  tipoRegistro,
  setFilter,
  gotoPage,
) => {
  if (filterValue !== '') {
    if (tipoRegistro === 'start') {
      setFilter(columnId, filterValue)
      gotoPage(0)
    }
    if (tipoRegistro === 'end') {
      setFilter(columnId, filterValue)
      gotoPage(0)
    }
  }
}

export const updateEmptyRows = (
  rowsLength,
  tableBodyRef,
  tableMiddleContainerRef,
  tableHeadRef,
  tableFootRef,
  setEmptyRow,
  columns,
) => {
  if (
    !tableBodyRef.current ||
    !tableMiddleContainerRef.current ||
    !tableHeadRef.current ||
    !tableFootRef.current
  )
    return

  const headHeight = tableHeadRef.current.clientHeight
  const footHeight = tableFootRef.current.clientHeight
  const containerHeight = tableMiddleContainerRef.current.clientHeight

  const rowHeight = 60
  const totalRowHeight = rowsLength * rowHeight

  const availableSpace = containerHeight - headHeight - footHeight

  if (!rowsLength || totalRowHeight >= availableSpace || rowsLength >= 10) {
    setEmptyRow(null)
    return
  }

  const diffHeight = Math.max(availableSpace - totalRowHeight, 0)
  const minHeight = 20

  setEmptyRow(
    diffHeight > minHeight ? (
      <tr style={{ height: `${diffHeight}px` }} data-testid="empty-row">
        <td colSpan={columns.length}></td>
      </tr>
    ) : null,
  )
}

export const handleFilterChange = (
  selectedValue,
  columnId,
  setFilter,
  tableContainerRef,
  gotoPage,
) => {
  setFilter(columnId, selectedValue || undefined)
  scrollToTop(tableContainerRef)
  gotoPage(0)
}

export const multiFilter = (rows, accessor, filterValue) => {
  if (!filterValue) return rows

  if (Array.isArray(filterValue)) {
    return rows.filter((row) => filterValue.includes(row.original[accessor]))
  }

  return rows.filter((row) => {
    const rowAccesor = row.original[accessor]
    if (!Array.isArray(rowAccesor)) return rowAccesor === filterValue
    else return rowAccesor.includes(filterValue)
  })
}

export const getChipsProps = (cell, chip) => {
  const { value } = cell
  const isChipArray = Array.isArray(value)
  const propCondition = (label) => (!isChipArray ? label === value : value.includes(label))
  const customCSS = chip.find(({ label }) => propCondition(label))?.customCSS ?? ''
  const color = chip.find(({ label }) => propCondition(label))?.color ?? 'primary'
  const variant = chip.find(({ label }) => propCondition(label))?.variant ?? 'outlined'
  const size = chip.find(({ label }) => propCondition(label))?.size ?? 'small'

  const isCustomCSS = Boolean(customCSS)
  return { value, customCSS, color, variant, size, isCustomCSS }
}
