import {
    MOSTRAR_KPI,
    MOSTRAR_KPI_EXITO,
    MOSTRAR_KPI_ERROR,
    CAMBIAR_VALOR_SELECCION_GRID_KPI,
    CAMBIAR_VALOR_SELECCION_GRID_KPI_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_KPI_ERROR,
    PUT_KPI,
    PUT_KPI_EXITO,
    PUT_KPI_ERROR,
    INSERTAR_NEW_KPI,
    INSERTAR_NEW_KPI_EXITO,
    INSERTAR_NEW_KPI_ERROR,
    CAMBIAR_MODAL_NEW_KPI,
    CAMBIAR_MODAL_NEW_KPI_EXITO,
    CAMBIAR_MODAL_NEW_KPI_ERROR,
    MOSTRAR_ALL_KPI,
    MOSTRAR_ALL_KPI_EXITO,
    MOSTRAR_ALL_KPI_ERROR,
    SET_KPI_STRUCTURE_INIT,
    SET_KPI_STRUCTURE,
    SET_KPI_STRUCTURE_ERROR
} from './types';

// cada reducer tiene su propio state
const initialState = {
    kpiListAPI: [],
    allKpiListAPI: [],
    filaSeleccionadaGrid: '',
    error: null,
    errorKpiStructure: null,
    loadingKpi: false,
    loadingKpiStructure: false,
    loadingAllKpi: false,
    visibilidadNewKpi: false,
    modoDialogKpi: '',
    kpisICanSee: [],
    myKpis: [],
    notMyKpis: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        //********** MOSTRAR ALL KPI API **********************************/
        case MOSTRAR_ALL_KPI:
            return {
                ...state,
                loadingAllKpi: action.payload,
            };
        case MOSTRAR_ALL_KPI_EXITO:
            return {
                ...state,
                loadingAllKpi: false,
                error: null,
                allKpiListAPI: action.payload,
            };
        case MOSTRAR_ALL_KPI_ERROR:
            return {
                ...state,
                loadingAllKpi: false,
                error: action.payload,
            };

        //********** VISIBILIDAD MODAL INSERTAR **********************************/
        case CAMBIAR_MODAL_NEW_KPI:
            return {
                ...state,
                loading: action.payload,
            };
        case CAMBIAR_MODAL_NEW_KPI_EXITO:
            return {
                ...state,
                loading: false,
                visibilidadNewKpi: action.payload,
                modoDialogKpi: action.nombre,
            };
        case CAMBIAR_MODAL_NEW_KPI_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        //********** INSERTAR NEW KPI **********************************/
        case INSERTAR_NEW_KPI:
            return {
                ...state,
                loading: action.payload,
            };
        case INSERTAR_NEW_KPI_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case INSERTAR_NEW_KPI_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        //********** UPDATE KPI **********************************/
        case PUT_KPI:
            return {
                ...state,
                loading: action.payload,
            };
        case PUT_KPI_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case PUT_KPI_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        //********** MOSTRAR KPI API **********************************/
        case MOSTRAR_KPI:
            return {
                ...state,
                loadingKpi: action.payload,
            };
        case MOSTRAR_KPI_EXITO:
            return {
                ...state,
                loadingKpi: false,
                error: null,
                kpiListAPI: action.payload,
            };
        case MOSTRAR_KPI_ERROR:
            return {
                ...state,
                loadingKpi: false,
                error: action.payload,
            };

        //********** FILA SELECCIONADA GRID **********************************/
        case CAMBIAR_VALOR_SELECCION_GRID_KPI:
            return {
                ...state,
                loading: action.payload,
            };
        case CAMBIAR_VALOR_SELECCION_GRID_KPI_EXITO:
            return {
                ...state,
                loading: false,
                filaSeleccionadaGrid: action.payload,
            };
        case CAMBIAR_VALOR_SELECCION_GRID_KPI_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        //********** SET KPI STRUCTURE **********************************/
        case SET_KPI_STRUCTURE_INIT:
            return {
                ...state,
                loadingKpiStructure: action.payload,
            };
        case SET_KPI_STRUCTURE:
            const { kpisICanSee, myKpis, notMyKpis } = action.payload;
            return {
                ...state,
                loadingKpiStructure: false,
                kpisICanSee,
                myKpis,
                notMyKpis,
            }
        case SET_KPI_STRUCTURE_ERROR:
            return {
                ...state,
                loadingKpiStructure: false,
                errorKpiStructure: action.payload,
            };

        default:
            return state;
    }
}
