import {

    // COMPONENTE PRINCIPAL ------------------

    CAMBIAR_VALOR_VISIBILIDAD_DIVISION,
    CAMBIAR_VALOR_VISIBILIDAD_DIVISION_EXITO,
    CAMBIAR_VALOR_VISIBILIDAD_DIVISION_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_DIVISION,
    CAMBIAR_VALOR_SELECCION_GRID_DIVISION_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_DIVISION_ERROR,

    ELIMINAR_DIVISION,
    ELIMINAR_DIVISION_EXITO,
    ELIMINAR_DIVISION_ERROR,

    MOSTRAR_DIVISION_API,
    MOSTRAR_DIVISION_EXITO,
    MOSTRAR_DIVISION_ERROR,

    // MODAL INSERTAR  -----------------------

    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION_EXITO,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION_ERROR,

    INSERTAR_DIVISION_MODAL_INSERTAR,
    INSERTAR_DIVISION_MODAL_INSERTAR_EXITO,
    INSERTAR_DIVISION_MODAL_INSERTAR_ERROR,

    ACTUALIZAR_DIVISION_MODAL_INSERTAR,
    ACTUALIZAR_DIVISION_MODAL_INSERTAR_EXITO,
    ACTUALIZAR_DIVISION_MODAL_INSERTAR_ERROR,


} from './types';
import axios from 'axios'
import {getCookie} from 'app/js/generalFunctions'
import { showMessage } from 'app/store/fuse/messageSlice'

//Constantes
const urlSubdivisionApi = process.env.REACT_APP_URL_DJANGO + "/api/division/"


//************************CAMBIAR VISIBILIDAD ACTION**********************************************

export function cambiarValorVisibilidadAction(valorNuevo) {

    return (dispatch) => {
        dispatch (cambiarValorVisibilidad())

        try {
            dispatch (cambiarValorVisibilidadExito(valorNuevo))

        } catch (error) {

            dispatch (cambiarValorVisibilidadError(true))
        }

    }

}

const cambiarValorVisibilidad = () => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_DIVISION,
    payload: true

})

const cambiarValorVisibilidadExito = valorNuevo => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_DIVISION_EXITO,
    payload: valorNuevo

})

const cambiarValorVisibilidadError = estado => ({
  type: CAMBIAR_VALOR_VISIBILIDAD_DIVISION_ERROR,
  payload: estado
})

//************************ CAMBIAR SELECCION GRID ACTION**********************************************

export function cambiarValorSeleccionAction(valorNuevo) {

    return (dispatch) => {
        dispatch (cambiarValorSeleccion())

        try {
            dispatch (cambiarValorSeleccionExito(valorNuevo))

        } catch (error) {

            dispatch (cambiarValorSeleccionError(true))
        }

    }

}

const cambiarValorSeleccion = () => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_DIVISION,
    payload: false

})

const cambiarValorSeleccionExito = valorNuevo => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_DIVISION_EXITO,
    payload: valorNuevo

})

const cambiarValorSeleccionError = estado => ({
  type: CAMBIAR_VALOR_SELECCION_GRID_DIVISION_ERROR,
  payload: estado
})


//************************ ELIMINAR PROGRAMA **********************************************

export function eliminarDivisionAction(id) {

    return (dispatch) => {
        dispatch (eliminarDivision())

            
               axios.delete(urlSubdivisionApi + id)
                 .then((response) => {
                         dispatch (eliminarDivisionExito(response.data))
                 })
                 .catch(error => {
                    console.log(error.response)
                    dispatch (eliminarDivisionError(true))
                })

       
      }
}

const eliminarDivision = (id) => ({
    type: ELIMINAR_DIVISION,
    payload: id

})

const eliminarDivisionExito = estado => ({
    type: ELIMINAR_DIVISION_EXITO,
    payload: estado

})

const eliminarDivisionError = estado => ({
    type:  ELIMINAR_DIVISION_ERROR,
    payload: estado
})



//************************ MOSTRAR PROGRAMAS API **********************************************

export function mostrarDivisionsAPIAction() {

    return async (dispatch) => {
        dispatch (mostrarDivisionsAPI(true))

        
            await axios({
                method: "GET",
                url: urlSubdivisionApi,
                headers: {
                    'Authorization': `Token ${getCookie('token')}` 
                        }
                
                    })
                     .then(response=>{
                        dispatch (mostrarDivisionsAPIExito(response.data))
                    })
                    .catch(error => {
                        console.log(error.response)
                        dispatch (mostrarDivisionsAPIError(true))

                        
                    })

          }
}

const mostrarDivisionsAPI = () => ({
    type: MOSTRAR_DIVISION_API,
    payload: true

})

const mostrarDivisionsAPIExito = programas => ({
    type: MOSTRAR_DIVISION_EXITO,
    payload: programas

})

const mostrarDivisionsAPIError = estado => ({
  type: MOSTRAR_DIVISION_ERROR,
  payload: estado
})

//************************CAMBIAR VISIBILIDAD MODAL INSERTAR**********************************************

export function cambiarVisibilidadModalInsertarAction(valorNuevo, modoApertura) {

    return (dispatch) => {
        dispatch (cambiarVisibilidadModalInsertar())

        try {
            dispatch (cambiarVisibilidadModalInsertarExito(valorNuevo, modoApertura))

        } catch (error) {

            dispatch (cambiarVisibilidadModalInsertarError(true))
        }
    }
}

const cambiarVisibilidadModalInsertar = () => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION,
    payload: true

})

const cambiarVisibilidadModalInsertarExito = (valorNuevo, modoApertura) => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION_EXITO,
    payload: valorNuevo,
    visibilidadModal: modoApertura

})

const cambiarVisibilidadModalInsertarError = estado => ({
  type:  CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION_ERROR,
  payload: estado
})

//************************ INSERTAR PROGRAMA MODAL INSERTAR**********************************************

export function insertarDivisionModalInsertarAction(programa) {

    return  (dispatch) => {
        dispatch (insertarDivisionModalInsertar())

            
             axios({
                    method: "POST",
                    url: urlSubdivisionApi,
                    data: programa,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (insertarDivisionModalInsertarExito(response.data))

                 dispatch(
                    showMessage({
                        message: "Successfully created",
                        variant: "success"
                    })
                )

                 dispatch (mostrarDivisionsAPIAction())
             })
             .catch(error => {
                console.log(error.response)
                dispatch (insertarDivisionModalInsertarError(true))

                dispatch(
                    showMessage({
                        message: "Error creating",
                        variant: "error"
                    })
                )
            })

    }
}

const insertarDivisionModalInsertar = (programa) => ({
    type: INSERTAR_DIVISION_MODAL_INSERTAR,
    payload: programa

})

const insertarDivisionModalInsertarExito = estado => ({
    type: INSERTAR_DIVISION_MODAL_INSERTAR_EXITO,
    payload: estado

})

const insertarDivisionModalInsertarError = estado => ({
    type:  INSERTAR_DIVISION_MODAL_INSERTAR_ERROR,
    payload: estado
})

//************************ INSERTAR PROGRAMA MODAL INSERTAR**********************************************

export function actualizarDivisionModalInsertarAction(id, programa) {

    return  (dispatch) => {
        dispatch (actualizarDivisionModalInsertar());

            
             axios({
                    method: "PUT",
                    url: urlSubdivisionApi+id,
                    data: programa,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (actualizarDivisionModalInsertarExito(response.data));

                 if(response.data['active'] == false){
                    dispatch(
                        showMessage({
                            message: "Delete Division",
                            variant: "success"
                        })
                    )
                }
                else{

                 dispatch(
                    showMessage({
                        message: "Updated Division",
                        variant: "success"
                    })
                 ) 
                }
                 

                 dispatch (mostrarDivisionsAPIAction())
             })
             .catch(error => {
                console.log(error.response)
                dispatch (actualizarDivisionModalInsertarError(true))

                dispatch(
                    showMessage({
                        message: "Error when updating Division",
                        variant: "error"
                    })
                )
            })

    }
}

const actualizarDivisionModalInsertar = (programa) => ({
    type: ACTUALIZAR_DIVISION_MODAL_INSERTAR,
    payload: programa

})

const actualizarDivisionModalInsertarExito = estado => ({
    type: ACTUALIZAR_DIVISION_MODAL_INSERTAR_EXITO,
    payload: estado

})

const actualizarDivisionModalInsertarError = estado => ({
    type:  ACTUALIZAR_DIVISION_MODAL_INSERTAR_ERROR,
    payload: estado
})

