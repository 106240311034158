
import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_DIRECTORATE_REQUEST_OBJ, POST_DIRECTORATE_REQUEST_OBJ, PUT_DIRECTORATE_REQUEST_OBJ } from './utils/functions'
import axios from 'axios'
import { ERROR_TRY_AGAIN, SUCESS_MESSAGE_CREATED_DIRECTORATE, SUCESS_MESSAGE_EDITED_DIRECTORATE } from './utils/const'
import { showMessage } from 'app/store/fuse/messageSlice'

export const getDirectorate = createAsyncThunk(
    'directorate/getDirectorate',
    async (_, { rejectWithValue }) => {
        try {
            const config = GET_DIRECTORATE_REQUEST_OBJ()

            const response = await axios(config)

            if (response.status !== 200) throw new Error(`Error: Received status code ${response.status}`)

            return response.data
        } catch (error) {
            return rejectWithValue(
                error || 'Failed to fetch data'
            )
        }
    }
)

export const postDirectorate = createAsyncThunk(
    'directorate/postDirectorate',
    async (directorateData, { rejectWithValue, dispatch }) => {
        const { name_subdivision } = directorateData
        try {
            const config = POST_DIRECTORATE_REQUEST_OBJ(directorateData)

            const response = await axios(config)

            if (response.status !== 201) throw new Error(`Error: Received status code ${response.status}`)

            dispatch(showMessage(SUCESS_MESSAGE_CREATED_DIRECTORATE))

            const data = { ...response.data, name_subdivision }
            return data
        } catch (error) {
            dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(error || 'Failed to create directorate')
        }
    }
)

export const putDirectorate = createAsyncThunk(
    'directorate/putDirectorate',
    async (directorateData, { rejectWithValue, dispatch }) => {
        const { id, name_subdivision } = directorateData
        try {
            const config = PUT_DIRECTORATE_REQUEST_OBJ(directorateData)

            const response = await axios(config)

            if (response.status !== 200) throw new Error(`Error: Received status code ${response.status}`)

            dispatch(showMessage(SUCESS_MESSAGE_EDITED_DIRECTORATE))

            const data = { ...response.data, name_subdivision }
            return data
        } catch (error) {
            dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(error || 'Failed to update directorate')
        }
    }
)
