import { TextField } from '@mui/material';

export const ModalTexField=({label, value, onChangeFunc, rows = 1, disabled= false})=>(
    <TextField
    label={label}
    id={label}
    value={value}
    size="small"
    fullWidth={true}
    onChange={e => onChangeFunc(e.target.value)}
    multiline
    rows={rows}
    disabled={disabled}
/>
)