export const DEPARTMENT_MISSION_URL = process.env.REACT_APP_URL_DJANGO + "/api/mision/funcionDepartamentos/"
export const AGRESSO_CODES_URL = process.env.REACT_APP_URL_DJANGO + "/api/agresso/contract/"
export const VISUAL_TIME_GROUPS_URL = process.env.REACT_APP_URL_DJANGO + "/api/agresso/vt_group/"
export const SITES_URL = process.env.REACT_APP_URL_DJANGO + "/api/locationCustomer/"
export const MISSION_URL = process.env.REACT_APP_URL_DJANGO + "/api/mision/"

export const SUCESS_MESSAGE_CREATED_TEAM = {
    message: "Created team",
    variant: "success"
}

export const SUCESS_MESSAGE_EDITED_TEAM = {
    message: "Edited team",
    variant: "success"
}

export const ERROR_TRY_AGAIN = {
    message: "Error, try again",
    variant: "error"
}
