import { useSelector } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { useEditButtom, useActionsButtom } from '../../hooks/RyO/RyOTopButtomsHooks'
import { visibilidadModalActionRequired } from '../../store/RyO/actions.js'
import { useCloseAndShowNext } from '../../hooks/Actions/actionsHooks'

const PedingActionAdvise = ({ pendingActions }) => {
  const { editButtom } = useEditButtom()
  const { actionsButtom } = useActionsButtom()
  const actionRequiredModalVisibility = useSelector(
    (state) => state.fuse.RyOComponente.visibilidadModalActionRequired,
  )
  const idRyOWhenIsNoSelectedrow = useSelector(
    (state) => state.fuse.RyOComponente.idRyOWhenNoSelectedRow?.id,
  )

  const { closeAndShowNext, dismissed } = useCloseAndShowNext(pendingActions)

  if (dismissed) return null

  return (
    <Dialog open={actionRequiredModalVisibility} fullWidth maxWidth="xs">
      <DialogTitle>Action for R&O with id {idRyOWhenIsNoSelectedrow} must be taken</DialogTitle>
      <DialogContent>
        R&O with id {idRyOWhenIsNoSelectedrow} has a NPR higher than 100, and you are the manager of
        the FMEA that contains this R&O. That's why you must take an action or edit the R&O NPR.
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            editButtom()
            closeAndShowNext()
          }}
        >
          Edit R&O
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            actionsButtom()
            // closeAndShowNext()
          }}
        >
          Take action
        </Button>
        <Button variant="outlined" onClick={closeAndShowNext}>
          Take action later
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PedingActionAdvise
