//UPDATE RISK_MANAGEMENT
export const SET_LOADING_USERS_TRUE = 'SET_LOADING_USERS_TRUE'
export const SET_USER_LIST = 'SET_USER_LIST'
export const EDIT_USER_LIST = 'EDIT_USER_LIST'
export const SET_USERS_FETCH_ERROR = 'SET_USERS_FETCH_ERROR'

//SESION USER
export const SESION_USER = 'SESION_USER'
export const SESION_USER_EXITO = 'SESION_USER_EXITO'
export const SESION_USER_ERROR = 'SESION_USER_ERROR'

//PERMISOS SESION USER
export const PERMISOS_SESION_USER = 'PERMISOS_SESION_USER'
export const PERMISOS_SESION_USER_EXITO = 'PERMISOS_SESION_USER_EXITO'
export const PERMISOS_SESION_USER_ERROR = 'PERMISOS_SESION_USER_ERROR'

//PERMISOS ASOCIADOS AL USUARIO (NO DICCIONARIO)
export const MOSTRAR_USER_PERMISOS = 'MOSTRAR_USER_PERMISOS'
export const MOSTRAR_USER_PERMISOS_EXITO = 'MOSTRAR_USER_PERMISOS_EXITO'
export const MOSTRAR_USER_PERMISOS_ERROR = 'MOSTRAR_USER_PERMISOS_ERROR'

//PERMISOS ASOCIADOS AL USUARIO PROVENIENTE DE LOS GRUPOS
export const MOSTRAR_USER_PERMISOS_BY_GROUP = 'MOSTRAR_USER_PERMISOS_BY_GROUP'
export const MOSTRAR_USER_PERMISOS_BY_GROUP_EXITO = 'MOSTRAR_USER_PERMISOS_BY_GROUP_EXITO'
export const MOSTRAR_USER_PERMISOS_BY_GROUP_ERROR = 'MOSTRAR_USER_PERMISOS_BY_GROUP_ERROR'

//SESION USER
export const CAMBIAR_VALOR_SELECCION_GRID_USER = 'CAMBIAR_VALOR_SELECCION_GRID_USER'
export const CAMBIAR_VALOR_SELECCION_GRID_USER_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_USER_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_USER_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_USER_ERROR'

//modal sin permisos
export const MODAL_SIN_PERMISOS = 'MODAL_SIN_PERMISOS'
export const MODAL_SIN_PERMISOS_EXITO = 'MODAL_SIN_PERMISOS_EXITO'
export const MODAL_SIN_PERMISOS_ERROR = 'MODAL_SIN_PERMISOS_ERROR'

//get contratos user
export const OBTENER_CONTRATOS_USER = 'OBTENER_CONTRATOS_USER'
export const OBTENER_CONTRATOS_USER_EXITO = 'OBTENER_CONTRATOS_USER_EXITO'
export const OBTENER_CONTRATOS_USER_EXITO_RESPALDO = 'OBTENER_CONTRATOS_USER_EXITO_RESPALDO'
export const OBTENER_CONTRATOS_USER_ERROR = 'OBTENER_CONTRATOS_USER_ERROR'

//GET INFO USER AGRESSO
export const GET_USER_AGRESSO = 'GET_USER_AGRESSO'
export const GET_USER_AGRESSO_EXITO = 'GET_USER_AGRESSO_EXITO'
export const GET_USER_AGRESSO_ERROR = 'GET_USER_AGRESSO_ERROR'

//UPDATE USER
export const PUT_USER = 'PUT_USER'
export const PUT_USER_EXITO = 'PUT_USER_EXITO'
export const PUT_USER_ERROR = 'PUT_USER_ERROR'

//INSERTAR USER
export const INSERTAR_NEW_USER = 'INSERTAR_NEW_USER'
export const INSERTAR_NEW_USER_EXITO = 'INSERTAR_NEW_USER_EXITO'
export const INSERTAR_NEW_USER_ERROR = 'INSERTAR_NEW_USER_ERROR'

//MOSTRAR ALL USER
export const MOSTRAR_ALL_USER = 'MOSTRAR_ALL_USER'
export const MOSTRAR_ALL_USER_EXITO = 'MOSTRAR_ALL_USER_EXITO'
export const MOSTRAR_ALL_USER_ERROR = 'MOSTRAR_ALL_USER_ERROR'

//RESET PASSWORD
export const PUT_PASSWORD_USER = 'PUT_PASSWORD_USER'
export const PUT_PASSWORD_USER_EXITO = 'PUT_PASSWORD_USER_EXITO'
export const PUT_PASSWORD_USER_ERROR = 'PUT_PASSWORD_USER_ERROR'

//RESET PASSWORD MANUAL
export const PUT_PASSWORD_USER_MANUAL = 'PUT_PASSWORD_USER_MANUAL'
export const PUT_PASSWORD_USER_MANUAL_EXITO = 'PUT_PASSWORD_USER_MANUAL_EXITO'
export const PUT_PASSWORD_USER_MANUAL_ERROR = 'PUT_PASSWORD_USER_MANUAL_ERROR'

//GET BLOQUES HEREDADOS Y NO USUARIO
export const GET_BLOQUES_HEREDADOS = 'GET_BLOQUES_HEREDADOS'
export const GET_BLOQUES_HEREDADOS_EXITO = 'GET_BLOQUES_HEREDADOS_EXITO'
export const GET_BLOQUES_HEREDADOS_ERROR = 'GET_BLOQUES_HEREDADOS_ERROR'

// SECTIONS VIEWS
export const SET_SECTIONS_VIEWS = 'SET_SECTIONS_VIEWS'

// ! SECTIONS VIEWS 2.0
export const SET_USER_SECTIONS_VIEWS = 'SET_USER_SECTIONS_VIEWS'
export const INSERT_NEW_SECTION_IN_SECTIONS_VIEWS = 'INSERT_NEW_SECTION_IN_SECTIONS_VIEWS'
export const EDIT_SECTION_IN_SECTIONS_VIEWS = 'EDIT_SECTION_IN_SECTIONS_VIEWS'
// ORGANIZATION CHART
export const SET_MODULES_STRUCTURE = 'SET_MODULES_STRUCTURE'

export const SET_USER_EMPTY = 'SET_USER_EMPTY'
export const DELETE_PERMISSIONS = 'DELETE_PERMISSIONS'
export const RESET_USER_VALUES = 'RESET_USER_VALUES'
