import * as XLSX from 'xlsx'
import { getTodayFullDate } from 'app/js/generalFunctions'
import dayjs from 'dayjs'

export const scrollToTop = (containerRef) => {
  if (containerRef.current) containerRef.current.scrollTop = 0
}

export const rowColorStyle = (row, selectedRowIndex) => {
  // Check if the row is selected
  if (row.index === selectedRowIndex) {
    return `${row.isExpanded ? 'bg-company-green-lighter' : 'bg-company-green-lighter'}`
  }

  const nprValue = parseInt(row.original.npr)
  if (nprValue > 100) {
    return row.visiblePosition % 2 === 0 ? 'bg-[#ffb8b8]' : 'bg-red-100'
  } else if (nprValue >= 90 && nprValue <= 100) {
    return row.visiblePosition % 2 === 0 ? 'bg-[#fde68a]' : 'bg-yellow-100'
  }

  return row.visiblePosition % 2 === 0 ? 'bg-gray-100' : 'bg-white'
}

export const exportToExcel = ({ sectionName = 'default_table_data_name', columns, data }) => {
  const currentFullDate = getTodayFullDate()
  const firstRowWithHiddenData = data.find((row) => row.hasOwnProperty('hidden_data'))
  const genericHiddenData = firstRowWithHiddenData?.hidden_data || null

  // Filter out expander columns
  const visibleColumns = columns.filter((column) => column.id !== 'expander')

  // Process columns to handle both accessor and Cell cases
  const { headers, accessors } = visibleColumns.reduce(
    (acc, column) => {
      acc.headers.push(column.Header)

      if (column.accessor) {
        // Standard accessor case
        acc.accessors.push(column.accessor)
      } else if (column.Cell) {
        // Cell render function case - we need to evaluate it
        acc.accessors.push((row) => {
          // Create a mock row object similar to what react-table provides
          const mockRow = { original: row }
          return column.Cell({ row: mockRow })
        })
      } else {
        // Fallback - use Header as property name
        acc.accessors.push(column.Header)
      }

      return acc
    },
    { headers: [], accessors: [] },
  )

  // Process row data
  const rowData = data.map((row) =>
    accessors.map((accessor) => {
      let value = typeof accessor === 'function' ? accessor(row) : row[accessor]

      // Handle React elements safely
      if (value?.$$typeof === Symbol.for('react.element')) {
        value = value.props?.value ?? value.props?.tagsList ?? null
      }

      // Handle null/undefined cases
      if (value == null) return ''

      if (typeof value === 'number') {
        value = Math.round(value)
      }

      return Array.isArray(value) ? value.join(', ') : value.toString()
    }),
  )

  // Rest of the export function remains the same...
  const workbook = XLSX.utils.book_new()
  const tableData = [headers, ...rowData]
  const mainSheet = XLSX.utils.aoa_to_sheet(tableData)

  // Define table range for autofilter
  const lastColIndex = headers.length - 1
  mainSheet['!autofilter'] = {
    ref: XLSX.utils.encode_range({
      s: { r: 0, c: 0 },
      e: { r: 0, c: lastColIndex },
    }),
  }

  // Add main sheet
  const sheetName = sectionName.length > 31 ? sectionName.substring(0, 31) : sectionName
  XLSX.utils.book_append_sheet(workbook, mainSheet, sheetName)

  // Add hidden data if exists
  if (genericHiddenData?.length > 0) {
    const hiddenDataRows = genericHiddenData.map((row) => [...row])
    XLSX.utils.book_append_sheet(
      workbook,
      XLSX.utils.aoa_to_sheet(hiddenDataRows, { skipHeader: true }),
      'Extra info',
    )
  }

  XLSX.writeFile(workbook, `${sectionName}_${currentFullDate}.xlsx`)
}

export const exportToExcelImprovementProposal = ({
  sectionName = 'default_table_data_name',
  columns,
  data,
  dateRanges,
}) => {
  // Filter for considered and applied improvement proposals
  const consideredImprovementProposals = data.filter(
    (row) => row.estado === 'ACCEPTED' || row.estado === 'COMPLETED',
  )
  const appliedImprovementProposals = data.filter((row) => row.estado === 'COMPLETED')

  // Calculate percentage of improvement proposals applied
  let percentageImprovementProposalsApplied =
    (appliedImprovementProposals.length / consideredImprovementProposals.length) * 100
  percentageImprovementProposalsApplied = percentageImprovementProposalsApplied.toFixed(2)

  // Calculate sum and average implementation time of improvement proposals
  const sumImplementationTime = appliedImprovementProposals
    .map((row) => dayjs(row.fecha_implementacion).diff(dayjs(row.fecha_solicitud), 'day'))
    .reduce((acc, curr) => acc + curr, 0)

  let averageImplementationTime = sumImplementationTime / appliedImprovementProposals.length
  averageImplementationTime = averageImplementationTime.toFixed(2)

  // Utility function to get the requested date range string
  const getRequestedDateRange = (dateRanges) => {
    if (dateRanges?.fecha_solicitud?.start && dateRanges?.fecha_solicitud?.end) {
      return `From ${dateRanges?.fecha_solicitud?.start} to ${dateRanges?.fecha_solicitud?.end}`
    }
    return 'All'
  }

  // Prepare extra report data
  const extraReportData = [
    ['Requested Date Range', getRequestedDateRange(dateRanges)],
    ['Considered Improvement Proposals', consideredImprovementProposals.length],
    ['Applied Improvement Proposals', appliedImprovementProposals.length],
    ['Percentage of Improvement Proposals Applied', `${percentageImprovementProposalsApplied}%`],
    ['Sum of Implementation Time of Improvement Proposals', `${sumImplementationTime} days`],
    ['Average Implementation Time of Improvement Proposals', `${averageImplementationTime} days`],
    [],
  ]

  const currentFullDate = getTodayFullDate()

  // Filter out expander columns for data
  const visibleColumns = columns.filter((column) => column.id !== 'expander')

  const { headers, accessors } = visibleColumns.reduce(
    (acc, { Header, accessor }) => {
      acc.headers.push(Header)
      acc.accessors.push(accessor)
      return acc
    },
    { headers: [], accessors: [] },
  )

  // Map data rows to column accessors
  const tableRowData = data.map((row) =>
    accessors.map((accessor) => {
      let value = typeof accessor === 'function' ? accessor(row) : row[accessor]

      // Handle React elements safely
      if (value?.$$typeof === Symbol.for('react.element')) {
        value = value.props?.value ?? value.props?.tagsList ?? null
      }

      if (typeof value === 'number') {
        value = Math.round(value)
      }

      return Array.isArray(value) ? value.join(', ') : value
    }),
  )

  // Combine headers with data
  const fullTableData = [headers, ...tableRowData]

  // Create the main sheet with the table data
  const mainSheet = XLSX.utils.aoa_to_sheet(fullTableData)

  // Define table range (A1 to last column + last row)
  const range = {
    s: { r: 0, c: 0 },
    e: { r: tableRowData.length, c: headers.length - 1 },
  }

  // Add autofilter to the header row
  mainSheet['!autofilter'] = {
    ref: XLSX.utils.encode_range({
      s: { r: 0, c: 0 },
      e: { r: 0, c: headers.length - 1 },
    }),
  }

  // Add table definition
  mainSheet['!table'] = {
    name: 'DataTable',
    displayName: 'Data Table',
    ref: XLSX.utils.encode_range(range),
    columns: headers.map((header) => ({
      name: header,
      filterButton: true,
    })),
  }

  // Create workbook
  const workbook = XLSX.utils.book_new()

  // Add sheets to workbook
  const sheetName = sectionName.length > 31 ? sectionName.substring(0, 31) : sectionName
  XLSX.utils.book_append_sheet(workbook, mainSheet, sheetName)
  XLSX.utils.book_append_sheet(workbook, XLSX.utils.aoa_to_sheet(extraReportData), 'Report Summary')

  // Write the file
  XLSX.writeFile(workbook, `${sectionName}_${currentFullDate}.xlsx`)
}

export const selectRowFunc = ({ row, funcionSetValue, selectedRowIndex, setselectedRowIndex }) => {
  if (row === null || !funcionSetValue) setselectedRowIndex(null)
  else {
    if (selectedRowIndex === row.index) {
      funcionSetValue(null)
      setselectedRowIndex(null)
    } else if (row.original?.id) {
      setselectedRowIndex(row.index)
      const selectedValue = row.original
      const indexValue = row.index
      funcionSetValue({ ...selectedValue, indexValue })
    }
  }
}

export const deleteFilterTable = ({
  setValorSelect,
  setDateValue,
  setDateValueExtra,
  columns,
  setFilter,
  tableContainerRef,
  tableMiddleContainerRef,
  setActiveFilters,
  setFilterValues,
}) => {
  setValorSelect(null)
  setDateValue(null)
  setDateValueExtra(null)
  setActiveFilters([])
  setFilterValues({})

  columns.forEach((column) => {
    if (column['type'] !== 'expand') {
      if (typeof column['accessor'] !== 'function') {
        setFilter(column['accessor'], undefined)
      } else {
        setFilter(column['id'], undefined)
      }
    }
  })

  scrollToTop(tableMiddleContainerRef)
}

export const handleFilterChangeDate = (
  filterValue,
  columnId,
  tipoRegistro,
  setFilter,
  gotoPage,
) => {
  if (filterValue !== '') {
    if (tipoRegistro === 'start') {
      setFilter(columnId, filterValue)
      gotoPage(0)
    }
    if (tipoRegistro === 'end') {
      setFilter(columnId, filterValue)
      gotoPage(0)
    }
  }
}

export const updateEmptyRows = (
  rowsLength,
  tableBodyRef,
  tableMiddleContainerRef,
  tableHeadRef,
  tableFootRef,
  setEmptyRow,
  columns,
) => {
  if (
    !tableBodyRef.current ||
    !tableMiddleContainerRef.current ||
    !tableHeadRef.current ||
    !tableFootRef.current
  )
    return

  const headHeight = tableHeadRef.current.clientHeight
  const footHeight = tableFootRef.current.clientHeight
  const containerHeight = tableMiddleContainerRef.current.clientHeight

  const rowHeight = 60
  const totalRowHeight = rowsLength * rowHeight

  const availableSpace = containerHeight - headHeight - footHeight

  if (!rowsLength || totalRowHeight >= availableSpace || rowsLength >= 10) {
    setEmptyRow(null)
    return
  }

  const diffHeight = Math.max(availableSpace - totalRowHeight, 0)
  const minHeight = 20

  setEmptyRow(
    diffHeight > minHeight ? (
      <tr style={{ height: `${diffHeight}px` }} data-testid="empty-row">
        <td colSpan={columns.length}></td>
      </tr>
    ) : null,
  )
}

export const handleFilterChange = (
  selectedValue,
  columnId,
  setFilter,
  tableContainerRef,
  gotoPage,
) => {
  setFilter(columnId, selectedValue || undefined)
  scrollToTop(tableContainerRef)
  gotoPage(0)
}

export const multiFilter = (rows, accessor, filterValue) => {
  if (!filterValue) return rows

  if (Array.isArray(filterValue)) {
    return rows.filter((row) => filterValue.includes(row.original[accessor]))
  }

  return rows.filter((row) => {
    const rowAccesor = row.original[accessor]
    if (!Array.isArray(rowAccesor)) return rowAccesor === filterValue
    else return rowAccesor.includes(filterValue)
  })
}

export const getChipsProps = (cell, chip) => {
  const { value } = cell
  const isChipArray = Array.isArray(value)
  const propCondition = (label) => (!isChipArray ? label === value : value.includes(label))
  const customCSS = chip.find(({ label }) => propCondition(label))?.customCSS ?? ''
  const color = chip.find(({ label }) => propCondition(label))?.color ?? 'primary'
  const variant = chip.find(({ label }) => propCondition(label))?.variant ?? 'outlined'
  const size = chip.find(({ label }) => propCondition(label))?.size ?? 'small'

  const isCustomCSS = Boolean(customCSS)
  return { value, customCSS, color, variant, size, isCustomCSS }
}
