import { useEffect } from 'react'
import { Tooltip, IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import HistoryIcon from '@mui/icons-material/History'
import {
  getRecordHistoryThunk,
  setCurrentRecordId,
} from 'app/store/general_states/record_history/record_history_slice'

export const RecordHistoryButton = ({ modelName, recordId }) => {
  const dispatch = useDispatch()
  const currentRecordId = useSelector(({ fuse }) => fuse.recordHistory.currentRecordId)

  const searchHistory = () => {
    if (currentRecordId !== recordId) {
      dispatch(setCurrentRecordId(recordId))
    }
  }

  useEffect(() => {
    if (currentRecordId && currentRecordId === recordId) {
      dispatch(getRecordHistoryThunk({ modelName, recordId: currentRecordId }))
    }
  }, [currentRecordId, dispatch])

  return (
    <Tooltip title="Record history" placement="top">
      <span>
        <IconButton
          className="p-0 m-0 text-gray-900 hover:text-gray-800 transition-color duration-75"
          disabled={!recordId}
          aria-label="View record history"
          onClick={searchHistory}
        >
          <HistoryIcon />
        </IconButton>
      </span>
    </Tooltip>
  )
}
