import { getCookie } from 'app/js/generalFunctions'
import { AGRESSO_CODES_URL, DEPARTMENT_MISSION_URL, MISSION_URL, SITES_URL, VISUAL_TIME_GROUPS_URL } from './const'
const getAuth = () => ({ 'Authorization': `Token ${getCookie('token')}` })

export const GET_ALL_DEPARTMENT_MISSIONS_REQUEST_OBJ = () => ({
    method: "GET",
    url: DEPARTMENT_MISSION_URL,
    headers: getAuth()
})

export const GET_AGRESSO_CODES_REQUEST_OBJ = () => ({
    method: "GET",
    url: AGRESSO_CODES_URL,
    headers: getAuth()
})

export const GET_VISUAL_TIME_GROUPS_REQUEST_OBJ = () => ({
    method: "GET",
    url: VISUAL_TIME_GROUPS_URL,
    headers: getAuth()
})

export const GET_SITES_REQUEST_OBJ = () => ({
    method: "GET",
    url: SITES_URL,
    headers: getAuth()
})

export const POST_TEAM_REQUEST_OBJ=(data) =>({
    method: "POST",
    url: MISSION_URL,
    data: data,
    headers: getAuth()
})

export const PUT_TEAM_REQUEST_OBJ=(id, data) =>({
    method: "PUT",
    url: `${MISSION_URL}${id}`,
    data: data,
    headers: getAuth()
})
