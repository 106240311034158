import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { showMessage } from 'app/store/fuse/messageSlice'
import { GET_IMPROVEMENTS_ACTION_PLANS_REQUEST_OBJ, GET_IMPROVEMENTS_ACTION_PLANS_BY_IMPROVEMENT_REQUEST_OBJ, PUT_IMPROVEMENT_ACTION_PLAN_REQUEST_OBJ, POST_IMPROVEMENT_ACTION_PLAN_REQUEST_OBJ, DELETE_IMPROVEMENT_ACTION_PLAN_REQUEST_OBJ } from '../utils/functions'
import { ERROR_TRY_AGAIN, SUCESS_MESSAGE_CREATED_ACTION_PLAN, SUCESS_MESSAGE_EDITED_ACTION_PLAN, SUCESS_MESSAGE_TOGGLE_STATUS_ACTION_PLAN, SUCESS_MESSAGE_DELETED_ACTION_PLAN } from '../utils/const'

export const fetchImprovementActionPlans = createAsyncThunk(
    'improvementProposalActionPlan/fetchImprovementActionPlans',
    async (_, { rejectWithValue }) => {
        try {
            const config = GET_IMPROVEMENTS_ACTION_PLANS_REQUEST_OBJ()

            const response = await axios(config)

            if (response.status !== 200) throw new Error('Error retrieving improvement proposals')

            return response.data
        } catch (error) {
            return rejectWithValue(
                error.message || 'Failed to fetch data'
            )
        }
    }
)

export const fetchImprovementActionPlansByImprovement = createAsyncThunk(
    'improvementProposalActionPlan/fetchImprovementActionPlansByImprovement',
    async (improvementId, { rejectWithValue }) => {
        try {
            const config = GET_IMPROVEMENTS_ACTION_PLANS_BY_IMPROVEMENT_REQUEST_OBJ(improvementId)

            const response = await axios(config)

            if (response.status !== 200) throw new Error('Error retrieving improvement proposals')

            return response.data
        } catch (error) {
            return rejectWithValue(
                error.message || 'Failed to fetch data'
            )
        }
    }
)

export const putImprovementActionPlan = createAsyncThunk(
    'improvementProposalActionPlan/putImprovementActionPlan',
    async (improvementActionPlanData, { rejectWithValue, dispatch }) => {
        const { id, data } = improvementActionPlanData
        try {
            const config = PUT_IMPROVEMENT_ACTION_PLAN_REQUEST_OBJ(id, data)

            const response = await axios(config)

            if (response.status !== 200) throw new Error('Error saving action plan')

            if (!data.completed) {
                dispatch(showMessage(SUCESS_MESSAGE_EDITED_ACTION_PLAN))
            } else {
                dispatch(showMessage(SUCESS_MESSAGE_TOGGLE_STATUS_ACTION_PLAN))
            }

            return response.data
        } catch (error) {
            dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(
                error.message || 'Failed to save data'
            )
        }
    }
)

export const postImprovementActionPlan = createAsyncThunk(
    'improvementProposalActionPlan/postImprovementActionPlan',
    async (improvementActionPlanData, { rejectWithValue, dispatch }) => {
        try {
            const config = POST_IMPROVEMENT_ACTION_PLAN_REQUEST_OBJ(improvementActionPlanData)

            const response = await axios(config)

            if (response.status !== 201) throw new Error('Error creating action plan')

            dispatch(showMessage(SUCESS_MESSAGE_CREATED_ACTION_PLAN))

            return response.data
        } catch (error) {
            dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(
                error.message || 'Failed to create data'
            )
        }
    }
)

export const deleteImprovementActionPlan = createAsyncThunk(
    'improvementProposalActionPlan/deleteImprovementActionPlan',
    async (improvementActionPlanData, { rejectWithValue, dispatch }) => {
        const { id } = improvementActionPlanData
        try {
            const config = DELETE_IMPROVEMENT_ACTION_PLAN_REQUEST_OBJ(id)

            const response = await axios(config)

            if (response.status !== 204) throw new Error('Error deleting action plan')

            dispatch(showMessage(SUCESS_MESSAGE_DELETED_ACTION_PLAN))

            return improvementActionPlanData
        } catch (error) {
            dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(
                error.message || 'Failed to delete data'
            )
        }
    }
)
