import { useEffect, useContext, useMemo } from 'react'
import Pagination from '@mui/material/Pagination'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import DownloadIcon from '@mui/icons-material/Download'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import { deleteFilterTable, scrollToTop } from '../utils/functions'
import { CurrentLocationContext } from '@fuse/core/FusePageCarded/FusePageCarded'
import { useGetPermissions } from 'app/js/customHooks/PermissionsHooks'

export const CustomPagination = ({
  setValorSelect,
  setDateValue,
  setDateValueExtra,
  setFilter,
  tableContainerRef,
  tableMiddleContainerRef,
  pageIndex,
  rows,
  pageSize,
  filters,
  columns,
  filteredData: data,
  resetTableRowSelection,
  selectedRowIndex,
  setselectedRowIndex,
  exportToExcel,
  exportToExcelImprovementProposal,
  setPageSize,
  gotoPage,
  setActiveFilters,
  setFilterValues,
  dateRanges,
  isImprovementProposal,
}) => {
  const DELETE_FILTER_TABLE_PARAMS = {
    columns,
    setValorSelect,
    setDateValue,
    setDateValueExtra,
    setFilter,
    tableContainerRef,
    tableMiddleContainerRef,
    setActiveFilters,
    setFilterValues,
  }
  const { getViewPermission } = useGetPermissions()

  const canViewReport = useMemo(() => !!getViewPermission('improvement report'), [])

  const pageCount = Math.ceil(rows.length / pageSize)

  const recordTextNoFilters = `Total records: ${data.length}`
  const recordsWithFiltersText = `Records: ${rows.length} of ${data.length}`
  const recordsText = filters.length ? recordsWithFiltersText : recordTextNoFilters

  const handleUnselectRow = () => {
    resetTableRowSelection()
    setselectedRowIndex(null)
  }

  useEffect(() => {
    if (filters.length) {
      handleUnselectRow()
    }
  }, [filters.length])

  const currentLocationContext = useContext(CurrentLocationContext)

  const currentLocation =
    currentLocationContext.currentLocationName
      .split(' - ')[1]
      ?.toLowerCase()
      .replace(/\s+/g, '_') || 'default_table_data_name'

  const tabName = currentLocationContext.tabNameFromCurrentLocation.trim().replace(/\s+/g, '_')
  const sectionName = tabName ? `${currentLocation}(${tabName})` : currentLocation

  const activeColumn = Object.keys(dateRanges).find(
    (key) => dateRanges[key].start && dateRanges[key].end,
  )

  return (
    <div className="w-full flex flex-row items-center justify-between p-3 shadow-[0px_10px_30px_2px_#00000010]">
      <div className="flex items-center gap-2">
        <span className="text-gray-700 mr-4">{recordsText}</span>

        {filters.length > 0 && (
          <Tooltip title="Delete filter" placement="top">
            <Button
              variant="outlined"
              color="error"
              onClick={() => deleteFilterTable(DELETE_FILTER_TABLE_PARAMS)}
              sx={{ minWidth: 'fit-content', borderRadius: '50%', padding: '6px' }}
            >
              <FilterListOffIcon />
            </Button>
          </Tooltip>
        )}

        {selectedRowIndex !== null && (
          <Tooltip title="Unselect record" placement="top">
            <Button
              variant="outlined"
              onClick={handleUnselectRow}
              sx={{ minWidth: 'fit-content', borderRadius: '50%', padding: '6px' }}
            >
              <ClearIcon />
            </Button>
          </Tooltip>
        )}
      </div>

      <div className="flex items-center gap-4">
        {isImprovementProposal && canViewReport && data.length > 0 && (
          <Tooltip title="Download report" placement="top">
            <Button
              variant="outlined"
              color="warning"
              onClick={() =>
                exportToExcelImprovementProposal({
                  sectionName,
                  columns,
                  data: rows.map((row) => row.original),
                  dateRanges,
                })
              }
            >
              <DownloadIcon /> Report
            </Button>
          </Tooltip>
        )}

        {(filters.length > 0 || activeColumn) && data.length > 0 && (
          <Tooltip title="Download filtered table" placement="top">
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                exportToExcel({ sectionName, columns, data: rows.map((row) => row.original) })
              }
            >
              <DownloadIcon /> Filtered data
            </Button>
          </Tooltip>
        )}

        <Tooltip title="Download table" placement="top">
          <Button
            variant="outlined"
            onClick={() => exportToExcel({ sectionName, columns, data: data })}
            sx={{ minWidth: 'fit-content', borderRadius: '50%', padding: '6px' }}
          >
            <DownloadIcon />
          </Button>
        </Tooltip>

        <FormControl variant="standard" className="min-w-48">
          <Select
            labelId="page-size-select-label"
            id="page-size-select"
            name="pageSize"
            value={pageSize}
            label="Page Size"
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="px-2 py-2"
          >
            {[10, 30, 50, 100].map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Pagination
          className="flex-shrink-0"
          color="primary"
          count={pageCount}
          page={pageIndex + 1}
          onChange={(event, value) => {
            gotoPage(value - 1)
            scrollToTop(tableMiddleContainerRef)
          }}
          sx={{
            '& ul': {
              flexWrap: 'nowrap',
            },
          }}
        />
      </div>
    </div>
  )
}
