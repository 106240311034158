import * as global from 'global'
import { createSlice } from '@reduxjs/toolkit'
import {
  fetchRyOActionPlansByRyO,
  postRyOActionPlan,
  putRyOActionPlan,
  deleteRyOActionPlan,
  fetchAllRyOActions,
  updateFmeaReviewDate,
} from './ryoActionPlanThunks'

const initialState = {
  status: {
    fetchAllRyOActions: 'idle',
    fetchRyOActionPlansByRyO: 'idle',
    postRyOActionPlan: 'idle',
    putRyOActionPlan: 'idle',
    deleteRyOActionPlan: 'idle',
    updateFmeaReviewDate: 'idle',
  },
  error: null,
  riskOpportunityActionPlansByRyO: [],
  RyOActions: [],
  modalVisibilityAction: false,
  modalActionPlanValues: {
    id: null,
    proposal: '',
    date_planned: null,
    date_executed: null,
    observations: '',
    completed: false,
    managers: [],
  },
  isEditMode: false,
  showCloseDate: false,
  permissionRoles: [global.rolN4, global.rolN5, global.rolN6, global.rolN7],
  filteredUsers: [],
}

const riskOpportunityActionPlanSlice = createSlice({
  name: 'riskOpportunityActionPlan',
  initialState,
  reducers: {
    setModalVisibilityAction: (state, action) => {
      state.modalVisibilityAction = action.payload
    },
    setModalActionPlanValues: (state, action) => {
      state.modalActionPlanValues = { ...state.modalActionPlanValues, ...action.payload }
    },
    resetModalActionPlanValues: (state) => {
      state.modalActionPlanValues = initialState.modalActionPlanValues
    },
    filterUsers: (state, action) => {
      state.filteredUsers = action.payload.filter((user) =>
        state.permissionRoles.includes(user.rolUser),
      )
    },
    setIsEditMode: (state, action) => {
      state.isEditMode = action.payload
    },
    setShowCloseDate: (state, action) => {
      state.showCloseDate = action.payload
    },
    clearRiskOpportunityActionPlansByRyO: (state) => {
      state.riskOpportunityActionPlansByRyO = initialState.riskOpportunityActionPlansByRyO
    },
  },
  extraReducers: (builder) => {
    builder
      // handle fetchAllRyOActions
      .addCase(fetchAllRyOActions.pending, (state) => {
        state.status.fetchAllRyOActions = 'pending'
        state.error = false
      })
      .addCase(fetchAllRyOActions.rejected, (state, action) => {
        state.status.fetchAllRyOActions = 'rejected'
        state.error = true
      })
      .addCase(fetchAllRyOActions.fulfilled, (state, action) => {
        state.status.fetchAllRyOActions = 'fulfilled'
        state.RyOActions = action.payload
      })
      // handle fetchRyOActionPlansByRyO
      .addCase(fetchRyOActionPlansByRyO.pending, (state) => {
        state.status.fetchRyOActionPlansByRyO = 'pending'
        state.error = false
      })
      .addCase(fetchRyOActionPlansByRyO.rejected, (state, action) => {
        state.status.fetchRyOActionPlansByRyO = 'rejected'
        state.error = true
      })
      .addCase(fetchRyOActionPlansByRyO.fulfilled, (state, action) => {
        state.status.fetchRyOActionPlansByRyO = 'fulfilled'
        state.riskOpportunityActionPlansByRyO = action.payload
      })
      // handle postRyOActionPlan
      .addCase(postRyOActionPlan.pending, (state) => {
        state.status.postRyOActionPlan = 'pending'
        state.error = false
      })
      .addCase(postRyOActionPlan.rejected, (state, action) => {
        state.status.postRyOActionPlan = 'rejected'
        state.error = true
      })
      .addCase(postRyOActionPlan.fulfilled, (state, action) => {
        state.status.postRyOActionPlan = 'fulfilled'
        state.riskOpportunityActionPlansByRyO = [
          ...state.riskOpportunityActionPlansByRyO,
          action.payload,
        ]
        state.RyOActions = [...state.RyOActions, action.payload]
      })
      // handle putRyOActionPlan
      .addCase(putRyOActionPlan.pending, (state) => {
        state.status.putRyOActionPlan = 'pending'
        state.error = false
      })
      .addCase(putRyOActionPlan.rejected, (state, action) => {
        state.status.putRyOActionPlan = 'rejected'
        state.error = true
      })
      .addCase(putRyOActionPlan.fulfilled, (state, action) => {
        state.status.putRyOActionPlan = 'fulfilled'
        state.riskOpportunityActionPlansByRyO = state.riskOpportunityActionPlansByRyO.map((plan) =>
          plan.id === action.payload.id ? action.payload : plan,
        )
        state.RyOActions = state.RyOActions.map((plan) =>
          plan.id === action.payload.id ? action.payload : plan,
        )
      })
      // handle deleteRyOActionPlan
      .addCase(deleteRyOActionPlan.pending, (state) => {
        state.status.deleteRyOActionPlan = 'pending'
        state.error = false
      })
      .addCase(deleteRyOActionPlan.rejected, (state, action) => {
        state.status.deleteRyOActionPlan = 'rejected'
        state.error = true
      })
      .addCase(deleteRyOActionPlan.fulfilled, (state, action) => {
        state.status.deleteRyOActionPlan = 'fulfilled'
        state.riskOpportunityActionPlansByRyO = state.riskOpportunityActionPlansByRyO.filter(
          (plan) => plan.id !== action.payload.id,
        )
        state.RyOActions = state.RyOActions.filter((plan) => plan.id !== action.payload.id)
      })
      // handle updateFmeaReviewDate
      .addCase(updateFmeaReviewDate.pending, (state) => {
        state.status.updateFmeaReviewDate = 'pending'
        state.error = false
      })
      .addCase(updateFmeaReviewDate.rejected, (state, action) => {
        state.status.updateFmeaReviewDate = 'rejected'
        state.error = true
      })
      .addCase(updateFmeaReviewDate.fulfilled, (state, action) => {
        state.status.updateFmeaReviewDate = 'fulfilled'
      })
  },
})

export const {
  setModalVisibilityAction,
  setModalActionPlanValues,
  setIsEditMode,
  setShowCloseDate,
  resetModalActionPlanValues,
  filterUsers,
  clearRiskOpportunityActionPlansByRyO,
} = riskOpportunityActionPlanSlice.actions

export const RyOActions = (state) => state.fuse.riskOpportunityActionPlan.RyOActions
export const RyOActionsStatus = (state) => state.fuse.riskOpportunityActionPlan.status.fetchAllRyOActions
export const riskOpportunityActionPlansByRyO = (state) =>
  state.fuse.riskOpportunityActionPlan.riskOpportunityActionPlansByRyO
export const riskOpportunityActionPlansByRyOStatus = (state) =>
  state.fuse.riskOpportunityActionPlan.status.fetchRyOActionPlansByRyO
export const modalActionPlanValues = (state) =>
  state.fuse.riskOpportunityActionPlan.modalActionPlanValues
export const modalVisibilityAction = (state) =>
  state.fuse.riskOpportunityActionPlan.modalVisibilityAction
export const isEditModeStatus = (state) => state.fuse.riskOpportunityActionPlan.isEditMode
export const showCloseDateStatus = (state) => state.fuse.riskOpportunityActionPlan.showCloseDate
export const filteredUsers = (state) => state.fuse.riskOpportunityActionPlan.filteredUsers

export default riskOpportunityActionPlanSlice.reducer
