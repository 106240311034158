import React, { useEffect, useMemo, useRef, useState, memo } from 'react'
import { styled } from '@mui/material/styles'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import reducer from './store'
import withReducer from 'app/store/withReducer'
import { toggleQuickPanel } from './store/stateSlice'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import DoneIcon from '@mui/icons-material/Done'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import IconButton from "@mui/material/IconButton"
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import RefreshIcon from '@mui/icons-material/Refresh'
import { motion, AnimatePresence } from "framer-motion"
import { descargarAdjuntoNotificacionAPIAction } from '../../../../components/Managment/Notifications/store/actions'
import useNotification from 'components/GlobalHooks/Notifications/reload_notifications'

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: 340,
    backgroundColor: '#003863',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
}))

const panelVariants = {
  hidden: { height: 0, opacity: 0 },
  visible: {
    height: 'auto',
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: [0.4, 0, 0.2, 1],
    }
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.25,
      ease: [0.4, 0, 1, 1]
    }
  }
}

const listItemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 }
}

function QuickPanel(props) {
  const dispatch = useDispatch()
  const state = useSelector(({ quickPanel }) => quickPanel.state)
  const { listUnreadNotifications, listReadNotifications } = useSelector(state => state.fuse.peopleManagementComponente.listNotificactions)
  const archivoDescargado = useSelector(state => state.fuse.notificacionesComponente.archivoDescargado)

  const {
    getNotificationAPI,
    markNotificationAsRead,
    markAllNotificationsAsRead,
    loadingState
  } = useNotification()

  const { getNotifications: isLoadingGetNotifications, markAll: isLoadingMarkAllNotificationsAsRead } = loadingState

  const descargarAdjuntoNotificacionAPI = (idNotificacion) => dispatch(descargarAdjuntoNotificacionAPIAction(idNotificacion))

  const sortedUnreadNotifications = useMemo(() => {
    return [...listUnreadNotifications].sort((a, b) => b.id - a.id)
  }, [listUnreadNotifications])

  const sortedReadNotifications = useMemo(() => {
    return [...listReadNotifications].sort((a, b) => b.id - a.id)
  }, [listReadNotifications])

  const [isHistoryOpen, setIsHistoryOpen] = useState(false)
  const [historyDisplayCount, setHistoryDisplayCount] = useState(10)
  const historyPanelRef = useRef(null)

  const toggleHistory = () => {
    setIsHistoryOpen(!isHistoryOpen)
  }

  // Reset the history count when the history panel is opened
  useEffect(() => {
    if (isHistoryOpen) {
      setHistoryDisplayCount(10)
    }
  }, [isHistoryOpen])

  // Slice the read notifications based on the current display count
  const limitedReadNotifications = useMemo(() => {
    return sortedReadNotifications.slice(0, historyDisplayCount)
  }, [sortedReadNotifications, historyDisplayCount])

  // Handler for infinite scroll on the history panel
  const handleHistoryScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget
    // Check if we're near the bottom (within 10px)
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      if (historyDisplayCount < sortedReadNotifications.length) {
        setHistoryDisplayCount(prev => prev + 10)
      }
    }
  }

  return (
    <StyledSwipeableDrawer
      open={state}
      onOpen={() => { }}
      anchor="right"
      onClose={() => dispatch(toggleQuickPanel())}
      disableSwipeToOpen
    >
      <div className="text-white w-full text-center p-2 relative">
        <div className="absolute left-5 top-3">
          {sortedUnreadNotifications.length > 1 && (
            <IconButton
              className={`w-28 h-28 text-white ${isLoadingMarkAllNotificationsAsRead ? 'bg-pink-500/40 animate-spin' : 'hover:bg-white/40'}`}
              onClick={() => markAllNotificationsAsRead(listUnreadNotifications)}
              size="large"
              disabled={isLoadingMarkAllNotificationsAsRead}
            >
              <DoneAllIcon />
            </IconButton>
          )}
        </div>
        <h3 className="text-lg font-semibold">Notifications</h3>
        <div className="absolute right-5 top-3">
          <IconButton
            className={`w-28 h-28 text-white ${isLoadingGetNotifications ? 'animate-spin' : 'hover:bg-white/40'}`}
            onClick={getNotificationAPI}
            size="large"
            disabled={isLoadingGetNotifications}
          >
            <RefreshIcon />
          </IconButton>
        </div>
      </div>
      <Divider className="w-full bg-white" />

      <div className="flex-1 overflow-y-auto p-2 pb-4">
        {sortedUnreadNotifications.length === 0 ? (
          <Typography variant="body2" className="text-center text-white px-2 py-4">
            No notifications available. Press the refresh button to check for new notifications.
          </Typography>
        ) : (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={{ visible: { transition: { staggerChildren: 0.05 } } }}
            >
              {sortedUnreadNotifications.map((notification, index) => (
                <motion.div
                  key={index}
                  variants={listItemVariants}
                  layout
                >
                  <Card className="flex bg-white m-2 mb-3 shadow-lg">
                    <Box className="flex flex-col w-full">
                      <CardHeader
                        avatar={
                          <Avatar className="bg-black">
                            {notification.origen_notification_first_name[0]}
                            {notification.origen_notification_last_name[0]}
                          </Avatar>
                        }
                        action={
                          <motion.div whileHover={{ scale: 1.1 }}>
                            <IconButton onClick={() => markNotificationAsRead(notification)}>
                              <DoneIcon />
                            </IconButton>
                          </motion.div>
                      }
                        title={`${notification.origen_notification_first_name} ${notification.origen_notification_last_name}`}
                        subheader={`${notification.fecha} at ${new Date(notification.time_stamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
                      />
                      <CardContent>
                        <div dangerouslySetInnerHTML={{ __html: notification.observations }} />
                        {notification.archivo && (
                          <motion.div whileHover={{ scale: 1.05 }}>
                            <Button
                              variant="outlined"
                              onClick={() => descargarAdjuntoNotificacionAPI(notification.id)}
                              className="mt-2"
                            >
                              Download
                            </Button>
                          </motion.div>
                        )}
                      </CardContent>
                    </Box>
                  </Card>
                </motion.div>
            ))}
          </motion.div>
        )}
      </div>

      <AnimatePresence>
        {isHistoryOpen && (
          <motion.div
            key="history-panel"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={panelVariants}
            className="bg-[#002244] overflow-y-auto border-t-2 border-white/20"
            ref={historyPanelRef}
            onScroll={handleHistoryScroll}
            style={{ maxHeight: '70vh' }}
          >
            <Typography variant="h6" className="text-center text-white mt-4 mb-2 sticky top-0 py-3 bg-[#002244] z-50">
              Notification History
            </Typography>
            {limitedReadNotifications.length === 0 ? (
              <Typography variant="body1" className="text-center text-white pb-4">
                No read notifications.
              </Typography>
            ) : (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={{ visible: { transition: { staggerChildren: 0.05 } } }}
                className="px-2 pb-4"
              >
                {limitedReadNotifications.map((notification, index) => (
                  <motion.div
                    key={index}
                    variants={listItemVariants}
                    layout
                  >
                    <Card className="flex bg-gray-300 m-2 mb-3 shadow-md">
                      <Box className="flex flex-col w-full">
                        <CardHeader
                          avatar={
                            <Avatar className="bg-black">
                              {notification.origen_notification_first_name[0]}
                              {notification.origen_notification_last_name[0]}
                            </Avatar>
                          }
                          title={`${notification.origen_notification_first_name} ${notification.origen_notification_last_name}`}
                          subheader={`${notification.fecha} at ${new Date(notification.time_stamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
                        />
                        <CardContent>
                          <div dangerouslySetInnerHTML={{ __html: notification.observations }} />
                          {notification.archivo && (
                            <motion.div whileHover={{ scale: 1.05 }}>
                              <Button
                                variant="outlined"
                                onClick={() => descargarAdjuntoNotificacionAPI(notification.id)}
                                className="mt-2"
                              >
                                Download
                              </Button>
                            </motion.div>
                          )}
                        </CardContent>
                      </Box>
                    </Card>
                  </motion.div>
                ))}
              </motion.div>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      <div className="sticky bottom-0 bg-[#002244] border-t-2 border-white/20">
        <motion.div
          whileHover={{ scale: 1.05 }}
          className="flex justify-center items-center py-2"
        >
          <Button
            onClick={toggleHistory}
            className="text-white"
            size="large"
          >
            {isHistoryOpen ? (
              <>
                <KeyboardArrowDownIcon />
                <span className="ml-2 text-sm">Hide History</span>
              </>
            ) : (
              <>
                <KeyboardArrowUpIcon />
                <span className="ml-2 text-sm">Show History</span>
              </>
            )}
          </Button>
        </motion.div>
      </div>
    </StyledSwipeableDrawer>
  )
}

export default withReducer('quickPanel', reducer)(memo(QuickPanel))
