import { createSlice, current } from "@reduxjs/toolkit";
import { closeInpectorAuthThunk, editInspectorThunk, getInspectorBasicInfoThunk, getInspectorsContinuousEvaluationThunk, getInspectorsThunk, postInspectorJobThunk, postInspectorThunk } from "./AuthOfInspectorsThunk";
import { addStateByLastJobDateDateToInpectorList, getAllInspectorJobs, insertScopeNameInInspectorList, setInspectorsBasicList, setInspectorsDetailList } from "../utils/functions";
import store from "app/store";
import { getIdsFromArr } from "app/js/generalFunctions";
import { SCOPE_NAMES_JOB_MODAL } from "../utils/consts";

const INITIAL_STATE = {
    inpsectorBasicList: [],
    inspectorsList: [],
    inpectorDataDetailTable: [],
    loadingInspectorsList: true,
    errorInspectorsList: false,
    inspectorBasicListRowSelected: null,
    inspectorRowSelected: null,
    createInspectorModalVisibility: false,
    editInspectorModalVisibility: false,
    createJobModalVisibility: false,
    closeAuthModalVisibility: false,
    continuousEvaluationModalVisibility: false,
    detailsAuthTableVisibility: false,
    jobsTableVisibility: false,
    jobRowSelected: null,
    inspectorJobsInJobsTable: [],
    jobModalOnlyViewVisibility: false,
    userCanBeInspector: null,
    userBasicCoursesInfo: null,
    userScope: null,
    belowPeopleInStructure: [],
    inpectorSelectedJobsInfo: { totalJobs: 0, correctPerformanceJobsNumber: 0 },
    usersStructureUpperN0: []
}


const reducersActions = {
    setCreateInspectorModalVisibility: (state, { payload }) => {
        state.createInspectorModalVisibility = payload
    },
    setEditInspectorModalVisibility: (state, { payload }) => {
        state.editInspectorModalVisibility = payload
    },
    setJobModalVisibility: (state, { payload }) => {
        state.createJobModalVisibility = payload
    },

    setCloseAuthModalVisibility: (state, { payload }) => {
        state.closeAuthModalVisibility = payload
    },

    setContinuousEvaluationModalVisibility: (state, { payload }) => {
        state.continuousEvaluationModalVisibility = payload
    },

    setDetailAuthTableVisibilityAction: (state, { payload: visibility }) => {
        state.detailsAuthTableVisibility = visibility
        const { inpectorDataDetailTable } = state
        const jobsObj = visibility ? getAllInspectorJobs([...inpectorDataDetailTable]) : []

        state.inspectorJobsInJobsTable = jobsObj
    },

    setJobsTableVisibility: (state, { payload }) => {
        state.jobsTableVisibility = payload
        state.jobRowSelected = !payload ? null : state.jobRowSelected
        state.detailsAuthTableVisibility = !payload
        state.inspectorRowSelected = !payload ? null : state.inspectorRowSelected

    },

    setBelowPeopleInStructure: (state, { payload }) => {
        state.belowPeopleInStructure = payload
    },
    setInspectorRowSelectedAction: (state, { payload }) => {
        if (!payload) state.inspectorRowSelected = null
        else {
            const { jobs } = payload
            const correctPerformanceJobsNumber = jobs.filter(({ peration_performed_correctly, active }) => !!peration_performed_correctly && !!active).length
            state.inspectorRowSelected = payload
            state.inpectorSelectedJobsInfo = { totalJobs: jobs.length, correctPerformanceJobsNumber }

        }
    },
    setInspectorBasicListRowSelectedAction: (state, { payload: inspectorBasicListRowSelected }) => {
        const { inspectorsList } = state
        const inspectorAuthDetails = inspectorBasicListRowSelected && setInspectorsDetailList([...inspectorsList], inspectorBasicListRowSelected)
        state.inpectorDataDetailTable = inspectorAuthDetails ?? state.inpectorDataDetailTable
        state.inspectorBasicListRowSelected = inspectorBasicListRowSelected
    },
    resetUserCanBeInspector: (state) => {
        state.userCanBeInspector = null
    },
    setUsersStructureUpperN0: (state, { payload }) => {
        state.usersStructureUpperN0 = payload
    },
    setJobRowSelected: (state, { payload }) => {
        state.jobRowSelected = payload
    },
    setJobModalOnlyViewVisibility: (state, { payload }) => {
        state.jobModalOnlyViewVisibility = payload
    }
}


const extraReducersActions = {

    inspectorsList: {
        [getInspectorsThunk.pending]: (state) => {
            state.inspectorsList = []
            state.loadingInspectorsList = true
            state.errorInspectorsList = false
        },
        [getInspectorsThunk.fulfilled]: (state, { payload: inspectorList }) => {
            const inspectorListWithScopeName = insertScopeNameInInspectorList(inspectorList)
            state.inspectorsList = inspectorListWithScopeName
            state.inpsectorBasicList = setInspectorsBasicList(inspectorListWithScopeName)
            state.loadingInspectorsList = false
            state.errorInspectorsList = false
        },
        [getInspectorsThunk.rejected]: (state) => {
            state.inspectorsList = []
            state.loadingInspectorsList = false
            state.errorInspectorsList = true
        },
    },

    getPossibleInspectorBasicInfo: {
        [getInspectorBasicInfoThunk.pending]: (state) => {
            state.userCanBeInspector = null;
            state.userScope = null;
            state.userBasicCoursesInfo = null;
        },
        [getInspectorBasicInfoThunk.fulfilled]: (state, { payload }) => {
            const { can_be_inspector_info, inspector_scope } = payload;
            state.userCanBeInspector = can_be_inspector_info.can_be_inspector;
            state.userScope = inspector_scope;
            state.userBasicCoursesInfo = can_be_inspector_info.can_be_inspector
                ? null
                : can_be_inspector_info.courses_data;
        },
        [getInspectorBasicInfoThunk.rejected]: (state) => {
            state.userCanBeInspector = null;
            state.userScope = null;
            state.userBasicCoursesInfo = null;
        },
    },

    postInspector: {
        [postInspectorThunk.fulfilled]: (state, { payload }) => {
            // state.inspectorBasicListRowSelected=null
            console.log("state.inspectorsList", current(state.inspectorsList))
            console.log("🚀 ~ payload:", payload)
            const inspectorAddedToList = [...state.inspectorsList, ...payload]
            const inspectorListWithScopeName = insertScopeNameInInspectorList(inspectorAddedToList)
            state.inpectorDataDetailTable = []
            state.inspectorBasicListRowSelected = null

            state.inspectorsList = inspectorListWithScopeName
            state.inpsectorBasicList = setInspectorsBasicList(inspectorListWithScopeName)
            state.userCanBeInspector = null;
            state.userScope = null;
            state.userBasicCoursesInfo = null;
        }
    },

    editInspector: {
        [editInspectorThunk.fulfilled]: (state, { payload: updatedInspectorAuths }) => {
            let updatedList = current(state.inspectorsList)
            const mainAuthInfo = updatedInspectorAuths[0]
            const { program_info, inspector_info, tutor_info, initial_date } = mainAuthInfo
            const newBasicRowSelected = { ...state.inspectorBasicListRowSelected, program_info, inspector_info, tutor_info, initial_date }


            for (let inpectorAuth of updatedInspectorAuths) {
                const indexToUpdate = updatedList.findIndex(({ id }) => id === inpectorAuth.id)
                updatedList = updatedList.with(indexToUpdate, inpectorAuth)
            }
            updatedList = insertScopeNameInInspectorList(updatedList)
            state.inspectorList = updatedList
            const inspectorDetailsUpdate = setInspectorsDetailList(updatedList, newBasicRowSelected)
            state.inpectorDataDetailTable = inspectorDetailsUpdate
            state.inpsectorBasicList = setInspectorsBasicList(updatedList)
            state.inspectorBasicListRowSelected = newBasicRowSelected



        },

    },

    postInpectorJob: {
        [postInspectorJobThunk.fulfilled]: (state, { payload }) => {
            const { inspectorBasicListRowSelected, inspectorRowSelected } = state
            let inspectorsListCopy = [...state.inspectorsList]
            const { inspetor_auths } = payload
            let jobsUpdatedRowSelected;
            inspetor_auths.forEach(authModify => {
                const authIndex = inspectorsListCopy.findIndex(({ id }) => id === authModify.id)
                const authUpdate = { ...inspectorsListCopy[authIndex], ...authModify }
                if (authModify.id === inspectorRowSelected?.id) {
                    jobsUpdatedRowSelected = authUpdate.jobs
                    state.inspectorRowSelected = { ...inspectorRowSelected, ...authUpdate }
                }
                inspectorsListCopy = inspectorsListCopy.with(authIndex, authUpdate)
            })

            const jobsCorrect = jobsUpdatedRowSelected?.filter(({ peration_performed_correctly, active }) => !!peration_performed_correctly && !!active).length
            const inspectorListWithScopeName = insertScopeNameInInspectorList(inspectorsListCopy)
            const inspectorDetailsUpdate = setInspectorsDetailList(inspectorListWithScopeName, inspectorBasicListRowSelected)
            state.inspectorsList = inspectorListWithScopeName
            state.inpectorDataDetailTable = inspectorDetailsUpdate
            state.inspectorJobsInJobsTable = getAllInspectorJobs(inspectorDetailsUpdate)
            state.inpsectorBasicList = setInspectorsBasicList(inspectorListWithScopeName)
            if (jobsUpdatedRowSelected && jobsCorrect !== undefined) {
                state.inpectorSelectedJobsInfo = { totalJobs: jobsUpdatedRowSelected.length, correctPerformanceJobsNumber: jobsCorrect }
            }
        },


    },


    closeInspectorAuth: {
        [closeInpectorAuthThunk.fulfilled]: (state, { payload: inspectorAuthUpdated }) => {
            const { inspectorBasicListRowSelected, inspectorRowSelected } = state
            const { indexValue } = inspectorRowSelected

            const inspectorListCopy = [...state.inspectorsList]
            const inspectorUpdatedIndexInList = inspectorListCopy.findIndex(({ id }) => id === inspectorAuthUpdated.id)
            const inspectorUpdated = { ...inspectorListCopy[inspectorUpdatedIndexInList], ...inspectorAuthUpdated }

            const uploadInspectorList = inspectorListCopy.with(inspectorUpdatedIndexInList, inspectorUpdated)

            const inspectorListWithScopeName = insertScopeNameInInspectorList(uploadInspectorList)

            state.inspectorsList = inspectorListWithScopeName
            state.inspectorRowSelected = { ...inspectorUpdated, indexValue }
            state.inpectorDataDetailTable = setInspectorsDetailList(inspectorListWithScopeName, inspectorBasicListRowSelected)
            state.inpsectorBasicList = setInspectorsBasicList([...uploadInspectorList])

        }
    },

    getContinuousEvaluation: {
        [getInspectorsContinuousEvaluationThunk.fulfilled]: (state, { payload: inspectorsIdsAndProgramas }) => {
            const { inspectors_ids, programs_ids, programs_info } = inspectorsIdsAndProgramas
            state.continuousEvInfo = { inspectorsIds: inspectors_ids, programsIds: programs_ids, programsInfo: programs_info }
        },
        [getInspectorsContinuousEvaluationThunk.rejected]: (state) => {
            state.continuousEvInfo = INITIAL_STATE.continuousEvInfo
        }
    }
}

const { getPossibleInspectorBasicInfo, inspectorsList, postInspector, closeInspectorAuth, postInpectorJob, getContinuousEvaluation, editInspector } = extraReducersActions

const authorizationOfInspectorsSlice = createSlice({
    name: 'authorizationOfInspectors',
    initialState: INITIAL_STATE,
    reducers: reducersActions,

    extraReducers: { ...getPossibleInspectorBasicInfo, ...inspectorsList, ...postInspector, ...closeInspectorAuth, ...postInpectorJob, ...getContinuousEvaluation, ...editInspector }

})

export const { setCreateInspectorModalVisibility, setEditInspectorModalVisibility, setBelowPeopleInStructure, setJobsTableVisibility, setInspectorRowSelectedAction, setJobModalVisibility, setCloseAuthModalVisibility, setContinuousEvaluationModalVisibility, setInpectorSelectedJobsInfo, setInspectorBasicListRowSelectedAction, setDetailAuthTableVisibilityAction, resetUserCanBeInspector, setUsersStructureUpperN0, setJobRowSelected, setJobModalOnlyViewVisibility } = authorizationOfInspectorsSlice.actions

export default authorizationOfInspectorsSlice.reducer