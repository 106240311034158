import * as global from 'global.js';
import { createSlice } from "@reduxjs/toolkit"
import {
    getAreas,
    postArea,
    putArea
} from './areaThunks'

const initialState = {
    status: {
        getAreas: 'idle',
        postArea: 'idle',
        putArea: 'idle',
    },
    error: null,
    selectedWorkPackageRow: null,
    modalVisibilityAction: { action: false, mode: null },
    modalData: {
        nameData: '',
        codeData: '',
        managersData: [],
        contractData: null,
        departmentData: null,
        descriptionData: ''
    },
    managerRoles: [global.rolN4, global.rolN5, global.rolN6, global.rolN7],
    usersManagers: [],
    areas: [],
}

const areaSlice = createSlice({
    name: 'area',
    initialState,
    reducers: {
        setSelectedWorkPackageRow: (state, action) => {
            state.selectedWorkPackageRow = action.payload
        },
        setModalVisibilityAction: (state, action) => {
            state.modalVisibilityAction = action.payload
        },
        filterUsersManagers: (state, action) => {
            state.usersManagers = action.payload.filter(user =>
                state.managerRoles.includes(user.rolUser)
            );
        },
        setModalData: (state, action) => {
            state.modalData = { ...state.modalData, ...action.payload };
        },
        resetModalData: (state) => {
            state.modalData = initialState.modalData
        }
    },
    extraReducers: (builder) => {
        // Handling getAreas
        builder
            .addCase(getAreas.pending, (state) => {
                state.status.getAreas = 'pending'
                state.error = null
            })
            .addCase(getAreas.rejected, (state, action) => {
                state.status.getAreas = 'rejected'
                state.error = action.error.message
            })
            .addCase(getAreas.fulfilled, (state, action) => {
                state.status.getAreas = 'fulfilled'
                state.areas = action.payload
            })

        // Handle postArea
        builder
            .addCase(postArea.pending, (state) => {
                state.status.postArea = 'pending'
                state.error = null
            })
            .addCase(postArea.rejected, (state, action) => {
                state.status.postArea = 'rejected'
                state.error = action.error.message
            })
            .addCase(postArea.fulfilled, (state, action) => {
                state.status.postArea = 'fulfilled'
                state.areas = [...state.areas, action.payload]
            })

        // Handle putArea
        builder
            .addCase(putArea.pending, (state) => {
                state.status.putArea = 'pending'
                state.error = null
            })
            .addCase(putArea.rejected, (state, action) => {
                state.status.putArea = 'rejected'
                state.error = action.error.message
            })
            .addCase(putArea.fulfilled, (state, action) => {
                state.status.putArea = 'fulfilled'
                const index = state.areas.findIndex(area => area.id === action.payload.id)
                if (index !== -1) {
                    state.areas[index] = action.payload
                }
            })

    }
})

export const {
    setSelectedWorkPackageRow,
    setModalVisibilityAction,
    filterUsersManagers,
    setModalData,
    resetModalData
} = areaSlice.actions

export const selectedWorkPackageRow = state => state.fuse.area.selectedWorkPackageRow
export const modalVisibilityAction = state => state.fuse.area.modalVisibilityAction
export const modalData = state => state.fuse.area.modalData
export const usersManagers = state => state.fuse.area.usersManagers
export const areas = state => state.fuse.area.areas
export const areasStatus = state => state.fuse.area.status.getAreas

export default areaSlice.reducer
