import { createAsyncThunk } from "@reduxjs/toolkit";
import { ERROR_TRY_AGAIN, SUCESS_MESSAGE_CREATED_AREA, SUCESS_MESSAGE_EDITED_AREA } from './utils/const'
import { GET_AREAS_REQUEST_OBJ, POST_AREA_REQUEST_OBJ, PUT_AREA_REQUEST_OBJ } from './utils/functions'
import axios from 'axios'
import { showMessage } from 'app/store/fuse/messageSlice'

export const getAreas = createAsyncThunk(
    'area/getAreas',
    async (_, { rejectWithValue }) => {
        try {
            const config = GET_AREAS_REQUEST_OBJ()

            const response = await axios(config)

            if (response.status !== 200) throw new Error(`Error: Received status code ${response.status}`)

            return response.data
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || 'Failed to fetch data'
            )
        }
    }
)

export const postArea = createAsyncThunk(
    'area/postArea',
    async (newData, { rejectWithValue, dispatch }) => {
        try {
            const config = POST_AREA_REQUEST_OBJ(newData)

            const response = await axios(config)

            if (response.status !== 201) throw new Error(`Error: Recieved status code ${response.status}`)

            dispatch(showMessage(SUCESS_MESSAGE_CREATED_AREA))

            const data = { id: response.data.id, ...newData }

            return data
        } catch (error) {
            dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(
                error || 'Failed to post data'
            )
        }
    }
)

export const putArea = createAsyncThunk(
    'area/putArea',
    async (dataToEdit, { rejectWithValue, dispatch }) => {
        const { id } = dataToEdit
        try {
            const config = PUT_AREA_REQUEST_OBJ(id, dataToEdit)

            const response = await axios(config)

            if (response.status !== 200) throw new Error(`Error: Recieved status code ${response.status}`)

            dispatch(showMessage(SUCESS_MESSAGE_EDITED_AREA))

            return dataToEdit
        } catch (error) {
            dispatch(showMessage(ERROR_TRY_AGAIN))
            return rejectWithValue(
                error || 'Failed to put data'
            )
        }
    }
)
