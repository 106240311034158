import {  useSelector } from "react-redux"
import { useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent, Grid, DialogActions, Button, FormGroup, Typography, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import { useStyles } from 'app/js/customHooks/GeneralHooks';
import { useCloseJobModal, usePostInspectorJob  } from '../utils/hooks';
import { DateYearFilterYMD } from 'components/GlobalComponents/ModalsFilters/DateFilterYMD';
import { DisabledFieldRenderName, DisabledUserFilter } from "components/GlobalComponents/ModalsFilters/DisabledFields";
import { ModalTexField } from "components/GlobalComponents/ModalsFilters/TextFields";
import {  CONTINUOUS_EV_MODAL_STATIC_INITIAL_STATE, JOB_MODAL_STATES_KEYS, JOB_MODAL_STATIC_INITIAL_STATES, SCOPE_NAMES_JOB_MODAL } from "../utils/consts";
import { tooglePerformanceCorrectly } from "../utils/functions";
import { UsersAutoComplete } from "components/GlobalComponents/ModalsFilters/AutocompleteFields";




export const InspectorJobModal = () => {
    const classes = useStyles();

    const { inspectorRowSelected, createJobModalVisibility, usersStructureUpperN0 } = useSelector(({ fuse }) => fuse.inspectorsComponent)
    // const { usersListAPI } = useSelector(({ fuse }) => fuse.userComponente)
    // const usersUpperN0 = useMemo(()=>{
    //     if(!usersListAPI.length) return []
    //     return usersListAPI.filter(({rolUser})=> rolUser!=='Technician')
    // },[usersListAPI])

    
    const { closeModal } = useCloseJobModal()

    const [jobModalState, setJobModalState] = useState({
        inspectorSelected: inspectorRowSelected.inspector_info,
        tutorSelected: inspectorRowSelected.tutor_info,
        programSelected: inspectorRowSelected.program_info,
        scopeSelected: {...CONTINUOUS_EV_MODAL_STATIC_INITIAL_STATE.scopesSelected, [inspectorRowSelected.scope]: true},
        ...JOB_MODAL_STATIC_INITIAL_STATES
    })

    const { inspectorSelected, tutorSelected, programSelected, profile, order, operation, evaluatedActivities, deviations, comments, operationPerformedCorrecly,  operationPerformedInCorrecly, jobDateSelected } = jobModalState
    const setModalValuesPropFunc = (key, value) => setJobModalState(prev => ({ ...prev, [key]: value }))




    const {postInspectorJob} =  usePostInspectorJob()

//     UsersAutoComplete
// export const UsersAutoComplete = ({ options, value, setValueFunc, label, disabled=false }) => (
    

    return (
        <Dialog open={createJobModalVisibility} onClose={closeModal} fullWidth maxWidth="md">

            <DialogTitle classes={{ root: classes.customDialogTitle }}  >
                Create job
            </DialogTitle>

            <DialogContent>

                <Grid container spacing={4} style={{ marginTop: '1px' }}>
                    {/* Columna izquierda */}
                    <Grid item xs={12} sm={6}>
                        {/* Grid container para la columna izquierda */}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DisabledUserFilter optionAndValue={inspectorSelected}  label={'Inspector'} />
                            </Grid>

                            <Grid item xs={12}>
                                <DisabledFieldRenderName optionAndValue={programSelected}  label={'Program'} />
                            </Grid>

                            <Grid item xs={12}>
                                <UsersAutoComplete options = {usersStructureUpperN0} value = {tutorSelected} setValueFunc = {(value) => setModalValuesPropFunc(JOB_MODAL_STATES_KEYS.tutorSelected, value)} label = {'Supervised by'} />
                            </Grid>

                            <Grid item xs={12}>
                                <DisabledFieldRenderName optionAndValue={{name: SCOPE_NAMES_JOB_MODAL[inspectorRowSelected.scope], id: 0}} label={'Scope'} />
                            </Grid>

                            <Grid item xs={12}>
                                <ModalTexField label={'Profile'} value={profile} onChangeFunc={(value) => setModalValuesPropFunc(JOB_MODAL_STATES_KEYS.profile, value)} />
                            </Grid>

                            <Grid item xs={12}>
                                <ModalTexField label={'Order'} value={order} onChangeFunc={(value) => setModalValuesPropFunc(JOB_MODAL_STATES_KEYS.order, value)} />
                            </Grid>

                            <Grid item xs={12}>
                                <ModalTexField label={'Operation'} value={operation} onChangeFunc={(value) => setModalValuesPropFunc(JOB_MODAL_STATES_KEYS.operation, value)} />
                            </Grid>

                            <Grid item xs={12}>
                                <ModalTexField label={'Related activities'} value={evaluatedActivities} rows={3} onChangeFunc={(value) => setModalValuesPropFunc(JOB_MODAL_STATES_KEYS.evaluatedActivities, value)} />
                            </Grid>


                        </Grid>

                    </Grid>



                    {/* Columna derecha */}
                    <Grid item xs={12} sm={6}>
                        {/* Grid container para la columna izquierda */}
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Typography variant="p">Operation performed correctly</Typography>
                                <FormGroup className="flex-row">
                                    <FormControlLabel control={<Checkbox onChange={() => tooglePerformanceCorrectly(JOB_MODAL_STATES_KEYS, setModalValuesPropFunc,true)} checked={operationPerformedCorrecly} />} label="Yes" />
                                    <FormControlLabel  control={<Checkbox onChange={() => tooglePerformanceCorrectly(JOB_MODAL_STATES_KEYS, setModalValuesPropFunc,false)} checked={operationPerformedInCorrecly} />} label="No" />
                                </FormGroup>
                            </Grid>


                            <Grid item xs={12}>
                                <DateYearFilterYMD componentContextDate={jobDateSelected} setFatherDate={(value) => setModalValuesPropFunc(JOB_MODAL_STATES_KEYS.jobDateSelected, value)} label={'Job date'} />
                            </Grid>

                            <Grid item xs={12}>
                                <ModalTexField label={'Deviations'} value={deviations} rows={2} onChangeFunc={(value) => setModalValuesPropFunc(JOB_MODAL_STATES_KEYS.deviations, value)} />
                            </Grid>

                            <Grid item xs={12}>
                                <ModalTexField label={'Coments'} value={comments} rows={3} onChangeFunc={(value) => setModalValuesPropFunc(JOB_MODAL_STATES_KEYS.comments, value)} />
                            </Grid>


                        </Grid>


                    </Grid>

                    <Grid item xs={24}>
                        <Typography variant='p' className='italic'>
                            During the mentoring phase, the mentor should supervise inspections representative of the tasks that the inspector candidate will perform once the authorization is granted, and should supervise as many inspections as deemed necessary to ensure the inspector's autonomy in the scopes to be authorized.
                        </Typography>

                    </Grid>


                </Grid>


            </DialogContent>

            <DialogActions>
                <Button onClick={closeModal}>
                    Close
                </Button>
                <Button onClick={()=>postInspectorJob(jobModalState)}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )

}