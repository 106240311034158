import { lazy, Suspense, useState } from 'react'
import { useDispatch } from 'react-redux'
import FusePageCarded from '@fuse/core/FusePageCarded'
import { Tabs, Tab } from '@mui/material'
import { LinearLoader } from 'components/GlobalComponents/loaders/loader.jsx'
import { DEFAULT_TAB_VALUE_PROPS, TAB_LABEL, TABS_VALUES } from './utils/consts.js'

const Division = lazy(() => import('../Division/Division'))
const SubDivision = lazy(() => import('../SubDivision/SubDivision'))
const Program = lazy(() => import('../Program/Program'))
const Assembly = lazy(() => import('../Assembly/Assembly'))
const SubAssembly = lazy(() => import('../SubAssembly/SubAssembly'))

import { cambiarValorSeleccionAction as changeDivisionRowValue } from 'components/Gestion/Division/store/actions.js'
import { cambiarValorSeleccionAction as changeSubDivisionRowValue } from 'components/Gestion/SubDivision/store/actions.js'
import { cambiarValorSeleccionAction as changeProgramRowValue } from 'components/Gestion/Programa/store/actions.js'
import { cambiarValorSeleccionAction as changeAssemblyRowValue } from 'components/Gestion/Conjunto/store/actions.js'
import { cambiarValorSeleccionAction as changeSubAssemblyRowValue } from 'components/Gestion/SubConjunto/store/actions.js'

export const DivisionsView = () => {
  const dispatch = useDispatch()
  const [tabvalue, setTabValue] = useState(TABS_VALUES.division)

  const modalView = {
    [TABS_VALUES.division]: (
      <Suspense fallback={<LinearLoader />}>
        <Division />
      </Suspense>
    ),
    [TABS_VALUES.subDivision]: (
      <Suspense fallback={<LinearLoader />}>
        <SubDivision />
      </Suspense>
    ),
    [TABS_VALUES.program]: (
      <Suspense fallback={<LinearLoader />}>
        <Program />
      </Suspense>
    ),
    [TABS_VALUES.assembly]: (
      <Suspense fallback={<LinearLoader />}>
        <Assembly />
      </Suspense>
    ),
    [TABS_VALUES.subAssembly]: (
      <Suspense fallback={<LinearLoader />}>
        <SubAssembly />
      </Suspense>
    ),
  }

  const handleClearSelectedRows = () => {
    const clearRowValue = {
      [TABS_VALUES.division]: changeDivisionRowValue,
      [TABS_VALUES.subDivision]: changeSubDivisionRowValue,
      [TABS_VALUES.program]: changeProgramRowValue,
      [TABS_VALUES.assembly]: changeAssemblyRowValue,
      [TABS_VALUES.subAssembly]: changeSubAssemblyRowValue,
    }

    dispatch(clearRowValue[tabvalue](null))
  }

  return (
    <FusePageCarded
      content={
        <section className="flex flex-col h-full relative">
          <Tabs
            value={tabvalue}
            onChange={(event, value) => {
              setTabValue(value)
              handleClearSelectedRows()
            }}
            {...DEFAULT_TAB_VALUE_PROPS}
          >
            <Tab value={TABS_VALUES.division} label={TAB_LABEL.division} />
            <Tab value={TABS_VALUES.subDivision} label={TAB_LABEL.subDivision} />
            <Tab value={TABS_VALUES.program} label={TAB_LABEL.program} />
            <Tab value={TABS_VALUES.assembly} label={TAB_LABEL.assembly} />
            <Tab value={TABS_VALUES.subAssembly} label={TAB_LABEL.subAssembly} />
          </Tabs>
          {modalView[tabvalue]}
        </section>
      }
    />
  )
}
