import FusePageCarded from '@fuse/core/FusePageCarded';
import Box from '@mui/material/Box';
import { InspectorsTopButtoms } from './Components/TopButtoms';
import { CreateInspectorModal } from './Modals/CreateInspector';
import { useGetBelowPeopleInStructure, useGetInspectorsList, useGetUserStructureUpperN0 } from './utils/hooks';
import { useSelector } from 'react-redux';
import { InspectorsTable } from './Components/InspectorsTable';
import { InspectorJobModal } from './Modals/JobModal';
import { CloseInspectorAth } from './Modals/CloseInspectorAth';
import { ContinuousEvJobModal } from './Modals/ContinuousEvJobModal';
import Divider from '@mui/material/Divider';





export const AuthorizationOfInspectors = () => {
    const { createInspectorModalVisibility, createJobModalVisibility, closeAuthModalVisibility, continuousEvaluationModalVisibility, editInspectorModalVisibility, jobModalOnlyViewVisibility } = useSelector(({ fuse }) => fuse.inspectorsComponent)

    const createEditInspectorModalVisibility = !!createInspectorModalVisibility || !!editInspectorModalVisibility

    useGetBelowPeopleInStructure()
    useGetInspectorsList()
    useGetUserStructureUpperN0()
    // useGetContinuousEvaluationInspectors()


    return (
      <FusePageCarded
        content={
          <Box className="h-md w-full overflow-none">
            <section className="w-full overflow-hidden" style={{ height: 'calc(100dvh - 17%)' }}>
              <InspectorsTopButtoms />
              <Divider />
              <InspectorsTable />
              {createEditInspectorModalVisibility && <CreateInspectorModal />}
              {!!createJobModalVisibility && <InspectorJobModal />}
              {!!closeAuthModalVisibility && <CloseInspectorAth />}
              {(!!continuousEvaluationModalVisibility || !!jobModalOnlyViewVisibility) && (
                <ContinuousEvJobModal />
              )}
            </section>
          </Box>
        }
      />
    )
}
