import React, { useEffect, useState, useRef, Fragment } from 'react'
import {
  useTable,
  useFilters,
  useGroupBy,
  useExpanded,
  usePagination,
  useSortBy,
} from 'react-table'
import { Autocomplete, TextField, IconButton, Box, Button } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { es } from 'date-fns/locale'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TodayIcon from '@mui/icons-material/Today'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import { CustomPagination } from './Components/CustomPagination'
import {
  exportToExcel,
  selectRowFunc,
  handleFilterChange,
  handleFilterChangeDate,
  rowColorStyle,
  updateEmptyRows,
  exportToExcelImprovementProposal,
} from './utils/functions'
import { TableRows } from './Components/TableRows'
import dayjs from 'dayjs'
import ExpandableRow from './Components/ExpandableRow'

export const Table = ({
  rowsProp: data,
  columnsProp: columns,
  funcionSetValue,
  cellMaxWidth,
  tableWidth = '100%',
  selectedRowFunctions = null,
  apiCustomPagination = {},
  isImprovementProposal = false,
}) => {
  // State Hooks
  const [filteredData, setFilteredData] = useState(data)
  const [selectedRowIndex, setselectedRowIndex] = useState(null)
  const [valorSelect, setValorSelect] = useState(null)
  const [dateValue, setDateValue] = useState(null)
  const [dateValueExtra, setDateValueExtra] = useState(null)
  const [emptyRow, setEmptyRow] = useState()
  const [activeFilters, setActiveFilters] = useState([])
  const [filterValues, setFilterValues] = useState({})
  const [openDetailedData, setOpenDetailedData] = useState({})
  const [totalDetails, setTotalDetails] = useState({})
  const [dateRanges, setDateRanges] = React.useState({})
  const [hoveredColumns, setHoveredColumns] = useState({})
  const [rangeInputSize, setRangeInputSize] = useState(0)

  // Sync filteredData with data when data changes
  useEffect(() => {
    const activeColumn = Object.keys(dateRanges).find(
      (key) => dateRanges[key].start && dateRanges[key].end,
    )

    if (activeColumn) {
      const { start, end } = dateRanges[activeColumn]
      const newFilteredData = data.filter((item) => {
        if (!item[activeColumn]) return false
        const itemDate = dayjs(item[activeColumn])
        return itemDate.isAfter(start, 'day') && itemDate.isBefore(end, 'day')
      })
      setFilteredData(newFilteredData)
    } else {
      setFilteredData(data)
    }
  }, [data, dateRanges])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    page,
    setFilter,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, filters },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        filters: activeFilters,
        sortBy: [
          { id: 'mes_tratado', desc: false },
          { id: 'deli_ID', desc: false },
          { id: 'sort_date_desc', desc: true },
        ],
      },
      autoResetPage: false,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
  )

  // useRef Hooks
  const tableContainerRef = useRef(null)
  const tableMiddleContainerRef = useRef(null)
  const tableRef = useRef(null)
  const tableBodyRef = useRef(null)
  const initialDataLength = useRef(filteredData.length || 0)
  const tableHeadRef = useRef(null)
  const tableFootRef = useRef(null)
  const rangeDateInputSize = useRef(null)

  // Update selection if data length changes
  useEffect(() => {
    if (filteredData.length !== initialDataLength.current) {
      resetTableRowSelection()
      initialDataLength.current = filteredData.length
    }
  }, [filteredData])

  const { setClearSelectedRow, clearSelectedRow } = selectedRowFunctions || {}
  useEffect(() => {
    if (clearSelectedRow) {
      resetTableRowSelection()
      setClearSelectedRow(false)
    }
  }, [clearSelectedRow])

  // Update empty rows on page or dimension changes
  useEffect(() => {
    if (tableContainerRef.current && tableBodyRef.current) {
      updateEmptyRows(
        page.length,
        tableBodyRef,
        tableMiddleContainerRef,
        tableHeadRef,
        tableFootRef,
        setEmptyRow,
        columns,
      )
    }
  }, [page.length])

  // Reset table row selection if data length changes
  useEffect(() => {
    if (filteredData.length) {
      resetTableRowSelection()
      resetPagination()
      initialDataLength.current = filteredData.length
    }
  }, [filteredData.length])

  // Update range input size
  useEffect(() => {
    const element = rangeDateInputSize.current
    if (!element) return

    const updateWidth = () => {
      setRangeInputSize(element.offsetWidth)
    }

    const resizeObserver = new ResizeObserver(updateWidth)
    resizeObserver.observe(element)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  // Function to reset pagination
  const resetPagination = () => {
    setPageSize(10)
    gotoPage(0)
  }

  // Function to reset table row selection
  const resetTableRowSelection = () => {
    setselectedRowIndex(null)
    funcionSetValue(null)
  }

  // Custom pagination properties passed to pagination component
  const CUSTOM_PAGINATION_PROPS = {
    setValorSelect,
    setDateValue,
    setDateValueExtra,
    columns,
    setFilter,
    tableContainerRef,
    tableMiddleContainerRef,
    pageIndex,
    rows,
    pageSize,
    filters,
    page,
    filteredData,
    resetTableRowSelection,
    selectedRowIndex,
    setselectedRowIndex,
    exportToExcel,
    exportToExcelImprovementProposal,
    setPageSize,
    gotoPage,
    tableContainerRef,
    setActiveFilters,
    setFilterValues,
    apiCustomPagination,
    dateRanges,
    isImprovementProposal,
  }

  const hasExpander = () => {
    return headerGroups.some((headerGroup) =>
      headerGroup.headers.some((column) => column.type === 'expand'),
    )
  }

  const handleFilterChangeLocal = (columnId, selectedValue) => {
    setFilterValues((prev) => ({
      ...prev,
      [columnId]: selectedValue,
    }))
    handleFilterChange(selectedValue, columnId, setFilter, tableMiddleContainerRef, gotoPage)
  }

  const monthOrder = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  }

  const handleOpenSubTotalDetails = ({ type }) => {
    handleSubTotalDetails(type)
    setOpenDetailedData((prev) => ({
      ...prev,
      [type]: {
        open: !prev[type]?.open,
      },
    }))
  }

  const handleSubTotalDetails = (type) => {
    const total = calculateColumnTotal(type)

    setTotalDetails((prev) => ({
      ...prev,
      [type]: total,
    }))
  }

  const calculateColumnTotal = (type) => {
    const dataToFind = type.split('_')[1]
    return filteredData
      .reduce((sum, row) => {
        const quantity = Number(row.quantity) || 0
        const unit = Number(row[dataToFind]) || 0
        return sum + quantity * unit
      }, 0)
      .toFixed(2)
  }

  const handleDateRangeChange = (columnId, type, newValue) => {
    const dateValue = newValue ? dayjs(newValue).format('YYYY-MM-DD') : null
    setDateRanges((prev) => ({
      ...prev,
      [columnId]: {
        ...prev[columnId],
        [type]: dateValue,
      },
    }))
  }

  const handleClearDateFilter = (columnId) => {
    setDateRanges((prev) => ({
      ...prev,
      [columnId]: { start: null, end: null },
    }))
    handleFilterChangeDate('', columnId, 'start', setFilter, gotoPage)
    handleFilterChangeDate('', columnId, 'end', setFilter, gotoPage)
    hoveredColumns[columnId] = false
  }

  return (
    <>
      <div ref={tableContainerRef} className="flex flex-col h-full overflow-hidden relative">
        <div ref={tableMiddleContainerRef} className="flex-grow overflow-y-auto">
          <table
            ref={tableRef}
            {...getTableProps()}
            className={`min-w-full h-full border-collapse overflow-auto ${tableWidth}`}
          >
            <thead ref={tableHeadRef}>
              {headerGroups.map((headerGroup, i) => (
                <tr
                  key={i}
                  {...headerGroup.getHeaderGroupProps()}
                  className="sticky top-0 z-10 shadow-[_0px_2px_5px#00000015] bg-white"
                >
                  {headerGroup.headers.map((column, i) => (
                    <th
                      key={i}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        width: column.width ? `${column.width}px` : undefined,
                        maxWidth: column.maxWidth ? `${column.maxWidth}px` : undefined,
                      }}
                      className={`text-center h-[60px] font-medium text-base ${
                        column.isSorted ? (column.isSortedDesc ? 'bg-gray-200' : 'bg-gray-100') : ''
                      }`}
                    >
                      <p className="m-0">
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ExpandLessIcon />
                          )
                        ) : (
                          ''
                        )}
                      </p>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} ref={tableBodyRef}>
              {!page.length || !rows.length ? (
                <tr className="h-md">
                  <td>
                    <div className="w-full flex items-center justify-center absolute">No data</div>
                  </td>
                </tr>
              ) : (
                <>
                  {page.map((row, i) => {
                    prepareRow(row)
                    row.visiblePosition = i
                    return (
                      <Fragment key={i}>
                        <tr
                          {...row.getRowProps()}
                          onClick={() =>
                            selectRowFunc({
                              row,
                              funcionSetValue,
                              selectedRowIndex,
                              setselectedRowIndex,
                            })
                          }
                          className={`cursor-pointer h-[60px] ${rowColorStyle(
                            row,
                            selectedRowIndex,
                          )}`}
                        >
                          <TableRows row={row} cellMaxWidth={cellMaxWidth} />
                        </tr>
                        {hasExpander() && (
                          <ExpandableRow
                            row={row}
                            columns={columns}
                            headerGroups={headerGroups}
                            data={data}
                            selectedRowIndex={selectedRowIndex}
                          />
                        )}
                      </Fragment>
                    )
                  })}
                  {emptyRow}
                </>
              )}
            </tbody>
            <tfoot
              ref={tableFootRef}
              className="sticky -bottom-1 h-80 bg-white shadow-[#00000015_0px_-4px_5px]"
            >
              {footerGroups.map((group, i) => (
                <tr {...group.getFooterGroupProps()} key={i}>
                  {group.headers.map((column) => (
                    <td {...column.getFooterProps()} className="text-center">
                      {column.render('Footer')}
                    </td>
                  ))}
                </tr>
              ))}
              {headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {headerGroup.headers.map((column, j) => (
                    <th
                      {...column.getHeaderProps()}
                      className={`text-center ${(column.id === 'total_price' || column.id === 'total_hours') && 'h-[55px]'}`}
                    >
                      <div className="relative">
                        {(column.id === 'total_price' || column.id === 'total_hours') && (
                          <div className="absolute -top-32 left-0 right-0 flex flex-col items-center">
                            {openDetailedData[column.id]?.open && (
                              <div
                                onClick={() =>
                                  handleOpenSubTotalDetails({
                                    type: column.id,
                                  })
                                }
                                className="cursor-pointer text-xs absolute -top-64 left-0 right-0 flex flex-col items-center justify-center z-20 p-2 bg-gray-100 rounded-sm shadow-md h-56"
                              >
                                <div>Sub Total {column.render('Header').split(' ')[1]}</div>
                                <div>{totalDetails[column.id]}</div>
                              </div>
                            )}

                            <IconButton
                              className="w-full h-24 rounded-sm bg-gray-300"
                              onClick={() =>
                                handleOpenSubTotalDetails({
                                  type: column.id,
                                })
                              }
                            >
                              {openDetailedData[column.id]?.open ? (
                                <ExpandMoreIcon />
                              ) : (
                                <ExpandLessIcon />
                              )}
                            </IconButton>
                          </div>
                        )}
                        {column.type === 'string' && (
                          <TextField
                            label={column.render('Header')}
                            id={`${column.render('Header')}-${i}-${j}`}
                            value={column.filterValue || ''}
                            size="small"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                              setActiveFilters((prev) => {
                                const updatedFilters = prev.filter(
                                  (filter) => filter.id !== column.id,
                                )
                                if (e.target.value === '') return updatedFilters
                                return [...updatedFilters, { id: column.id, value: e.target.value }]
                              })
                              handleFilterChange(
                                e.target.value,
                                column.id,
                                setFilter,
                                tableMiddleContainerRef,
                                gotoPage,
                              )
                            }}
                            sx={{
                              '& .MuiInputBase-input': {
                                padding: '8px 8px',
                              },
                            }}
                          />
                        )}
                      </div>

                      {column.type === 'list' && (
                        <Autocomplete
                          id={`tags-outlined-${i}`}
                          clearOnEscape
                          value={
                            filterValues[column.id] ? { label: filterValues[column.id] } : null
                          }
                          getOptionLabel={(option) => option.label || ''}
                          options={(() => {
                            let filteredData = data
                            Object.keys(filterValues).forEach((filterKey) => {
                              const filterValue = filterValues[filterKey]
                              if (filterValue && filterKey !== column.id) {
                                filteredData = filteredData.filter(
                                  (row) => row[filterKey] === filterValue,
                                )
                              }
                            })
                            let uniqueOptions = [
                              ...new Set(filteredData.map((obj) => obj[column.render('id')])),
                              ...(column.customMultipleSortable
                                ? column.customMultipleSortable.sortableKeys
                                : []),
                            ]
                            if (Array.isArray(uniqueOptions)) {
                              uniqueOptions = [
                                ...new Set(uniqueOptions.flatMap((option) => option)),
                              ]
                            }
                            if (column.id === 'mes_tratado') {
                              uniqueOptions.sort((a, b) => monthOrder[a] - monthOrder[b])
                            } else if (column.id === 'deli_ID') {
                              uniqueOptions.sort((a, b) => a.localeCompare(b))
                            } else if (column.id === 'sort_date_desc') {
                              uniqueOptions.sort((a, b) => new Date(a) - new Date(b))
                            }
                            return uniqueOptions.map((option) => {
                              const isSortableKey =
                                column.customMultipleSortable?.sortableKeys.includes(option)
                              const group = isSortableKey ? 'Multiple' : 'Single'
                              return {
                                label: option,
                                group: column.customMultipleSortable ? group : '',
                              }
                            })
                          })()}
                          groupBy={(option) => option.group}
                          onChange={(event, value) => {
                            const selectedValue = value ? value.label : null
                            const isSortableKey =
                              column.customMultipleSortable &&
                              column.customMultipleSortable.sortableKeys.includes(selectedValue)
                            handleFilterChangeLocal(
                              column.id,
                              isSortableKey
                                ? column.customMultipleSortable[selectedValue]
                                : selectedValue,
                            )
                          }}
                          filterSelectedOptions
                          isOptionEqualToValue={(option, value) =>
                            option.label === (value?.label || '')
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              label={column.render('Header')}
                              size="small"
                              fullWidth
                              sx={{
                                '& .MuiInputBase-input': {
                                  padding: '8px 8px',
                                },
                              }}
                            />
                          )}
                        />
                      )}

                      {column.type === 'html' && (
                        <Autocomplete
                          disabled
                          id={`${column.render('Header')}-${i}-${j}`}
                          size="small"
                          options={['Success', 'Failure']}
                          getOptionLabel={(option) => option}
                          value={filterValues[column.id] || ''}
                          onChange={(event, newValue) => {
                            const value = newValue ? newValue : ''
                            setFilterValues((prev) => ({
                              ...prev,
                              [column.id]: value,
                            }))
                            handleFilterChange(
                              value,
                              column.id,
                              setFilter,
                              tableMiddleContainerRef,
                              gotoPage,
                            )
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={column.render('Header')}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          )}
                        />
                      )}

                      {column.type === 'date' && (
                        <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={column.render('Header')}
                            size="small"
                            fullWidth
                            id={`date-${i}-${j}`}
                            inputFormat="yyyy-MM-dd"
                            format="yyyy-MM-dd"
                            value={dateValue}
                            onChange={(newValue) => {
                              if (newValue && !isNaN(newValue.getTime())) {
                                const localDate = dayjs(newValue).startOf('day')
                                const formattedDate = localDate.format('YYYY-MM-DD')
                                setActiveFilters((prev) => {
                                  const updatedFilters = prev.filter(
                                    (filter) => filter.id !== column.id,
                                  )
                                  return [
                                    ...updatedFilters,
                                    { id: column.id, value: formattedDate },
                                  ]
                                })
                                handleFilterChangeDate(
                                  formattedDate,
                                  column.id,
                                  'start',
                                  setFilter,
                                  gotoPage,
                                )
                                setDateValue(formattedDate)
                              } else {
                                handleFilterChangeDate('', column.id, 'start', setFilter, gotoPage)
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                  '& .MuiInputBase-input': {
                                    padding: '8px 8px',
                                  },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}

                      {column.type === 'dateRange' && (
                        <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
                          <Box
                            ref={rangeDateInputSize}
                            display="flex"
                            gap={2}
                            className={`relative justify-around border-x-1 border-b-1 border-gray-500/60 rounded-bl-[4px] rounded-br-[4px] ${
                              dateRanges[column.id]?.start || dateRanges[column.id]?.end
                                ? 'border-t-none'
                                : 'border-t-1 rounded-tl-[4px] rounded-tr-[4px]'
                            }`}
                          >
                            {/* Start Date Picker */}
                            <DatePicker
                              className="w-80"
                              value={dateRanges[column.id]?.start || null}
                              onChange={(newValue) =>
                                handleDateRangeChange(column.id, 'start', newValue)
                              }
                              components={{
                                OpenPickerIcon: TodayIcon,
                                PaperContent: ({ children }) => (
                                  <>
                                    <div
                                      style={{
                                        padding: '12px 16px',
                                        fontWeight: '600',
                                        borderBottom: '1px solid #e0e0e0',
                                        backgroundColor: '#f5f5f5',
                                      }}
                                    >
                                      Select Start Date
                                    </div>
                                    {children}
                                  </>
                                ),
                              }}
                              renderDay={(day, _, pickersDayProps) => {
                                const start = dateRanges[column.id]?.start
                                const end = dateRanges[column.id]?.end
                                const today = dayjs().startOf('day')
                                const currentDay = dayjs(day).startOf('day')

                                const isToday = currentDay.isSame(today, 'day')
                                const isStart = start && currentDay.isSame(start, 'day')
                                const isEnd = end && currentDay.isSame(end, 'day')
                                const isInRange =
                                  start &&
                                  end &&
                                  currentDay.isAfter(start) &&
                                  currentDay.isBefore(end)

                                return (
                                  <PickersDay
                                    {...pickersDayProps}
                                    day={day}
                                    sx={{
                                      ...(isStart && {
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                        '&:hover': { backgroundColor: 'primary.dark' },
                                      }),
                                      ...(isEnd && {
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                        '&:hover': { backgroundColor: 'primary.dark' },
                                      }),
                                      ...(isInRange && {
                                        backgroundColor: '#336b8e',
                                        color: 'white',
                                        '&:hover': { backgroundColor: '#336b8e', opacity: 0.5 },
                                      }),
                                      ...(isToday && {
                                        backgroundColor: '#8dc63f',
                                        color: '#272727',
                                        '&:hover': { backgroundColor: '#a8d080' },
                                      }),
                                    }}
                                  />
                                )
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  InputLabelProps={{ shrink: true }}
                                  sx={{
                                    '& .MuiInputBase-input': {
                                      padding: '8px 5px',
                                      width: '0%',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      border: 'none',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      display: 'none',
                                    },
                                  }}
                                />
                              )}
                            />

                            {/* Separator */}
                            <span className="flex flex-col items-center justify-center text-center text-xs text-gray-500">
                              –
                            </span>

                            {/* End Date Picker */}
                            <DatePicker
                              className="w-80"
                              value={dateRanges[column.id]?.end || null}
                              onChange={(newValue) =>
                                handleDateRangeChange(column.id, 'end', newValue)
                              }
                              minDate={dateRanges[column.id]?.start || null}
                              disabled={!dateRanges[column.id]?.start}
                              components={{
                                PaperContent: ({ children }) => (
                                  <>
                                    <div
                                      style={{
                                        padding: '12px 16px',
                                        fontWeight: '600',
                                        borderBottom: '1px solid #e0e0e0',
                                        backgroundColor: '#f5f5f5',
                                      }}
                                    >
                                      {dateRanges[column.id]?.start
                                        ? `Select End Date (after ${dayjs(dateRanges[column.id].start).format('MMM D, YYYY')})`
                                        : 'Select End Date'}
                                    </div>
                                    {children}
                                  </>
                                ),
                              }}
                              renderDay={(day, _, pickersDayProps) => {
                                const start = dateRanges[column.id]?.start
                                const end = dateRanges[column.id]?.end
                                const today = dayjs().startOf('day')
                                const currentDay = dayjs(day).startOf('day')

                                const isToday = currentDay.isSame(today, 'day')
                                const isStart = start && currentDay.isSame(start, 'day')
                                const isEnd = end && currentDay.isSame(end, 'day')
                                const isInRange =
                                  start &&
                                  end &&
                                  currentDay.isAfter(start) &&
                                  currentDay.isBefore(end)

                                return (
                                  <PickersDay
                                    {...pickersDayProps}
                                    day={day}
                                    sx={{
                                      ...(isStart && {
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                        '&:hover': { backgroundColor: 'primary.dark' },
                                      }),
                                      ...(isEnd && {
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                        '&:hover': { backgroundColor: 'primary.dark' },
                                      }),
                                      ...(isInRange && {
                                        backgroundColor: '#336b8e',
                                        color: 'white',
                                        '&:hover': { backgroundColor: '#336b8e', opacity: 0.5 },
                                      }),
                                      ...(isToday && {
                                        backgroundColor: '#8dc63f',
                                        color: '#272727',
                                        '&:hover': { backgroundColor: '#a8d080' },
                                      }),
                                    }}
                                  />
                                )
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  InputLabelProps={{ shrink: true }}
                                  disabled={!dateRanges[column.id]?.start}
                                  sx={{
                                    '& .MuiInputBase-input': { padding: '8px 5px', width: '0%' },
                                    '& .MuiOutlinedInput-root': { border: 'none' },
                                    '& .MuiOutlinedInput-notchedOutline': { display: 'none' },
                                  }}
                                />
                              )}
                            />

                            {/* Hover and Clear Functionality */}
                            <Box
                              className="absolute w-full"
                              onMouseEnter={() =>
                                setHoveredColumns((prev) => ({ ...prev, [column.id]: true }))
                              }
                              onMouseLeave={() =>
                                setHoveredColumns((prev) => ({ ...prev, [column.id]: false }))
                              }
                            >
                              {/* Display Selected Date Range */}
                              <div
                                style={{ width: rangeInputSize }}
                                className={`text-center text-xs text-gray-500 absolute -left-1 transform -translate-x-1/2 transition-opacity duration-200 ${
                                  dateRanges[column.id]?.start || dateRanges[column.id]?.end
                                    ? 'h-32 -top-32 border-t-1 border-x-1 border-gray-500/60 rounded-tl-[4px] rounded-tr-[4px] pt-2'
                                    : ''
                                }`}
                              >
                                <span
                                  className={`${
                                    hoveredColumns[column.id] &&
                                    (dateRanges[column.id]?.start || dateRanges[column.id]?.end)
                                      ? 'opacity-0'
                                      : 'opacity-100'
                                  }`}
                                >
                                  {dateRanges[column.id]?.start
                                    ? dateRanges[column.id]?.end
                                      ? `${dayjs(dateRanges[column.id].start).format('YYYY-MM-DD')} to ${dayjs(dateRanges[column.id].end).format('YYYY-MM-DD')}`
                                      : `${dayjs(dateRanges[column.id].start).format('YYYY-MM-DD')} to`
                                    : dateRanges[column.id]?.end
                                      ? `to ${dayjs(dateRanges[column.id].end).format('YYYY-MM-DD')}`
                                      : ''}
                                </span>
                              </div>

                              {/* Clear Button */}
                              {hoveredColumns[column.id] &&
                                (dateRanges[column.id]?.start || dateRanges[column.id]?.end) && (
                                  <div className="absolute -top-28 left-0 transform -translate-x-1/2 w-full">
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      size="small"
                                      sx={{
                                        minWidth: '97%',
                                        padding: '3px',
                                      }}
                                      className="transition-opacity duration-200 opacity-100 rounded-[4px]"
                                      onClick={() => handleClearDateFilter(column.id)}
                                    >
                                      <EventBusyIcon />
                                    </Button>
                                  </div>
                                )}
                            </Box>
                          </Box>
                        </LocalizationProvider>
                      )}

                      {column.type === 'dateExtra' && (
                        <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={column.render('Header')}
                            size="small"
                            fullWidth
                            id={`dateExtra-${i}-${j}`}
                            inputFormat="yyyy-MM-dd"
                            format="yyyy-MM-dd"
                            value={dateValueExtra}
                            onChange={(newValue) => {
                              if (newValue && !isNaN(newValue.getTime())) {
                                const localDate = dayjs(newValue).startOf('day')
                                const formattedDate = localDate.format('YYYY-MM-DD')
                                setActiveFilters((prev) => {
                                  const updatedFilters = prev.filter(
                                    (filter) => filter.id !== column.id,
                                  )
                                  return [
                                    ...updatedFilters,
                                    { id: column.id, value: formattedDate },
                                  ]
                                })
                                handleFilterChangeDate(
                                  formattedDate,
                                  column.id,
                                  'end',
                                  setFilter,
                                  gotoPage,
                                )
                                setDateValueExtra(formattedDate)
                              } else {
                                handleFilterChangeDate('', column.id, 'end', setFilter, gotoPage)
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                  '& .MuiInputBase-input': {
                                    padding: '8px 8px',
                                  },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
        </div>
        <CustomPagination {...CUSTOM_PAGINATION_PROPS} />
      </div>
    </>
  )
}

export default Table
