import { memo, forwardRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SupportIcon from '@mui/icons-material/Support';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {
  cambiarValorVisibilidadAction,
  cambiarValorVisibilidadEstadoIncidenciasAction,
} from '../../../components/Gestion/Kanban/store/actions';
import ModalInsertar from '../../../components/Gestion/Kanban/modals/insertar.js';
import MisIncidencias from '../../../components/Gestion/Kanban/modals/misIncidencias.js';

// Actions for the SpeedDial
const actions = [
  { icon: <DashboardIcon />, name: 'Incident dashboard' },
  { icon: <LibraryBooksIcon />, name: 'User Guide' },
  { icon: <SupportIcon />, name: 'Support' },
];

// Styled SpeedDial with transitions and scaling
const StyledSpeedDial = styled(SpeedDial)(({ theme, open }) => ({
  position: 'fixed',
  bottom: theme.spacing(3),
  right: theme.spacing(1),
  transition: 'all 0.3s ease',
  '& .MuiSpeedDial-fab': {
    width: open ? 56 : 40,
    height: open ? 56 : 40,
    backgroundColor: open ? theme.palette.primary.main : '#27272780',
    '&:hover': {
      backgroundColor: open ? theme.palette.primary.dark : '#27272798',
    },
  },
  '& .MuiSpeedDialAction-fab': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

function SettingsPanel() {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const cambiarValorVisibilidad = (valorNuevo) =>
    dispatch(cambiarValorVisibilidadAction(valorNuevo));
  const cambiarValorVisibilidadEstadoIncidencias = (valorNuevo) =>
    dispatch(cambiarValorVisibilidadEstadoIncidenciasAction(valorNuevo));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleActionClick = (actionName) => {
    switch (actionName) {
      case 'User Guide':
        window.open('https://trigo-group.gitbook.io/csi-guides/', '_blank', 'noreferrer');
        break;
      case 'Incident dashboard':
        cambiarValorVisibilidadEstadoIncidencias(true);
        break;
      case 'Support':
        cambiarValorVisibilidad(true);
        break;
      default:
        break;
    }
    handleClose();
  };

  if (location.pathname === '/login') return null;

  return (
    <>
      <StyledSpeedDial
        ariaLabel="SpeedDial tooltip example"
        icon={<SpeedDialIcon icon={<QuestionMarkIcon />} openIcon={<QuestionMarkIcon />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
        TransitionProps={{ timeout: 300 }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => handleActionClick(action.name)}
          />
        ))}
      </StyledSpeedDial>

      <ModalInsertar />
      <MisIncidencias />
    </>
  );
}

export default memo(SettingsPanel);
