import { getCookie } from 'app/js/generalFunctions'
import { GET_ACTIONS_BY_RO_ID_URL, ACTIONS_RO_URL, FMEA_RENEWAL_URL } from './const'
import { method } from 'lodash'
const getAuth = () => ({ Authorization: `Token ${getCookie('token')}` })

export const GET_ALL_RO_ACTIONS = () => ({
  method: 'GET',
  url: ACTIONS_RO_URL,
  headers: getAuth(),
})

export const GET_ACTIONS_BY_RO_ID = (id) => ({
  method: 'GET',
  url: GET_ACTIONS_BY_RO_ID_URL + id,
  headers: getAuth(),
})

export const POST_ACTIONS_RO = (data) => ({
  method: 'POST',
  url: ACTIONS_RO_URL,
  data: data,
  headers: getAuth(),
})

export const PUT_ACTIONS_RO = (id, data) => ({
  method: 'PUT',
  url: ACTIONS_RO_URL + id,
  data: data,
  headers: getAuth(),
})

export const DELETE_ACTIONS_RO = (id) => ({
  method: 'DELETE',
  url: ACTIONS_RO_URL + id,
  headers: getAuth(),
})

export const PATCH_FMEA_REVIEW_DATE = (id) => ({
  method: 'PATCH',
  url: FMEA_RENEWAL_URL + id,
  headers: getAuth(),
})
