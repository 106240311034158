import Chip from '@mui/material/Chip'

const getChipsProps = (cell, chip) => {
    const value = Array.isArray(cell?.value) ? cell?.value : [cell?.value]
    const getProp = (propName, defaultValue) => chip.find(({ label }) => value.includes(label))?.[propName] ?? defaultValue

    const customCSS = getProp('customCSS', '')
    const color = getProp('color', 'primary')
    const variant = getProp('variant', 'outlined')
    const size = getProp('size', 'small')

    const isCustomCSS = Boolean(customCSS);
    return { value, customCSS, color, variant, size, isCustomCSS }
}


export const ChipComponent = ({ cell, chip }) => {
    const chipProps = getChipsProps(cell, chip)
    let { value, customCSS, color, variant, size, isCustomCSS } = chipProps

    return (
        <div className='flex gap-[5px]'>
            {
                value.map(valueItem => <Chip
                    key={valueItem}
                    label={valueItem}
                    size={size}
                    variant={variant}
                    color={isCustomCSS ? undefined : color}
                    className={`w-full px-10 py-[16px] text-xs ${customCSS}`}
                />)
            }
        </div>
    )
}
