const KEYS_TRANSFORM = {
    manager: 'Manager',
    description: 'Description',
    responsables: 'Managers',
    responsable: 'Manager',
    code: 'Code',
    codigo: 'Code',
    definicion: 'Definition',
    id_mision: 'Mission ID',
    name: 'Name',
    code_agresso: 'Agresso code',
    site: 'Site',
    id_workPackage: 'Work Package ID',
    empleados: 'Employees',
    proceso: 'Process',
    tipo: 'Type',
    mision: 'Mission ID',
    titulo: 'Title',
    valor_aviso: 'Warning value',
    tipoFrecuencia: 'Type of delivery',
    meses_afectados: 'Months of delivery',
    unidad: 'Unit',
    metodo_calculo: 'Calculation method',
    objetivo: 'Objetive',
    idProceso: 'Process ID',
    fechaInicio: 'Start date',
    modoCalculo: 'Calculation mode',
    id_division: 'Division ID',
    code_proceso: 'Code',
    nombre_proceso: 'Name',
    responsable_proceso: 'Manager',
    estandarizador_proceso: 'Standarizer',
    year: 'Year',
    sector: 'Sector',
    id_sub_division: 'Subdivision ID',
    id_proceso: 'Process ID',
    code_sub_proceso: 'Code',
    nombre_sub_proceso: 'Name',
    responsable_sub_proceso: 'Manager',
    estandarizador_sub_proceso: 'Sub process standarizer',
    id_program: 'Program ID',
    id_set: 'Assemby ID',
    rolUser: 'User rol',
    first_name: 'First name',
    last_name: 'Last name',
    phone: 'Phone',
    IDRes: 'Agresso code',
    subdivisions: 'Subdivisions ID',
    responsableWP: 'Managers',
    id_service: 'Service ID',
    id_departamento: 'Deparment ID',
    id_subdivision: 'Subdivision ID',
    responsablesDD: 'Managers',
    id_direccion_departamental: 'Department directorate ID',
    responsableDepartamento: 'Managers',
    visual_time_group: 'Visual time group',
    sitio_web: 'Web site',
    direccion: 'Adress',
    latitud: 'Latitude',
    longuitud: 'Longitude',
    observations: 'Observations',
    razon_atraso: 'OTD Reason',
    razon_calidad: 'OQD Reason',
    categorizacionNOTD: 'OTD category',
    categorizacionNOQD: 'OQD category',
    objetivoTiempoCumplido: 'Delivered on date',
    resultado: 'Result',
    objetivoCumplido: 'Target accomplished',
    id_risk_management: 'FMEA ID',
    risk_description: 'Risk desciption',
    opportunity_description: 'Oportunity description',
    categorizacion: 'Categorization',
    site: 'Site',
    mision_id: 'Mission ID',
    proceso_id: 'Process ID',
    subproceso_id: 'Sub process ID',
    rules_id: 'Abstract ID',
    current_controls: 'current_controls',
    priorization: 'Priorization',
    cause_failure: 'Failurecause',
    glitch_effect: 'Glitch effect',
    current_controls: 'Current controls',
    d_detection: 'Detection date',
    gravity: 'Gravity',
    rev: 'Reevaluation',
    idea: 'Idea',
    closed: 'Closed',
    detection: 'Detection',
    npr: 'Npr',
    completed: 'Completed',
    proposal: 'Proposal',
    d_planned: 'Planned date',
    sets: 'Sets',
    locations: 'Locations',
    subSets: 'Sub sets',
    d_closed: 'Date closed',
    member: 'Members',
    date: 'Date',
    title: 'Title',
    estado: 'State',
    subMision: 'Sub mission',
    situacion_actual: 'Current situation',
    fecha_solicitud: 'Application date',
    situacion_futura: 'Future situation',
    user_id_principal: 'Main user',
    procesos_afectados: 'Affected process',
    user_id_secundario: 'Secundary user',
    departamentoImprovement: 'Department',
    unit: 'Unit',
    uuid: 'ID',
    mission: 'Mission',
    contract: 'Contract',
    quantity: 'Quantity'
}

export const getHistoryData =(valuesObj)=>{
    return  Object.entries(valuesObj).reduce((acc, [key, value]) =>{
            if(typeof value === 'boolean') value = value ? 'Yes' : 'No'
            else if(Array.isArray(value)) value = value.join(', ')
            else if(value?.constructor === Object) return acc
            const updatedKey = KEYS_TRANSFORM[key]
            if(updatedKey) acc[updatedKey] = value 
            return acc
        }, {})
}

