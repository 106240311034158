import { makeStyles } from '@mui/styles';

// ? Styles
export const useStyles = makeStyles((theme) => ({
    formControl: {
        '& .MuiInputLabel-root': {
            color: 'white',
            borderColor: 'white',
        },
        '& .MuiInputBase-input': {
            color: 'white',
            borderColor: 'white',
        },
        '& .MuiSvgIcon-root': {
            color: 'white !important',
            borderColor: 'white',
        },
        '& .muiltr-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
    },
    dateFormControl: {
        '& .MuiInputLabel-root': {
            color: 'black',
            borderColor: 'black',
        },
        '& .MuiInputBase-input': {
            color: 'black',
            borderColor: 'black',
        },
        '& .MuiSvgIcon-root': {
            color: 'black !important',
            borderColor: 'black',
        },
        '& .muiltr-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            borderColor: 'black',
        },
        '& .MuiOutlinedInput-root': {
            border: '1px solid black',

        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black',
            },
            '&:hover fieldset': {
                borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'black',
            },
        },
    },
    outlinedInput: {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },

    },
}));

export const ALL_AGRESSO_CODES_STRING= "All agresso codes"

export const ALL_MISSIONS_STRING = "All missions"

export const ALL_SUB_MISSIONS_STRING = "All submissions"

export const ALL_VT_GROUPS_STRING =  "All visual time groups"

export const ALL_MISSIONS_MANAGERS_STRING = "All managers"

export const NO_SUBMISSIONS_MESSAGE = "There is no sub missions in your search. In order to display the data, the search must have associated sub missions."

export const ALL_RECORDS_OPTION = {id: -1, name: "All records"}
export const ALL_MANAGERS_OPTION = { id:-1, IDRes:"", first_name: "All managers"}

export const KEYS_FILTER_STRUCTURE = ['filter_contracts', 'filter_wps', 'filter_missions', 'filter_submissions']

export const KEYS_FILTER_SECTIONS_NAMES = {
    filter_contracts: 'contract',
    filter_wps: 'wp',
    filter_missions: 'mission',
    filter_submissions: 'submission',
}

export const EMPTY_STRUCTURE_OBJ = Object.fromEntries(KEYS_FILTER_STRUCTURE.map(key => [key, []]))


export const SECTIONS_SELECTED_OBJ_KEYS = [ 'contractSelected', 'wpSelected', 'missionSelected', 'submissionSelected']


export const SECTIONS_SELECTED_INITAL_STATE={
    // subdivisionSelected: null,
    contractSelected: null,
    wpSelected: null,
    missionSelected: null,
    submissionSelected: null
}

export const SECTIONS_SELECTED_KEYS_EQUIVALENT_FILTER_KEYS={
    // filter_subdivisions: "subdivisionSelected",
    filter_contracts: "contractSelected",
    filter_wps: "wpSelected",
    missionSelected: "missionSelected",
    filter_submissions: "submissionSelected"
}

export const MANAGERS_SELECTED_INITIAL_STATE = {
    // contractManagerSelected: null,
    wpManagerSelected: null,
    missionsManagerSelected: null,
    submissionsManagerSelected: null,
}


export const SECTIONS_PEOPLE_TO_SEARCH = {
    contract: [],
    wp: [],
    mission:[],
    submission: []
}


export const SECTIONS_NAMES_SECTIONS_SELECTED_EQUIVALENCE = {
    contract: 'contractSelected',
    wp: 'wpSelected',
    mission: 'contracmissionSelectedtSelected',
    submission: 'submissionSelected',
}
export const SECTIONS_MANAGERS_EQUIVALENCE = ['submission', 'mission', 'wp', 'contract']

export const SECTIONS_FATHERS = {
    submission: 'mission',
    mission: 'wp',
    wp: 'contract'
}