//**************IMPORTACIONES*************************************************

import FusePageSimple from '@fuse/core/FusePageSimple';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import store from "app/store/index"

//Redux importaciones
import { useDispatch, useSelector } from 'react-redux';


import {
  cambiarEstadoAnfeAction,
  cambiarVisibilidadModalEliminarAnfeAction,
  updateAnfeAction,
} from '../../store/Anfe/actions'

import {
  insertarLogRiskAPIAction
} from '../../../../Managment/LogRisk/store/actions'
import { useEffect } from 'react';

import { useDeleteRyO } from '../../hooks/RyO/RyOMainSectionHooks.js'


//**************END IMPORTACIONES*************************************************



const useStyles = makeStyles({

  customDialogTitle: {
    backgroundColor: 'rgb(37, 47, 62)',
    color: 'rgb(255, 255, 255)',
    marginBottom: '2em'
  }

});



const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
  },
}));


export function ModalEliminarRyO() {

  const dispatch = useDispatch();
  const classes = useStyles();



  //Obtener los states de Redux
  const visibilidadModalEliminar = useSelector(state => state.fuse.anfeComponente.visibilidadModalEliminar)



  const {deleteRyOAction} = useDeleteRyO();




  return (

    <Dialog open={visibilidadModalEliminar} onClose={() => dispatch(cambiarVisibilidadModalEliminarAnfeAction(false))} fullWidth maxWidth='sm'>


      <DialogTitle classes={{ root: classes.customDialogTitle }} >

        Confirm

      </DialogTitle>


      <DialogContent>
        Are you sure you want to delete this record?

      </DialogContent>
      <DialogActions>

        <Button onClick={() => dispatch(cambiarVisibilidadModalEliminarAnfeAction(false))}>Close</Button>
        <Button onClick={() => { dispatch(cambiarVisibilidadModalEliminarAnfeAction(false)), deleteRyOAction() }}>Yes</Button>


      </DialogActions>
    </Dialog>
  );
  }
