import { display, height } from "@mui/system";


export const headerStyles = {

    height: "60px",
    fontWeight: 500,
    fontSize: "1.3rem",
    textAlign: "center"
};

export const rowStyles = {
    background: '#FFFFFF',
    cursor: 'pointer',
    // height: '50px',
    verticalAlign: "middle",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
};

const {background, ...rowStylesWithNoBackground} = rowStyles
const redRowStyle = {
    ...rowStylesWithNoBackground,
    backgroundColor: "#FFE5E5",
};
const organgeRowStyle = {
    ...rowStylesWithNoBackground,
    backgroundColor: "#ffcf96",
};

const rowStylesSelected = {
    ...rowStylesWithNoBackground,
    backgroundColor: 'rgb(56 168 255 / 30%)',
}

export const containerStyles = {
    height: "68vh",
    overflow: "auto",
};

export const containerNoScroll = {
    height: "68vh",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
};

export const headStyles = {
    // height: "52px",
    // maxHeight: "52px",
    // padding: "15px 0",


    position: "sticky",
    top: 0,
    zIndex: 1,
    background: "white",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",


};


export const footerStyles = {
    position: "sticky",
    bottom: "-1px",
    zIndex: 1,
    background: "white",
};




export const rowColorStyle = (row,selectedRowIndex) => {
    if (row?.index === selectedRowIndex) return rowStylesSelected
    else if (row?.original?.redRow) return redRowStyle
    else if (row?.original?.organgeRow) return organgeRowStyle
    else return rowStyles
}


export const cellStyles = (cellMaxWidth) => ({
    width: cellMaxWidth !== "disbled" && "1px",
    maxWidth: cellMaxWidth !== "disbled" && "70px",
    height: '60px',

    overflow: "auto",
    padding: "0 8px"
});

export const tableStyles = (tableWidth) => ({
    width: tableWidth,
    borderCollapse: "collapse",
});
