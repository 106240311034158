import { useState, useEffect, useLayoutEffect } from 'react';
import history from '@history';
import { Router } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { deleteCookies, getCookie } from 'app/js/generalFunctions';
import { getSesionActualAPIAction } from 'components/Managment/Users/store/actions';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import axios from 'axios';
import { showMessage } from 'app/store/fuse/messageSlice';

function BrowserRouter({ children }) {
    const dispatch = useDispatch()
  

  const [location, setLocation] = useState({
    location: history.location,
    action: history.action
  });


  const token = getCookie('token')


  const {sectionsViewsIsLoad, person: personLogin} = useSelector(state => state.fuse.userComponente)
  const personPermissions = useSelector(state => state.fuse.userComponente.personPermisos)

  const redirect=(action, route)=>{
    setLocation({action, location:{...history.location, pathname: route} })
    history.replace(route)
  }
  const [loadingDependencies, setLoadingDependencies] = useState(false)
  const [pagesView, setPagesView] = useState(true)

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      
      const errorResponseData = error.response?.data
      if (errorResponseData?.detail === "Token has expired") {
        redirect('REPLACE','/login')
        dispatch(showMessage({variant: 'error', message: 'Your token was expired, please, log in again'}))
        deleteCookies()
      }
      else return Promise.reject(error);
    }
  );

  useEffect(() => {

    const userLoad = personLogin?.id
    const permissionsLoad = personPermissions?.length
    const dependenciesLoad = !!userLoad && !!permissionsLoad && !!sectionsViewsIsLoad
    const loginPath = location.location.pathname === '/login'
    
    if (!token && !loginPath)  {
      redirect('REPLACE','/login')
    }
    else  {
      if(dependenciesLoad){
        if(loginPath)  redirect('REPLACE', '/')
        setLoadingDependencies(false)
        setPagesView(true)
      }
      else if(!dependenciesLoad && !loadingDependencies && !loginPath){
        dispatch(getSesionActualAPIAction({token}))
        setLoadingDependencies(true)
        setPagesView(false)
      }
    }
  }, [token,sectionsViewsIsLoad,personLogin, personPermissions, location.location.pathname])

 

  useLayoutEffect(() => history.listen(setLocation), [history]);


  return (
    <>
      {
        pagesView ?
          <Router location={location.location} navigator={history} 
          navigationType={location.action} 
          >  {children} </Router>
          :
          <FuseSplashScreen />
      }
    </>

  );

}

export default BrowserRouter;

