import {

    // COMPONENTE PRINCIPAL ------------------


    OBTENER_TOKEN,
    OBTENER_TOKEN_ERROR,

    CHECK_TOKEN,
    CHECK_TOKEN_ERROR,
    SET_TOKEN


} from './types';
import axios from 'axios'

import { showMessage } from 'app/store/fuse/messageSlice'
import { getSesionActualAPIAction, getPermisosSesionActualAPIAction } from 'components/Managment/Users/store/actions';

//Constantes
const urlAuth = process.env.REACT_APP_URL_DJANGO + "/api/auth/"
const urlCheckToken = process.env.REACT_APP_URL_DJANGO + "/api/checktoken/"
const urlCheckTokenAzure = process.env.REACT_APP_URL_DJANGO + "/api/checktoken_azure/"

//************************ OBTENER_TOKEN ACTION**********************************************

export function obtenerTokenAction(credenciales) {
    return (dispatch) => {
        dispatch(obtenerToken(true))


        axios({
            method: "POST",
            url: urlAuth,
            data: credenciales


        })
            .then(response => {
                if (response.data.name) {
                    document.cookie = "token=" + response.data.token;
                    document.cookie = "name=" + response.data.name;
                    document.cookie = "email=" + response.data.email;
                    const {token} = response.data

                    dispatch(getSesionActualAPIAction({token}))
                    dispatch(setToken())

                }
                else {
                    dispatch(obtenerTokenError(true))
                    dispatch(
                        showMessage({
                            message: "No access permissions",
                            variant: "error",
                        })
                    )
                }


            })
            .catch(error => {
                console.log(error.response)
                dispatch(
                    showMessage({
                        message: "Invalid credentials",
                        variant: "error",
                    })
                )

                dispatch(obtenerTokenError(true))
            })

    }
}

const obtenerToken = loading => ({
    type: OBTENER_TOKEN,
    payload: loading

})



const obtenerTokenError = error => ({
    type: OBTENER_TOKEN_ERROR,
    payload: error
})


//************************ CHECK_TOKEN ACTION**********************************************

// export function checkTokenAction(token) {

//     return (dispatch) => {
//         dispatch(checkToken(true))

//         axios({
//             method: "GET",
//             url: urlCheckToken,
//             params: {
//                 token: token
//             }


//         })
//             .then(response => {
//                 if(response.status !== 200) throw new Error('Error in token validation');
//                 dispatch(getSesionActualAPIAction({token}))
//                 dispatch(setToken())

//             })
//             .catch(error => {
//                 console.log(error)
//                 dispatch(checkTokenError(false))
//             })

//     }
// }

const checkToken = loading => ({
    type: CHECK_TOKEN,
    payload: loading

})

const setToken = () =>({
    type: SET_TOKEN,
})


const checkTokenError = error => ({
    type: CHECK_TOKEN_ERROR,
    payload: error
})


