import {

    // COMPONENTE PRINCIPAL ------------------


    CAMBIAR_VALOR_SELECCION_GRID,
    CAMBIAR_VALOR_SELECCION_GRID_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_ERROR,

    ELIMINAR_IMPROVEMENT_PROPOSALS,
    ELIMINAR_IMPROVEMENT_PROPOSALS_EXITO,
    ELIMINAR_IMPROVEMENT_PROPOSALS_ERROR,

    MOSTRAR_ALL_IMPROVEMENT_PROPOSALS,
    MOSTRAR_ALL_IMPROVEMENT_PROPOSALS_EXITO,
    MOSTRAR_ALL_IMPROVEMENT_PROPOSALS_ERROR,

    MOSTRAR_IMPROVEMENT_PROPOSALS,
    MOSTRAR_IMPROVEMENT_PROPOSALS_EXITO,
    MOSTRAR_IMPROVEMENT_PROPOSALS_ERROR,

    CAMBIAR_STATE_INSERTADORISKMANAGEMENT,
    CAMBIAR_STATE_INSERTADORISKMANAGEMENT_EXITO,
    CAMBIAR_STATE_INSERTADORISKMANAGEMENT_ERROR,

    UPDATE_IMPROVEMENT_PROPOSALS,
    UPDATE_IMPROVEMENT_PROPOSALS_EXITO,
    UPDATE_IMPROVEMENT_PROPOSALS_ERROR,

    // MODAL INSERTAR  -----------------------

    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_EXITO,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_ERROR,

    INSERTAR_IMPROVEMENT_PROPOSALS_MODAL_INSERTAR,
    INSERTAR_IMPROVEMENT_PROPOSALS_MODAL_INSERTAR_EXITO,
    INSERTAR_IMPROVEMENT_PROPOSALS_MODAL_INSERTAR_ERROR,

    CONSULTA_SUBDIVISIONES_MODAL_INSERTAR,
    CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_EXITO,
    CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_ERROR,

    CAMBIAR_ESTADO_IMPROVEMENT_PROPOSALS,
    CAMBIAR_ESTADO_IMPROVEMENT_PROPOSALS_EXITO,
    CAMBIAR_ESTADO_IMPROVEMENT_PROPOSALS_ERROR,

    EDIT_IMPROVEMENT_LIST,
    INSERT_NEW_IMPROVEMENT,
    UPDATE_MIX_ACTIONS



} from './types';


// cada reducer tiene su propio state
const initialState = {
    allImprovementProposalsListAPI: [],
    improvementProposalsListAPI: [],
    error: false,
    loading: true
}

export default function(state = initialState, action) {

    switch (action.type) {
      //********** MOSTRAR IMPROVEMENT_PROPOSALS API**********************************/

      case MOSTRAR_ALL_IMPROVEMENT_PROPOSALS:
        return { ...state, loading: true }
      case MOSTRAR_ALL_IMPROVEMENT_PROPOSALS_EXITO:
        return {
          ...state,
          loading: false,
          error: false,
          allImprovementProposalsListAPI: action.payload,
        }
      case MOSTRAR_ALL_IMPROVEMENT_PROPOSALS_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        }

      case MOSTRAR_IMPROVEMENT_PROPOSALS_EXITO:
        return {
          ...state,
          loading: false,
          error: false,
          improvementProposalsListAPI: action.payload,
        }

      case MOSTRAR_IMPROVEMENT_PROPOSALS_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        }

      //**********INSERT IMPROVEMENT LIST API  **********************************/
      case INSERT_NEW_IMPROVEMENT:
        const newImprovement = action.payload

        const targetListForInsert = state.improvementProposalsListAPI.length
          ? state.improvementProposalsListAPI
          : state.allImprovementProposalsListAPI

        const updatedListWithNewItem = [newImprovement, ...targetListForInsert]

        return {
          ...state,
          improvementProposalsListAPI: state.improvementProposalsListAPI.length
            ? updatedListWithNewItem
            : state.improvementProposalsListAPI,
          allImprovementProposalsListAPI: !state.improvementProposalsListAPI.length
            ? updatedListWithNewItem
            : state.allImprovementProposalsListAPI,
        }

      //**********EDIT IMPROVEMENT LIST API  **********************************/
      case EDIT_IMPROVEMENT_LIST:
        const improvementEdited = action.payload
        const { indexValue } = improvementEdited

        const targetList = state.improvementProposalsListAPI.length
          ? state.improvementProposalsListAPI
          : state.allImprovementProposalsListAPI

        if (indexValue >= 0 && indexValue < targetList.length) {
          const updatedList = [
            ...targetList.slice(0, indexValue),
            improvementEdited,
            ...targetList.slice(indexValue + 1),
          ]

          return {
            ...state,
            improvementProposalsListAPI: state.improvementProposalsListAPI.length
              ? updatedList
              : state.improvementProposalsListAPI,
            allImprovementProposalsListAPI: !state.improvementProposalsListAPI.length
              ? updatedList
              : state.allImprovementProposalsListAPI,
          }
        } else {
          console.error(`Index ${indexValue} is out of bounds for the selected list.`)
          return state
        }

      //**********UPDATE MIX ACTIONS FROM IMPROVEMENT_PROPOSALS **********************************/
      case UPDATE_MIX_ACTIONS:
        const { improvement_id, mix_actions } = action.payload
        const updateAllList = state.allImprovementProposalsListAPI?.length
          ? state.allImprovementProposalsListAPI.map((improvement) =>
              improvement.id === improvement_id ? { ...improvement, mix_actions } : improvement,
            )
          : []

        const updatedList = state.improvementProposalsListAPI?.length
          ? state.improvementProposalsListAPI.map((improvement) =>
              improvement.id === improvement_id ? { ...improvement, mix_actions } : improvement,
            )
          : []
        return {
          ...state,
          improvementProposalsListAPI: updatedList,
          allImprovementProposalsListAPI: updateAllList,
        }

      default:
        return state
    }

}
