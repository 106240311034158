import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@mui/material/MenuItem';

// ? RyO Help modal

function createDataHelp(severity, criteria, value) {
    return { severity, criteria, value };
}

export const rowsSeverity = [
    createDataHelp('Very low', 'This is a minor flaw. It would probably not affect the customer.', '1'),
    createDataHelp('Low', 'The failure would cause a minor inconvenience to the customer. It is easily rectifiable.', '2-3'),
    createDataHelp('Moderate', 'The failure causes some customer dissatisfaction. The customer will observe deterioration in performance.', '4-6'),
    createDataHelp('High', 'The failure may be critical to the system. It produces a high degree of dissatisfaction.', '7-8'),
    createDataHelp('Very High', 'Highly critical failure that affects the safety of the product or process. Serious non-compliance with regulatory standards.', '9-10'),
];

export const rowsFrequency = [
    createDataHelp('Very low', 'Failures are not associated with nearly identical processes.', '1'),
    createDataHelp('Low', 'Isolated failures in similar or almost identical processes. They are expected in the system but unlikely to occur.', '2-3'),
    createDataHelp('Moderate', 'Occasional defect. These defects are likely to appear a few times in the life of the system.', '4-6'),
    createDataHelp('High', 'The failure has occurred with some frequency in the past in similar or previous processes.', '7-8'),
    createDataHelp('Very High', 'Almost inevitable failure. Failure is certain to occur frequently.', '9-10'),
];


export const rowsDetection = [
    createDataHelp('Very low', 'The defect is obvious. It is highly unlikely to go undetected by existing controls.', '1'),
    createDataHelp('Low', 'The defect might not be detected in a first control, although it would certainly be detected later.', '2-3'),
    createDataHelp('Moderate', 'The defect is detectable and may not reach the customer. It is likely to be detected in the later stages of production.', '4-6'),
    createDataHelp('High', 'The defect is difficult to detect with current procedures.', '7-8'),
    createDataHelp('Very High', 'The defect cannot be detected. It is very likely to reach the end customer.', '9-10'),
];


// ? RyO History styles

export const useStyles = makeStyles({

    customDialogTitle: {
      backgroundColor: 'rgb(37, 47, 62)',
      color: 'rgb(255, 255, 255)',
      marginBottom: '1em'
    }
  });

//   ? Header title
export function encabezadoTitulo({modoRyO}) {
    let titulo;
    if (modoRyO === 'editar') {
        titulo = 'Edit R&O';
    } else if (modoRyO === 'crear') {
        titulo = 'Create R&O';
    } else {
        titulo = 'Re-evaluate';
    }
    return titulo;
}

// ? Buttom label
export const buttonLabel = ({ modoRyO, alertaTipo, ryoAldredyHaveAction }) => {
    let label;
    if (modoRyO === 'editar') {
        label = `Edit${alertaTipo === 'error' && !ryoAldredyHaveAction ? ' and create actions' : ''}`;
    } else if (modoRyO === 'crear') {
        label = `Create${alertaTipo === 'error' && !ryoAldredyHaveAction ? ' and create actions' : ''}`;
    } else {
        label = `Save and create re-evaluation`;
    }
    return label;
}

export const nprChartOptions = {
    indexAxis: 'y',
    responsive: true,
    scales: {
      x: {
        display: false,
        beginAtZero: true,
        max: 1020,
        ticks: {
          stepSize: 100,
        },
        stacked: true,
      },
      y: {
        display: false,
        grid: { display: false },
        stacked: true,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, // Desactiva los tooltips
      },
    },
  };


export function nprChartFunction ({ npr, color }) {

    let labels = [""];

    let datasets = [
        {
            label: "",
            data: [{ x: 0, y: npr }, { x: npr, y: npr }],
            backgroundColor: color,
            borderColor: color,
            type: 'line',
            borderWidth: 3,
            borderSkipped: false,
            stepped: true,
        }

    ];

    return { labels, datasets }
}
