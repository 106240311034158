export const SUBMISSION_URL = process.env.REACT_APP_URL_DJANGO + "/api/subMision/"
export const DEPARTMENT_SUBMISSION_URL = process.env.REACT_APP_URL_DJANGO + "/api/subMision/departamentales/"
export const ALL_USERS_URL = process.env.REACT_APP_URL_DJANGO + "/api/user/"
export const TECHNICIAN_JOBS_URL = process.env.REACT_APP_URL_DJANGO + "/api/jobInSubMision/"

export const SUCESS_MESSAGE_CREATE_SUBMISSION = {
    message: "Created sub mision",
    variant: "success"
}

export const SUCESS_MESSAGE_EDIT_SUBMISSION = {
    message: "Updated sub mision",
    variant: "success"
}

export const ERROR_GET_ALL_DEPARTMENT_SUBMISSIONS = {
    message: "Error when trying to get sub missions, please, reload the page",
    variant: "error"
}

export const ERROR_MESSAGE_CREATE_SUBMISSION = {
    message: "Error when created Sub Mission",
    variant: "error"
}

export const ERROR_MESSAGE_EDIT_SUBMISSION = {
    message: "Error when editing Sub Mission",
    variant: "error"
}

export const CANT_DELETE_JOB = {
    message: "There are employees assigned to this position. Before eliminating it, reassign these employees to other positions.",
    variant: "error"
}
