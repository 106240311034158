import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState, useRef } from 'react'
import {
  setActionsStructure
} from '../../store/actions/actions.js'
import { setMyActionsAction, setNotMyActionsAction } from '../../store/actions/actions.js'
import { getIdsFromArr } from 'app/js/generalFunctions.js'
import { saveRyOIdAction, visibilidadModalActionRequired } from '../../store/RyO/actions.js'
import { RyOActions } from '../../features/actionPlan/ryoActionPlanSlice.js'

// Function to handle closing the modal and showing the next R&O if necessary
export const useCloseAndShowNext = (initialPendingActions) => {
  const dispatch = useDispatch()
  const [dismissed, setDismissed] = useState(false)
  const needToTakeActionRef = useRef([...initialPendingActions])

  const closeAndShowNext = () => {
    const nextRyoId = needToTakeActionRef.current.shift()
    if (nextRyoId && needToTakeActionRef.current.length > 0) {
      dispatch(saveRyOIdAction(needToTakeActionRef.current[0]))
      setDismissed(false)
      dispatch(visibilidadModalActionRequired(true, false))
    } else {
      dispatch(visibilidadModalActionRequired(false, true))
      setDismissed(true)
    }
  }

  return { closeAndShowNext, dismissed }
}

export const useSetActions = () => {
  const dispatch = useDispatch()

  const anfeListAPI = useSelector((state) => state.fuse.anfeComponente.anfeListAPI)
  const { myAnfesList, notMyAnfesList } = useSelector((state) => state.fuse.anfeComponente)
  const RyOList = useSelector((state) => state.fuse.RyOComponente.RyOListAPI)
  const actionsList = useSelector(RyOActions)
  const {
    RyOsICanSee,
    myRyOsList,
    noRevRyOICanSee,
    myNoRevRyO,
    notMyNoRevRyO,
  } = useSelector((state) => state.fuse.RyOComponente)

  // ? Get section rows. Actions from no reevaluated RyO
  // Ç Mostramos acciones de ryos no reevaluados y que deriven de anfes que nos pertenecen

  const ryoICanSeeIds = useMemo(() => getIdsFromArr(RyOsICanSee), [RyOsICanSee])
  const myRyOsIds = useMemo(() => getIdsFromArr(myRyOsList), [myRyOsList])
  // const notMyRyOIds = useMemo(()=> getIdsFromArr(notMyRyOList), [notMyRyOList])

  const noRevRyOICanSeeIds = useMemo(() => getIdsFromArr(noRevRyOICanSee), [noRevRyOICanSee])
  const myNoRevRyOIds = useMemo(() => getIdsFromArr(myNoRevRyO), [myNoRevRyO])
  const notMyNoRevRyOIds = useMemo(() => getIdsFromArr(notMyNoRevRyO), [notMyNoRevRyO])

  // Ç Muestro acciones de ryos i can see no rev
  useEffect(() => {
    const actionsStructure = actionsList.reduce(
      (acc, action) => {
        const actionRyOId = action.id_record
        if (ryoICanSeeIds.includes(actionRyOId)) {
          acc.actionsIcanSee = [...acc.actionsIcanSee, action]
          if (noRevRyOICanSeeIds.includes(actionRyOId))
            acc.actionsICanSeeNoRev = [...acc.actionsICanSeeNoRev, action]
          else acc.actionsICanSeeRev = [...acc.actionsICanSeeRev, action]
        }

        if (myRyOsIds.includes(actionRyOId)) {
          acc.myActions = [...acc.myActions, action]
          if (myNoRevRyOIds) acc.myActionsNoRev = [...acc.myActionsNoRev, action]
          else acc.myActionsRev = [...acc.myActionsRev, action]
        } else {
          acc.notMyActions = [...acc.notMyActions, action]
          if (notMyNoRevRyOIds) acc.notMyActionsNoRev = [...acc.notMyActionsNoRev, action]
          else acc.notMyActionsRev = [...acc.notMyActionsRev, action]
        }

        return acc
      },
      {
        actionsIcanSee: [],
        myActions: [],
        notMyActions: [],
        actionsICanSeeNoRev: [],
        myActionsNoRev: [],
        notMyActionsNoRev: [],
        actionsICanSeeRev: [],
        myActionsRev: [],
        notMyActionsRev: [],
      },
    )

    // console.log("🚀 ~ useEffect ~ actionsStructure:", actionsStructure)
    dispatch(setActionsStructure(actionsStructure))
  }, [actionsList, RyOsICanSee, myRyOsList, noRevRyOICanSee, myNoRevRyO, notMyNoRevRyO])
}
