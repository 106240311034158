

export const YEAR_MONTHS_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export const YEAR_MONTHS_NAMES_OBJ = Object.fromEntries(YEAR_MONTHS_NAMES.map(month => [month, month]))

export const ERROR_MESSAGE_OBJ = {message:'Error, please, try again', variant: 'error'}
export const COMPLETE_ALL_FIELDS_MESSAGE = {
    message: "Complete all fields",
    variant: "error"
}
