import {

    MOSTRAR_SUBPROCESS,
    MOSTRAR_SUBPROCESS_EXITO,
    MOSTRAR_SUBPROCESS_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS,
    CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS_ERROR,

    PUT_SUBPROCESS,
    PUT_SUBPROCESS_EXITO,
    PUT_SUBPROCESS_ERROR,

    INSERTAR_NEW_SUBPROCESS,
    INSERTAR_NEW_SUBPROCESS_EXITO,
    INSERTAR_NEW_SUBPROCESS_ERROR,

    CAMBIAR_MODAL_NEW_SUBPROCESS,
    CAMBIAR_MODAL_NEW_SUBPROCESS_EXITO,
    CAMBIAR_MODAL_NEW_SUBPROCESS_ERROR


} from './types';
import axios from 'axios'
import { showMessage } from 'app/store/fuse/messageSlice'
import {getCookie} from 'app/js/generalFunctions'
import { useDispatchError } from 'app/js/customHooks/GeneralHooks';

//Constantes globales
const urlSubProcessApi = process.env.REACT_APP_URL_DJANGO + "/api/sub_process/"

//************************CAMBIAR VISIBILIDAD MODAL NUEVO USUARIO**********************************************

export function cambiarVisibilidadModalInsertarSubProcessAPI(valorNuevo, modoDialogSubProcess) {

    return (dispatch) => {
        dispatch (cambiarVisibilidadModalNuevoSubProcess())

        try {
            dispatch (cambiarVisibilidadModalNuevoSubProcessExito(valorNuevo, modoDialogSubProcess))

        } catch (error) {

            dispatch (cambiarVisibilidadModalNuevoSubProcessError(true))
        }
    }
}

const cambiarVisibilidadModalNuevoSubProcess = () => ({
    type: CAMBIAR_MODAL_NEW_SUBPROCESS,
    payload: true

})

const cambiarVisibilidadModalNuevoSubProcessExito = (valorNuevo, modoDialogSubProcess) => ({
    type: CAMBIAR_MODAL_NEW_SUBPROCESS_EXITO,
    nombre: modoDialogSubProcess,
    payload: valorNuevo

})

const cambiarVisibilidadModalNuevoSubProcessError = estado => ({
  type:  CAMBIAR_MODAL_NEW_SUBPROCESS_ERROR,
  payload: estado
})

//************************ INSERTA RM_ACCION MODAL INSERTAR**********************************************

export function insertarNewSubProcessAPIAction(SubProcessData) {

    return async (dispatch) => {
        dispatch (createNewSubProcess())
        console.log("SubProcessDATA", SubProcessData)
             axios({
                    method: "POST",
                    url: urlSubProcessApi,
                    data: SubProcessData,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (createNewSubProcessExito(response.data))

                 dispatch(
                    showMessage({
                        message: "SubProcess successfully created",
                        variant: "success"
                    })
                 ) 

                 dispatch(mostrarSubProcessAPIAction())

             })
             .catch(error => {
                console.log(error.response)
                dispatch (createNewSubProcessError(true))
                useDispatchError(dispatch, error, {
                            message: "Error creating Sub process",
                            variant: "error"
                        })
                // dispatch(
                //     showMessage({
                //         message: "Error creating SubProcess",
                //         variant: "error"
                //     })
                // )
            })

    }
}

const createNewSubProcess = (customer) => ({
    type: INSERTAR_NEW_SUBPROCESS,
    payload: customer

})

const createNewSubProcessExito = estado => ({
    type: INSERTAR_NEW_SUBPROCESS_EXITO,
    payload: estado

})

const createNewSubProcessError = estado => ({
    type:  INSERTAR_NEW_SUBPROCESS_ERROR,
    payload: estado
})

//PUT PROCESS

export function putSubProcessAPIAction(id,customer) {

    return async (dispatch) => {
        dispatch (putSubProcess());

             axios({
                    method: "PUT",
                    url: urlSubProcessApi + id,
                    data: customer,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (putSubProcessExito(response.data))

                 dispatch(
                    showMessage({
                        message: "SubProcess successfully modified",
                        variant: "success"
                    })
                 ) 

                 dispatch(mostrarSubProcessAPIAction())

             })
             .catch(error => {
                console.log(error.response)
                dispatch (putSubProcessError(true))
                dispatch (createNewSubProcessError(true))
                useDispatchError(dispatch, error, {
                            message: "Error modified Sub process",
                            variant: "error"
                        })
                // dispatch(
                //     showMessage({
                //         message: "Error modified Sub process",
                //         variant: "error"
                //     })
                // )
            })

    }
}

const putSubProcess = () => ({
    type: PUT_SUBPROCESS,
    payload: true

})

const putSubProcessExito = estado => ({
    type: PUT_SUBPROCESS_EXITO,
    payload: estado

})

const putSubProcessError = estado => ({
    type:  PUT_SUBPROCESS_ERROR,
    payload: estado
})

//************************ CAMBIAR SELECCION GRID ACTION**********************************************

export function cambiarValorSeleccionAction(valorNuevo) {

    return (dispatch) => {
        dispatch (cambiarValorSeleccion())

        try {
            dispatch (cambiarValorSeleccionExito(valorNuevo))
            

        } catch (error) {

            dispatch (cambiarValorSeleccionError(true))
        }

    }

}

const cambiarValorSeleccion = () => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS,
    payload: false

})

const cambiarValorSeleccionExito = valorNuevo => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS_EXITO,
    payload: valorNuevo

})

const cambiarValorSeleccionError = estado => ({
  type: CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS_ERROR,
  payload: estado
})

//************************ OBTENER PROCESOS RELACIONADOS **********************************************

export function mostrarSubProcessAPIAction() {

    return async (dispatch) => {
        dispatch (mostrarSubProcessAPI(true))

        
            await axios({
                method: "GET",
                url: urlSubProcessApi,
                headers: {
                    'Authorization': `Token ${getCookie('token')}` 
                        }
                
                    })
                     .then(response=>{
                        dispatch (mostrarSubProcessAPIExito(response.data))
                    })
                    .catch(error => {
                        console.log(error.response)
                        dispatch (mostrarSubProcessAPIError(true))
                    })

          }
}

export function obtenerAllSubProcesosAPIAction() {

    return async (dispatch) => {
        dispatch (mostrarSubProcessAPI(true))

        
            await axios({
                method: "GET",
                url: urlSubProcessApi,
                headers: {
                    'Authorization': `Token ${getCookie('token')}` 
                        }
                
                    })
                     .then(response=>{
                        dispatch (mostrarSubProcessAPIExito(response.data))
                    })
                    .catch(error => {
                        console.log(error.response)
                        dispatch (mostrarSubProcessAPIError(true))

                        
                    })

          }
}

export function obtenerSubProcesosWpAPIAction(idWp) {

    return async (dispatch) => {
        dispatch (mostrarSubProcessAPI(true))

        
            await axios({
                method: "GET",
                url: urlSubProcessApi + "wp/" + idWp,
                headers: {
                    'Authorization': `Token ${getCookie('token')}` 
                        }
                
                    })
                     .then(response=>{
                        dispatch (mostrarSubProcessAPIExito(response.data))
                    })
                    .catch(error => {
                        console.log(error.response)
                        dispatch (mostrarSubProcessAPIError(true))

                        
                    })

          }
}

export function obtenerSubProcesosMisionAPIAction(idMision) {

    return async (dispatch) => {
        dispatch (mostrarSubProcessAPI(true))

        
            await axios({
                method: "GET",
                url: urlSubProcessApi + "mision/" + idMision,
                headers: {
                    'Authorization': `Token ${getCookie('token')}` 
                        }
                
                    })
                     .then(response=>{
                        dispatch (mostrarSubProcessAPIExito(response.data))
                    })
                    .catch(error => {
                        console.log(error.response)
                        dispatch (mostrarSubProcessAPIError(true))

                        
                    })

          }
}

const mostrarSubProcessAPI = () => ({
    type: MOSTRAR_SUBPROCESS,
    payload: false

})

const mostrarSubProcessAPIExito = subProcesos => ({
    type: MOSTRAR_SUBPROCESS_EXITO,
    payload: subProcesos

})

const mostrarSubProcessAPIError = estado => ({
  type: MOSTRAR_SUBPROCESS_ERROR,
  payload: estado
})