import { lazy, Suspense, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import FusePageCarded from '@fuse/core/FusePageCarded'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Grid from '@mui/material/Grid'
import TabContext from '@mui/lab/TabContext'
import { Filters } from 'components/Filter/Filter.jsx'
import { NewFilter } from 'components/Filter/components/filterInternalComponents/newFilter'
import { FilterSearchButtom } from 'components/Filter/components/filterInternalComponents/filterSearchButtom'
import { FilterIcon } from 'components/Filter/components/FilterIcon'
import { COURSES_TAB_LABELS, COURSES_TAB_VALUES } from './utils/consts'
import { useTrainingRequest } from './utils/hooks'
import { LinearLoader } from 'components/GlobalComponents/loaders/loader'
import { setFirstRenderAction, setvisibilityAction } from 'components/Filter/store/actions'

const Courses = lazy(() => import('./Pages/Courses.jsx'))
const Announcements = lazy(() => import('./Pages/Announcements.jsx'))

export const TrainingFollowUp = () => {
  const dispatch = useDispatch()
  const [tabValue, settabValue] = useState(COURSES_TAB_VALUES.courses)
  const setTabValue = (e, tabValue) => settabValue(tabValue)
  useTrainingRequest()

  const modalView = {
    [COURSES_TAB_VALUES.courses]: (
      <Suspense fallback={<LinearLoader />}>
        <Courses />
      </Suspense>
    ),
    [COURSES_TAB_VALUES.announcements]: (
      <Suspense fallback={<LinearLoader />}>
        <Announcements />
      </Suspense>
    ),
  }
  useEffect(() => {
    dispatch(setFirstRenderAction(true))
    dispatch(setvisibilityAction(false))
  }, [dispatch])

  return (
    <FusePageCarded
      content={
        <section className="flex flex-col h-full relative">
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <TabContext value={tabValue}>
                <Tabs
                  value={tabValue}
                  onChange={setTabValue}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab value={COURSES_TAB_VALUES.courses} label={COURSES_TAB_LABELS.courses} />
                  <Tab
                    value={COURSES_TAB_VALUES.announcements}
                    label={COURSES_TAB_LABELS.announcements}
                  />
                </Tabs>
              </TabContext>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className={tabValue === '' ? 'hidden' : 'flex justify-end px-4 pt-2 gap-1'}
              sx={{ marginTop: { xs: '10px', md: '0px' } }}
            >
              <Filters>
                <NewFilter />
                <FilterSearchButtom />
              </Filters>
              <FilterIcon />
            </Grid>
          </Grid>
          {modalView[tabValue]}
        </section>
      }
    />
  )
}
