import {


    MOSTRAR_RYO_ACCION,
    MOSTRAR_RYO_ACCION_EXITO,
    MOSTRAR_RYO_ACCION_ERROR,

    CAMBIAR_ESTADO_RYO_ACCION,
    CAMBIAR_ESTADO_RYO_ACCION_EXITO,
    CAMBIAR_ESTADO_RYO_ACCION_ERROR,


    INSERTAR_RYO_ACCION,
    INSERTAR_RYO_ACCION_EXITO,
    INSERTAR_RYO_ACCION_ERROR,

    UPDATE_RYO_ACCION,
    UPDATE_RYO_ACCION_EXITO,
    UPDATE_RYO_ACCION_ERROR,

    RESET_STATES_RYO_ACCION,
    RESET_STATES_RYO_ACCION_EXITO,
    RESET_STATES_RYO_ACCION_ERROR,

    DELETE_RYO_ACCION,
    DELETE_RYO_ACCION_EXITO,
    DELETE_RYO_ACCION_ERROR,

    CAMBIAR_VALOR_SELECCION_ACTIONS,

    CONSULTA_RM_REGISTRO_MODAL_INSERTAR,
CONSULTA_RM_REGISTRO_MODAL_INSERTAR_EXITO,
CONSULTA_RM_REGISTRO_MODAL_INSERTAR_ERROR,

    MOSTRAR_MY_ACTIONS_RYO_ACCION,
MOSTRAR_MY_ACTIONS_RYO_ACCION_EXITO,
MOSTRAR_MY_ACTIONS_RYO_ACCION_ERROR,

CHANGE_EDIT_MODE,
SET_MY_ACTIONS,
SET_NOT_MY_ACTIONS,
SET_ACTIONS_STRUCTURE


} from './types';



// cada reducer tiene su propio state
const initialState = {
    myActionsList: [],
    notMyActionsList: [],

    actionsIcanSee: [], myActions: [], notMyActions: [],
    actionsICanSeeNoRev: [], myActionsNoRev: [], notMyActionsNoRev: [],
    actionsICanSeeRev: [], myActionsRev: [], notMyActionsRev: [],



    visibilidad: false,
    visibilidadNuevaAccion: false,
    rmAccionesListAPI: [],
    rmRegistrosListAPI: [],
    editMode: false,
    filaSeleccionada: '',
    guardarCambios: false,
    visibilidadModalInsertar: false,
    visibilidadModalEditar: false,
    visibilidadModalExito: false,
    error: null,
    accionCreada: '',
    loading: false
}

export default function(state = initialState, action) {

    switch(action.type) {

        //********** DELETE RYO_ACCION **********************************/


        case DELETE_RYO_ACCION:
            return {
                ...state,
                loading: action.payload
            }

        case DELETE_RYO_ACCION_EXITO:
            return {
                ...state,
                loading: false,
                error: null

            }

        case DELETE_RYO_ACCION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }


     //********** MOSTRAR RYO_ACCION API**********************************/


        case MOSTRAR_RYO_ACCION:
            return {
                ...state,
                loading: action.payload
            }

        case MOSTRAR_RYO_ACCION_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                rmAccionesListAPI: action.payload

            }

        case MOSTRAR_RYO_ACCION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }


    //**********CAMBIAR ESTADO RYO_TASKS **********************************/

            case CAMBIAR_ESTADO_RYO_ACCION:
                return {
                    ...state,
                    loading: action.payload
                }

            case CAMBIAR_ESTADO_RYO_ACCION_EXITO:
                return {
                    ...state,
                    loading: false,
                    [action.nombre]: action.payload

                }

            case CAMBIAR_ESTADO_RYO_ACCION_ERROR:
                return {
                    ...state,
                    loading: false,
                    error: action.payload

                }




     //********** INSERTAR RYO_ACCION **********************************/


         case INSERTAR_RYO_ACCION:
            return {
                ...state,
                loading: action.payload
            }

        case INSERTAR_RYO_ACCION_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                accionCreada: action.payload

            }

        case INSERTAR_RYO_ACCION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

    //********** UPDATE RYO_ACCION **********************************/


         case UPDATE_RYO_ACCION:
            return {
                ...state,
                loading: action.payload
            }

        case UPDATE_RYO_ACCION_EXITO:
            return {
                ...state,
                loading: false,
                error: null

            }

        case UPDATE_RYO_ACCION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }


      //**********  RESET_STATES_RYO_ACCION **********************************


      case RESET_STATES_RYO_ACCION:
        return {
            ...state,
            loading: action.payload
        }

    case RESET_STATES_RYO_ACCION_EXITO:
        return {
            ...initialState,
            loading: false,
            error: null,


        }

    case RESET_STATES_RYO_ACCION_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }

        case CAMBIAR_VALOR_SELECCION_ACTIONS:
            return{
                ...state,
                filaSeleccionada: action.payload
            }

        case CHANGE_EDIT_MODE:
            return{
                ...state,
                editMode:action.payload
            }

        case SET_MY_ACTIONS:
            return{
                ...state,
                myActionsList:action.payload
            }

        case SET_NOT_MY_ACTIONS:
            return{
                ...state,
                notMyActionsList:action.payload
            }
        case SET_ACTIONS_STRUCTURE:
            const {
                actionsIcanSee, myActions, notMyActions,
                actionsICanSeeNoRev, myActionsNoRev, notMyActionsNoRev,
                actionsICanSeeRev, myActionsRev, notMyActionsRev
            } = action.payload
            return{
                ...state,
                actionsIcanSee, myActions, notMyActions,
                actionsICanSeeNoRev, myActionsNoRev, notMyActionsNoRev,
                actionsICanSeeRev, myActionsRev, notMyActionsRev
            }






        default:
            return state

    }

}
